import InputStyling from '../../../../styling/InputStyling.js';
import TextStyling from '../../../../styling/TextStyling.js';
import ResponsiveValues from '../../../../styling/ResponsiveValues.js';
import KeywordBubble from '../KeywordBubble.js';

const ForumNew = () => {
  return (
    <div className='w-full flex flex-row justify-center'>
      <div className="min-w-[90vh] max-w-[90vh] flex flex-col">

        <>
          <label
            className={`mt-1 ${TextStyling.inputLabel}`}
            htmlFor="Topic Title">
            Topic Title *
          </label>
          <input
            className={`mt-1 ${InputStyling.text}`}
            type="text"
          />
        </>

        <>
          <label
            className={`mt-8 ${TextStyling.inputLabel}`}
            htmlFor="Description">
            Description *
          </label>
          <textarea
            className={`mt-1 ${InputStyling.text}`}
            type="text"
            rows={5}
          />
        </>

        <>
          <label
            className={`mt-8 ${TextStyling.inputLabel}`}
            htmlFor="Tags">
            Tags
          </label>
          <div
            className={`mt-1 
            ${InputStyling.textNoSpacing}
            flex flex-row justify-start items-start
          `}
          >
            <KeywordBubble title={'Dev'} />
            <KeywordBubble title={'Client'} />
            <KeywordBubble title={'Professional Work'} />
          </div>
        </>

        <div className='mt-8 flex flex-row justify-end gap-2'>
          <button
            className={ResponsiveValues.buttonSquareFitSecondary}
            onClick={() => { }}
          >
            Cancel
          </button>

          <button
            className={ResponsiveValues.buttonSquareFit}
            onClick={() => { }}
          >
            Create Post
          </button>
        </div>

      </div>
    </div>

  )
}

export default ForumNew;