import React from "react";
import { useNavigate } from "react-router-dom";

function RegistrationSuccess() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center font-bold bg-white">
      <div className="flex gap-4 justify-center px-4 mt-36 max-w-full text-3xl tracking-tight leading-9 text-blue-900 w-[527px] max-md:flex-wrap max-md:mt-10">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fdc93f5a88803f7af429338757948c2b1fec08e80bd25281147c1fc081cdfc49?"
          className="w-10 shrink-0 aspect-square"
        />
        <div className="my-auto max-md:max-w-full">
          Thank you for your registration
        </div>
      </div>
      <div className="mt-6 text-base font-light leading-6 max-w-[500px] text-center text-neutral-600 max-md:max-w-full">
        Thank you for signing up here at Full ID Check. Please check your email for the
        confirmation of this registration.
      </div>
      <button onClick={() => {
        navigate('/');
      }} className="justify-center py-2.5 pr-14 pl-14 mt-20 text-lg font-semibold text-white bg-blue-900 rounded-3xl max-md:px-8 max-md:mt-10">
        Go back to homepage
      </button>
    </div>
  );
}

export default RegistrationSuccess;
