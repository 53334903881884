import { authAxios } from "../../../config/common";

const getUserInfo = async (id) => {
  try {
    const response = await authAxios().get(`/api/user/${id}/messages`);
    return response;
  } catch (error) {
    throw error;
  }
};



const messagesApi = {
  getUserInfo,
};

export default messagesApi;