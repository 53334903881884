import { authAxios } from "../../../../../config/common";

const get = async () => {
	try {
		const response = await authAxios().get(`/api/profile/skills`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (data) => {
	try {
		const response = await authAxios().post(`/api/profile/skills`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const del = async (skillsId) => {
	try {
		const response = await authAxios().delete(`/api/profile/skills/${skillsId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const skillsApi = {
	get,
	post,
	del,
};

export default skillsApi;