import React, { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { MdUploadFile } from "react-icons/md";
import useUploadDocument from "../../api/useUploadDocument";
import FacialRecognition from "./FacialRecognition";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../../components/Loader";
import UploadDocumentIDs from "./UploadDocumentIDs";
import UploadDocumentBill from "./UploadDocumentBill";
import UploadDocumentSelfie from "./UploadDocumentSelfie";
import UploadDocumentSuccess from "./UploadDocumentSuccess";
import LoadingIndicator from "../../components/LoadingIndicator";
import SuccessIndicator from "../../components/SuccessIndicator";
import FailureIndicator from "../../components/FailureIndicator";

function UploadDocuments() {
  const {
    isModalOpen,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isCheckboxChecked2,
    setIsCheckboxChecked2,
    openModal,
    closeModal,
    fileListID,
    fileListUtilityBill,
    fileListSelfie,
    onFileIdDrop,
    onFileUtilityBillDrop,
    onFileSelfieDrop,
    removeSelectedFileID,
    removeUtilityBillFile,
    removeSelfieFile,
    handleNextBtn,
    openFacialRecognition,
    qrCodeDataUser,
    handleBackBtn,
    docType,
    isModalOpen2,
    setFileUploadError,
    resetUploads
  } = useUploadDocument();

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);

  const [isFadingID, setIsFadingID] = useState(false);
  const [isFadingDOC, setIsFadingDOC] = useState(false);
  const [isFadingSP, setIsFadingSP] = useState(false);

  const [cmpError, setCmpError] = useState(null);
  const [iShouldRedirect, setIShouldRedirect] = useState(false);

  const [showScreen, setShowScreen] = useState(0);
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    resetUploads();
  }, []);


  useEffect(() => {
    setIsFading(true);

    setTimeout(() => {
      setIsFading(false);
    }, 15000);

    if (isFadingID && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingID(false);
      }, 7000);
    }

    if (isFadingDOC && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingDOC(false);
      }, 7000);
    }

    if (isFadingSP && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingSP(false);
      }, 7000);
    }

    const uData = JSON.parse(localStorage.getItem('user'));
    if (!auth.loading && +uData.is_document_uploaded === 1) {
      setIShouldRedirect(true);
    }

  }, [auth.error, auth.file.upload.error, auth.loading]);

  useEffect(() => {
    setShowNotif(true);
  }, [auth.file.upload.error, auth.file.delete.error, cmpError, auth.error,]);

  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/upload-documents"
  );

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  if (iShouldRedirect || openFacialRecognition) {
    return <Navigate to="/register/face-recognition" />;
  }

  return (
    <>
      {(auth.file.upload.loading || auth.file.delete.loading || isModalOpen2 === true) &&
        <Loader loadingText={"Processing..."} isOpen={true} />
      }

      {(showNotif && auth.file.upload.error) && (
        <FailureIndicator message={auth.file.upload.error} setShowNotif={setShowNotif} />
      )}

      {(showNotif && auth.file.delete.error) && (
        <FailureIndicator message={auth.file.delete.error} setShowNotif={setShowNotif} />
      )}

      {auth.loading && (
        <Loader loadingText={"Please wait while we are verifying your documents..."} isOpen={true} />

      )}
      {showNotif && auth.error && (
        <FailureIndicator message={auth.error + " A"} setShowNotif={setShowNotif} />
      )}

      {showNotif && cmpError && (
        <FailureIndicator message={cmpError + " B"} setShowNotif={setShowNotif} />
      )}


      {!openFacialRecognition && (
        <>
          {/* Utility Bill */}
          {showScreen === 0 &&
            <UploadDocumentBill
              onFileDrop={(e) => { onFileUtilityBillDrop(e) }}
              fileList={fileListUtilityBill}
              onSubmit={() => {
                setShowScreen(1);
              }}
              onDelete={removeUtilityBillFile}
            />
          }

          {/* IDs */}
          {showScreen === 1 &&
            <UploadDocumentIDs
              onFileDrop={(e) => { onFileIdDrop(e) }}
              fileList={fileListID}
              setError={setCmpError}
              onSubmit={() => {
                setShowScreen(2);
              }}
              onDelete={removeSelectedFileID}
            />
          }

          {/* selfie */}
          {showScreen === 2 &&
            <UploadDocumentSelfie
              onFileDrop={(e) => { onFileSelfieDrop(e) }}
              fileList={fileListSelfie}
              onSubmit={() => {

                if (fileListUtilityBill.length === 0) {
                  setCmpError("Please upload utility billing document");
                  return;
                }

                if (fileListID.length < 2) {
                  setCmpError("Please upload 2 valid ids");
                  return;
                }

                if (fileListSelfie.length === 0) {
                  setCmpError("Please upload your selfie photo");
                  return;
                }

                setShowScreen(3);
                handleNextBtn();

              }}
              onDelete={removeUtilityBillFile}
            />
          }

          {/* Alerts */}
          {(showScreen === 3 && UploadDocumentSuccess === true) && <UploadDocumentSuccess />}


          {/* {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              className="modal max-w-[700px] m-auto h-full flex flex-col justify-center items-center"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
              <>
                <div className="flex flex-col px-9 py-9 mx-auto bg-white rounded-2xl shadow-lg max-w-[599px] max-md:px-5">
                  <div className="text-lg font-bold leading-7 tracking-tight text-blue-900 max-md:max-w-full">
                    Terms & Condition and Honesty and Transparency
                  </div>
                  <div className="flex flex-col p-4 mt-5 border border-solid rounded-2xl border-zinc-200 max-md:max-w-full">
                    <div className="text-sm font-bold leading-5 tracking-tight text-black text-opacity-80 max-md:max-w-full">
                      Terms & Condition
                    </div>
                    <div className="mt-2 text-sm font-light leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
                      Integer vitae nisl sit amet odio elementum ultrices in
                      eget sem. Nunc consectetur pellentesque metus mattis
                      venenatis. Integer vitae nisl sit amet odio elementum
                      ultrices in eget sem. Nunc consectetur pellentesque metus
                      mattis venenatis. Integer vitae nisl sit amet odio
                      elementum ultrices in eget sem. Nunc consectetur
                      pellentesque metus mattis venenatis. Integer vitae nisl
                      sit amet odio elementum ultrices in eget sem. Nunc
                      consectetur pellentesque metus mattis venenatis. Integer
                      vitae nisl sit amet odio elementum ultrices in eget sem.
                      Nunc consectetur pellentesque metus mattis venenatis.
                      Integer vitae nisl sit amet odio elementum ultrices in
                      eget sem. Nunc consectetur pellentesque metus mattis
                      venenatis.Integer vitae nisl sit amet odio elementum
                      ultrices in eget sem. Nunc consectetur pellentesque metus
                      mattis venenatis. Integer vitae nisl sit amet
                    </div>
                  </div>
                  <div className="flex justify-center gap-2 mt-4 max-md:flex-wrap">
                    <div className="flex flex-col self-start justify-center">
                      <input
                        type="checkbox"
                        checked={isCheckboxChecked2}
                        name=""
                        id=""
                        className="w-8 mt-1 rounded-md shrink-0 bg-zinc-300"
                        onChange={() =>
                          setIsCheckboxChecked2(!isCheckboxChecked2)
                        }
                      />
                    </div>
                    <div className="flex-1 text-base tracking-tight leading-6max-md:max-w-full">
                      I have read the accepted all
                      <span className="text-sky-600">
                        {" "}
                        Terms & Condition and Honesty and Transparency
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-center gap-4 py-4 mt-2 text-base font-semibold text-white px-11 whitespace-nowrap max-md:flex-wrap max-md:px-5">
                    <div
                      onClick={closeModal}
                      className="flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer"
                    >
                      Close
                    </div>
                    <div
                      onClick={closeModal}
                      className="flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer"
                    >
                      Decline
                    </div>
                    <div
                      onClick={() => {
                        closeModal();
                        setIsCheckboxChecked(true);
                      }}
                      disabled={!isCheckboxChecked2}
                      className={`${isCheckboxChecked2 ? "opacity-100" : "opacity-80"
                        } flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer`}
                    >
                      Accept
                    </div>
                  </div>
                </div>
              </>
            </Modal>
          )} */}
        </>
      )}

    </>
  );
}

export default UploadDocuments;
