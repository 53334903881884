import ForumTopic from "./ForumTopic";
import ForumReply from "./ForumReply";
import { MdSearch } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";
import ResponsiveValues from "../../../../styling/ResponsiveValues";

const ForumTopicMain = () => {
  return (
    <div className="w-full flex flex-row justify-center">
      <div className="min-w-[100vh] max-w-[100vh]">
        <div className="flex flex-col gap-4">

          {/* Search bar */}
          <div className="relative w-full">
            <MdSearch size={20} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 " />
            <input
              type="text"
              placeholder="Search Anything..."
              className="w-full pl-8 pr-4 py-2 flex justify-start items-center gap-2 text-neutral-600 font-semibold bg-opacity-10 rounded-lg border border-neutral-300"
            />
          </div>

          {/* Main Topic */}
          <div className="py-8 border-b">
            <ForumTopic />
          </div>

          <div className="mt-4">
            <button
              className={`flex flex-row items-center gap-2
                  ${ResponsiveValues.buttonSquareFitSecondary}
                  font-light
                  `}
            >
              <MdOutlineAdd /> Add Comment
            </button>
          </div>

          <ForumReply>
            <ForumReply />
            <ForumReply >
              <ForumReply />
              <ForumReply />
            </ForumReply>
          </ForumReply>


        </div>
      </div >
    </div >
  )
}

export default ForumTopicMain