import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formDataHelper from "../../../../../utils/formDataHelper";
import skillsApi from "./skillsApi";

export const getSkills = createAsyncThunk('/skills/get', async ({ }, { rejectWithValue }) => {
	try {
		const response = await skillsApi.get();
		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addSkill = createAsyncThunk('/skills/add', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await skillsApi.post(formData);

		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const removeSkill = createAsyncThunk('/skills/remove', async ({ skillId }, { rejectWithValue }) => {
	try {
		const response = await skillsApi.del(skillId);

		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const skillsSlice = createSlice({
	name: 'skills',
	initialState: {
		success: false,
		loading: false,
		error: null,
		skills: [],
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
		setSkills: (state, action) => {
			state.skills = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// get Skill
			.addCase(getSkills.pending, (state) => {
				state.loading = true;
				state.error = null;
			}).addCase(getSkills.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getSkills.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.skills = action?.payload
			})
			// addSkill
			.addCase(addSkill.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addSkill.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addSkill.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// removeSkill
			.addCase(removeSkill.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(removeSkill.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(removeSkill.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
	}
});

export const skillsAction = skillsSlice.actions;
export default skillsSlice;