import { authAxios } from "../../../../../config/common";

const getAll = async () => {
	try {
		const response = await authAxios().get(`/api/profile/educations`);
		return response;
	} catch (error) {
		throw error;
	}
};

const get = async (educationAndCertificationId) => {
	try {
		const response = await authAxios().get(`/api/profile/educations/${educationAndCertificationId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (data) => {
	try {
		const response = await authAxios().post(`/api/profile/educations`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const put = async (educationAndCertificationId, data) => {
	try {
		const response = await authAxios().post(`/api/profile/educations/${educationAndCertificationId}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const del = async (educationAndCertificationId) => {
	try {
		const response = await authAxios().delete(`/api/profile/educations/${educationAndCertificationId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const educationAndCertificationsApi = {
	getAll,
	get,
	post,
	put,
	del,
};

export default educationAndCertificationsApi;