import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  compileDocuments,
  resetDocuments,
  deleteDocument,
  personalInfo,
  qrCode,
  uploadDocument,
} from "../feature/authSlice";
import { setFileUploadError } from "../feature/authSlice";

const useUploadDocument = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openFacialRecognition, setOpenFacialRecognition] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxChecked2, setIsCheckboxChecked2] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [UploadDocumentSuccess, setUploadDocumentSuccess] = useState(false);

  const dispatch = useDispatch();
  const [docType, setDocType] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [responseMessage, setResponseMessage] = useState({
    id: "",
    utils: "",
    selfie: "",
  });
  const [fileListID, setFileListID] = useState([]);
  const [fileListUtilityBill, setFileListUtilityBill] = useState([]);
  const [fileListSelfie, setFileListSelfie] = useState([]);
  const [qrCodeDataUser, setQrCodeDataUser] = useState("");

  const extractIdsIfNotEmpty = (fileList) => {
    if (fileList.length > 0) {
      return fileList.map((item) => item.id);
    }
    return [];
  };

  const uniqueIds = () => {
    const idIds = extractIdsIfNotEmpty(fileListID);
    const utilityBillIds = extractIdsIfNotEmpty(fileListUtilityBill);
    const selfieIds = extractIdsIfNotEmpty(fileListSelfie);
    const allIds = [...idIds, ...utilityBillIds, ...selfieIds];

    return new Set(allIds);
  };

  const onFileDrop = async (
    e,
    type,
    fileListID,
    setFileListID,
    fileListUtilityBill,
    setFileListUtilityBill,
    fileListSelfie,
    setFileListSelfie
  ) => {
    setDocType(type);

    const droppedFile = e.target.files[0];

    if (!droppedFile) return;

    const { name } = droppedFile;
    const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

    // const formData = new FormData();
    // formData.append("name", fileNameWithoutExtension);
    // formData.append("file", droppedFile);
    // formData.append("type", type);

    const formData = {
      // name: fileNameWithoutExtension,
      // file: droppedFile,
      // type: type,
      document: droppedFile,
    }

    const { id } = JSON.parse(localStorage.getItem("user")) || { id: 0 };
    await dispatch(uploadDocument({ id, data: { ...formData } })).then(
      (data) => {
        console.log(`uploadDocument  data: ${JSON.stringify(data)}`);

        if (data?.payload?.message === "User File Uploaded") {
          const getResponseId = data?.payload?.data?.id;
          const fileType = data?.payload?.data?.type || 'none';
          const combineParameter = { file: droppedFile, id: getResponseId, type: fileType };

          switch (type) {
            case "VALID_ID":
              setFileListID([...fileListID, combineParameter]);
              break;
            case "DOCUMENT":
              setFileListUtilityBill([
                ...fileListUtilityBill,
                combineParameter,
              ]);

              break;
            case "SELFIE_PHOTO":
              setFileListSelfie([...fileListSelfie, combineParameter]);
              break;
            default:
              break;
          }
        }
      }
    );
  };

  const removeSelectedFile = async (
    index,
    type,
    fileListID,
    setFileListID,
    fileListUtilityBill,
    setFileListUtilityBill,
    fileListSelfie,
    setFileListSelfie,
    uploadId
  ) => {
    setDocType(type);

    const { id } = JSON.parse(localStorage.getItem("user")) || { id: 0 };
    await dispatch(deleteDocument({ id, data: { id: uploadId } })).then(
      (data) => {
        if (data?.payload?.message === "User File Removed") {
          const setMessage = (message) => {
            switch (type) {
              case "VALID_ID":
                setResponseMessage({ id: message });
                setFileListID(fileListID.filter((_, i) => i !== index));
                break;
              case "DOCUMENT":
                setResponseMessage({ utils: message });
                setFileListUtilityBill(
                  fileListUtilityBill.filter((_, i) => i !== index)
                );
                break;
              case "SELFIE_PHOTO":
                setResponseMessage({ selfie: message });
                setFileListSelfie(fileListSelfie.filter((_, i) => i !== index));
                break;
              default:
                break;
            }
          };

          setMessage(data?.payload?.message);
        }
      }
    );
  };

  const onFileIdDrop = async (e) => {
    onFileDrop(
      e,
      "VALID_ID",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const onFileUtilityBillDrop = async (e) => {
    onFileDrop(
      e,
      "DOCUMENT",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const onFileSelfieDrop = async (e) => {
    onFileDrop(
      e,
      "SELFIE_PHOTO",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const removeSelectedFileID = async (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };

  const removeUtilityBillFile = (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };

  const removeSelfieFile = (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };

  const handleNextBtn = async () => {
    if (
      fileListID.length === 0 ||
      fileListUtilityBill.length === 0 ||
      fileListSelfie.length === 0
    ) {
      setResponseMessage({
        id: fileListID.length === 0 ? "Please upload your valid ID" : "",
        utils:
          fileListUtilityBill.length === 0
            ? "Please upload your utility bill"
            : "",
        selfie: fileListSelfie.length === 0 ? "Please upload your selfie" : "",
      });
      return;
    }

    _compileDocuments();
  };

  const _compileDocuments = () => {
    setIsModalOpen2(true);
    const allIds = Array.from(uniqueIds());

    const { id } = JSON.parse(localStorage.getItem('user')) || { id: 0 };
    dispatch(compileDocuments({ id, data: { ids: allIds } })).then(data => {
      if (data?.payload?.message === 'Uploaded Documents validation success!' || data?.payload?.message === 'User Already Enrolled') {

        const uData = JSON.parse(localStorage.getItem('user'));
        if (uData) {
          uData.is_document_uploaded = 1;
          localStorage.setItem('user', JSON.stringify(uData));

          dispatch(personalInfo({ id, data: { is_document_uploaded: 1 } }));
        }
        qrCoderequest();
        setOpenFacialRecognition(true);
        setUploadDocumentSuccess(true);
      }

      setIsModalOpen2(false);
    });
  };

  const resetUploads = () => {
    dispatch(resetDocuments({})).then(data => { });
  };

  const handleBackBtn = () => {
    setOpenFacialRecognition(false);
  };

  const qrCoderequest = async () => {
    const { id } = JSON.parse(localStorage.getItem('user')) || { id: 0 };
    await dispatch(qrCode({ id, data: { used_for: 'APPLICANT' } })).then(data => {
      if (!data?.payload?.message) {
        const qrCodeData = data.payload;
        setQrCodeDataUser(qrCodeData);
      }
    });
  };

  return {
    isModalOpen,
    setIsModalOpen,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isCheckboxChecked2,
    setIsCheckboxChecked2,
    openModal,
    closeModal,
    responseMessage,
    setResponseMessage,
    setFileListID,
    fileListID,
    setFileListUtilityBill,
    fileListUtilityBill,
    setFileListSelfie,
    fileListSelfie,
    onFileDrop,
    removeSelectedFile,
    onFileIdDrop,
    onFileUtilityBillDrop,
    onFileSelfieDrop,
    removeSelectedFileID,
    removeUtilityBillFile,
    removeSelfieFile,
    handleNextBtn,
    openFacialRecognition,
    qrCodeDataUser,
    handleBackBtn,
    setOpenFacialRecognition,
    docType,
    isModalOpen2,
    setFileUploadError,
    UploadDocumentSuccess,
    resetUploads
  };
};

export default useUploadDocument;
