import React from "react";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import check from "../../assets/check.png";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";

function ApplicationSent() {
  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/application-sent"
  );

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <div className={`${ResponsiveValues.minHeight} flex flex-row justify-center pt-12 md:p-24 `}>
      <div className={`flex flex-col items-center gap-4 ${ResponsiveValues.cardScreenStyle} w-fit h-fit`}>

        <div className={`flex flex-row justify-center gap-4 ${TextStyling.pageBodyBold}`}>
          <img
            alt="img"
            loading="lazy"
            src={check}
            className="w-10 shrink-0 aspect-square "
          />
          <div className="my-auto">Application Sent</div>
        </div>

        <div className={` ${TextStyling.pageBody}`}>
          Please check your inbox and confirm your email to continue with your
          registration.
        </div>

        <div className={`"max-md:max-w-full" 
            ${TextStyling.note}`}>
          Note: If you do not see the email in your inbox, please check your
          spam or junk folder.
        </div>

      </div>
    </div>
  );
}

export default ApplicationSent;
