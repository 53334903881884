import ForumCategories from "./ForumCategories";
import ForumItemCard from "./ForumItemCard";
import { FaRegBookmark, FaPlus } from "react-icons/fa6";
import { MdSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ForumMain = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-row">

      <div className="max-w-[30vh]">
        <button className="min-w-[30vh] m-4 px-4 py-2 flex justify-start items-center gap-2 text-neutral-600 font-semibold bg-opacity-10 rounded-lg border border-neutral-300">
          <FaRegBookmark />
          Saved Posts
        </button>
        <div> <ForumCategories /></div>
      </div>

      <div className="w-full m-4 pl-4 flex flex-col">
        <div className="relative">
          <MdSearch size={20} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 " />
          <input
            type="text"
            placeholder="Search Anything..."
            className="w-full pl-8 pr-4 py-2 flex justify-start items-center gap-2 text-neutral-600 font-semibold bg-opacity-10 rounded-lg border border-neutral-300"
          />
        </div>

        <div className="w-full mt-8 flex flex-row justify-between">
          <select
            className="max-w-[30vh] justify-center items-start p-2 focus:outline-blue-900 w-full text-base text-neutral-400 rounded-lg border border-solid border-neutral-300 max-md:pr-5 max-md:max-w-full"
            name="" id="">
            <option value="default">Most Recent</option>
          </select>

          <button
            className="w-fit flex flex-row gap-2 items-center justify-center items-start px-4 py-2 focus:outline-blue-900 text-base text-white bg-[#1C3775] rounded-lg border border-solid border-neutral-300"
            onClick={() => {
              navigate("/dashboard/forum-new");
            }}
          >
            <FaPlus size={12} /> New Post
          </button>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <ForumItemCard />
          <ForumItemCard />
          <ForumItemCard />
          <ForumItemCard />
          <ForumItemCard />
        </div>

      </div>
    </div>
  )
}

export default ForumMain;