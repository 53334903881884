import { useEffect, useState } from "react";

const useInspectRoute = (route) => {
  
  const uData = JSON.parse(localStorage.getItem('user'));
  const twoFactored = localStorage.getItem('two_factor');
  const [shouldRedirect,setShouldRedirect] = useState(false);
  const [redirectTo,setRedirectTo] = useState(false);

  useEffect(() => {

    if(uData){
      if(+uData.is_verified !== 1){
        if(route !== '/register/application-sent'){
          setShouldRedirect(true);
          setRedirectTo('/register/application-sent');
        }
      }else if(+uData.is_profile_info_done === 0){
        if(route !== '/register/personal-information'){
          setShouldRedirect(true);
          setRedirectTo('/register/personal-information');
        }
      }else if(+uData.is_paid === 0){
        if(route !== '/register/process-payment'){
          setShouldRedirect(true);
          setRedirectTo('/register/process-payment');
        }
      }else if(+uData.is_document_uploaded === 0){
        if(route !== '/register/upload-documents'){
          setShouldRedirect(true);
          setRedirectTo('/register/upload-documents');
        }
      }else if(+uData.is_face_recognized === 0){
        if(route !== '/register/face-recognition'){
          setShouldRedirect(true);
          setRedirectTo('/register/face-recognition');
        }
      }else if(+uData.is_interviewing === 0){
        if(route !== '/register/schedule-interview'){
          setShouldRedirect(true);
          setRedirectTo('/register/schedule-interview');
        }
      }else{
        if(uData.registration_status !== 'APPROVED'){
          if(route !== '/register/registration-success' && route !== '/register'){
            setShouldRedirect(true);
            setRedirectTo('/register/registration-success');
          }
        }else if(!twoFactored){
          setShouldRedirect(true);
          setRedirectTo('/login');
        }
      }
    }

  },[]);
  
  return {
    shouldRedirect,
    redirectTo
  };

};

export default useInspectRoute;
