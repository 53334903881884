import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo } from '../../../../authSlice';

import { multiImageCheck } from '../../../../../utils/imageCheck';
import isValidImageUrl from '../../../../../utils/isValidImageUrl';
import usePortfolioValidation from './usePortfolioValidation';

import { portfolioAction as action } from './portfolioSlice';
import { getPortfolio, addPortfolio, updatePortfolio, removePortfolio, getPortfolioImage, addPortfolioImage, removePortfolioImage } from './portfolioSlice';

const usePortfolio = () => {
  const dispatch = useDispatch();
  const { success, error, loading, values, images } = useSelector(state => state.portfolios);
  const { validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif, validateTitle, validateMessage, validateDescription, } = usePortfolioValidation();


  const [displayed, setDisplayed] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false);

  const postPortfolioDefaultValues = {
    title: "",
    description: "",
    // message: "Message",
    // thumbnail: null,
    is_featured: 0,
    images: []
  };
  const [postPortfolio, setPostPortfolio] = useState(postPortfolioDefaultValues);

  const postImageDefaultValue = {
    src: "",
    file: null,
  };
  const [postImages, setPostImages] = useState([]);
  const [myError, setMyError] = useState('');


  useEffect(() => {
    clearInputFields();
    displayAddButton();
    getData();
  }, []);

  const stringPortfolio = JSON.stringify(values);
  useEffect(() => {
    setDisplayed(values);
  }, [values, stringPortfolio]);

  useEffect(() => {
    if (error !== null) {
      setDisplayed(values);
    }
  }, [error, values]);

  const clearInputFields = async () => {
    setPostPortfolio(postPortfolioDefaultValues);
    setPostImages([]);
  }

  const displayAddButton = () => {
    setShowAddButton(true);
    setShowEditButton(false);
  }

  const displayUpdateButton = () => {
    setShowAddButton(false);
    setShowEditButton(true);
  }

  const getData = async () => {
    await dispatch(getPortfolio({}));
  }

  const getImageData = async (portfolioId) => {
    try {
      await dispatch(getPortfolioImage({ portfolioId: portfolioId }));

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const addImage = async (event) => {
    const result = await multiImageCheck({ event: event, mb: 2, setError: setMyError });

    if (result?.length > 0) {
      result.forEach((item, index) => {
        const { image: thisImage, file } = item;
        setPostImages(prevPostImages => [
          ...prevPostImages,
          {
            src: thisImage.src,
            file: file,
          }
        ]);
        setPostPortfolio({ ...postPortfolio, thumbnail: file });
      });
    }
  };

  const removeImageFromArray = (image, indexToRemove) => {
    setPostImages(prevPostImages =>
      prevPostImages.filter((_, index) => index !== indexToRemove)
    );
  }

  const handleAddButton = async () => {
    try {
      const promises = await Promise.all([
        validateTitle(postPortfolio.title),
        validateDescription(postPortfolio.description),
      ]);

      if (isSafeToSubmit) {
        const result = await dispatch(addPortfolio({ data: postPortfolio }));
        const newRecord = JSON.parse(result?.payload).data;

        if (newRecord && newRecord.id) {
          const projectPromises = await addImagesToPortfolio(newRecord.id, postImages);
          await Promise.all(projectPromises);
        }

        getData();
        clearInputFields();
      }

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const addImagesToPortfolio = async (newlyAddedPortfolioId, images) => {
    try {
      const projectRequests = images.map(async (image, index) => {
        if (image !== null) {
          const result = await dispatch(addPortfolioImage({ portfolioId: newlyAddedPortfolioId, data: { image: image.file, } }));
          return JSON.parse(result?.payload).data;
        }
      });
      return projectRequests;
    } catch (error) {
      throw error;
    }
  }

  const handleRemoveButton = async (intId) => {
    try {
      // const newDisplayed = displayed.filter((item) => item.id !== intId);
      // setDisplayed(newDisplayed);
      await dispatch(removePortfolio({ portfolioId: intId }));
      getData();

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleEditButton = async (intId) => {
    try {
      displayUpdateButton();
      clearInputFields();
      setUpdateId(intId);

      await rePopulateForEditing(displayed, intId);

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const rePopulateForEditing = async (items, updateId) => {
    const recordForUpdate = await items.filter((item) => item.id === updateId)[0];
    await setPostPortfolio(recordForUpdate);
    await setPostImages([{
      src: recordForUpdate?.thumbnail_path,
      file: null,
    }]);
  }

  const handleUpdateButton = async () => {
    try {

      const promises = await Promise.all([
        validateTitle(postPortfolio.title),
        // validateMessage(postPortfolio.message),
        validateDescription(postPortfolio.description)

      ]);

      if (isSafeToSubmit) {
        let data = {
          title: postPortfolio.title,
          description: postPortfolio.description,
        };

        if (postPortfolio.thumbnail && !isValidImageUrl(postPortfolio.thumbnail)) {
          data = { ...data, thumbnail: postPortfolio.thumbnail };
        }

        await dispatch(updatePortfolio({ portfolioId: updateId, data: data }));
        getData();
        clearInputFields();
        displayAddButton();
      }

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleFeaturedUpaded = async (data, boolValue) => {
    try {
      // updatedDisplayedIsFeatured(data.id, boolValue);
      const formData = {
        title: data.title,
        is_featured: boolValue ? 1 : 0,

      };
      await dispatch(updatePortfolio({ portfolioId: data.id, data: formData }));
      getData();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const updatedDisplayedIsFeatured = (intId, boolValue) => {
    let updatedDisplayed = displayed.map(item => {
      if (item.id === intId) {
        return { ...item, is_featured: boolValue ? 1 : 0 };
      }
      return item;
    });

    setDisplayed(updatedDisplayed);
  }

  return {
    success, error, loading,
    displayed,
    showAddButton, showEditButton,
    handleAddButton, handleRemoveButton, handleEditButton, handleUpdateButton,
    postPortfolio, postImages, setPostPortfolio, setPostImages, setShowNotif,
    getImageData, addImage, removeImageFromArray, handleFeaturedUpaded,
    validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateMessage, validateDescription,
  }

}

export default usePortfolio;
