import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../../../authSlice";
import { imageCheckSquare } from "../../../../../utils/imageCheck";
import dateHelper from "../../../../../utils/dateHelper";
import { getAllEducationAndCertifications, getEducationAndCertifications, addEducationAndCertifications, updateEducationAndCertifications, removeEducationAndCertifications } from "./educationAndCertificationsSlice";
import { educationAndCertificationsAction } from "./educationAndCertificationsSlice";
import isValidImageUrl from "../../../../../utils/isValidImageUrl";
import useEducationAndCertificationsValidation from "./useEducationAndCertificationsValidation";

const useEducationAndCertifications = () => {
	const dispatch = useDispatch();
	const { success, error, loading, values } = useSelector(state => state.educationAndCertifications);
	const { validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateSchool, validateCourse, validateStarted, validateGraduated } = useEducationAndCertificationsValidation();

	const userData = useSelector(state => state.auth.user);
	const [displayedEducationAndCertifications, setDisplayedEducationAndCertifications] = useState([]);

	const postEducationAndCertificationsDefaultValues = {
		name: "",
		//type: "Education", // "Type must be either \"Education\" or \"Certificate\"" - Removed in new API
		degree_or_course: "",
		date_started: "",
		date_graduated: "",
		thumbnail: "",
	};
	const [postEducationAndCertifications, setPostEducationAndCertifications] = useState(postEducationAndCertificationsDefaultValues);
	const [updateId, setUpdateId] = useState(0);


	const thumbnailDefaultValue = {
		src: "",
		file: null,
	};
	const [thumbnail, setThumbnail] = useState(thumbnailDefaultValue);
	const [myError, setMyError] = useState('');

	const [showAddButton, setShowAddButton] = useState(true);
	const [showEditButton, setShowEditButton] = useState(false);


	useEffect(() => {
		clearInputFields();
		displayAddButton();
		getData();
	}, []);

	const stringeducationAndCertificationsJson = JSON.stringify(values);
	useEffect(() => {
		setDisplayedEducationAndCertifications(values);
	}, [stringeducationAndCertificationsJson]);

	useEffect(() => {
		if (error !== null) {
			setDisplayedEducationAndCertifications(values);
		}
	}, [error, stringeducationAndCertificationsJson]);


	const getData = async () => {
		await dispatch(getAllEducationAndCertifications({}));
	}


	const handleImageUpload = (event) => {

		const result = imageCheckSquare(event, 2, setMyError);

		if (result) {
			const { image: thisImage, file } = result;
			setThumbnail({
				src: thisImage.src,
				file: file,
			});
			setPostEducationAndCertifications({ ...postEducationAndCertifications, thumbnail: file });
		}
	};

	const clearInputFields = async () => {
		setPostEducationAndCertifications(postEducationAndCertificationsDefaultValues);
		setThumbnail(thumbnailDefaultValue);
	}

	const handleAddRecord = async () => {
		try {
			const promises = await Promise.all([
				validateSchool(postEducationAndCertifications.name),
				validateCourse(postEducationAndCertifications.degree_or_course),
				validateStarted(postEducationAndCertifications.date_started),
				validateGraduated(postEducationAndCertifications.date_graduated)
			]);

			if (isSafeToSubmit) {
				const result = await dispatch(addEducationAndCertifications({ data: postEducationAndCertifications }));
				const newRecord = JSON.parse(result?.payload).data;
				getData();
				clearInputFields();
			}

		} catch (err) {
			await dispatch(educationAndCertificationsAction.setError(`Error: ${err}`));
		}

	}

	const handleRemoveButton = async (intId) => {
		try {

			const newdDsplayedEducationAndCertifications = displayedEducationAndCertifications.filter((item) => item.id !== intId);
			setDisplayedEducationAndCertifications(newdDsplayedEducationAndCertifications);

			await dispatch(removeEducationAndCertifications({ educationAndCertificationId: intId }));
			getData();

		} catch (err) {
			await dispatch(educationAndCertificationsAction.setError(`Error: ${err}`))
		}
	}

	const handleEditButton = async (intId) => {
		displayUpdateButton();
		clearInputFields();

		setUpdateId(intId);
		await rePopulateForEditing(displayedEducationAndCertifications, intId);

	}

	const displayAddButton = () => {
		setShowAddButton(true);
		setShowEditButton(false);
	}

	const displayUpdateButton = () => {
		setShowAddButton(false);
		setShowEditButton(true);
	}

	const rePopulateForEditing = async (items, updateId) => {
		const recordForUpdate = await items.filter((item) => item.id === updateId)[0];
		await setPostEducationAndCertifications(recordForUpdate);
		await setThumbnail({
			src: recordForUpdate?.thumbnail_path,
			file: null,
		});
	}

	const handleUpdateButton = async () => {

		try {
			const promises = await Promise.all([
				validateSchool(postEducationAndCertifications.name),
				validateCourse(postEducationAndCertifications.degree_or_course),
				validateStarted(postEducationAndCertifications.date_started),
				validateGraduated(postEducationAndCertifications.date_graduated)
			]);

			if (isSafeToSubmit) {
				let data = {
					name: postEducationAndCertifications.name,
					// type: postEducationAndCertifications.type,
					degree_or_course: postEducationAndCertifications.degree_or_course,
					date_started: dateHelper.isDatetimeChecker(postEducationAndCertifications.date_started) ? dateHelper.datetimeToInputDate(postEducationAndCertifications.date_started) : postEducationAndCertifications.date_started,
					date_graduated: dateHelper.isDatetimeChecker(postEducationAndCertifications.date_graduated) ? dateHelper.datetimeToInputDate(postEducationAndCertifications.date_graduated) : postEducationAndCertifications.date_graduated,
				};
				if (postEducationAndCertifications.thumbnail && !isValidImageUrl(postEducationAndCertifications.thumbnail)) {
					data = { ...data, thumbnail: postEducationAndCertifications.thumbnail };
				}

				await dispatch(updateEducationAndCertifications({ educationAndCertificationId: updateId, data: data }));
				getData();
				clearInputFields();
				displayAddButton();
			}

		} catch (err) {
			await dispatch(educationAndCertificationsAction.setError(`Error: ${err}`));
		}
	}

	return {
		userData,
		success, error, loading,
		showAddButton, showEditButton, displayAddButton, displayUpdateButton,
		displayedEducationAndCertifications, setDisplayedEducationAndCertifications,
		postEducationAndCertifications, setPostEducationAndCertifications,
		thumbnail, setThumbnail,
		handleImageUpload,
		handleAddRecord, handleRemoveButton, handleEditButton, handleUpdateButton,
		validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateSchool, validateCourse, validateStarted, validateGraduated
	}

}

export default useEducationAndCertifications