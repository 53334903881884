import React, { useState } from "react";
import BannerProfile from "../../components/Dashboard/BannerProfile";
import SidebarDesktop from "../../components/Dashboard/SidebarDesktop";
import useEarnings from "../../feature/dashboard/earningsAndReferrals/useEarnings";
import ShareButton from "../../components/Dashboard/ShareButton";
import CopyToClipboard from "../../utils/CopyToClipboardHelper";
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";
import { MdContentCopy, MdShare } from "react-icons/md";
import ResponsiveValues from "../../styling/ResponsiveValues";
import PageTitle from "../../components/PageTitle";
import TruncateText from "../../utils/TruncateText.js";

function EarningAndReferrals() {
  const {
    success, error, loading,
    copied, setCopied,
    userData, earningsData,
  } = useEarnings();

  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div className="min-h-[80vh]">
      <div className="">
        <BannerProfile
          data={userData}
        />
        <div className="flex flex-col mt-6">
          <div className={`${TextStyling.pageBody} font-semibold`}>
            Refer and Earn
          </div>
          <div className="flex justify-start items-center gap-2">
            <div className={`${ResponsiveValues.forMobileScreen} ${InputStyling.text} w-fit`}>
              <TruncateText text={earningsData.referral_link || ''} maxLength={30} />
            </div>
            <div className={`${ResponsiveValues.forDesktopScreen} ${InputStyling.text} w-fit`}>
              <TruncateText text={earningsData.referral_link || ''} maxLength={60} />
            </div>
            <div className="flex gap-2 text-sky-600">
              <button
                className={`p-2.5 flex flex-row items-center border rounded-md border-blue-400 ${copied ? 'bg-blue-200' : 'bg-blue-100'}`}
                onClick={() => {
                  CopyToClipboard(earningsData.referral_link + "&t=c");
                  setCopied(!copied);
                }}
              >
                <MdContentCopy />
              </button>
              <ShareButton url={earningsData.referral_link + "&t=s"} text='' />
            </div>
          </div>

          <div className={`mt-8 ${ResponsiveValues.cardSectionStyle} shadow-lg`}>

            <div className="border-b border-neutral-200">
              <div className="pb-3 flex flex-col justify-between items-start">
                <PageTitle title='My Referrals'
                  hasSpacing='false'
                  customTextStyleDesktop={`${TextStyling.pageHeader2} text-neutral-500`}
                  customTextStyleMobile={`${TextStyling.pageHeader2} text-neutral-500`}
                />
              </div>
            </div>

            <div className="flex md:gap-4 py-6 items-center max-md:flex-wrap border-b border-neutral-200">
              <div className="w-full md:w-fit flex gap-2  py-6 items-center justify-evenly md:justify-start">
                <div className="flex-1 md:flex-none py-4 px-2 md:px-4 flex flex-col justify-center items-center bg-[#E5E7EB] border rounded-lg border-neutral-500">
                  <div className={`${TextStyling.pageBody} flex justify-center`}>
                    Referrals
                  </div>
                  <div className="text-2xl font-bold leading-7 text-[#0082BA]">
                    {earningsData.referrals || 0}
                  </div>
                </div>

                <div className="flex-1 md:flex-none py-4 px-2 md:px-4 flex flex-col justify-center items-center bg-[#E5E7EB] border rounded-lg border-neutral-500">
                  <div className={`${TextStyling.pageBody} flex justify-center`}>
                    Badge Draw
                  </div>
                  <div className="text-2xl font-bold leading-7 text-[#0082BA]">
                    {earningsData.badge_draw_amount || 0}
                  </div>
                </div>

                <div className="flex-1 md:flex-none py-4 px-2 md:px-4 flex flex-col justify-center items-center bg-[#E5E7EB] border rounded-lg border-neutral-500">
                  <div className={`${TextStyling.pageBody} flex justify-center`}>
                    Total
                  </div>
                  <div className="text-2xl font-bold leading-7 text-[#0082BA]">
                    {earningsData.referral_amount || 0}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-fit p-4 flex flex-col justify-center items-center bg-[#0082BA] border rounded-lg border-neutral-500">
                <div className={`w-full flex justify-center text-md tracking-tight text-white max-md:max-w-full`}>
                  Total Earned
                </div>
                <div className="text-2xl font-bold leading-7 text-white">
                  $ {earningsData.total_earned || 0}
                </div>
              </div>
            </div>

            <div className={`w-full mt-4 ${TextStyling.pageBodyBold}`}>
              Terms and Conditions
            </div>
            <div className={`w-full mt-4 ${TextStyling.pageBody}`}>
              rem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
              lobortis nulla, accumsan posuere dolor. Sed congue leo eros, nec
              tempor leo rutrum id. Donec lacinia lacus nec neque eleifend
              vestibulum. Donec vel porta purus, ac dignissim purus. Cras maximus
              nec tellus quis auctor. Nulla dignissim elit ut dui porttitor, a
              viverra dolor pellentesque. Curabitur vitae tincidunt neque. Proin
              et efficitur felis, at viverra turpis. Praesent blandit semper nisl
              eu dictum. Pellentesque in augue quis erat dapibus pellentesque a
              non quam. Vestibulum neque nunc, dignissim ornare mi vitae,
              ultricies feugiat elit. Maecenas orci est, pharetra sed dui vitae,
              consectetur finibus felis. Cras eget est tincidunt, imperdiet ligula
              non, pharetra mauris. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Mauris lectus mauris, tincidunt ut fermentum eu,
              egestas mollis dui.

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default EarningAndReferrals;
