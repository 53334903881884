import { useEffect, useState } from "react";
import { FaRegCircle, FaCircle } from "react-icons/fa6";
import ResponsiveValues from "../../styling/ResponsiveValues"
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";
import FileUploadedInfo from "./FileUploadedInfo";

const UploadDocumentBill = ({ onFileDrop = null, fileList = [], onSubmit = null, onDelete = null, setError = null, }) => {


  useEffect(() => {
    console.log(`fileList 1: ${JSON.stringify(fileList)}`);
  }, [fileList,]);

  return (
    <div className={`w-full flex flex-row justify-center`}>
      <div className={`${ResponsiveValues.maxWidthCard} ${ResponsiveValues.minWidthCard}  
        ${ResponsiveValues.cardScreenStyle} min-h-[85vh]`}>

        {/* Circles progress indicator */}
        <div className="flex flex-row justify-center items-center gap-2">
          <FaRegCircle size={12} className="text-[#0082BA] font-bold" />
          <FaRegCircle size={12} className="text-neutral-400 font-bold" />
          <FaRegCircle size={12} className="text-neutral-400 font-bold" />
          <FaRegCircle size={12} className="text-neutral-400 font-bold" />
          {/* <FaCircle size={12} className="text-[#0082BA]" /> */}
        </div>

        {/* Title */}
        <div className="mt-4 ">
          <span className={`${TextStyling.pageHeader1}`}>Additional Document Requirements</span>
        </div>

        {/* Conent 1 */}
        <div className="mt-4 ">
          <span className={`${TextStyling.pageBodyBold}`}>Please upload the following:</span>
          <ul className={`list-disc pl-8 ${TextStyling.pageBody}`}>
            <li>Any 2 valid government ID documents</li>
            <li>Amenity Bill</li>
          </ul>
        </div>

        {/* Conent 2 - main content */}
        <div className="mt-8">
          <span className={`${TextStyling.pageHeader2}`}>Billing Statement</span>
          <br />
          <span className={`${TextStyling.pageBody}`}>Upload your billing statement</span>
          <br />
          <br />
          <span className={`${TextStyling.note}`}>Note: Statement must not be more than 30 days</span>

          <div className={`mt-1 relative hover:opacity-80 ${InputStyling.text}`}>
            <div className={`py-12 flex flex-col justify-center items-center text-center`}>
              <span className={TextStyling.pageBody}>Drop or Upload your documents here</span>
              <span className={TextStyling.note}>(jpg, png, pdf)</span>
            </div>
            <input
              type="file"
              value=""
              onChange={(e) => {
                onFileDrop(e);
              }}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
        </div>

        {/* Uploaded Files */}
        <div className="my-4 grid grid-cols-2 gap-4">
          {fileList.length > 0 && fileList.map((item, i) => {

            const filename = item.file.name;
            const index = filename.lastIndexOf('.');
            const type = index !== -1 ? filename.substring(index + 1) : 'File';

            // if (item.type !== "BILLING") {
            return <FileUploadedInfo
              fileName={item.file.name}
              fileType={type}
              fileSize={
                `${(item.file.size / (1024 * 1024)).toFixed(2)} mb`
              }
              onDelete={() => { onDelete(i, "DOCUMENT", item.id) }}
            />
            // }
          }

          )}
        </div>

        {/* Spacer */}
        <div className="border-b border-neutral-200" />

        {/* Button */}
        <button
          onClick={onSubmit}
          className={`${ResponsiveValues.buttonFull}`}
          disabled={fileList.some(item => item.type === "BILLING") ? false : true}
        >
          Continue
        </button>
      </div>
    </div >
  )
}
export default UploadDocumentBill;

