import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import TextStyling from "../../../styling/TextStyling";


const MessagesMenu = ({ toggleTab, setToggleTab }) => {
  const navigate = useNavigate();

  return (
    <div className={`flex cursor-pointer ${TextStyling.note} mb-0`}>
      <div
        className={`p-2 border-2 border-transparent ${toggleTab === 1 && "border-b-[#1C3775] text-[#1C3775] "
          }`}
        onClick={() => {
          setToggleTab(1);
          navigate("/dashboard/messages?tab=fullcheckid");
        }}
      >
        Full ID Check
      </div>
      <div
        className={`p-2 border-2 border-transparent ${toggleTab === 2 && "border-b-[#1C3775] text-[#1C3775]  "
          }`}
        onClick={() => {
          setToggleTab(2);
          navigate("/dashboard/messages?tab=theregister");
        }}
      >
        The Register
      </div>
      <div
        className={`p-2 border-2 border-transparent ${toggleTab === 3 && "border-b-[#1C3775] text-[#1C3775]  "
          }`}
        onClick={() => {
          setToggleTab(3);
          navigate("/dashboard/messages?tab=admin");
        }}
      >
        Admin
      </div>
    </div>
  )
}
export default MessagesMenu;



export const MessageMenuSmallerScreen = ({ loading, success, error, showNotif, toggleTab, setToggleTab }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`flex flex-cols justify-between ${toggleTab === 1 ? " text-[#1C3775] font-semibold" : "hidden"}`}>
        <div
          className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1`}
          onClick={() => {
            setToggleTab(1);
            navigate("/dashboard/messages?tab=fullcheckid");
          }}
        >
          Full ID Check
        </div>
        <div className="flex items-center ">
          {showNotif && (
            <>
              {loading && <LoadingIndicator loading={loading} styling="text-base" />}
              {!loading && success && <SuccessIndicator message="Update Success" />}
              {!loading && !success && error && <FailureIndicator message={error} />}
            </>
          )}
        </div>
      </div>

      <div className={`flex flex-cols justify-between ${toggleTab === 2 ? " text-[#1C3775] font-semibold" : "hidden"}`}>
        <div
          className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1`}
          onClick={() => {
            setToggleTab(2);
            navigate("/dashboard/messages?tab=fullcheckid");
          }}
        >
          The Register
        </div>
        <div className="flex items-center">
          {showNotif && (
            <>
              {loading && <LoadingIndicator loading={loading} styling="text-base" />}
              {!loading && success && <SuccessIndicator message="Update Success" />}
              {!loading && !success && error && <FailureIndicator message={error} />}
            </>
          )}
        </div>
      </div>

      <div className={`flex flex-cols justify-between ${toggleTab === 3 ? " text-[#1C3775] font-semibold" : "hidden"}`}>
        <div
          className={`pr-8 max-sm:pr-6 border-4 border-transparent py-1`}
          onClick={() => {
            setToggleTab(3);
            navigate("/dashboard/messages?tab=fullcheckid");
          }}
        >
          Admin
        </div>
        <div className="flex items-center">
          {showNotif && (
            <>
              {loading && <LoadingIndicator loading={loading} styling="text-base" />}
              {!loading && success && <SuccessIndicator message="Update Success" />}
              {!loading && !success && error && <FailureIndicator message={error} />}
            </>
          )}
        </div>
      </div>
    </>
  )
}


