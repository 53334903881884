const returnValue = {
  isValid: false,
  errorMessage: null,
}

const stringCountMin = ({ str, intCount }) => {
  const returnVal = returnValue;
  if (str.length >= intCount) {
    returnVal.isValid = true;
    returnVal.errorMessage = null;
  } else {
    returnVal.isValid = false;
    returnVal.errorMessage = `Text should be atleast ${intCount} characters long.`;
  }
  return returnVal;
}

const stringIsNumber = ({ str }) => {
  const returnVal = returnValue;
  if (!isNaN(+str) === true) {
    returnVal.isValid = true;
    returnVal.errorMessage = null;
  } else {
    returnVal.isValid = false;
    returnVal.errorMessage = `Value should be a number`;
  }
  return returnVal;
}

const stringIsAlphaNumeric = ({ str }) => {
  const regex = /^[a-z0-9]+$/i;
  const returnVal = returnValue;

  if (regex.test(str)) {
    returnVal.isValid = true;
    returnVal.errorMessage = null;
  } else {
    returnVal.isValid = false;
    returnVal.errorMessage = 'Value should be numbers or a combination of numbers and letters.';
  }
  return returnVal;
}



const URL = ({ str }) => {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  const returnVal = returnValue;
  if (regex.test(str)) {
    returnVal.isValid = true;
    returnVal.errorMessage = null;
  } else {
    returnVal.isValid = false;
    returnVal.errorMessage = 'Enter a valid URL/Website Link.';
  }
  return returnVal;
}

const email = ({ str }) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const returnVal = returnValue;
  if (regex.test(str)) {
    returnVal.isValid = true;
    returnVal.errorMessage = null;
  } else {
    returnVal.isValid = false;
    returnVal.errorMessage = 'Enter a valid email.';
  }
  return returnVal;
}

const ValidationHelper = {
  returnValue,
  stringCountMin,
  stringIsNumber,
  stringIsAlphaNumeric,
  email,
  URL,
}
export default ValidationHelper;