import { authAxios } from "../../../../../config/common";

const getAll = async () => {
	try {
		const response = await authAxios().get(`/api/profile/languages`);
		return response;
	} catch (error) {
		throw error;
	}
};

const get = async (languageId) => {
	try {
		const response = await authAxios().get(`/api/profile/languages/${languageId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (data) => {
	try {
		const response = await authAxios().post(`/api/profile/languages`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const del = async (languageId) => {
	try {
		const response = await authAxios().delete(`/api/profile/languages/${languageId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const languageApi = {
	getAll,
	get,
	post,
	del,
};

export default languageApi;