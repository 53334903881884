const TextStyling = {
  pageTitle: "my-2 w-full text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full",
  pageSubTitle: "my-2 w-full text-2xl font-bold tracking-tight leading-7 text-black max-md:max-w-full",
  pageHeader1: "my-2 w-full text-xl font-bold tracking-tight leading-7 text-black max-md:max-w-full",
  pageHeader2: "my-2 w-full text-lg font-bold tracking-tight leading-7 text-black max-md:max-w-full",
  pageHeader2NoSpacing: "w-full text-lg font-bold tracking-tight leading-7 text-black max-md:max-w-full",
  pageHeader3: "my-2 w-full text-md font-bold tracking-tight leading-7 text-black max-md:max-w-full",
  pageBodyBold: "my-2 w-full text-base font-bold tracking-tight leading-7 text-neutral-500 max-md:max-w-full",
  pageBodyBoldNoSpacing: "w-full text-base font-bold tracking-tight leading-7 text-neutral-500 max-md:max-w-full",
  pageBodySemiBold: "my-2 w-full text-base font-semibold tracking-tight leading-7 text-neutral-500 max-md:max-w-full",
  pageBody: " w-full text-base tracking-tight text-neutral-500 max-md:max-w-full",
  pageBodyFit: " w-fit text-base tracking-tight text-neutral-500 max-md:max-w-full",
  pageBodyDark: " w-full text-base tracking-tight text-neutral-600 max-md:max-w-full",
  pageBody2: "w-full text-sm tracking-tight text-neutral-500 max-md:max-w-full",

  inputLabel: "text-sm text-neutral-500",
  textLink: "cursor-pointer font-semibold text-[#0082BA]",
  note: "my-2 text-sm text-neutral-400",
  noteNoSpacing: "text-sm text-neutral-400",

  noteSmaller: "my-2 text-xs text-neutral-400",

}

export default TextStyling;