import { authAxios } from "../../../../../config/common";

const get = async () => {
	try {
		const response = await authAxios().get(`/api/profile/awards`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (data) => {
	try {
		const response = await authAxios().post(`/api/profile/awards`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const put = async (honorAndAwardId, data) => {
	try {
		const response = await authAxios().post(`/api/profile/awards/${honorAndAwardId}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const del = async (honorAndAwardId) => {
	try {
		const response = await authAxios().delete(`/api/profile/awards/${honorAndAwardId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const honorsAndAwardsApi = {
	get,
	post,
	put,
	del,
};

export default honorsAndAwardsApi;