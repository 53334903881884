import { authAxios } from "../../../../config/common";

// Full ID Check Messages
// GET | HEAD   api / user / { id } / fullidcheck - messages
// POST         api / user / { id } / fullidcheck - messages
// DELETE       api / user / { id } / fullidcheck - messages
// POST         api / user / { id } / fullidcheck - messages / mark - as - read - unread
// GET | HEAD   api / user / { id } / fullidcheck - messages / { messageId }
// DELETE       api / user / { id } / fullidcheck - messages / { messageId }
// GET | HEAD   api / user / { id } / fullidcheck - messages / { messageId } / replies
// POST         api / user / { id } / fullidcheck - messages / { messageId } / replies
// GET | HEAD   api / user / { id } / fullidcheck - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / fullidcheck - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / fullidcheck - sent--messages
// GET | HEAD   api / user / { id } / fullidcheck - sent - messages
// POST         api / user / { id } / fullidcheck - sent - messages
// POST         api / user / { id } / fullidcheck - sent - messages / mark - as - read - unread
// GET | HEAD   api / user / { id } / fullidcheck - sent - messages / { messageId }
// DELETE       api / user / { id } / fullidcheck - sent - messages / { messageId }
// GET | HEAD   api / user / { id } / fullidcheck - sent - messages / { messageId } / replies
// POST         api / user / { id } / fullidcheck - sent - messages / { messageId } / replies
// GET | HEAD   api / user / { id } / fullidcheck - sent - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / fullidcheck - sent - messages / { messageId } / replies / { replyId }

// POST         api / user / { id } / devreg - messages(same with send & replies)
// payload:     subject, message, receiver_email
// POST         api / user / { id } / devreg - messages / mark - as - read - unread(same with devreg - sent - messages / mark - as - read - unread)
// payload:     ids(i.e '1,2,3,4')
// DELETE       api / user / { id } / devreg - messages(same with devreg - sent - messages)
// payload:     ids(i.e "1,2,3,4")


const get = async (id) => {
  try {
    // const response = await authAxios().get(`/api/user/${id}/fullidcheck-messages`);
    const response = await authAxios().get(`/api/messages/received`, {
      params: {
        platform: 'fullidcheck'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const getConversation = async (conversationId) => {
  try {
    const response = await authAxios().get(`/api/conversations/${conversationId}`, {
      params: {
        platform: 'fullidcheck'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const postMarkAsReadUnread = async (data) => {
  try {
    const response = await authAxios().post(`/api/messages/received/mark-read-unread`, data, {
      params: {
        platform: 'fullidcheck'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const postReply = async (id, messageId, data) => {
  try {
    // const response = await authAxios().post(`/api/user/${id}/fullidcheck-messages/${messageId}/replies`, data);
    const response = await authAxios().post(`/api/messages`, data, {
      params: {
        platform: 'fullidcheck'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
}

const del = async (id, messageId) => {
  try {
    const response = await authAxios().delete(`/api/messages/received/${messageId}`, {
      params: {
        platform: 'fullidcheck'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const delMultiple = async (id, data) => {
  try {
    const response = await authAxios().delete(`/api/messages/received`, data, {
      params: {
        platform: 'fullidcheck'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const fullIdCheckMessagesApi = {
  get, getConversation,
  postMarkAsReadUnread, postReply,
  del, delMultiple,
};

export default fullIdCheckMessagesApi;