import MessageRow from "./messageRow";
import useRegisterMessages from "../../../feature/dashboard/messages/theRegister/useRegisterMessages";
import { MessageTopRow } from "./messageRow";
import { useEffect } from "react";

const MessagesTheRegister = ({ isHovered, setIsHovered, checkedMessage, onClickCheckedBox, setCheckedMessage, setShowMessage, handleOpenMessage }) => {

  const {
    messages, searchedMessages, getAllMessages,
    isCheckedTopBox,
    handleClickAllMessages, handleClickCheckboxSingle, handleCheckboxesSingleValues,
    handleRemoveButton, handleRemoveMultipleButton, handleReadUnReadMultiple
  } = useRegisterMessages();

  return (
    <>
      {searchedMessages?.length > 0 &&
        <MessageTopRow
          isCheckedBox={isCheckedTopBox}
          onClickCheckedBox={() => { handleClickAllMessages() }}
          onClickUnRead={() => { handleReadUnReadMultiple(0) }}
          onClickRead={() => { handleReadUnReadMultiple(1) }}
          onClickTrashBin={() => { handleRemoveMultipleButton() }}
        />
      }
      {searchedMessages?.length > 0 &&
        searchedMessages?.map((item, index) => (
          < MessageRow
            key={index}
            data={item}
            index={index}
            isHovered={isHovered}
            isCheckedBox={handleCheckboxesSingleValues(item)}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(null)}
            onClickMessage={() => {
              setShowMessage(true);
              handleOpenMessage(item);
            }}
            onClickCheckedBox={() => { handleClickCheckboxSingle(item) }}
            onClickTrashBin={handleRemoveButton}
          />
        ))
      }
    </>
  );
}

export default MessagesTheRegister;