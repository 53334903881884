const uploadImageFileUtil = ({ file }) => {
  const { name } = file;
  const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

  const img = new Image();
  const objectUrl = URL.createObjectURL(file);

  img.onerror = () => {
    URL.revokeObjectURL(objectUrl);
    return null;
  };

  img.src = objectUrl;
  return { image: img, name: fileNameWithoutExtension, file: file };
}

const uploadFileUtil = ({ file }) => {
  const { name } = file;
  const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

  const objectUrl = URL.createObjectURL(file);

  // Create an anchor element to test if the file is a valid PDF
  const anchor = document.createElement('a');
  anchor.href = objectUrl;
  anchor.target = '_blank';

  anchor.onerror = () => {
    URL.revokeObjectURL(objectUrl);
    return null;
  };

  return { url: objectUrl, name: fileNameWithoutExtension, file: file };
}


const FileUploadHelper = {
  uploadImageFileUtil,
  uploadFileUtil,
}
export default FileUploadHelper;