import InputStyling from "../../styling/InputStyling"
import TextStyling from "../../styling/TextStyling"
import InputValidationMessage from "../InputValidationMessage"

const CustomInputText = ({
  outerClassName = '',
  className = InputStyling.text,
  label = '',
  placeholder = '',
  name = "",
  value = null,
  onChange = null,
  type = 'text',
  showErrorMessage = false,
  errorMessage = '',
}) => {
  return (
    <div className={`${outerClassName}`}>
      <span className={TextStyling.inputLabel}>
        {label}
      </span>
      <input
        className={`mt-1 ${className}`}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
      <InputValidationMessage showNotif={showErrorMessage} message={errorMessage} />
    </div>
  )
}

export default CustomInputText;

