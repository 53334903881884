// src/components/ShareButton.js
import React, { useState } from 'react';
import ShareModal from './ShareModal';
import { MdOutlineShare } from "react-icons/md";

const ShareButton = ({ url, text, body }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        className="p-2.5 flex flex-row items-center border rounded-md border-blue-400 bg-blue-100"
      >
        <MdOutlineShare />
      </button>
      <ShareModal isOpen={isModalOpen} onClose={closeModal} url={url} text={text} />
    </>
  );
};

export default ShareButton;
