import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formDataHelper from "../../../../../utils/formDataHelper";
import honorsAndAwardsApi from "./honorsAndAwardsApi";


export const getHonorsAndAwards = createAsyncThunk('/honors-and-awards/getAll', async ({ }, { rejectWithValue }) => {
	try {
		const response = await honorsAndAwardsApi.get();
		if (response.status === 200) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addHonorsAndAwards = createAsyncThunk('/honors-and-awards/add', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await honorsAndAwardsApi.post(formData);

		if (response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const updateHonorsAndAwards = createAsyncThunk('/honors-and-awards/update', async ({ honorAndAwardId, data }, { rejectWithValue }) => {
	try {

		const formData = await formDataHelper(data);
		const response = await honorsAndAwardsApi.put(honorAndAwardId, formData);

		if (response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const removeHonorsAndAwards = createAsyncThunk('/honors-and-awards/remove', async ({ honorAndAwardId }, { rejectWithValue }) => {
	try {
		const response = await honorsAndAwardsApi.del(honorAndAwardId);

		if (response.status === 200) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const honorsAndAwardsSlice = createSlice({
	name: 'honorsAndAwards',
	initialState: {
		success: false,
		loading: false,
		error: null,
		values: [],
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// getHonorsAndAwards
			.addCase(getHonorsAndAwards.pending, (state) => {
				state.loading = true;
				state.error = null;
			}).addCase(getHonorsAndAwards.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getHonorsAndAwards.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.values = action?.payload;
			})
			// addHonorsAndAwards
			.addCase(addHonorsAndAwards.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addHonorsAndAwards.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addHonorsAndAwards.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// updateHonorsAndAwards
			.addCase(updateHonorsAndAwards.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(updateHonorsAndAwards.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(updateHonorsAndAwards.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// removeHonorsAndAwards
			.addCase(removeHonorsAndAwards.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(removeHonorsAndAwards.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(removeHonorsAndAwards.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
	}
});

export const honorsAndAwardsAction = honorsAndAwardsSlice.actions;
export default honorsAndAwardsSlice;