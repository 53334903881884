import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeAPI from "./homeAPI";

// Helper function to handle async thunks
const createAsyncThunkWithAPI = (type, apiCall) => {
  return createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const response = await apiCall(args);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  });
};

export const updateReferralClicks = createAsyncThunkWithAPI('/referral-update/clicks', ({ref_code}) => homeAPI.updateReferralClicks(ref_code));
export const updateReferralShares = createAsyncThunkWithAPI('/referral-update/shared', ({ref_code}) => homeAPI.updateReferralShares(ref_code));

// Initial state
const initialState = {
  updateReferralClicks: {
    success: null,
    loading: false,
    error: null,
  },
  updateReferralShares: {
    success: null,
    loading: false,
    error: null,
  },
};

// Reusable reducers for pending, fulfilled, and rejected states with payload conditions
const createReducersWithPayloadConditions = (builder, action, name, successMessages) => {
  builder
    .addCase(action.pending, (state) => {
      state[name].loading = true;
      state[name].error = null;
    })
    .addCase(action.rejected, (state, action) => {
      state[name].loading = false;
      state[name].error = action.payload;
    })
    .addCase(action.fulfilled, (state, action) => {
      state[name].loading = false;
      state[name].error = null;
      const { message } = action.payload || {};

      if (successMessages.includes(message)) {
        state[name].success = message;
        state[name].error = null;
      } else {
          state[name].error = message || null;
      }

    });
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setUpdateReferralClicksError: (state, action) => {
      state.updateReferralClicks.error = action.payload || null;
    },
    setUpdateReferralClicksSuccess: (state, action) => {
      state.updateReferralClicks.success = action.payload || null;
    },
    
    
    setUpdateReferralSharesError: (state, action) => {
      state.updateReferralShares.error = action.payload || null;
    },
    setUpdateReferralSharesSuccess: (state, action) => {
      state.updateReferralShares.success = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    createReducersWithPayloadConditions(builder, updateReferralClicks, 'updateReferralClicks', ['Referral Clicks updated successfully!']);
    createReducersWithPayloadConditions(builder, updateReferralShares, 'updateReferralShares', ['Number of referral shared counts updated successfully!']);
  },
});

export const { 
  setUpdateReferralClicksError,
  setUpdateReferralClicksSuccess,
  setUpdateReferralSharesError,
  setUpdateReferralSharesSuccess,
} = homeSlice.actions;

export default homeSlice.reducer;
