import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendComplaint, sendCompliment, sendFeedback, setFeedbackSuccess } from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import DestopCardScreen from "../../../components/DesktopCardScreen";
import MobileCardScreen from "../../../components/MobileCardScreen";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import TextStyling from "../../../styling/TextStyling";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import useHelp from "../../../feature/dashboard/professionalProfile/settings/help/useHelp";

function Help() {

  const {
    success, error, loading,
    showNotif, setShowNotif,
    helpValue, setHelpValue,
    handleAddButton,
  } = useHelp();

  return (
    <>
      <DestopCardScreen
        title='Contact Admin'
        size='min-w-[100vh] max-w-[100vh]'
        children={
          <>
            <div className=" flex flex-col items-center">

              <div className="justify-center max-w-full">
                {showNotif && loading && (
                  <LoadingIndicator />
                )}
                {showNotif && error && (
                  <FailureIndicator message={error} setShowNotif={setShowNotif} />
                )}
                {showNotif && success &&
                  <SuccessIndicator message="Message Sent" setShowNotif={setShowNotif} />
                }
              </div>

              <div className={`self-start mt-4 ${TextStyling.inputLabel}`}>
                Type of Message
              </div>

              <div className={`w-full mt-1 flex flex-row gap-4 justify-start ${TextStyling.pageBody}`}>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="compliment"
                    name="feebackType"
                    value="Compliment"
                    checked={helpValue.feedback_type === "Compliment"}
                    onChange={(e) =>
                      setHelpValue(prevState => ({
                        ...prevState,
                        feedback_type: e.target.value
                      }))}
                    className="w-4 h-4 bg-teal-400 rounded-full shrink-0"
                  />
                  <label htmlFor="compliment">Compliment</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="feedback"
                    name="feebackType"
                    value="Feedback"
                    checked={helpValue.feedback_type === "Feedback"}
                    onChange={(e) =>
                      setHelpValue(prevState => ({
                        ...prevState,
                        feedback_type: e.target.value
                      }))}
                    className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                  />
                  <label htmlFor="feedback">Feedback</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="complaint"
                    name="feebackType"
                    value="Complaint"
                    checked={helpValue.feedback_type === "Complaint"}
                    onChange={(e) =>
                      setHelpValue(prevState => ({
                        ...prevState,
                        feedback_type: e.target.value
                      }))}
                    className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                  />
                  <label htmlFor="complaint">Complaint</label>
                </div>
              </div>

              <div className={`self-start mt-4 ${TextStyling.inputLabel}`}>
                Message
              </div>

              <textarea
                className={`w-full mt-1 ${ResponsiveValues.cardSectionStyle}`}
                value={helpValue.message}
                placeholder="Message"
                rows={5}
                onChange={(e) => setHelpValue(prevState => ({
                  ...prevState,
                  message: e.target.value
                }))}
              />

              <div className="w-full flex gap-4 justify-end">
                <button
                  className={`${ResponsiveValues.buttonFitSecondary} mt-4 mb-2`}
                  onClick={() => { }}
                >
                  Cancel
                </button>
                <button
                  className={`${ResponsiveValues.buttonFit} mt-4 mb-2`}
                  disabled={helpValue.message.trim() === '' || loading}
                  onClick={handleAddButton}
                >
                  Send to Admin
                </button>
              </div>
            </div>
          </>}
      />

      <MobileCardScreen
        title='Two-factor Recovery Codes'
        children={
          <>
            <div className=" flex flex-col items-center">

              <div className="justify-center max-w-full">
                {loading && (
                  <LoadingIndicator />
                )}
                {error && (
                  <FailureIndicator message={error} setShowNotif={setShowNotif} />
                )}
                {success &&
                  <SuccessIndicator message="Message Sent" setShowNotif={setShowNotif} />
                }
              </div>

              <div className={`self-start ${TextStyling.inputLabel}`}>
                Type of Message
              </div>

              <div className={`w-full mt-1 flex flex-row gap-2 justify-start ${TextStyling.note}`}>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="compliment"
                    name="feebackType"
                    value="Compliment"
                    checked={helpValue.feedback_type === "Compliment"}
                    onChange={(e) => setHelpValue(prevState => ({
                      ...prevState,
                      feedback_type: e.target.value
                    }))}
                    className="w-4 h-4 bg-teal-400 rounded-full shrink-0"
                  />
                  <label htmlFor="compliment">Compliment</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="feedback"
                    name="feebackType"
                    value="Feedback"
                    checked={helpValue.feedback_type === "Feedback"}
                    onChange={(e) => setHelpValue(prevState => ({
                      ...prevState,
                      feedback_type: e.target.value
                    }))}
                    className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                  />
                  <label htmlFor="feedback">Feedback</label>
                </div>
                <div className="flex items-center gap-2"
                >
                  <input
                    type="radio"
                    id="complaint"
                    name="feebackType"
                    value="Complaint"
                    checked={helpValue.feedback_type === "Complaint"}
                    onChange={(e) => setHelpValue(prevState => ({
                      ...prevState,
                      feedback_type: e.target.value
                    }))}
                    className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                  />
                  <label htmlFor="complaint">Complaint</label>
                </div>
              </div>

              <div className={`self-start mt-4 ${TextStyling.inputLabel}`}>
                Message
              </div>

              <textarea
                className={`w-full mt-1 ${ResponsiveValues.cardSectionStyle}`}
                value={helpValue.message}
                placeholder="Message"
                rows={5}
                onChange={(e) => setHelpValue(prevState => ({
                  ...prevState,
                  message: e.target.value
                }))}
              />

              <div className="w-full flex flex-col">
                <button
                  className={`${ResponsiveValues.buttonFull}`}
                  disabled={helpValue.message.trim() === '' || loading}
                  onClick={() => { handleAddButton() }}
                >
                  Send to Admin
                </button>

                <button
                  className={`${ResponsiveValues.buttonFullSecondary}`}
                  onClick={() => { }}
                >
                  Cancel
                </button>

              </div>
            </div>
          </>
        }
      />

    </>
  );
}

export default Help;
