import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import check from "../../assets/check.png";

function ThankyouForPayment() {
  const navigate = useNavigate();
  const handleContinueRegistartion = () => {
    navigate("/register/upload-documents");
  };
  return (
    <>
      <div className={`${ResponsiveValues.minHeight} flex flex-row justify-center pt-12 md:p-24 `}>
        <div className={`flex flex-col items-center gap-4 ${ResponsiveValues.cardScreenStyle} w-fit h-fit`}>

          <div className={`flex flex-row justify-center gap-4 ${TextStyling.pageBodyBold}`}>
            <img
              alt="img"
              loading="lazy"
              src={check}
              className="w-10 shrink-0 aspect-square "
            />
            <div className="my-auto">
              Thank you for your Payment!
            </div>
          </div>

          <div className={`${TextStyling.pageBody}`}>
            Thank you for your payment. Please check your email for
            confirmation of this payment.
          </div>

          <div className="mt-4">
            <button
              onClick={handleContinueRegistartion}
              className={`${ResponsiveValues.buttonFit}`}
            >
              Continue with Registration
            </button>
          </div>
        </div>
      </div>

    </>
  );
}

export default ThankyouForPayment;
