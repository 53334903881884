import { useState, useEffect } from "react";
import { FaRegCircle, FaCircle } from "react-icons/fa6";
import ResponsiveValues from "../../styling/ResponsiveValues"
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";
import FileUploadedInfo from "./FileUploadedInfo";

const UploadDocumentIDs = ({ onFileDrop = null, fileList = null, onSubmit = null, onDelete = null, setError = null }) => {

  const [type, setType] = useState("Driver's License");

  return (
    <div className={`w-full flex flex-row justify-center`}>
      <div className={`${ResponsiveValues.maxWidthCard} ${ResponsiveValues.minWidthCard}  
        ${ResponsiveValues.cardScreenStyle} min-h-[85vh]`}>

        {/* Circles progress indicator */}
        <div className="flex flex-row justify-center items-center gap-2">

          {type === "Driver's License" &&
            <>
              <FaCircle size={12} className="text-[#0082BA]" />
              <FaRegCircle size={12} className="text-[#0082BA] font-bold" />
              <FaRegCircle size={12} className="text-neutral-400 font-bold" />
              <FaRegCircle size={12} className="text-neutral-400 font-bold" />
            </>
          }

          {type === "Passport" &&
            <>
              <FaCircle size={12} className="text-[#0082BA]" />
              <FaCircle size={12} className="text-[#0082BA]" />
              <FaRegCircle size={12} className="text-[#0082BA] font-bold" />
              <FaRegCircle size={12} className="text-neutral-400 font-bold" />
              {/* <FaRegCircle size={12} className="text-neutral-400 font-bold" /> */}
            </>
          }

        </div>

        {/* Title */}
        <div className="mt-8">
          <div className={`${TextStyling.pageHeader2}`}>Government Valid ID</div>
          <div className={`${TextStyling.pageBody}`}>A current and valid <b>(not expired or cancelled)</b> government-issued document that includes the applicant's photo and signature.</div>
        </div>

        {/* Spacer */}
        <div className="mt-4 border-b border-neutral-200" />

        {/* Conent - main content */}
        <div className="mt-6">
          <select
            className={`${InputStyling.select}`}
            name="" id=""
            onChange={(e) => {
              // console.log(`e : ${JSON.stringify(e.target.value)}`);
              setType(e.target.value);
            }}
          >
            <option value="Driver's License">Driver's License</option>
            <option value="Passport">Passport</option>
          </select>
        </div>

        <div className="mt-8">
          <div className={`${TextStyling.noteNoSpacing}`}>Note: India’s Driver License not accepted</div>
          <div className={`mt-1 relative hover:opacity-80 ${InputStyling.text}`}>
            <div className={`py-12 flex flex-col justify-center items-center text-center`}>
              <span className={TextStyling.pageBody}>Upload front of your ID</span>
              <span className={TextStyling.noteNoSpacing}>(jpg, png, pdf)</span>
              <span className={TextStyling.noteNoSpacing}>Image must not be larger than 5mb</span>
            </div>
            <input
              type="file"
              value=""
              onChange={(e) => {
                onFileDrop(e);
              }}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
          {type === "Driver's License" &&
            <div className={`mt-4 relative hover:opacity-80 ${InputStyling.text}`}>
              <div className={`py-12 flex flex-col justify-center items-center text-center`}>
                <span className={TextStyling.pageBody}>Upload back of your ID</span>
                <span className={TextStyling.noteNoSpacing}>(jpg, png, pdf)</span>
                <span className={TextStyling.noteNoSpacing}>Image must not be larger than 5mb</span>
              </div>
              <input
                type="file"
                value=""
                onChange={(e) => {
                  onFileDrop(e);
                }}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              />
            </div>
          }
        </div>

        {/* Uploaded Files */}
        <div className="my-4 grid grid-cols-2 gap-4">
          {fileList.length > 0 && fileList.map((item, i) => {

            const filename = item.file.name;
            const index = filename.lastIndexOf('.');
            const type = index !== -1 ? filename.substring(index + 1) : 'File';

            // if (item.type === "ID-FRONT" || item.type === "ID-BACK") {
            return <FileUploadedInfo
              fileName={item.file.name}
              fileType={type}
              fileSize={
                `${(item.file.size / (1024 * 1024)).toFixed(2)} mb`
              }
              onDelete={() => {
                onDelete(i, "VALID_ID", item.id)
              }}
            />
            // }
          }

          )}
        </div>

        {/* Spacer */}
        <div className="border-b border-neutral-200" />

        {/* Button */}
        {type === "Driver's License" &&
          <button
            // onClick={onSubmit}
            onClick={
              () => {
                console.log("CLICK CLICK CLICK");
                if (fileList < 3) {
                  setError("Please upload Driver's License and Password images.");
                } else {
                  onSubmit();
                }

              }
            }
            className={`${ResponsiveValues.buttonFull}`}
          // disabled={
          //   fileList.some(item => item.type === "ID-FRONT") && fileList.some(item => item.type === "ID-BACK")
          //     ? false : true
          // }
          >
            Continue
          </button>
        }

        {type === "Passport" &&
          <button
            onClick={
              () => {
                if (fileList < 3) {
                  setError("Please upload Driver's License and Password images.");
                } else {
                  onSubmit();
                }

              }
            } className={`${ResponsiveValues.buttonFull}`}
            disabled={
              fileList.some(item => item.type === "ID-FRONT") ? false : true
            }
          >
            Continue
          </button>
        }
      </div>
    </div >
  )
}
export default UploadDocumentIDs;

