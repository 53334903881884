import { useState } from "react";
import TextStyling from "../styling/TextStyling";

const TruncateText = ({ text, maxLength = 100, showMore = false, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength);

  return (
    <>
      <p>
        {displayText}
        {text.length > maxLength && !isExpanded && '...'}
        {" "}
        {(showMore === true && text.length > maxLength) && (
          <button
            onClick={toggleExpansion}
            className={`text-blue-400 hover:underline ${TextStyling.note}`}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </p>
    </>
  );
};

export default TruncateText;