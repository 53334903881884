import { Country, State, City } from 'country-state-city';
import { useEffect, useState } from 'react';

const useCountryCityState = () => {

  const [countries, setCountries] = useState(null);
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);

  const getCountryIsoCode = (countryName) => {
    if (countries !== null && countryName !== null) {
      const country = countries.find((c) => c.name === countryName);
      return country?.isoCode;
    }
    return '';
  }

  const getStateIsoCode = (stateName) => {
    if (states !== null & stateName !== null) {
      const state = states.find((c) => c.name === stateName);
      return state?.isoCode;
    }
    return '';
  }

  const getCityIsoCode = (cityName) => {
    if (cities !== null && cityName !== null) {
      const city = cities.find((c) => c.name === cityName);
      return city?.isoCode;
    }
    return '';
  }

  const populateCountries = () => {
    setCountries(Country.getAllCountries());
  }

  const populateStates = (countryName) => {
    if (countryName !== null && countryName !== "") {
      const isoCode = getCountryIsoCode(countryName);
      setStates(State.getStatesOfCountry(isoCode));
    }
  }

  const populateCities = (countryName, stateName) => {
    if (countryName !== null && countryName !== ""
      && stateName !== null && stateName !== "") {
      const isoCountryCode = getCountryIsoCode(countryName);
      const isoStateCode = getStateIsoCode(stateName);
      setCities(City.getCitiesOfState(isoCountryCode, isoStateCode));
    }
  }


  return {
    countries, cities, states,
    populateCountries, populateCities, populateStates,
  }

}

export default useCountryCityState;


// const {countries, cities, states, populateCountries, populateCities, populateStates, } = useCountryCityState();