import React, { useEffect, useState } from "react";
import useLanguage from "../../../feature/dashboard/professionalProfile/profileEdit/language/useLanguage";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import Bubble from "../../../components/Bubble";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useLanguageValidation from "../../../feature/dashboard/professionalProfile/profileEdit/language/useLanguageValidation";
import InputValidationMessage from "../../../components/InputValidationMessage";
import PageTitle from "../../../components/PageTitle";

function Language() {
  const {
    success, error, loading,
    typed, setTyped,
    displayedLanguages,
    handleOnload,
    handleTyping,
    handleAddbutton,
    handleRemoveButton,
  } = useLanguage();

  const { validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateLanguage, } = useLanguageValidation();

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <>
      <div className="flex flex-col pb-20 max-md:max-w-full">
        <PageTitle title='Add Language' description='Let us know what languages you speak! Adding them to your profile helps you connect with others and makes your experience more personal.' />

        <div className="flex justify-end items-center">
          {
            showNotif && (
              <>
                {loading && (<LoadingIndicator loading={loading} styling="text-base" />)}
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <div className="flex flex-col md:flex-row justify-left items-start px-2 py-1 mt-8 md:mt-2 text-sm leading-4 rounded-md border border-solid border-neutral-400 text-neutral-600 max-md:pr-5 max-md:max-w-full">
          <input
            className="justify-center px-4 py-2 my-2 mx-2 rounded-md bg-neutral-100"
            placeholder="Add Language"
            type="text"
            autoComplete="language"
            value={typed}
            onChange={(event) => {
              handleTyping(event.target.value);
              validateLanguage(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && isSafeToSubmit === true) {
                handleAddbutton(event.target.value);
                setTyped("");
              }
            }}
          />
          <div className="flex flex-row flex-wrap">
            {displayedLanguages &&
              displayedLanguages.map((e, i) => {
                return <Bubble key={i} title={e.language} handleRemove={() => handleRemoveButton(e.id)}></Bubble>
              })
            }
          </div>
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.language.errorMessage} />
      </div>
    </>
  );
}

export default Language;
