import { FaCheck } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";

import TextStyling from "../../styling/TextStyling";

const FileUploadedInfo = ({ fileType = 'File', fileName = 'file.file', fileSize = '1.2mb / 1.2mb', onDelete = null }) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <div className="flex flex-row items-center gap-4">
        <div class="flex items-center justify-center w-12 h-12 aspect-square 
      border border-[#0082BA] rounded-full
      bg-[rgba(0,130,186,0.1)]
      text-[#0082BA] text-center text-sm">
          {fileType}
        </div>

        <div className="flex flex-col justify-center">
          <span className={`${TextStyling.pageBodyBoldNoSpacing}`}>{fileName}</span>
          <span className={`${TextStyling.noteNoSpacing}`}>{fileSize}</span>
        </div>

        <FaCheck size={21} className="text-green-500" />
      </div>
      <button
        onClick={onDelete}
        className="flex flex-col items-start h-full">
        <IoIosClose size={20} className={TextStyling.noteNoSpacing} />
      </button>
    </div>

  )
}

export default FileUploadedInfo;