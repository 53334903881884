import { useDispatch, useSelector } from "react-redux";
import { certificateActions, downloadCertificate } from "./certificatesSlice";
import { useState } from "react";

const useCertificates = () => {
	const dispatch = useDispatch();
	const userData = useSelector(state => state.auth.user);
	const { error, loading } = useSelector(state => state.certificate);
	const [showNotif, setShowNotif] = useState(false);

	const handlesDownloadButton = async () => {
		try {
			await dispatch(downloadCertificate({ id: userData.id }));
		} catch (err) {
			setShowNotif(true);
			await dispatch(certificateActions.setError(`Error: ${err}`))
		}
	}

	return {
		handlesDownloadButton,
		error,
		loading,
		userData,
		showNotif, setShowNotif,
	}

}

export default useCertificates