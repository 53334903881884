import { useState, useEffect } from 'react';
import Debounce from '../../../../../utils/Debounce';
import { DebounceDelay } from '../../../../../utils/Debounce';
import ValidationHelperChain from '../../../../../utils/ValidationHelperChain';

const useOverviewValidation = () => {
  const itemDefaultValue = { isValid: false, errorMessage: [] };
  const validationErrorMessageDefault = {
    image: itemDefaultValue,
    imageProfile: itemDefaultValue,
    akai: itemDefaultValue,
    about: itemDefaultValue,
    yearsOfExperience: itemDefaultValue,
    noProjectsCompleted: itemDefaultValue,
    city: itemDefaultValue,
    state: itemDefaultValue,
    country: itemDefaultValue,
    postalCode: itemDefaultValue,
    profession: itemDefaultValue,
    salaryRate: itemDefaultValue,
    isAvailableToWork: itemDefaultValue,
    availability: itemDefaultValue,
    workSetupPreferences: itemDefaultValue,
    hasTradeLicense: itemDefaultValue,
    tradeLicenseNumber: itemDefaultValue,
  };
  const [validationErrorMessage, setValidationErrorMessage] = useState(validationErrorMessageDefault);
  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState(false);

  const [showNotif, setShowNotif] = useState(true);

  useEffect(() => {
    setIsSafeToSubmit(hasValidationError() === true || validationErrorMessage === validationErrorMessageDefault ? false : true);
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
      }, 15000);
    } else {
      setShowNotif(true);
    }
  }, [strValidationErrorMessage]);

  const hasValidationError = () => {
    return Object.values(validationErrorMessage).some(
      (item) => item.errorMessage?.length > 0
    );
  };

  const setErrorsToEmpty = () => {
    setValidationErrorMessage(validationErrorMessageDefault);
  }

  const validateAkai = Debounce((str) => {
    const validation = ValidationHelperChain()
      .notEmpty({ str: str, })
      .stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, akai: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, akai: { isValid: true, errorMessage: null } }));
    }

  }, DebounceDelay);

  const validateAbout = Debounce((str) => {

    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 10 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, about: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, about: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateYearsOfExperience = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringIsNumber({ str: str });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, yearsOfExperience: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, yearsOfExperience: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateNoProjectsCompleted = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringIsNumber({ str: str });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, noProjectsCompleted: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, noProjectsCompleted: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateCity = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, city: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, city: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateState = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, state: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, state: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateCountry = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, country: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, country: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validatePostalCode = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 }).stringIsAlphaNumeric({ str: str, });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, postalCode: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, postalCode: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateProfession = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, profession: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, profession: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateSalaryRate = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringIsNumber({ str: str });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, salaryRate: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, salaryRate: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateIsAvailableToWork = Debounce((str) => {
    const validation = ValidationHelperChain().selectOption({ str: str, });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, isAvailableToWork: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, isAvailableToWork: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateAvailability = Debounce((str) => {
    const validation = ValidationHelperChain().selectOption({ str: str, });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, availability: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, availability: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateWorkSetupPreferences = Debounce((str) => {
    const validation = ValidationHelperChain().selectOption({ str: str, });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, workSetupPreferences: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, workSetupPreferences: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateHasTradeNumber = Debounce((str) => {
    const validation = ValidationHelperChain().selectOption({ str: str, });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, hasTradeLicense: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, hasTradeLicense: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);

  const validateTradeLicenseNumber = Debounce((str) => {
    const validation = ValidationHelperChain().notEmpty({ str: str, }).stringCountMin({ str: str, intCount: 3 });

    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) {
      setValidationErrorMessage(prevState => ({ ...prevState, tradeLicenseNumber: { isValid: false, errorMessage: errorMessage[0] } }));
    }

    if (hasError === false) {
      setValidationErrorMessage(prevState => ({ ...prevState, tradeLicenseNumber: { isValid: true, errorMessage: null } }));
    }
  }, DebounceDelay);


  return {
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit,
    validateAkai, validateAbout, validateYearsOfExperience, validateNoProjectsCompleted, validateCity,
    validateState, validateCountry, validatePostalCode, validateProfession, validateSalaryRate,
    validateIsAvailableToWork, validateAvailability, validateWorkSetupPreferences,
    validateHasTradeNumber, validateTradeLicenseNumber,
  };
}

export default useOverviewValidation;


// const { validationErrorMessage, validateMessage, isSafeToSubmit } = useOverviewValidation();

{ /* 
  <input
    value={postMessage.message}
    onChange={(e) => {
      setPostMessage({ ...postMessage, message: e.target.value });
      validateMessage(e.target.value);
    }}
  />

  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.message.errorMessage} />

  <button className={`${isSafeToSubmit === true ? '' : 'disabled'}`} onClick={onClickSend}>
    Send
  </button>

  <button className={`${(loading === false) ? '' : 'disabled' }`}
    onClick={() => {
      validateMessage(postMessage.message);
      if (validationErrorMessage.message.isValid) onClickSend();
    }}
  >
*/}