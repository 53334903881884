import React, { useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
function FaqAccordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <div className="accordion md:w-[100%]  min-[766px]:min-w-[730px] max-w-[730px]">
      {items.map((item, index) => (
        <div
          key={index}
          className="accordion-item m-4 cursor-pointer border-b-2  border-[#E3E3E3]"
        >
          <div
            className={`accordion-title ${
              index === activeIndex ? "active" : ""
            } flex justify-between items-center`}
            onClick={() => onTitleClick(index)}
          >
            <div className="text-[#111827] font-semibold text-[16px]   max-sm:text-lg mt-2 mb-6">
              {item.title}
            </div>
            <div className="mt-[-5px]">
              {index === activeIndex ? (
                <MdKeyboardArrowDown size={30} color="#6B7280" />
              ) : (
                <MdKeyboardArrowRight size={30} color="#6B7280" />
              )}
            </div>
          </div>
          {index === activeIndex && (
            <div className="accordion-content text-sm14 text-[#6B7280] max-sm:pr-8 max-w-[90%] mb-10">
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default FaqAccordion;
