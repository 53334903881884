import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formDataHelper from "../../../../../utils/formDataHelper";
import languageApi from "./languageApi";

export const getLanguages = createAsyncThunk('/languages/getAll', async ({ }, { rejectWithValue }) => {
	try {
		const response = await languageApi.getAll();
		if (response.status === 200) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addLanguage = createAsyncThunk('/languages/add', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await languageApi.post(formData);

		if (response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const removeLanguage = createAsyncThunk('/languages/remove', async ({ languageId }, { rejectWithValue }) => {
	try {
		const response = await languageApi.del(languageId);

		if (response.status === 200) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const languageSlice = createSlice({
	name: 'language',
	initialState: {
		success: null,
		loading: false,
		error: null,
		languages: [],
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
		setLanguages: (state, action) => {
			state.languages = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// getLanguages
			.addCase(getLanguages.pending, (state) => {
				state.loading = true;
				state.error = null;
			}).addCase(getLanguages.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getLanguages.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.languages = action?.payload;
			})
			// addLanguage
			.addCase(addLanguage.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addLanguage.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addLanguage.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// removeLanguage
			.addCase(removeLanguage.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(removeLanguage.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(removeLanguage.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
	}
});

export const languageAction = languageSlice.actions;
export default languageSlice;