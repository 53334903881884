import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getOverview, updateOverview, overviewAction as action } from "./overviewSlice";
import useOverviewValidation from "./useOverviewValidation";
import useCountryCityState from "./useCountryCityState";
import imageCheck from "../../../../../utils/imageCheck";

const useOverview = () => {
	const dispatch = useDispatch();

	const { values: overviewData, loading, success } = useSelector((state) => state.overview) || null;

	const { countries, cities, states, populateCountries, populateCities, populateStates, } = useCountryCityState();

	const {
		validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit,
		validateAkai, validateAbout, validateYearsOfExperience, validateNoProjectsCompleted, validateCity,
		validateState, validateCountry, validatePostalCode, validateProfession, validateSalaryRate,
		validateIsAvailableToWork, validateAvailability, validateWorkSetupPreferences,
		validateHasTradeNumber, validateTradeLicenseNumber,
	} = useOverviewValidation();

	const imagesArray = useSelector((state) => state.auth.user?.fileUploads) || [];

	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(overviewData ? overviewData.profile_banner : '');

	const [imageProfile, setImageProfile] = useState(null);
	const [imageProfileFile, setImageProfileFile] = useState('');

	const [currentProfileCover, setCurrentProfileCover] = useState('');
	const [currentProfilePhoto, setCurrentProfilePhoto] = useState('');

	const [akai, setAkai] = useState('');
	const [about, setAbout] = useState('');
	const [yearsOfExperience, setYearsOfExperience] = useState('0');
	const [noProjectsCompleted, setNoProjectsCompleted] = useState('0');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [profession, setProfession] = useState('');
	const [salaryRate, setSalaryRate] = useState('0');
	const [isAvailableToWork, setIsAvailableToWork] = useState('');
	const [availability, setAvailability] = useState('');
	const [workSetupPreferences, setWorkSetupPreferences] = useState('');
	const [hasTradeLicense, setHasTradeLicense] = useState('');
	const [tradeLicenseNumber, setTradeLicenseNumber] = useState('');

	const [updateSuccess, setUpdateSuccess] = useState("");
	const [messagesShow, setMessagesShow] = useState(true);

	const [infoError, setInfoError] = useState(null);
	const [error, setError] = useState(null);
	const [errorProfile, setErrorProfile] = useState(null);

	useEffect(() => {
		getOverviewData();
	}, []);

	useEffect(() => {
		console.log(`success : ${success}`);
	}, [success]);


	useEffect(() => {
		populateCountries();

		if (overviewData !== null) {
			if (overviewData.country !== null & overviewData.country !== '') {
				populateStates(overviewData.country);

				if (overviewData?.state !== null && overviewData?.state !== '') {
					populateCities(overviewData.country, overviewData.state);
				}
			}
		}
		populateFields();
	}, [overviewData]);

	useEffect(() => {
		if (overviewData !== null &&
			overviewData.country !== null &&
			overviewData.country !== '') {

			if (overviewData.country !== country) {
				populateStates(country);
				populateCities('', '');
			} else {
				populateStates(overviewData.country);
			}

		}
	}, [country]);

	useEffect(() => {
		if (overviewData !== null &&
			overviewData.country !== null &&
			overviewData.country !== '' &&
			overviewData.state !== null &&
			overviewData.state !== ''
		) {

			if (overviewData.state !== state) {
				populateCities(country, state);
			} else {
				populateCities(overviewData.country, overviewData.state);
			}

		}
	}, [state]);

	const populateFields = () => {
		if (overviewData !== null) {
			setAkai(overviewData.aka || '');
			setAbout(overviewData.about || '');
			setYearsOfExperience(overviewData.year_of_experience || 0);
			setNoProjectsCompleted(overviewData.projects_completed || 0);
			setCity(overviewData.city || '');
			setState(overviewData.state || '');
			setCountry(overviewData.country || '');
			setPostalCode(overviewData.postal_code || '');
			setProfession(overviewData.profession || '');
			setSalaryRate(overviewData.salary_rate || 0);
			setIsAvailableToWork(overviewData.is_available_to_work || '');
			setAvailability(overviewData.availability || '');
			setWorkSetupPreferences(overviewData.work_setup_preferences || '');

			setCurrentProfileCover(overviewData ? overviewData.profile_banner_path : '');
			setCurrentProfilePhoto(overviewData ? overviewData.profile_photo_path : '');

			setHasTradeLicense(overviewData ? overviewData.has_trade_license : '');
			setTradeLicenseNumber(overviewData ? overviewData.trade_license_number : '')
		}
	}

	const getOverviewData = async () => {
		try {
			const response = await dispatch(getOverview({}));
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const handleImageUpload = (event) => {
		const result = imageCheck(event, 2, 1000, 200, setError);
		if (result) {
			const { image: thisImage, name, file } = result;
			setImage(thisImage.src);
			setImageFile(file);
		}
	};

	const handleImageUploadProfile = (event) => {
		const result = imageCheck(event, 2, 320, 320, setErrorProfile);

		if (result) {
			const { image: thisImage, name, file } = result;

			setImageProfile(thisImage.src);
			setImageProfileFile(file);
		}
	};

	// const imageCheck = (event, mb, widthPx, heightPx, mySetError) => {
	// 	const file = event.target.files[0];
	// 	const { name } = file;
	// 	const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

	// 	mySetError("");

	// 	if (file) {
	// 		// mb size check
	// 		if (file.size > mb * 1024 * 1024) {
	// 			mySetError(`File size should be less than ${mb}MB.`);
	// 			return null;
	// 		}

	// 		const img = new Image();
	// 		const objectUrl = URL.createObjectURL(file);

	// 		img.onload = () => {
	// 			// dimension check
	// 			if (img.width !== widthPx || img.height !== heightPx) {
	// 				mySetError(`Image dimensions should be ${widthPx}x${heightPx} pixels.`);
	// 				URL.revokeObjectURL(objectUrl);
	// 				return null;
	// 			}
	// 		};

	// 		img.onerror = () => {
	// 			mySetError('Invalid image file');
	// 			URL.revokeObjectURL(objectUrl);
	// 			return null;
	// 		};

	// 		img.src = objectUrl;
	// 		return { image: img, name: fileNameWithoutExtension, file: file };
	// 	}
	// }

	const clearImagePlaceholders = () => {
		setImage(null);
		setImageFile(null);

		setImageProfile(null);
		setImageProfileFile(null);
	}

	const handleUpdate = async () => {

		try {

			const promises = await Promise.all([
				validateAkai(akai),
				validateAbout(about),
				validateYearsOfExperience(yearsOfExperience),
				validateNoProjectsCompleted(noProjectsCompleted),
				validateCity(city),
				validateState(state),
				validateCountry(country),
				validatePostalCode(postalCode),
				validateProfession(profession),
				validateSalaryRate(salaryRate),
				validateIsAvailableToWork(isAvailableToWork),
				validateAvailability(availability),
				validateWorkSetupPreferences(workSetupPreferences),
				validateHasTradeNumber(hasTradeLicense),
				// hasTradeLicense === '1' && validateTradeLicenseNumber(tradeLicenseNumber),
			]);

			if (isSafeToSubmit) {
				setUpdateSuccess("");
				const response = await updatePersonalInfo();

				if (response?.payload?.user) {
					setUpdateSuccess(true);
					clearImagePlaceholders();
				} else {
					setInfoError(response.message);
				}

			}

		} catch (err) {
			await dispatch(setError(`Error: ${err}`));
		}

	};

	const updatePersonalInfo = async () => {
		var data = {
			aka: akai ? akai.trim() : '',
			about: about ? about.trim() : '',
			year_of_experience: yearsOfExperience ? yearsOfExperience : '',
			projects_completed: noProjectsCompleted ? noProjectsCompleted : '',
			city: city ? city.trim() : '',
			state: state ? state.trim() : '',
			country: country ? country.trim() : '',
			postal_code: postalCode ? postalCode.trim() : '',
			profession: profession ? profession.trim() : '',
			salary_rate: salaryRate ? salaryRate : '',
			is_available_to_work: isAvailableToWork ? isAvailableToWork : '',
			availability: availability ? availability : '',
			work_setup_preferences: workSetupPreferences ? workSetupPreferences : '',
			profile_banner: imageFile,
			profile_photo: imageProfileFile,
			has_trade_license: hasTradeLicense ? hasTradeLicense : '0',
			trade_license_number: tradeLicenseNumber,
		}

		const response = await dispatch(updateOverview({ id: overviewData.id, data: data }));

		return response;
	}

	return {
		loading, success,
		overviewData,
		imagesArray,
		image, setImage,
		error, setError,
		imageProfile, setImageProfile,
		errorProfile, setErrorProfile,
		messagesShow, setMessagesShow,
		currentProfileCover, setCurrentProfileCover,
		currentProfilePhoto, setCurrentProfilePhoto,
		akai, setAkai,
		about, setAbout,
		yearsOfExperience, setYearsOfExperience,
		noProjectsCompleted, setNoProjectsCompleted,
		city, setCity,
		state, setState,
		country, setCountry,
		postalCode, setPostalCode,
		profession, setProfession,
		salaryRate, setSalaryRate,
		isAvailableToWork, setIsAvailableToWork,
		availability, setAvailability,
		workSetupPreferences, setWorkSetupPreferences,
		hasTradeLicense, setHasTradeLicense, tradeLicenseNumber, setTradeLicenseNumber,
		updateSuccess,
		infoError,
		handleUpdate,
		handleImageUpload,
		handleImageUploadProfile,
		validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit,
		validateAkai, validateAbout, validateYearsOfExperience, validateNoProjectsCompleted, validateCity,
		validateState, validateCountry, validatePostalCode, validateProfession, validateSalaryRate,
		validateIsAvailableToWork, validateAvailability, validateWorkSetupPreferences,
		validateHasTradeNumber, validateTradeLicenseNumber,
		countries, cities, states, populateCountries, populateCities, populateStates,
	};
}

export default useOverview