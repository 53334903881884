import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo } from '../../../../authSlice';
import imageCheck, { imageCheckSquare } from '../../../../../utils/imageCheck';
import isValidImageUrl from '../../../../../utils/isValidImageUrl';
import dateHelper from '../../../../../utils/dateHelper';

import {
  getUser,
  getEducationAndCertifications,
  getFeature,
  getHonorsAndAwards,
  getLanguages,
  getSkills,
  getOverview,
  getPortfolio,
  getSocials,
  getWorkHistory,
  getProjects,
  getFeedbacks,
  addRegisterFeedback,
} from './previewProfileSlice';

import { previewProfileAction as action } from './previewProfileSlice';
import { json } from 'react-router-dom';

const usePreviewProfile = () => {
  const dispatch = useDispatch();
  const {
    success, error, loading,
    user,
    educationAndCertifications,
    feature,
    awards,
    languages,
    skills,
    overview,
    portfolio,
    socials,
    workHistory,
    adminRating,
  } = useSelector(state => state.previewProfile);

  const [featuredProject, setFeaturedProject] = useState(null);
  const [splitKeywords, setSplitKeywords] = useState(null);
  const [workHistories, setWorkHistories] = useState(null);
  const [educationalCert, setEducationalCert] = useState(null);
  const [honors, setHonors] = useState(null);

  const SocialsListDefault = {
    instagram: { name: 'instagram', link: '' },
    facebook: { name: 'facebook', link: '' },
    youtube: { name: 'youtube', link: '' },
    linkedIn: { name: 'linkedin', link: '' },
    discord: { name: 'discord', link: '' },
    tiktok: { name: 'tiktok', link: '' },
  }
  const [socialLinks, setSocialLinks] = useState(SocialsListDefault);

  const [addFeedback, setAddFeedback] = useState('');

  useEffect(() => {
    handleGetRecords();
  }, []);

  useEffect(() => {
    getProfileCover();
    getAllLanguages();
    getFeaturedProject();
    splitKeywordsToArray();
    getWorkHistories();
    getEducationalCert();
    getHonors();
    getSocialLinks();
  }, [user]);

  useEffect(() => {
    splitKeywordsToArray();
  }, []);

  useEffect(() => {
    getProfileCover();
  }, [overview]);

  useEffect(() => {
    getAllLanguages();
  }, [languages]);

  useEffect(() => {
    getFeaturedProject();
  }, [feature]);

  const [runOnce, setrunOnce] = useState(true);
  useEffect(() => {
    getWorkHistories();
    if (workHistory?.length > 0 && runOnce) {
      workHistory.forEach((e) => {
        getWorkHistoryProjects(e.id);
      });
      setrunOnce(false);
    }
  }, [runOnce, workHistory]);

  useEffect(() => {
    getEducationalCert();
  }, [educationAndCertifications]);

  useEffect(() => {
    getHonors();
  }, [awards]);

  useEffect(() => {
  }, [skills]);

  useEffect(() => {
  }, [portfolio]);

  useEffect(() => {
    getSocialLinks();
  }, [socials]);

  useEffect(() => {
    splitKeywordsToArray();
  }, [featuredProject]);


  const handleGetRecords = async () => {
    try {
      await dispatch(getUser({}));
      await dispatch(getEducationAndCertifications({}));
      await dispatch(getFeature({}));
      await dispatch(getHonorsAndAwards({}));
      await dispatch(getLanguages({}));
      await dispatch(getSkills({}));
      await dispatch(getOverview({}));
      await dispatch(getPortfolio({}));
      await dispatch(getSocials({}));
      await dispatch(getWorkHistory({}));
      // await dispatch(getFeedbacks({}));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const getProfileCover = () => {
    try {
      const photos = overview.profile_banner_path;
      return photos;
    } catch (err) {
      return null;
    }
  }

  const getAllLanguages = () => {
    try {

      let langStr = "";
      languages.forEach((item, index) => {

        if (languages.length - 1 === index) {
          // last item
          langStr = `${langStr} and ${item.language}.`;
        } else {
          langStr = `${langStr} ${item.language},`;
        }
      });

      return langStr;

    } catch (err) {
      return null;
    }
  }

  const getFeaturedProject = () => {
    try {
      const project = feature;
      const featuredProject = project[project.length - 1];
      setFeaturedProject(featuredProject);
    } catch (err) {
      return null;
    }
  }

  const splitKeywordsToArray = () => {
    try {
      const keywords = featuredProject.keywords;
      const keywordArray = keywords.split(',');
      setSplitKeywords(keywordArray);
    } catch (err) {
      return null;
    }
  }

  const getWorkHistories = () => {
    try {
      const history = workHistory;
      setWorkHistories(history);
    } catch (err) {
      return null;
    }
  }

  const getWorkHistoryProjects = async (workHistoryId) => {
    try {
      await dispatch(getProjects({ workHistoryId: workHistoryId }));
    } catch (err) {
      return null;
    }
  }

  const getEducationalCert = () => {
    try {
      const ed = user.educationAndCertifications;
      setEducationalCert(ed);
    } catch (err) {
      return null;
    }
  }

  const getHonors = () => {
    try {
      const honorsAndAwards = user.honorsAndAwards;
      setHonors(honorsAndAwards);
    } catch (err) {
      return null;
    }
  }

  const getSocialLinks = async () => {
    try {
      const links = socials;
      const socialLinksObj = socialLinks;

      socialLinksObj.instagram.link = await getSocialLink(await getSocial(links, socialLinksObj.instagram.name));
      socialLinksObj.facebook.link = await getSocialLink(await getSocial(links, socialLinksObj.facebook.name));
      socialLinksObj.discord.link = await getSocialLink(await getSocial(links, socialLinksObj.discord.name));
      socialLinksObj.youtube.link = await getSocialLink(await getSocial(links, socialLinksObj.youtube.name));
      socialLinksObj.linkedIn.link = await getSocialLink(await getSocial(links, socialLinksObj.linkedIn.name));
      socialLinksObj.tiktok.link = await getSocialLink(await getSocial(links, socialLinksObj.tiktok.name));

      setSocialLinks(socialLinksObj);
    } catch (err) {
      return null;
    }
  }

  const getSocialLink = async (social) => {
    return await getLink(social.link);
  }

  const getSocial = async (arry, socialName) => {
    const result = await arry.find(item =>
      item?.social === socialName
    );
    return result;
  }

  const getLink = async (link) => {
    const isHttps = link.includes('https://');
    const isHttp = link.includes('http://');

    if (!isHttps && !isHttp) {
      return `https://${link}`;
    }

    return link;
  }

  const handleAddFeedback = async () => {
    try {
      await dispatch(addRegisterFeedback({ data: { message: addFeedback } }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  return {
    success, error, loading,
    user,
    educationAndCertifications,
    feature,
    awards,
    languages,
    skills,
    overview,
    portfolio,
    socials,
    workHistory,

    getProfileCover,
    getAllLanguages,
    featuredProject,
    splitKeywords,
    workHistories,
    educationalCert,
    honors,
    socialLinks, getSocialLink,
    addFeedback, setAddFeedback, handleAddFeedback, adminRating,
  }

}

export default usePreviewProfile;