import { authAxios, authAxiosPut } from '../../../../../config/common';

const get = async () => {
  try {
    const response = await authAxios().get(`/api/profile/social-links`);
    return response;
  } catch (error) {
    throw error;
  }
};

const post = async (data) => {
  try {
    const response = await authAxios().post(`/api/profile/social-links`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const put = async (socialLinkId, data) => {
  try {
    const response = await authAxios().post(`/api/profile/social-links/${socialLinkId}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const del = async (socialLinkId) => {
  try {
    const response = await authAxios().delete(`/api/profile/social-links/${socialLinkId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const socialLinksApi = {
  get,
  post,
  put,
  del,
};

export default socialLinksApi;