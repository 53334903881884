import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TwoStepVerification from "./TwoStepVerification";
import BacktoLogin from "./BacktoLogin";
import {
  sendOTPViaEmailNoLogin,
  setSendOTPViaEmailNoLoginSuccess,
  resendOTPViaEmailNoLogin,
} from "../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import Loader from "../../components/Loader";
import TopBarLogo from "./TopBarLogo";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";
import { FaRegEnvelope } from "react-icons/fa6";


function ForgotPass() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendOTPViaEmailNoLoginStates = useSelector(
    (state) => state.settings.sendOTPViaEmailNoLogin
  ) || { success: null, error: null, loading: false };
  const [ismodalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (sendOTPViaEmailNoLoginStates.success === "OTP sent") {
      setShowVerifyCode(true);
      dispatch(setSendOTPViaEmailNoLoginSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [sendOTPViaEmailNoLoginStates.success, isFading, dispatch]);

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handleSendEmailBtn = async (e) => {
    e.preventDefault();

    if (!isValidEmail || email.length <= 0) {
      setIsModalOpen(false); // Ensure modal is closed if email is invalid
      return;
    }
    try {
      setIsModalOpen(true);
      await dispatch(sendOTPViaEmailNoLogin({ data: { email } }));
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Optionally handle the error (e.g., show a notification to the user)
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleReSendEmailBtn = async (e) => {
    e.preventDefault();

    if (!isValidEmail || email.length <= 0) {
      setIsModalOpen(false); // Ensure modal is closed if email is invalid
      return;
    }
    try {
      setIsModalOpen(true);
      await dispatch(sendOTPViaEmailNoLogin({ data: { email } }));
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Optionally handle the error (e.g., show a notification to the user)
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {!showVerifyCode ? (
        <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">
          <div className={`max-w-[50vh] max-h-[55vh]
            ${ResponsiveValues.cardScreenStyleNoSpacing}
            flex flex-col grow`}>

            <TopBarLogo children={<BacktoLogin />} logoImg={"https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"} />

            <div className="p-6">
              <div className={`mt-2 ${TextStyling.pageHeader1}`}>
                Forgot Password
              </div>
              <div className={`mt-2 ${TextStyling.pageBody}`}>
                Enter your email and we’ll send you instructions to reset your password
              </div>

              <div className="mt-4 relative w-full">
                <div
                  className="absolute left-3 top-[50%] cursor-pointer "
                >
                  <FaRegEnvelope size={16} color="#BCBCBC" />

                </div>

                <input
                  value={email}
                  onChange={validateEmail}
                  placeholder="Email"
                  className={`mt-4 pl-10 ${InputStyling.text}`}
                />
              </div>

              {!isValidEmail && (
                <div className="mt-1 text-sm text-red-600">
                  Please enter a valid email address.
                </div>
              )}
              <button
                className={`${ResponsiveValues.buttonFull} ${!isValidEmail || sendOTPViaEmailNoLoginStates.loading
                  ? "opacity-50 pointer-events-none"
                  : ""
                  }`}
                onClick={(e) => { handleSendEmailBtn(e) }}
                disabled={!isValidEmail || sendOTPViaEmailNoLoginStates.loading}
              >
                Send to Email
              </button>
              <div className="mt-2 text-sm max-sm:mx-auto max-sm:text-sm max-md:text-md">
                {(sendOTPViaEmailNoLoginStates.loading || resendOTPViaEmailNoLogin.loading) && (
                  <p className="text-left text-blue-600">Processing...</p>
                )}
                {(sendOTPViaEmailNoLoginStates.error || resendOTPViaEmailNoLogin.error) && (
                  <p className="text-left text-red-600">
                    {sendOTPViaEmailNoLoginStates.error || resendOTPViaEmailNoLogin.error}
                  </p>
                )}
              </div>
              <div
                onClick={(e) => { handleReSendEmailBtn(e) }}
                className="mt-3 flex flex-row justify-center items-center gap-2"
              >
                <span className={`${TextStyling.note}`}>Didn’t get the email? </span>
                <span className={`text-sm font-bold text-[#0082BA] cursor-pointer`}>Resend</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TwoStepVerification email={email} />
      )}
      <Loader loadingText={"Sending..."} isOpen={ismodalOpen} />
    </>
  );
}

export default ForgotPass;
