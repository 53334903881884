import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { FormattedTimeComponentWithinTheDayISO8601ToLocal } from "../../../components/FormattedDateComponent";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import TruncateText from "../../../utils/TruncateText.js"

const MessageRow = ({ key, data, index, isHovered, isCheckedBox, onMouseEnter, onMouseLeave, onClickMessage, onClickCheckedBox, onClickEnvelope, onClickTrashBin }) => {

  return (
    <>
      <div className={`${ResponsiveValues.forMobileScreen}`}>
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={key}
          className={`
        ${isHovered === index && "bg-[#EDEDED]"} 
        ${isCheckedBox === true && "bg-blue-900"} 
        ${(data.is_seen && data.is_seen !== 0) && "bg-[#EDEDED]"} 
        cursor-pointer py-2 px-3`}
        >
          <div className="flex flex-cols justify-between items-center cursor-pointer">
            <div className="flex flex-cols">
              {
                isCheckedBox === true
                  ? <div className="flex justify-start items-center">
                    <div className="border border-white">
                      <input
                        className="accent-blue-900 flex justify-center items-center"
                        type="checkbox"
                        name="checkBox"
                        id="checkBox"
                        checked={isCheckedBox}
                        onChange={onClickCheckedBox}
                      />
                    </div>
                  </div>
                  : <div className="flex justify-start">
                    <input
                      value={isCheckedBox}
                      onClick={onClickCheckedBox}
                      type="checkbox"
                      name="checkBox"
                      id="checkBox"
                      className=""
                    />
                  </div>
              }

              <div className={`pl-2 text-left max-w-[300px] font-bold ${isCheckedBox === true && "text-white"} `}
                onClick={onClickMessage}
              >
                {data.sender.fullname}
              </div>
            </div>
            <div className={`text-right font-bold ${isCheckedBox === true && "text-white "}`}>
              <FormattedTimeComponentWithinTheDayISO8601ToLocal datetime={data.created_at} />
            </div>
          </div>
          <div className={`text-left font-bold ${isCheckedBox === true && "text-white"}`}
            onClick={onClickMessage}
          >{data.subject}</div>
          <div
            className={`col-span-4 text-left 
          ${isHovered === index && "text-[#3B3B3B] "} 
          ${isCheckedBox === true && "text-white "}
          `
            }
            onClick={onClickMessage}
          >
            {data.message_content.length > 70
              ? data.message_content.substring(0, 70) + "..."
              : data.message_content}
          </div>
        </div>
      </div>

      <div className={`${ResponsiveValues.forDesktopScreen}`}>
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={key}
          className={`
        ${isHovered === index && "bg-[#EDEDED]"} 
        ${isCheckedBox === true && "bg-blue-900"} 
        ${(data.is_seen && data.is_seen !== 0) && "bg-[#EDEDED]"} 
        py-2 grid grid-cols-10 items-center cursor-pointer md:text-sm lg:text-md`}
        >
          {
            isCheckedBox === true
              ? <div className="col-span-1 flex justify-center">
                <div className=" border border-white	">
                  <input
                    className="accent-blue-900 flex text-center justify-center"
                    type="checkbox"
                    name="checkBox"
                    id="checkBox"
                    checked={isCheckedBox}
                    onChange={onClickCheckedBox}
                  />
                </div>
              </div>
              : <input
                value={isCheckedBox}
                onClick={onClickCheckedBox}
                type="checkbox"
                name="checkBox"
                id="checkBox"
                className="col-span-1 text-left"
              />
          }

          <div className={` col-span-2 text-left max-w-[300px] ${(data.is_seen && data.is_seen !== 0) && "font-bold"}  ${isCheckedBox === true && "text-white"} `}
            onClick={onClickMessage}
          >
            {/* {data.sender.fullname} */}
            <TruncateText text={data.sender.fullname} maxLength={25} showMore={false} />
          </div>
          <div className={` col-span-2 text-left ${(data.is_seen && data.is_seen !== 0) && "font-bold"} ${isCheckedBox === true && "text-white"}`}
            onClick={onClickMessage}
          >
            {/* {data.subject} */}
            <TruncateText text={data.subject} maxLength={25} showMore={false} />

          </div>
          <div className={`col-span-3 text-left 
          ${isHovered === index && "text-[#3B3B3B] "} 
          ${isCheckedBox === true && "text-white "}
          `
          }
            onClick={onClickMessage}
          >
            {/* {data.message_content.length > 70
              ? data.message_content.substring(0, 70) + "..."
              : data.message_content} */}
            <TruncateText text={data.message_content} maxLength={40} showMore={false} />
          </div>
          <div className={`col-span-2 text-right pr-2 ${isCheckedBox === true && "text-white "}`}>
            <div className="flex space-x-2 justify-end items-center">
              {isHovered === index && (
                <>
                  {data.is_seen === 0 &&
                    <FaRegEnvelope
                      onClick={onClickEnvelope}
                    />
                  }
                  {data.is_seen !== 0 &&
                    <FaRegEnvelopeOpen
                      onClick={onClickEnvelope}
                    />
                  }
                  <FaRegTrashCan
                    onClick={() => { onClickTrashBin(data.id) }}
                  />
                </>
              )}
              <div onClick={onClickMessage} className={`pl-2 ${(data.is_seen && data.is_seen !== 0) && "font-bold"}`}>
                <FormattedTimeComponentWithinTheDayISO8601ToLocal datetime={data.created_at} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageRow;


export const MessageTopRow = ({ key, isCheckedBox, onClickCheckedBox, onClickRead, onClickUnRead, onClickTrashBin }) => {
  return (
    <>
      <div className={`${ResponsiveValues.forMobileScreen}`}>
        <div
          key={key}
          className={`py-2 px-3 flex flex-row items-center cursor-pointer`}
        >
          <input
            value={isCheckedBox}
            onClick={() => onClickCheckedBox()}
            type="checkbox"
            name="checkBox"
            id="checkBox"
            className="col-span-1 text-left"
          />
          <div className={`${isCheckedBox === true ? '' : 'md:hidden lg:hidden'}  pl-2 col-span-2 flex space-x-2 text-left items-end text-neutral-400`}>
            <FaRegEnvelope
              className="h-4 w-4"
              onClick={onClickUnRead}
            />
            <FaRegEnvelopeOpen
              className="h-4 w-4"
              onClick={onClickRead}
            />
            <FaRegTrashCan
              className="h-4 w-4"
              onClick={onClickTrashBin}
            />
          </div>
        </div>
      </div>

      <div className={`${ResponsiveValues.forDesktopScreen}`}>
        <div
          key={key}
          className={`py-2 grid grid-cols-10 items-center cursor-pointer  md:text-sm lg:text-md`}
        >
          <input
            value={isCheckedBox}
            onClick={() => onClickCheckedBox()}
            type="checkbox"
            name="checkBox"
            id="checkBox"
            className="text-left"
          />
          <div className={`${isCheckedBox === true ? '' : 'md:hidden lg:hidden'}  flex space-x-2 text-left items-end text-neutral-400`}>
            <FaRegEnvelope
              className="h-4 w-4"
              onClick={onClickUnRead}
            />
            <FaRegEnvelopeOpen
              className="h-4 w-4"
              onClick={onClickRead}
            />
            <FaRegTrashCan
              className="h-4 w-4"
              onClick={onClickTrashBin}
            />
          </div>
        </div>
      </div>
    </>
  )
}
