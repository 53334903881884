import { useState } from "react";
import { FaRegBookmark, } from "react-icons/fa6";
import { RiMessage2Line } from "react-icons/ri";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TruncateText from "../../../../utils/TruncateText";
import TextStyling from "../../../../styling/TextStyling";
import KeywordBubble from '../KeywordBubble.js';

const ForumTopic = ({ data }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col gap-2 text-neutral-600">
      <div className="flex flex-row justify-between font-semibold">
        <button className="text-base flex items-center"
          onClick={() => { handleBack(); }}
        >
          <MdArrowBackIos />{data?.title || "How to effectively get a client?"}
        </button>
        <div className="text-neutral-400 flex flex-row justify-end items-center gap-2">
          <span className="text-xs ">{data?.dateTime || "1 day ago"}</span>
          <FaRegBookmark />
        </div>
      </div>
      <span className={`${TextStyling.pageBody} w-fit`}>
        <TruncateText maxLength={200} showMore={true} expanded={true} text={data?.message || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec cursus convallis mauris, non facilisis neque placerat sed. Integer mi sapien, elementum id aliquam sit amet, placerat non lorem. Nullam at scelerisque purus. Cras imperdiet at nunc nec malesuada. Etiam metus libero, aliquet vitae turpis ut, tempus tincidunt felis. Curabitur egestas tempor orci sed dictum. Praesent vestibulum euismod nibh sed ornare. Morbi tempus gravida mattis. Sed id auctor sapien, at posuere massa. In faucibus sollicitudin nisl. Sed porta scelerisque porta."} />
      </span>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row justify-start">
          <KeywordBubble title='Dev' />
          <KeywordBubble title='Client' />
          <KeywordBubble title='Professional Work' />
        </div>
        <div className={`flex flex-row justify-end items-center gap-1 ${TextStyling.note}`}>
          <RiMessage2Line size={16} />
          <span>{data?.total || '20'}</span>
        </div>
      </div>
    </div>
  )
}

export default ForumTopic;


