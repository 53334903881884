import { authAxios } from '../../../config/common';

// GET|HEAD  /api/earnings-and-referrals/summary

const get = async (id) => {
  try {
    const response = await authAxios().get(`/api/earnings-and-referrals/summary`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getUserProfile = async (id) => {
  try {
    const response = await authAxios().get(`/api/profile`);
    return response;
  } catch (error) {
    throw error;
  }
};

const earningsApi = {
  get, getUserProfile,
};

export default earningsApi;