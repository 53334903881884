import FormattedDateComponent from "../../FormattedDateComponent";
import TextStyling from "../../../styling/TextStyling";

const HonorAwardsItemEdit = ({ data, onEdit, onDelete, showControls = true }) => {
  return (
    <>
      <div className="flex flex-row gap-4 mt-4 md:mt-8 max-md:flex-wrap">
        <img
          alt="img"
          loading="lazy"
          src={data.thumbnail_path}
          className="shrink-0 aspect-square w-[60px] h-[60px] md:w-[47px]"
        />
        <div className="flex flex-col flex-1 self-start max-md:max-w-full">
          <div className="text-base md:text-xl font-semibold max-md:max-w-full">
            {data.title}
          </div>
          <div className="max-md:max-w-full">
            Issued by {data.issued_by} · <FormattedDateComponent datetime={data.issued_date} />
          </div>
        </div>

        <div className="hidden w-full md:w-fit md:flex justify-end self-end gap-2">
          {showControls &&
            <><button className={TextStyling.textLink} onClick={() => onEdit(data.id)}>Edit</button> | <button className={`text-red-500`} onClick={() => onDelete(data.id)}>Delete</button></>
          }
        </div>
      </div>

      <div>
        {data.description}{data.description}{data.description}{data.description}{data.description}
      </div>
      <div className="flex justify-end items-center gap-2 md:hidden">
        {showControls &&
          <><button className={TextStyling.textLink} onClick={() => onEdit(data.id)}>Edit</button> | <button className={`text-red-500`} onClick={() => onDelete(data.id)}>Delete</button></>
        }
      </div>

    </>
  )
}

export default HonorAwardsItemEdit