import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import earningsApi from './earningsApi';
import formDataHelper, { formUrlEncoded } from '../../../utils/formDataHelper';

export const getEarnings = createAsyncThunk('/earnings/getAll', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await earningsApi.get(id);
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserProfile = createAsyncThunk('/earnings/getUserProfile', async ({ rejectWithValue }) => {
  try {
    const response = await earningsApi.getUserProfile();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

const defaultEarningss = {
  "referral_link": "",
  "referrals": 0,
  "badge_draw_amount": 0,
  "referral_amount": 0,
  "total_earned": 0,
};

export const earningsSlice = createSlice({
  name: 'earnings',
  initialState: {
    success: false,
    loading: false,
    error: null,
    values: defaultEarningss,
    userData: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSucccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getEarnings.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.values = action?.payload;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userData = action?.payload;
      })
  }
});

export const earningsAction = earningsSlice.actions;
export default earningsSlice;