import { FaRegCircle, FaCircle, FaCheck } from "react-icons/fa6";
import ResponsiveValues from "../../styling/ResponsiveValues"
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";

const UploadDocumentSelfie = ({ onFileDrop = null, fileList = null, onSubmit = null, onDelete = null }) => {
  return (
    <div className={`w-full flex flex-row justify-center`}>
      <div className={`${ResponsiveValues.maxWidthCard} ${ResponsiveValues.minWidthCard}  
        ${ResponsiveValues.cardScreenStyle} min-h-[70vh]`}>

        {/* Circles progress indicator */}
        <div className="flex flex-row justify-center items-center gap-2">
          <FaCircle size={12} className="text-[#0082BA]" />
          <FaCircle size={12} className="text-[#0082BA]" />
          <FaCircle size={12} className="text-[#0082BA]" />

          <FaRegCircle size={12} className="text-[#0082BA] font-bold" />
        </div>

        {/* Title */}
        <div className="mt-8">
          <div className={`${TextStyling.pageHeader2}`}>Upload a Selfie</div>
          <div className={`${TextStyling.pageBody}`}>Upload a clear selfie of yourself</div>
        </div>

        {/* Spacer */}
        <div className="mt-4 border-b border-neutral-200" />

        {/* Conent - main content */}
        <div className="mt-8">
          <div className={`mt-1 relative hover:opacity-80 ${InputStyling.text}`}>
            <div className={`py-12 flex flex-col justify-center items-center text-center`}>
              <span className={TextStyling.pageBody}>Upload your selfie</span>
              <span className={TextStyling.noteNoSpacing}>(jpg, png, pdf)</span>
              <span className={TextStyling.noteNoSpacing}>Image must not be larger than 5mb</span>
            </div>
            <input
              type="file"
              value=""
              onChange={(e) => {
                onFileDrop(e);
              }}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
        </div>

        {/* Checklist */}
        <div className="mt-8 flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <FaCheck size={21} className={fileList.length > 0 ? 'text-green-500' : 'text-neutral-300'} />
            <span className={TextStyling.pageBody}>Ensure your face is clearly visible and well-lit.</span>
          </div>

          <div className="flex flex-row items-center gap-2">
            <FaCheck size={21} className={fileList.length > 0 ? 'text-green-500' : 'text-neutral-300'} />
            <span className={TextStyling.pageBody}>Avoid any accessories such as hats, sunglasses, or masks.</span>
          </div>

        </div>

        {/* Button */}
        <button
          onClick={onSubmit}
          className={`${ResponsiveValues.buttonFull}`}
          disabled={fileList.length !== 0 ? false : true}
        >
          Continue
        </button>
      </div>
    </div >
  )
}
export default UploadDocumentSelfie;

