import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import messagesApi from './messagesApi';

export const getMessages = createAsyncThunk('/messages/getAll', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await messagesApi.get(id);
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.workHistory = action?.payload;
      })
  }
});

export const messagesAction = messagesSlice.actions;
export default messagesSlice;