import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { messagesAction as action } from './messagesSlice';
import { defaultMessageValues } from './fullIdCheck/fullIdCheckMessagesSlice';
import useFullIdCheckMessages from './fullIdCheck/useFullIdCheckMessages';
import useRegisterMessages from './theRegister/useRegisterMessages';
import useAdminMessages from './admin/useAdminMessages';

const useMessages = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user);

  const { success, error, loading } = useSelector(state => state.messages);
  const { success: successF, error: errorF, loading: loadingF } = useSelector(state => state.fullIdCheckMessages);
  const { success: successR, error: errorR, loading: loadingR } = useSelector(state => state.registerMessages);
  const { success: successA, error: errorA, loading: loadingA } = useSelector(state => state.adminMessages);

  const { handleAddButton: handleAddButtonF, setSearchText: setSearchTextF } = useFullIdCheckMessages();
  const { handleAddButton: handleAddButtonR, setSearchText: setSearchTextR } = useRegisterMessages();
  const { handleAddButton: handleAddButtonA, setSearchText: setSearchTextA } = useAdminMessages();

  const [showNotif, setShowNotif] = useState(true);

  const [toggleTab, setToggleTab] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [searchText, setSearchText] = useState(null);
  const [messageContent, setMessageContent] = useState(defaultMessageValues);
  const [postMessage, setPostMessage] = useState(defaultMessageValues);

  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");
    if (tabParam === "admin") {
      setToggleTab(3);
      setShowMessage(false);
      setMessageContent(defaultMessageValues);
    }
    if (tabParam === "theregister") {
      setToggleTab(2);
      setShowMessage(false);
      setMessageContent(defaultMessageValues);
    }
    if (tabParam === "fullcheckid") {
      setToggleTab(1);
      setShowMessage(false);
      setMessageContent(defaultMessageValues);
    }
  }, [location]);

  useEffect(() => {
    clearInputFields();
  }, []);

  useEffect(() => {
    clearInputFields();
    clearNotifs();
  }, [showMessage]);

  useEffect(() => {
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
        dispatch(action.setSuccess(false));
        dispatch(action.setLoading(false));
        dispatch(action.setError(null));
      }, 15000);
    } else {
      setShowNotif(true);
    }
  }, [success, loading, error]);

  useEffect(() => {
    handleLoadingStates();
  }, [loadingA, loadingF, loadingR,]);

  useEffect(() => {
    handleSuccessStates();
  }, [successA, successF, successR]);

  useEffect(() => {
    handleErrorsStates();
  }, [errorA, errorF, errorR]);

  useEffect(() => {
    setSearchTextF(searchText);
    setSearchTextR(searchText);
    setSearchTextA(searchText);
  }, [searchText]);

  const clearInputFields = async () => {
    setPostMessage(defaultMessageValues);
    setShowReplyPanel(false);
  }

  const clearNotifs = async () => {
    setShowNotif(false);
    dispatch(action.setSuccess(false));
    dispatch(action.setLoading(false));
    dispatch(action.setError(null));
  }

  const handleOpenMessage = (data) => {
    setMessageContent(data);
  };

  const handleReplyMessage = async (inMsg, outMsg) => {

    const postMsg = {
      subject: `RE: ${inMsg.subject}`,
      message_content: outMsg.message,
      receiver_id: inMsg.sender.id,
    };

    switch (toggleTab) {
      case 1:
        await handleAddButtonF(inMsg.id, postMsg);
        break;
      case 2:
        await handleAddButtonR(inMsg.id, postMsg);
        break;
      case 3:
        await handleAddButtonA(inMsg.id, postMsg);
        break;
      default:
        break;
    }

    clearInputFields();
  }

  const handleLoadingStates = () => {
    if (loadingA || loadingF || loadingR) {
      dispatch(action.setLoading(true));
    }

    if (!loadingA && !loadingF && !loadingR) {
      dispatch(action.setLoading(false));
    }
  }

  const handleSuccessStates = () => {
    if (successA === true || successF === true || successR === true) {
      dispatch(action.setSuccess(true));
    }

    if (successA === false && successF === false && successR === false) {
      dispatch(action.setSuccess(false));
    }
  }

  const handleErrorsStates = () => {
    if (errorA) {
      dispatch(action.setError(errorA));
    } else if (errorR) {
      dispatch(action.setError(errorR));
    } else if (errorF) {
      dispatch(action.setError(errorF));
    } else {
      dispatch(action.setError(null));
    }
  }

  const handleAddButton = async () => {
    try {
      clearInputFields();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleRemoveButton = async (intId) => {
    try {
      setMessageContent(defaultMessageValues);
      setShowMessage(false);
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleEditButton = async (intId) => {
    try {

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleUpdateButton = async () => {
    try {

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }


  return {
    userData,
    success, error, loading, showNotif,
    postMessage, setPostMessage, defaultMessageValues,
    toggleTab, setToggleTab,
    searchText, setSearchText,
    showMessage, setShowMessage,
    showReplyPanel, setShowReplyPanel,
    isHovered, setIsHovered,
    messageContent, setMessageContent,
    handleOpenMessage, handleReplyMessage,
    handleAddButton, handleRemoveButton, handleEditButton, handleUpdateButton
  }

}

export default useMessages;
