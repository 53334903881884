import { Link } from "react-router-dom";
const listTab = [
  {
    title: "General Information",
    link: "?tab=general-information",
    isActive: "general-information",
  },
  {
    title: "Registration and Membership",
    link: "?tab=registration-and-membership",
    isActive: "registration-and-membership",
  },
  {
    title: "Profile Management",
    link: "?tab=profile-management",
    isActive: "profile-management",
  },
  {
    title: "Verification Process",
    link: "?tab=verification-process",
    isActive: "verification-process",
  },
  {
    title: "Privacy and Security",
    link: "?tab=privacy-and-security",
    isActive: "privacy-and-security",
  },
  {
    title: "Support and Community",
    link: "?tab=support-and-community",
    isActive: "support-and-community",
  },
  {
    title: "Features & Referral Program",
    link: "?tab=features-and-referral-program",
    isActive: "features-and-referral-program",
  },
  {
    title: "Report and Dispute",
    link: "?tab=report-and-dispute",
    isActive: "report-and-dispute",
  },
];

const generalInformation = [
  {
    title: "What is fullidcheck.com?",
    content: (
      <div>
        <div>
          Fullidcheck.com is a platform designed to verify the identities of
          professionals and individuals across various roles and job types. This
          platform enables anyone to confirm the identity of a person they are
          interacting with, whether for professional, personal, or transactional
          purposes. Our service is especially useful for those seeking to verify
          the identity of a tradesperson, a professional, or even someone who
          has met online. By linking profiles on fullidcheck to register.dev,
          users can send a verification request that prompts the recipient to
          confirm their identity, report spam, or indicate a stolen ID. This
          ensures that the person you are dealing with is registered and
          trusted, providing peace of mind and preventing various types of scams
          and fraudulent activities, including:
        </div>
        <div className="mt-10">
          <Link
            to="/faq/category/view"
            className=" bg-white text-devRegBlue border-2 border-[#E5E7EB] px-4 py-2 rounded-xl"
          >
            see more
          </Link>
        </div>
      </div>
    ),
  },
  {
    title: "Are there any restrictions on who can join fullidcheck.com?",
    content: `: fullidcheck.com is open to everyone, regardless of profession or background. Our goal is to verify the identities
  of individuals across various fields, including those on matchmaking sites, to ensure trust and authenticity. By
  confirming your identity on fullidcheck.com, you can assure others that you are who you say you are, fostering a
  trustworthy community. All members must undergo a thorough verification process and provide the necessary
  documents, such as government-issued IDs, proof of address, and relevant work licenses, to ensure the integrity and
  trustworthiness of our platform.
  `,
  },
  {
    title: "Is fullidcheck.com worldwide?",
    content: `Yes, fullidcheck.com is available to individuals worldwide. Our platform is designed to verify
  identities globally, ensuring trust and authenticity across various regions. We aim to provide a reliable and
  secure verification service for users worldwide.
  `,
  },
  {
    title: "Can I use fullidcheck.com for personal identity verification?",
    content: `Yes, fullidcheck.com can be used for personal identity verification. Whether you are a professional,
  tradesperson, or an individual looking to verify your identity for personal reasons, our platform provides a thorough
  and secure verification process. If you are verified on our platform, companies and individuals can be confident that
  you are who you say you are, ensuring trust and authenticity in various interactions and transactions.`,
  },
];

const registrationAndMembership = [
  {
    title: "How do I register on fullidcheck.com?",
    content: (
      <div>
        <div>
          1. <span className="font-bold"> Sign Up:</span> Visit the website and
          complete the sign-up process.
        </div>
        <div>
          2. <span className="font-bold"> Pay the Registration Fee:</span> A
          one-off registration fee of $195.00 USD is required.
        </div>
        <div>
          3. <span className="font-bold"> Facial Recognition:</span> Upload a
          photo for facial recognition.
        </div>
        <div>
          4. <span className="font-bold"> Upload Government Documents:</span>{" "}
          Provide government-issued ID documents such as a passport, driver's
          license, and utility bill for address verification.
        </div>
        <div>
          5. <span className="font-bold"> Live Interview:</span> Complete a
          15-minute Zoom interview to verify your identity.
        </div>
        <div className="mt-4">
          This registration is a one-time process. Every five years, you may
          update your profile photo for a fee. There is also a voluntary annual
          membership fee of $135.00 USD. While paying this fee is optional, it
          provides additional benefits and keeps you as a financial member.
        </div>
        <div className="mt-4">
          Regardless of whether you pay the annual fee, your profile will remain
          live for the rest of your life.
        </div>
        <div className="mt-4">
          As a financial member, you can participate in the website's badge draw
          and referral program:
        </div>

        <div className="mt-2">
          ● <span className="font-bold">Badge Draw:</span> You have a chance to
          win various badges
        </div>
        <div>
          ● <span className="font-bold">Referral Program: </span> Y: Earn $10
          for each person you refer who successfully signs up. This payment is
          made after 90 days to prevent fraudulent sign-ups.
        </div>
      </div>
    ),
  },
  {
    title: " What is the cost of registration?",
    content: (
      <div>
        <div>
          The cost for a one-time lifetime registration is $195.00 USD. This fee
          covers your membership for the first year. After the first year, there
          is an optional annual membership fee of $135.00 USD.
        </div>
        <div className="mt-2">
          Paying the annual fee is optional because we understand that not
          everyone can afford it. Whether or not you pay the annual fee, your
          profile will remain active for life. However, paying the fee grants
          you additional benefits for that year, such as participation in the
          badge draw and referral program, where you can earn rewards and
          incentives.
        </div>
        <div className="mt-4">
          You are not required to pay the annual fee every year. You can pay
          occasionally as you can, and it will not be backdated. When you pay
          the yearly fee, you will be entitled to all services and prizes for
          that year. The website will remember your payment history, and your
          profile will remain in good standing regardless of when you choose to
          pay.
        </div>
      </div>
    ),
  },
  {
    title: "What happens if I don't pay the annual membership fee?",
    content: (
      <div>
        <div>
          If you choose not to pay the annual membership fee, your profile will
          remain active and visible to clients. However, there are several
          benefits you will miss out on if you are not a financial member:
        </div>
        <div className="mt-2">
          ●{" "}
          <span className="font-bold mt-2">Participation in Badge Draws:</span>
          Access to win various badges and recognitions is not available if you
          are unfinancial.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Priority Listing: </span> Your
          business profile will be unlinked, giving you less visibility and
          decreasing your chances of client contact.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Access to Premium Features: </span>
          You will not have access to additional tools and features that could
          help you manage and promote your services more effectively.
        </div>
      </div>
    ),
  },
  {
    title: "Can I upgrade my membership after registration?",
    content: `Currently, we do not offer an upgrade option for memberships. However, we are considering this feature for
the future. Stay tuned for updates as we continue to improve and expand our services.`,
  },
  {
    title: "What if my verification is unsuccessful?",
    content: `: If your verification is unsuccessful, you will receive an email detailing why the verification failed and the steps
you can take to fix any issues. Your case will be escalated to tier one, which is the admin. The admin will make contact
with you and, depending on your circumstances, will work with you to resolve the issue and complete your
verification`,
  },
];

const profileManagement = [
  {
    title: "What documents do I need to provide during registration?",
    content: (
      <div>
        <div>
          During registration, you need to provide the following documents:
        </div>
        <div className="mt-3">
          1. <span className="font-bold"> Government-Issued ID:</span> includes
          documents such as a passport or driver's license
        </div>
        <div>
          2. <span className="font-bold"> Proof of Address:</span>A utility bill
          or similar document to verify your address
        </div>
        <div>
          3. <span className="font-bold"> Work Licenses: </span> Any
          professional or trade license relevant to your work.
        </div>
        <div>
          4.{" "}
          <span className="font-bold">
            {" "}
            Additional Identification Documents:
          </span>{" "}
          Any other government-issued identification that may be required for
          verification.
        </div>

        <div className="mt-4">
          This registration is a one-time process. Every five years, you may
          These documents must be uploaded to the platform and may also be
          requested during the 15-minute live Zoom interview to verify your
          identity further. This thorough verification process helps ensure the
          legitimacy and trustworthiness of all members on fullidcheck.com.
        </div>
      </div>
    ),
  },
  {
    title: "How often do I need to update my profile photo?",
    content: (
      <div>
        <div>
          You must update your profile photo every five years to maintain your
          verified status
        </div>
        <div className="mt-2">
          For members aged 68 years and older, we offer discounted rates for
          updating your profile photo. Please approach the admin for more
          details on how to apply for this discount.
        </div>
      </div>
    ),
  },
  {
    title: " Can I update my information after registration?",
    content: (
      <div>
        <div>
          Yes, you can update your profile information, including your photo and
          contact details, through your account settings. However, there are
          some specific guidelines and checks for significant updates:
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Profile Photo:</span>
          When you update your photo, a warning will be sent to the admin. You
          must use an accurate, clear picture and not an avatar. This ensures
          the authenticity of your profile.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Work Licenses: </span> When you
          update your work licenses, a warning will also be sent to the admin.
          These updates are checked for fraud to ensure all licenses are
          legitimate.
        </div>
        <div className="mt-2">
          We keep a watchful eye on all changes to maintain the integrity and
          trustworthiness of our platform.
        </div>
      </div>
    ),
  },
  {
    title: "How can I ensure my profile stands out to clients?",
    content: (
      <div>
        <div>
          To ensure your profile stands out to clients, follow these tips:
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Keep Information Up to Date:</span>
          Regularly update your profile information, including your profile
          photo and work licenses.
        </div>

        <div className="mt-2">
          ● <span className="font-bold mt-2">Complete Your Profile:</span>:
          Ensure all sections of your profile are filled out with accurate and
          detailed information.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Add Credentials:</span> Display
          your credentials and certifications to build trust and showcase your
          qualifications.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Annual Membership:</span> Display
          your credentials and certifications to build trust and showcase your
          qualifications.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Engage with Clients: </span>Respond
          promptly to client inquiries and maintain a professional demeanour in
          all communications.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Use Keywords:</span>Use relevant
          keywords in your profile description to improve searchability. For
          example, if you are a carpenter, mention terms like "carpenter,"
          "carpentry work," and "woodworking."
        </div>
        <div className="mt-2">
          ●{" "}
          <span className="font-bold mt-2">
            Name Your Photos Appropriately:
          </span>
          Name them with relevant keywords when uploading JPEG photos. For
          instance, if you are a carpenter, name the photo "carpenter at work."
          However, do not use the same keyword repeatedly, as it can reduce the
          effectiveness of search engines.
        </div>
        <div className="mt-2">
          We keep a watchful eye on all changes to maintain the integrity and
          trustworthiness of our platform.
        </div>
      </div>
    ),
  },
  {
    title: "How do I update my next of kin information?",
    content: `To update your next of kin information, you must do it through the admin message panel. It is essential to keep
this information current to ensure your account is managed correctly in case of emergencies or other unforeseen
events. Keeping your next of kin information current helps us provide the best support and ensures your account is
handled according to your wishes.`,
  },
  {
    title: "Are there any fees for updating my information?",
    content: `Updating your basic profile information, such as contact details and work licenses, is free. However, there may
be a fee for updating your profile photo or other specific services. If you get married or have a change of name, there
is a fee of $135.00 to reconstruct your information. Don't hesitate to contact our support team for details on specific
fees. Keeping your profile information up to date is essential for maintaining the accuracy and trustworthiness of
your profile.
`,
  },
  {
    title: " How can I ensure my profile is not flagged for inactivity?",
    content: `Your profile will not be flagged for inactivity even if you do not access it yearly. However, it is recommended to
log in regularly to keep your information current. You must update your profile photo every five years, but your
profile will remain active and visible even if you access it infrequently. Maintaining up-to-date information helps
ensure the accuracy and trustworthiness of your profile.

`,
  },
  {
    title: " Are there any guidelines for the profile photo I upload?",
    content: `Yes, your profile photo should be a clear, recent image of yourself. Avatars, cartoons, or heavily edited photos
are not allowed. The photo should clearly show your face to help verify your identity. This photo is used in our facial
recognition system, which is cross-checked with your passport photo and checked by admin to ensure accuracy. For
adequate verification, the photo you upload must accurately represent yourself.

`,
  },
];

const verificationProcess = [
  {
    title: "How do clients verify my identity?",
    content: (
      <div>
        <div>
          Clients can verify your identity through fullidcheck.com by searching
          for registered professionals. When clients search for you on our
          platform, they can see that you have undergone a thorough verification
          process, including submission of government-issued IDs, facial
          recognition, and a live interview. This ensures that they are dealing
          with verified and trustworthy individuals.
        </div>

        <div className="mt-4">
          Additionally, clients can send a verification request through
          fullidcheck.com, which prompts you to confirm your identity. This
          process helps to provide an extra layer of security and trust,
          ensuring that interactions and transactions are safe and legitimate.
        </div>
      </div>
    ),
  },
  {
    title: " How long does the verification process take?",
    content: (
      <div>
        <div>
          The verification process, including the live interview, is designed to
          be immediate. We prefer the meeting on the same day as your
          registration to ensure that your files are not stored on the server
          longer than necessary.
        </div>

        <div className="mt-4">
          You can select the time and date for your Zoom interview during
          registration. If you download the Zoom meeting application and have a
          video conference setup, you can choose a time that suits you best,
          ensuring a smooth and efficient verification process.
        </div>
      </div>
    ),
  },
  {
    title:
      "How does fullidcheck.com ensure the authenticity of the verification process?",
    content: (
      <div>
        <div>
          fullidcheck.com uses a multi-step verification process to ensure the
          authenticity and legitimacy of all members on our platform. This
          process includes:
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Document Submission:</span>Members
          must submit government-issued IDs, proof of address, and relevant work
          licenses or certifications.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Facial Recognition:</span>Members
          upload a photo for facial recognition to confirm their identity.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Live Zoom Interview:</span>Members
          participate in a live Zoom interview with a manager to further verify
          their identity and credentials. During this interview, they are
          requested to show their documents and credentials.
        </div>

        <div className="mt-4">
          This thorough verification process helps us maintain a trustworthy and
          reliable community.
        </div>
      </div>
    ),
  },
  {
    title: "What should I expect during the interview?",
    content: (
      <div>
        <div>
          The interview process is an essential part of our verification to
          ensure the legitimacy of all members. Here's what you can expect:
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Duration:</span>The interview
          typically takes around 15 minutes
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Recording:</span>The interview is
          recorded to capture your facial features and to ensure that all
          provided documents are genuine.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Document Verification:</span>You will
          be asked to show your government-issued ID, proof of address, work
          licenses, and other relevant documents.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Qualification Discussion:</span>The
          interviewer will ask about your qualifications and work experience to
          verify the information you provided.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Profile Update:</span> All verified
          information, including the recorded interview and document
          verification, is zipped to a file and named with your serial number.
          This file is then sent to the admin for download and deletion from the
          server. You will be responsible for accurately updating your profile
          with this verified information.
        </div>

        <div className="mt-4">
          This thorough interview process helps maintain the trust and
          authenticity of our platform by ensuring that all members are who they
          claim to be.
        </div>
      </div>
    ),
  },
  {
    title: "What happens if I provide false information during registration?",
    content: (
      <div>
        <div>
          Providing false information during registration is strictly
          prohibited. When you upload your documents and go through facial
          recognition, followed by the interview, we will have all your
          information set on the website. This includes your photo, and you will
          already have an ID number.
        </div>

        <div className="mt-4">
          If you attempt to use false documents, your profile will be
          automatically flagged as fraudulent. This status will remain
          permanently, along with your photo, and will never be deleted. If you
          attempt to register again, the facial recognition system will identify
          you as a fraudster, and any new documents you use will be added to
          your existing file, further marking you as a fraud.
        </div>
        <div className="mt-4">
          It is highly recommended that you never attempt to defraud this
          platform. We take fraud very seriously and are incredibly unforgiving.
          We prioritise the integrity and security of our community over privacy
          laws when it comes to fraudulent activities.
        </div>
      </div>
    ),
  },
];
const privacyAndSecurity = [
  {
    title: "How is my personal information protected?",
    content: (
      <div>
        <div>
          fullidcheck.com takes your privacy and security seriously. We use
          advanced encryption and security measures to protect your personal
          information. Your data is only used for verification and not shared
          with third parties.
        </div>

        <div className="mt-4">
          The files you upload during registration are deleted from the website
          within 2 hours of signing up. The only information visible on the site
          is your first and last name, though you may choose to add a middle
          name to your profile. All other information you choose to include is
          at your discretion.
        </div>
        <div className="mt-4">
          After uploading and being checked by a manager, your documents are
          zipped, password protected, given a serial number as a document ID,
          and then downloaded from the server to an offline server for secure
          storage. Once this process is complete, the files on the server are
          wholly deleted. This ensures that your sensitive documents are
          protected and not accessible online. They will never come near to
          being online.
        </div>
      </div>
    ),
  },
  {
    title: "  Is my information shared with other members or third parties?",
    content: (
      <div>
        Your information is not shared with other members or third parties. Your
        data is used solely for verification and is protected with advanced
        encryption and security measures. We prioritise your privacy and ensure
        your sensitive information remains secure and confidential.
      </div>
    ),
  },
  {
    title: "How do I know if my documents have been securely processed?",
    content: (
      <div>
        Once your documents are uploaded, they are immediately checked by a
        manager, zipped, password protected, given a serial number, and
        downloaded to an offline server for secure storage. The files on the
        server are then wholly deleted. You will receive a confirmation once
        your documents have been securely processed.
      </div>
    ),
  },
  {
    title: "What measures does fullidcheck.com take to prevent fraud?",
    content: (
      <div>
        <div>
          fullidcheck.com employs several measures to prevent fraud and ensure
          the integrity of our platform:
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Duration:</span>The interview
          typically takes around 15 minutes
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Advanced Encryption: </span>
          {` `}We use advanced encryption techniques to protect your data.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Multi-Step Verification:</span> Our
          verification process includes document submission, facial recognition,
          and a live Zoom interview.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Thorough Document Checks:</span> Our
          team thoroughly checks all documents to ensure their authenticity.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Monitoring Profile Updates:</span> We
          monitor profile updates to detect suspicious changes
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Handling Complaints:</span> We
          investigate all complaints to ensure all members are legitimate and
          trustworthy.
        </div>

        <div className="mt-4">
          Any fraudulent activity is taken seriously and dealt with promptly to
          maintain a safe and trustworthy user environment.
        </div>
      </div>
    ),
  },
  {
    title:
      "What if law enforcement wants access to the server for information on the members?",
    content: (
      <div>
        We take the privacy and security of our members very seriously. Law
        enforcement access to our server for member information will never
        happen. We are committed to protecting your data and would go to great
        lengths to ensure its confidentiality. Additionally, our server is in a
        secure and undisclosed area, making it difficult to be found. Your
        privacy and trust are our top priorities.
      </div>
    ),
  },
];

const supportAndCommunity = [
  {
    title: "What should I do if I encounter a problem during registration?",
    content: (
      <div>
        <div>
          Contact our support team through the website's Help Desk section if
          you encounter any issues during registration. We are here to assist
          you and ensure a smooth registration experience.
        </div>
      </div>
    ),
  },
  {
    title: " What if I lose access to my account?",
    content: (
      <div>
        If you lose access to your account, you can reset your password using
        the "Forgot Password" feature on the login page. If you continue to
        experience issues, please get in touch with our support team through the
        Help Desk section for further assistance. To ensure your security, you
        may be asked to go through a Zoom meeting to confirm your identity. Our
        team is here to help you regain access to your account as quickly and
        securely as possible.
      </div>
    ),
  },
  {
    title: "How do I contact customer support?",
    content: (
      <div>
        You can contact our customer support team through the Help Desk section
        on our website. We're available to help you with any questions or issues
        regarding your account or the verification process. If you cannot find
        what you need, you may also contact the admin directly using your
        profile and send a message to the admin for further assistance.
      </div>
    ),
  },
  {
    title:
      " How can I provide feedback about my experience with fullidcheck.com?",
    content: (
      <div>
        <div>
          We value your feedback and constantly seek to improve our services.
          While we do not have a feedback form, if you like our service, please
          recommend us to others and earn a reward for your support. If you are
          unsatisfied with our service, don't hesitate to contact our customer
          support team to share your concerns; we will work to improve it.
        </div>

        <div className="mt-4">
          We also reward users who provide constructive support by identifying
          faults in our efforts to help others. Your input helps us enhance our
          platform and provide a better experience for all users.
        </div>
      </div>
    ),
  },
  {
    title:
      "How do I participate in community discussions or forums on fullidcheck.com?",
    content: (
      <div>
        To participate in community discussions on forums on fullidcheck.com,
        log in to your account and navigate to the forum section. The forum is
        the only place to discuss anything on our platform. Here, you can join
        discussions, ask questions, share experiences, and connect with other
        members. It is a great place to engage with the community, gain
        insights, and contribute to discussions relevant to your interests and
        professional field.
      </div>
    ),
  },
  {
    title: "What if I encounter a technical issue on the website?",
    content: (
      <div>
        If you encounter a technical issue on the website, please get in touch
        with our support team through the Help Desk section. We will work to
        resolve the issue as quickly as possible to ensure a smooth user
        experience. Our team provides prompt assistance and ensures that
        technical problems are addressed efficiently.
      </div>
    ),
  },
  {
    title: " Can I suggest new features or improvements for fullidcheck.com?",
    content: (
      <div>
        <div>
          Absolutely! We value your input and continually seek ways to improve
          our platform. If you have a great suggestion, such as an app that
          helps others stop more scams by working in a specific way, we would
          love to hear from you. Please submit your suggestions through the Help
          Desk section or contact our support team directly.
        </div>
        <div className="mt-3">
          We will provide a financial reward to anyone whose suggestions we
          implement. Your feedback is essential in helping us enhance our
          services and better serve our community
        </div>
      </div>
    ),
  },
];

const featuresAndReferralProgram = [
  {
    title: "How does the referral program work?",
    content: (
      <div>
        The referral program allows you to earn $10 for each person you refer
        who successfully signs up. This payment is made after 90 days to prevent
        fraudulent sign-ups. To participate, you must be a financial member with
        an active annual membership.
      </div>
    ),
  },
  {
    title: "  What is the badge draw, and how can I participate?",
    content: (
      <div>
        <div>
          The badge draw is a feature that offers financial members a chance to
          win cash prizes. Each registered person is linked to a unique serial
          number, displayed as a badge showing that they are a registered and
          verified member.
        </div>
        <div className="mt-4">
          A badge draw is held every week, and a serial number is randomly
          selected from all members across our platforms. The prize amount grows
          as members increase, and the cash prize is instantly paid to the
          winning member's account. You must have paid your annual membership
          fee to participate in the badge draw. Winning the badge draw can also
          enhance your profile visibility and credibility on the platform.
        </div>
      </div>
    ),
  },
  {
    title: "How do I participate in the referral program?",
    content: (
      <div>
        : You do not need to be a financial member to participate in the
        referral program. You can refer new members through your account, and
        you will earn $10 for each person who successfully signs up. This
        payment is made after 90 days to prevent fraudulent sign-ups.
        Additionally, the referral program helps you build your credit account
        profile, which can be used to pay for goods and services on the
        platform. The referral program is a great way to earn rewards while
        helping to expand our trusted community.
      </div>
    ),
  },
  {
    title:
      "What are the benefits of being a verified member on fullidcheck.com?",
    content: (
      <div>
        <div>
          Being a verified member on fullidcheck.com provides numerous benefits,
          including:
        </div>
        <div className="mt-2">
          ●{" "}
          <span className="font-bold mt-2">
            Enhanced Trust and Credibility:
          </span>{" "}
          Gain trust and credibility with clients by having a verified profile.
        </div>
        <div className="mt-2">
          ● <span className="font-bold mt-2">Increased Visibility:</span>
          {` `}Enjoy higher visibility and profile ranking on the platform
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Access to Premium Features:</span>
          Participate in badge draws, the referral program, and other premium
          features.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Showcase Legitimacy:</span> Demonstrate
          your legitimacy and qualifications through a verified profile, making
          it easier for clients to choose you.
        </div>
        <div className="mt-2">
          ● <span className="font-bold ">Trust Beyond Your Trade:</span> Allow
          others, even outside your trade, to verify you are who you are,
          enhancing trust in various interactions.
        </div>

        <div className="mt-4">
          These benefits help you stand out and maximise your opportunities on
          fullidcheck.com.
        </div>
      </div>
    ),
  },
  {
    title: "Can I refer friends or family to join fullidcheck.com?",
    content: (
      <div>
        Yes, you can refer friends or family to join fullidcheck.com. You can
        earn a referral reward for each person who successfully signs up through
        your referral link. This is a great way to help grow our community while
        earning rewards for yourself. Make sure they use your referral link
        during the registration process to ensure you receive credit for the
        referral.
      </div>
    ),
  },
  {
    title:
      "What if law enforcement wants access to the server for information on the members?",
    content: (
      <div>
        We take the privacy and security of our members very seriously. Law
        enforcement access to our server for member information will never
        happen. We are committed to protecting your data and would go to great
        lengths to ensure its confidentiality. Additionally, our server is in a
        secure and undisclosed area, making it difficult to be found. Your
        privacy and trust are our top priorities.
      </div>
    ),
  },
];
const reportAndDispute = [
  {
    title: "What happens if a complaint is lodged against me?",
    content: (
      <div>
        <div>
          If a complaint is lodged against you, our team will review the
          complaint and the associated evidence. You will be contacted and asked
          to provide an explanation if the complaint is related to scamming. A
          complaint will be added to your profile if you refuse to respond or
          allow contact.
        </div>
        <div className="mt-4">
          Please remember that once a scamming-related complaint is logged into
          your profile, it will remain permanently. It is in your best interest
          to respond to the complaint promptly to defend yourself and provide
          your side of the story. We focus on anti-scam activities and take all
          scam-related complaints seriously to maintain a trustworthy community.
          Complaints unrelated to scams are not our primary concern.
        </div>
      </div>
    ),
  },
  {
    title:
      "What should I do if I suspect someone on the platform is a scammer?",
    content: (
      <div>
        <div>
          If you suspect someone on the platform is a scammer, please report
          them immediately through the report feature on their profile. All
          reports are sent directly to the admin, who will investigate the
          complaint and take appropriate action to ensure the integrity of our
          community. We take all reports seriously and work diligently to
          maintain a safe and trustworthy environment for all members.
        </div>
        <div className="mt-4">
          Please note, if a report is found to be falsely made, the person
          sending the complaint will be labeled a nuisance, which may affect
          their standing within the community.
        </div>
      </div>
    ),
  },
  {
    title:
      "How does fullidcheck.com handle disputes between clients and members?",
    content: (
      <div>
        fullidcheck.com encourages clients and members to resolve disputes
        amicably. Members can contact our support team for assistance if a
        resolution cannot be reached. We will review the case, investigate the
        details, and guide to help resolve the issue. If we are unable to help,
        we will recommend other services that may be able to assist you. Our
        goal is to ensure a fair and trustworthy environment for all users on
        the platform.
      </div>
    ),
  },
];
export {
  listTab,
  generalInformation,
  registrationAndMembership,
  profileManagement,
  verificationProcess,
  privacyAndSecurity,
  supportAndCommunity,
  featuresAndReferralProgram,
  reportAndDispute,
};
