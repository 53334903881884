import React, { useEffect, useRef, useState } from "react";
import useFeature from "../../../feature/dashboard/professionalProfile/profileEdit/features/useFeature";
import Bubble from "../../../components/Bubble";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";

function AdvertisingFeatures() {
  const {
    success, loading, error, myError,
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit,
    title, handleTitleChange,
    content, handleContentChange,
    link, handleLinkChange,
    banner, setBanner,
    keywords, handleKeywordsChange,
    keywordEntry, setKeywordEntry,
    handleImageUpload,
    handlesAddFeature,
    handleKeywordChange,
    handleKeywordTyping,
    handleRemoveKeyword,
    getArrayFromString,
  } = useFeature();

  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <div className="flex flex-col  text-base md:mb-8 max-md:max-w-full">
        <PageTitle title='Add Feature' description='Promote your website, product, or any other things you want to feature
              on your professional profile.' />
        <div className="w-full h-10 flex justify-end items-center">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error ?? "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <div className={`${TextStyling.inputLabel}`}>
          Title
        </div>
        <input
          placeholder="Feature Title"
          className={`mt-1 ${InputStyling.text}`}
          autoComplete="on"
          value={title}
          onChange={(event) => handleTitleChange(event.target.value)}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.title.errorMessage} />

        <div className={`${TextStyling.inputLabel}`}>
          Content
        </div>
        <input
          placeholder="Content"
          // className="justify-center px-4 py-3 mt-2 whitespace-nowrap rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
          className={`mt-1 ${InputStyling.text}`}
          autoComplete="on"
          value={content}
          onChange={(event) => handleContentChange(event.target.value)}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.content.errorMessage} />


        <div className={`${TextStyling.inputLabel}`}>
          URL/Website Link
        </div>
        <input
          placeholder="https://mywebsite.com"
          // className="justify-center px-4 py-3 mt-2 whitespace-nowrap rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
          className={`mt-1 ${InputStyling.text}`}
          autoComplete="url"
          value={link}
          onChange={(event) => handleLinkChange(event.target.value)}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.link.errorMessage} />

        <div className="container bg-gray-200 max-w-[820px] max-h-[385px] border-neutral-300 border-2 rounded-2xl mt-7 relative  hover:opacity-80">
          {!banner &&
            <div className="py-4 text-neutral-400">
              <div className="text-center flex flex-col justify-center items-center text-lg">
                <div className="text-base font-semibold leading-5 text-black text-opacity-80">
                  Upload Image
                </div>
                <div className="text-sm15">Add banner</div>
                <div className="text-xs leading-4">
                  {`Image size: 815px x 380px`}
                </div>
                <div className="text-xs leading-4">{` MB up to 2MB`}</div>
                <div className="mt-2  text-sm15 font-bold tracking-tight text-devRegBlue">
                  Upload
                </div>
              </div>
            </div>
          }

          {banner &&
            <img
              loading="lazy"
              src={banner}
              onClick={handleClick}
              alt="Uploaded"
              className={`${banner ? 'block' : 'hidden'} object-contain rounded-2xl cursor-pointer`} />
          }

          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            value=""
            onChange={handleImageUpload}
            className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
          />
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.banner.errorMessage} />

        <div className={`${TextStyling.inputLabel}`}>
          Keywords (max of 3)
        </div>

        <div className="flex flex-col md:flex-row justify-left items-start px-2 py-1 mt-2 text-sm leading-4 rounded-md border border-solid border-neutral-400 text-neutral-600 max-md:pr-5 max-md:max-w-full">
          <input
            className="justify-center px-4 py-2 my-2 mx-2 rounded-md bg-neutral-100"
            placeholder="Add Keyword"
            type="text"
            autoComplete="on"
            value={keywordEntry}
            onChange={(event) => handleKeywordTyping(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleKeywordChange(event.target.value);
              }
            }}
          />
          <div className="flex flex-row flex-wrap">
            {keywords &&
              getArrayFromString(keywords).map((e, i) => {
                return <Bubble key={i} title={e} handleRemove={() => handleRemoveKeyword(e)}></Bubble>
              })
            }
          </div>
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.keywords.errorMessage} />

        <button
          className={`${isSafeToSubmit === true ? '' : 'disabled'} ${ResponsiveValues.forDesktopScreen} ${ResponsiveValues.buttonFit}`}
          onClick={() => { handlesAddFeature(); }}>
          Add Features
        </button>

        <button
          className={`${isSafeToSubmit === true ? '' : 'disabled'} ${ResponsiveValues.forMobileScreen} ${ResponsiveValues.buttonFull}`}
          onClick={() => { handlesAddFeature(); }}>
          Add Features
        </button>


        {
          showNotif && (
            <>
              {loading && (<LoadingIndicator loading={loading} styling="px-6 max-md:px-5" />)}
            </>
          )
        }
      </div>
    </>
  );
}

export default AdvertisingFeatures;
