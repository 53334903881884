import ReplyMessagePanel from "./replyMessagePanel";
import { FormattedTimeComponentWithinTheDayISO8601ToLocal, ElepasedTime } from "../../../components/FormattedDateComponent";
import useMessages from "../../../feature/dashboard/messages/useMessages";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import { useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import { FaRegTrashCan } from "react-icons/fa6";
import { LiaReplySolid } from "react-icons/lia";

const MessageContent = ({ data, postMessage, setPostMessage, onClickBackButton, onClickShowReplyPanel, onClickTrashBin, showReplyPanel, handleReplyMessage, handleReadUnRead }) => {
  const {
    loading
  } = useMessages();

  useEffect(() => {
    handleReadUnRead(data.id);
    data.getConversation(data.conversation_id);
  }, []);

  return (
    <div className="flex flex-col self-stretch">
      <div className="flex flex-wrap gap-2.5 content-center mt-6 text-2xl font-semibold tracking-tight leading-7 text-blue-900">
        <button
          onClick={onClickBackButton}
        >
          <PageTitle title={data.subject} hasSpacing='false' showBackButton={true} customTextStyleDesktop={`${TextStyling.pageBodyBold}`} />
        </button>
      </div>
      <div className={`flex flex-wrap ${TextStyling.inputLabel}`}>
        <div className="flex-1 my-auto max-md:max-w-full">
          from: <span className="text-blue-500">{data.sender.fullname}</span>
        </div>
        <div className="flex flex-1 gap-4 px-4 flex-wrap">
          <div className="flex flex-1 flex-cols justify-end flex-wrap">
            <div className="pr-2"><FormattedTimeComponentWithinTheDayISO8601ToLocal datetime={data.created_at} /></div>
            (<div className="px-2"><ElepasedTime datetime={data.created_at} /> ago</div>)
          </div>
          <div className="flex gap-4 items-center whitespace-nowrap">
            <button
              className="flex gap-2 cursor-pointer"
              onClick={onClickShowReplyPanel}
            >
              <LiaReplySolid size={16} />

              Reply
            </button>
            <button
              onClick={async () => {
                await onClickTrashBin.primaryFunction((data.id));
                await onClickTrashBin.defaultFunction();
              }}
            >
              <FaRegTrashCan size={16} />

            </button>
          </div>
        </div>
      </div>

      <div className={`justify-center p-8 mt-1 ${TextStyling.pageBody} ${ResponsiveValues.cardSectionStyle} shadow-md`}>
        {data.message_content}
        {
          data?.conversation?.map((item) => {
            // show only previous messages
            if (parseInt(item.id) < parseInt(data.id)) {
              return <ConversationMessage item={item} />
            }
          })
        }
      </div>

      {showReplyPanel && (
        <>
          <ReplyMessagePanel
            loading={loading}
            data={data}
            onClickSend={handleReplyMessage}
            postMessage={postMessage}
            setPostMessage={setPostMessage}
          />
        </>
      )}
    </div>
  )
}

export const MessageContentSmallerScreen = ({ data, postMessage, setPostMessage, onClickBackButton, onClickShowReplyPanel, onClickTrashBin, showReplyPanel, handleReplyMessage, handleReadUnRead }) => {
  const {
    success, error, loading, showNotif
  } = useMessages();

  useEffect(() => {
    handleReadUnRead(data.id);
    data.getConversation(data.conversation_id);
  }, []);

  return (
    <div className="flex flex-col self-stretch max-md:max-w-full">
      <div className="flex flex-wrap content-center">
        <div className="flex flex-cols justify-start items-center text-xl font-semibold tracking-tight text-blue-900">
          <button
            onClick={onClickBackButton}
          >
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/753002194888e74a2711616f8092dad48bcf98545518e55fcd95cd49009362ed?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="cursor-pointer shrink-0 my-auto w-3 aspect-[0.6]"
            />
          </button>

          <div className="pl-2">
            {data.subject.length > 15
              ? data.subject.substring(0, 15) + "..."
              : data.subject}
          </div>
        </div>
        <div className="flex flex-cols flex-1 justify-end items-center text-xs">
          <div className="pr-2"><FormattedTimeComponentWithinTheDayISO8601ToLocal datetime={data.created_at} /></div>
          (<div className=""><ElepasedTime datetime={data.created_at} /> ago</div>)
        </div>
      </div>
      <div className="flex flex-cols justify-between items-center mt-4 mb-1 text-xs font-light tracking-tight leading-4 text-neutral-600 max-md:flex-wrap">
        <div className="flex flex-1">
          from: {data.sender.fullname}
        </div>
        <div className="flex flex-cols justify-between items-center">
          {showNotif && (
            <>
              {!loading && success && <SuccessIndicator message="Reply Sent" />}
              {!loading && !success && error && <FailureIndicator message={error} />}
            </>
          )}
        </div>
        <div className="flex flex-1 justify-end">
          <div className="flex gap-4 px-px whitespace-nowrap">
            <button
              className="flex gap-2 cursor-pointer"
              onClick={onClickShowReplyPanel}
            >
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3b56a0bae23d5fa9a254a53fe656cdfa28fdc6035188a125ee4f3d83da3ad66?apiKey=3ae96302e69649f5be368f89230b0596&"
                className="shrink-0 aspect-[0.94] fill-neutral-600 w-[17px]"
              />
              <div className="my-auto">Reply</div>
            </button>
            <button
              onClick={() => {
                onClickTrashBin.primaryFunction((data.id));
                onClickTrashBin.defaultFunction();
              }}
            >
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/52cda57ff4afc336a97d229352cf76c4d9fb47fe74d6e7a2101b9edaccd511e3?apiKey=3ae96302e69649f5be368f89230b0596&"
                className="shrink-0 self-start aspect-[0.81] w-[13px]"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center p-8 mt-2 text-sm font-light leading-4 tracking-tight border border-solid rounded-2xl border-zinc-200 text-neutral-600 max-md:px-5 max-md:max-w-full whitespace-pre-wrap">
        {data.message_content}
        <br />
        <br />
        <br />
        {
          data?.conversation?.map((item) => {
            // show only previous messages
            if (parseInt(item.id) < parseInt(data.id)) {
              return <ConversationMessage item={item} />
            }
          })
        }
      </div>

      {showReplyPanel && (
        <ReplyMessagePanel
          loading={loading}
          data={data}
          onClickSend={handleReplyMessage}
          postMessage={postMessage}
          setPostMessage={setPostMessage}
        />
      )}

    </div>
  )
}

const ConversationMessage = ({ item }) => {
  return (
    <div className="m-2 p-2 text-purple-800 text-opacity-50	border border-b border-solid border-zinc-150">
      <div className="flex flex-row justify-between">
        <div>From:     {item.sender.fullname}</div>
        <div><FormattedTimeComponentWithinTheDayISO8601ToLocal datetime={item.created_at} /></div>
      </div>
      <div className="">Subject: {item.subject}</div>
      <div className="m-4">{item.message_content}</div>
    </div>
  )
}

export default MessageContent;