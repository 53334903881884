import ProfilePic from "../../../../assets/Profile/ProfilePic.jpg";
import TextStyling from "../../../../styling/TextStyling";
import { RiChat4Line } from "react-icons/ri";
import TruncateText from "../../../../utils/TruncateText";

const ForumReply = ({ children }) => {
  return (
    <div>
      <div className="w-full my-4 pb-4 border-b">
        <div className="flex flex-row items-center gap-2">
          <img
            className="rounded-full w-10 h-10 border border-cyan-600"
            src={ProfilePic} alt=""
          />
          <span className={`${TextStyling.pageBodySemiBold} w-fit`}>Ragnar Karlsefni</span>
          <div className={` h-5 border-r border-neutral-400`} />
          <span className={`${TextStyling.note}`}>1 hr ago</span>
        </div>

        <div className={`${TextStyling.pageBody} w-fit`}>
          <TruncateText maxLength={200} showMore={true} expanded={false} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec cursus convallis mauris, non facilisis neque placerat sed. Integer mi sapien, elementum id aliquam sit amet, placerat non lorem. Nullam at scelerisque purus. Cras imperdiet at nunc nec malesuada. Etiam metus libero, aliquet vitae turpis ut, tempus tincidunt felis. Curabitur egestas tempor orci sed dictum. Praesent vestibulum euismod nibh sed ornare. Morbi tempus gravida mattis. Sed id auctor sapien, at posuere massa. In faucibus sollicitudin nisl. Sed porta scelerisque porta."} />
        </div>

        <div className={`${TextStyling.note} w-fit flex flex-row items-center gap-2`}>
          <RiChat4Line size={16} />
          Reply
        </div>
      </div>
      <div className="pl-8">
        {children}
      </div>
    </div>
  )
}

export default ForumReply;