import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerMessagesAction as action } from './registerMessagesSlice';
import { getMessages, getConversation, sendReply, updateReadUnRead, removeMessage, removeMessageMultiple } from './registerMessagesSlice';

const useRegisterMessages = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user);
  const { success, error, loading, messages, searchedMessages, conversation } = useSelector(state => state.registerMessages);

  const [isCheckedTopBox, setIsCheckedTopBox] = useState(false);
  const [checkedMessagesList, setCheckedMessagesList] = useState([]);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    clearInputFields();
  }, []);

  useEffect(() => {
    getAllMessages();
  }, []);

  useEffect(() => {
    searchMessages();
  }, [searchText]);

  const clearInputFields = async () => {
    setCheckedMessagesList([]);
  }

  const searchMessages = async () => {
    if (searchText !== null && searchText !== '') {
      const searchedMessages = await messages.filter((item) => {
        return (
          item.from?.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
          item.subject?.toLowerCase().includes(searchText?.toLowerCase()) ||
          item.message?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });

      await dispatch(action.setSearchedMessages(searchedMessages));
    } else {
      await dispatch(action.setSearchedMessages(messages));
    }
  }

  const getAllMessages = async () => {
    try {
      await dispatch(getMessages({ id: userData.id }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const getConversations = async (conversationId) => {
    try {
      const result = await dispatch(getConversation({ id: conversationId }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const getSingle = async (messageId) => {
    try {
      await dispatch();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const toggleReadUnRead = async () => {
    try {
      await dispatch();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const removeSingle = async (messageId) => {
    try {
      await dispatch(removeMessage({ id: userData.id, messageId: messageId }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const removeMultiple = async () => {
    try {
      const ids = await checkedMessagesList.map((item) => item.id).join(',');
      const data = { ids: ids };
      await dispatch(removeMessageMultiple({ id: userData.id, data: data }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const removeMultipleTemporary = async () => {
    try {
      const promises = await checkedMessagesList.map(async (item) => {
        await removeSingle(item.id);
      });

      await Promise.all(promises);
      await getAllMessages();


    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const readUnReadMultiple = async (bool) => {
    try {
      const ids = checkedMessagesList.map(item => item.id);
      const result = await dispatch(updateReadUnRead({ data: { ids: ids, mark_as_read: bool, } }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleClickAllMessages = async () => {
    try {
      if (!isCheckedTopBox) {
        setCheckedMessagesList(messages);
        setIsCheckedTopBox(true);
      } else {
        setCheckedMessagesList([]);
        setIsCheckedTopBox(false);
      }
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleCheckboxesSingleValues = (item) => {
    const filterResult = checkedMessagesList.filter((i) => i.id === item.id);
    if (filterResult.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleClickCheckboxSingle = async (item) => {
    try {
      const isAlreadyChecked = checkedMessagesList.some((i) => i.id === item.id);

      if (isAlreadyChecked) {
        // Get all except the clicked box item.
        const list = checkedMessagesList.filter((i) => i.id !== item.id);
        setCheckedMessagesList(list);
      } else {
        // Add checked item to list
        setCheckedMessagesList([...checkedMessagesList, item]);
      }

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleAddButton = async (messageId, data) => {
    // handles reply button
    try {
      const result = await dispatch(sendReply({ id: userData.id, messageId: messageId, data: data }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleRemoveButton = async (intId) => {
    try {
      await removeSingle(intId);
      await getAllMessages();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleRemoveMultipleButton = async () => {
    try {
      // await removeMultiple();
      await removeMultipleTemporary();
      await getAllMessages();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleEditButton = async (intId) => {
    try {

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleReadUnRead = async (messageId) => {
    try {
      const result = await dispatch(updateReadUnRead({ data: { ids: [messageId], mark_as_read: 1, } }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const handleReadUnReadMultiple = async (bool) => {

    try {
      await readUnReadMultiple(bool);
      await getAllMessages();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  return {
    userData,
    success, error, loading,
    setSearchText,
    messages, searchedMessages, getAllMessages,
    getConversations, conversation,
    isCheckedTopBox, setIsCheckedTopBox,
    checkedMessagesList, setCheckedMessagesList, handleClickAllMessages, handleClickCheckboxSingle, handleCheckboxesSingleValues,
    handleAddButton, handleRemoveButton, handleRemoveMultipleButton, handleEditButton, handleReadUnRead, handleReadUnReadMultiple
  }

}

export default useRegisterMessages;
