import React from 'react';
import { FaFacebookF, FaXTwitter, FaLinkedinIn } from "react-icons/fa6";


const ShareModal = ({ isOpen, onClose, url, text }) => {
  if (!isOpen) return null;

  const shareUrl = encodeURIComponent(url);
  const shareText = encodeURIComponent(text);

  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}`;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl font-bold text-neutral-600">Share This Page</h2>
        <div className="flex flex-col space-y-2 my-8">

          <SocialMediaButton link={twitterShareUrl} styling="flex flex-row items-center w-full px-4 py-2 bg-slate-900 text-white rounded hover:bg-blue-700" >
            <FaXTwitter className='mr-2' />Share on X
          </SocialMediaButton>
          <SocialMediaButton link={facebookShareUrl} styling="flex flex-row items-center w-full px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-900">
            <FaFacebookF className='mr-2' />Share on Facebook
          </SocialMediaButton>
          <SocialMediaButton link={linkedinShareUrl} styling="flex flex-row items-center w-full px-4 py-2 bg-blue-800 text-white rounded hover:bg-blue-900">
            <FaLinkedinIn className='mr-2' />Share on LinkedIn
          </SocialMediaButton>

        </div>

        <div className="mt-auto flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-white rounded hover:bg-gray-800"
          >
            Close
          </button>
        </div>

      </div>
    </div>
  );
};

const SocialMediaButton = ({ link, children, styling }) => {
  return <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={styling}
  >
    {children}
  </a>
}

export default ShareModal;
