const formDataHelper = async (pData) => {
  const formData = new FormData();

  Object.keys(pData).forEach(key => {
    const value = pData[key];

    // Check if the value is an array
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        // Append each item with the key and index, or just the key depending on how your backend expects it
        formData.append(`${key}[${index}]`, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export default formDataHelper;

export const formUrlEncoded = async (pData) => {
  const params = new URLSearchParams(pData);
  return params
};

export const formUrlEncodedWithImage = ({ file, fieldName, pData }) => {
  const params = new URLSearchParams(pData);

  const base64image = onImageChange(file);
  params.append(fieldName, base64image.imageFile);

  return params;
}

export const imageToBase64 = async (file) => {
  // If the API you are working with only accepts x-www-form-urlencoded, 
  // you will need to convert the image file to a base64 string. 

  let setFile = null;
  const reader = new FileReader();
  reader.onloadend = () => {
    setFile = reader.result.split(',')[1];
  };
  reader.readAsDataURL(file);

  return setFile;
};

const onImageChange = (file) => {

  if (file) {

    let imageFile = null;
    let imageSrc = null;

    const reader = new FileReader();
    reader.onload = (e) => {
      imageFile = e.target.result;
    };
    reader.readAsDataURL(file);
    imageSrc = file;

    return {
      imageFile: imageFile,
      imageSrc: imageSrc,
    };
  }

};

// const onImageChange2 = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       imageFile.value = e.target.result;
//     };
//     reader.readAsDataURL(file);
//     imageSrc = file;
//   }
// };


