import React from "react";
import usePreviewProfile from "../../feature/dashboard/professionalProfile/profileEdit/previewProfile/usePreviewProfile";
import { useRef, useEffect, useState } from "react";
import { FcUp } from "react-icons/fc";
import ProfilePic from "../../assets/Profile/ProfilePic.jpg";
import profileRegisteredIcon from "../../assets/Profile/registered_logo.svg";
import AddressIcon from "../../assets/Profile/address_icon.svg";
import CheckIcon from "../../assets/Profile/check_icon.svg";
import LanguagesIcon from "../../assets/Profile/languages_icon.svg";
import SalaryIcon from "../../assets/Profile/salary_icon.svg";
import TradeLicenseIcon from "../../assets/Profile/trade_license_icon.svg";
import WorkArrangementIcon from "../../assets/Profile/work_arrangement_icon_blue.svg";
import AvailabilityIcon from "../../assets/Profile/availablity_icon_blue.svg";
import trophyProfile from "../../assets/Profile/trophyProfile.png";
import featuredProj from "../../assets/Profile/featuredProj.png";
import ProfileSidePanel from "../../components/ProfileSidePanel";
import WorkHistoryItem from "../../components/Dashboard/profileEdit/WorkHistoryItem";
import Bubble from "../../components/Bubble";
import EducationCertItem from "../../components/Dashboard/profileEdit/EducationCertItem";
import HonorAwardsItems from "../../components/Dashboard/profileEdit/HonorAwardsItem";
import ResponsiveValues from "../../styling/ResponsiveValues.js";

import { FaRegStar, FaRegStarHalfStroke, FaStar, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa6";
import { FaRegQuestionCircle } from "react-icons/fa";
import FailureIndicator from "../../components/FailureIndicator.js";
import LoadingIndicator from "../../components/LoadingIndicator.js";
import SuccessIndicator from "../../components/SuccessIndicator.js";

function Profile() {
  const {
    success, error, loading,
    user,
    educationAndCertifications,
    feature,
    awards,
    languages,
    skills,
    overview,
    portfolio,
    socials,
    workHistory,

    getProfileCover,
    getAllLanguages,
    featuredProject,
    splitKeywords,
    workHistories,
    educationalCert,
    honors,
    socialLinks,

    addFeedback, setAddFeedback, handleAddFeedback, adminRating,
  } = usePreviewProfile();

  const sectionRefs = {
    section0: useRef(null),
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
  };

  const [position, setPosition] = useState('');
  const [isSticky, setIsSticky] = useState(false);

  const scrollToSection = (section) => {
    setPosition(sectionRefs[section].current.offsetTop);

    if (section === 'section0') {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    if (position !== 0) {
      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  }, [position]);

  return (
    <>
      <div className={`${ResponsiveValues.forMobileScreen}`}>
        {/* Profile images */}
        <div className="justify-between w-full rounded-3xl ">
          <div className="flex flex-col gap-0 pb-4">
            {/* Profile Image Section */}
            <div className="flex flex-col w-full ">
              <div className="w-full ">
                <div className="relative flex flex-col gap-0 ">
                  <div className="absolute w-full top-0 left-0">
                    {getProfileCover() && (
                      <img
                        alt="Profile Banner"
                        loading="lazy"
                        src={getProfileCover()}
                        className="w-full justify-center items-center w-full text-2xl text-center text-white max-w-full max-h-[200px] min-h-[200px] max-sm:text-base"
                      />
                    )}
                    {!getProfileCover() && (
                      <>
                        <div className="flex overflow-hidden relative flex-col justify-center text-white max-w-full">
                          <img
                            alt="No Profile Banner"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8894b37ebcd17fd7f2eff898d1c991259450ae9e69e79a2ddef3b750e95f966a?"
                            className="object-cover absolute inset-0 size-full"
                          />
                          <div className="flex relative gap-5 max-sm:gap-2 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                            <div className="flex gap-5 max-sm:gap-2 justify-between text-sm leading-8 max-md:flex-wrap">
                              <div className="my-auto">GOVERNANCE IN PREVENTING FRAUD</div>
                            </div>
                            <div className="my-auto text-2xl font-semibold max-sm:text-xl">
                              My Profile
                            </div>
                          </div>
                        </div>
                        <div className="m-auto justify-center items-center px-16 pt-24 pb-20 w-full text-2xl text-center text-white bg-[#20396B] max-w-full max-md:px-5 max-md:max-w-full max-sm:text-base">
                          Profile Cover / Ad Space (1000px x 200px)
                        </div>
                      </>
                    )}
                  </div>
                  <div className="absolute top-32 left-4 w-36 h-36 object-cover flex flex-row justify-between items-center max-md:ml-0 max-md:w-full">
                    <img
                      alt="img"
                      loading="lazy"
                      srcSet={user.profile_photo_path || ProfilePic}
                      className="p-1 w-36 h-36 max-w-full rounded-full border border-solid border-white border-4 aspect-square max-md:mt-4"
                    />
                  </div>
                  <div className="absolute top-44 right-4">
                    <img
                      alt="img"
                      loading="lazy"
                      srcSet={profileRegisteredIcon}
                      className="w-full w-12 h-12"
                    />
                    <div className="text-sm font-semibold text-sky-600 flex justify-end">
                      {user.serial_number}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* User Info Section */}
            <div className="flex flex-col mx-4 w-full mt-72"> {/* Added margin-top (mt-48) */}
              <div className={`flex flex-col grow self-stretch max-md:mt-4 max-md:max-w-full`}>
                <div className="flex flex-row gap-2 items-end ">
                  <div className="text-2xl font-bold  text-neutral-800">
                    {`${user.fullname} `}
                  </div>
                  <div className="py-1 text-sm  text-neutral-800">
                    {`${overview?.aka ? `(${overview?.aka})` : ''}`}
                  </div>

                </div>

                <div className="flex gap-2 items-center mb-4">
                  <div className="text-lg leading-8 text-neutral-600 max-md:max-w-full">
                    {user.position || 'Full Stack Developer'}
                  </div>
                  <div className="flex gap-1 justify-center items-center px-2 my-auto text-sm text-[#0082BA] bg-[#DEF5FF] rounded-[100px]">
                    <img src={CheckIcon} alt="Check Icon" />
                    <div className="align-middle pr-1">Available to Work</div>
                  </div>
                </div>

                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={AddressIcon}
                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full text-start">
                    {`${user.address.city}, ${user.address.state}, ${user.address.country}, ${user.address.postal_code}`}
                  </div>
                </div>

                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={LanguagesIcon}
                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full">
                    {`I speak ${getAllLanguages()}`}
                  </div>
                </div>
                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={SalaryIcon}
                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full">
                    {`${overview?.salary_rate_currency ? overview?.salary_rate_currency : 'USD'} ${overview?.salary_rate}/${overview?.salary_rate_frequency ? overview?.salary_rate_frequency : 'hr'} (hidden)`}
                  </div>
                </div>
                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={TradeLicenseIcon}
                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full">
                    {`Trade License: ${overview?.trade_license_number || 'n/a'} `}
                  </div>
                </div>
                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={WorkArrangementIcon}

                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full">
                    {overview?.availability || 'n/a'}
                  </div>
                </div>
                <div className="flex gap-3 my-1 text-base leading-8 text-neutral-600 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src={AvailabilityIcon}
                    className="shrink-0 my-auto w-5 aspect-square"
                  />
                  <div className="max-md:max-w-full">
                    {overview?.work_setup_preferences || 'n/a'}
                  </div>
                </div>

              </div>
            </div>

            {/* Socials */}
            <div className="flex flex-wrap items-center gap-4 ml-4 mt-2">
              {
                socialLinks.instagram.link !== '' &&
                <a href={socialLinks.instagram.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d7e3633d257a7a7e332af5855b08b3bfd4cc547dd711e3078ddf2eb6e2ae2ae?"
                    className="shrink-0 w-6 aspect-square" />
                </a>
              }

              {socialLinks.facebook.link !== '' &&
                <a href={socialLinks.facebook.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ef7d187c435b319a4d45266d8072a816b3823db115ef1616858f850369e12e7?"
                    className="shrink-0 self-start w-6 aspect-[1.04]" />
                </a>
              }

              {socialLinks.linkedIn.link !== '' &&
                <a href={socialLinks.linkedIn.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1004beebd7f5c42c5945575d17b03265ae09c02b044c20d167d690203913db5?"
                    className="shrink-0 w-6 aspect-square" />
                </a>
              }

              {socialLinks.youtube.link !== '' &&
                <a href={socialLinks.youtube.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7b594e53a34401e179038ba3b890886f32acd350f572ec9ffc25ba70991b73b?"
                    className="shrink-0 my-auto w-6 aspect-[1.41]" />
                </a>
              }

              {socialLinks.discord.link !== '' &&
                <a href={socialLinks.discord.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c01eff91790244c9d1897a0ad8dd370f731f543b57cadcac817d10352c7f5894?"
                    className="shrink-0 my-auto w-6 aspect-[1.27]" />
                </a>
              }

              {socialLinks.tiktok.link !== '' &&
                <a href={socialLinks.tiktok.link} target="_blank" rel="noopener noreferrer">
                  <img alt="img" loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbb2e53d8c766350182b7229306fbd6d264bcbc85d009bfc4e467ecd6069ff57?"
                    className="shrink-0 w-6 aspect-square" />
                </a>
              }
            </div>
          </div>

          {/* ---------------------------------------- */}
          <div className="justify-start w-full  w-full w-full">
            <div className="flex gap-10 flex-col gap-0 ">
              <div className="flex flex-col w-[80%] max-md:ml-0 w-full">
                <div className="flex flex-col grow self-stretch px-2 mt-10 w-full">

                  {/* INFO - Summary */}
                  <div className="flex justify-between flex-wrap  px-2 items-center">
                    <div className="flex flex-col items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="w-5 aspect-square mb-2"
                      />
                      <div className="text-xs font-semibold">
                        {`${overview?.year_of_experience || 0} Years`}
                      </div>
                      <div className=" text-xs text-neutral-400">Work Experienced</div>
                    </div>

                    <div className="flex flex-col items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="w-5 aspect-square mb-2"
                      />
                      <div className="text-xs font-semibold">
                        {`${overview?.projects_completed || 0} Projects`}
                      </div>
                      <div className=" text-xs text-neutral-400">Completed</div>
                    </div>


                    <div className="flex flex-col items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="w-5 aspect-square mb-2"
                      />
                      <div className="text-xs font-semibold">
                        {`${awards?.length || 0}`} Awards
                      </div>
                      <div className=" text-xs  text-neutral-400">Achievements</div>
                    </div>

                    <div className="flex flex-col items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="w-5 aspect-square mb-2"
                      />
                      <div className="text-xs font-semibold">
                        {`${educationAndCertifications?.length || 0}`} Certificates
                      </div>
                      <div className=" text-xs  text-neutral-400">Accomplishment</div>
                    </div>

                  </div>

                  <button className="mx-2 my-8 p-2 bg-[#1C3775] text-white rounded-md ">Send Message</button>

                  {/* Menu */}
                  <HorizontalRule withPadding='false' />
                  {/* Menu */}
                  {/* <div ref={sectionRefs.section0}
                    className="m-auto flex gap-5 justify-between w-full py-2 pr-2 max-w-full text-xl leading-8  border-b border-solid border-neutral-400 text-neutral-700  max-md:flex-wrap">
                    <div className="font-bold text-blue-900"><button onClick={() => scrollToSection('section1')}>Overview</button></div>
                    <div><button onClick={() => scrollToSection('section6')}>Features</button></div>
                    <div><button onClick={() => scrollToSection('section2')}>Work History</button></div>
                    <div><button onClick={() => scrollToSection('section3')}>Skill & Expertise</button></div>
                    <div><button onClick={() => scrollToSection('section4')}>Education</button></div>
                    <div className="max-md:max-w-full" onClick={() => scrollToSection('section5')}><button>Awards</button></div>
                  </div> */}

                  <div ref={sectionRefs.section0} className="p-2 w-full flex justify-between ">
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section1')}>Overview</button>
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section6')}>Features</button>
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section2')}>Work History</button>
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section3')}>Skill & Expertise</button>
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section4')}>Education</button>
                    <button className="p-2 rounded-md text-[9px] bg-[#1C3775] text-white font-bold" onClick={() => scrollToSection('section5')}>Awards</button>
                  </div>


                  <HorizontalRule withPadding='false' />

                  <div className="px-2">
                    {/* ABOUT USER */}
                    <div ref={sectionRefs.section1} className="flex flex-col mt-6 rounded-xl w-full">
                      <div className="text-xl font-semibold text-neutral-800 max-w-full">
                        {`About ${user?.first_name}`}
                      </div>
                      <div className="mt-3 text-sm text-neutral-600 max-w-full">
                        {`${overview?.about}`}
                      </div>
                      <HorizontalRule />
                    </div>

                    {/* Featured Project */}
                    {featuredProject !== null &&
                      <div ref={sectionRefs.section6} className="flex flex-col rounded-xl text-neutral-800 max-md:max-w-full">
                        <div className="text-xl font-semibold max-md:max-w-full">Features</div>
                        <a href={featuredProject?.link || '#'}>
                          <img
                            alt="img"
                            loading="lazy"
                            srcSet={featuredProject?.banner || featuredProj}
                            className="mt-2 mb-5 w-full aspect-[2.38] max-md:max-w-full"
                          />
                        </a>
                        <div className="text-base font-semibold max-md:max-w-full">{featuredProject?.title || ''}</div>
                        <div className="mt-2 text-sm text-neutral-600 max-md:max-w-full">
                          {`${featuredProject?.content || ''}`}
                        </div>
                        <div className="mt-2 text-sm text-neutral-600 max-md:max-w-full">
                          {/* {splitKeywords?.length > 0 && `Keywords: `} */}
                          {splitKeywords?.map((item) => {
                            return <div className="text-xs inline-block justify-center mx-1 p-2 rounded-2xl bg-neutral-100">{item}</div>
                          })}
                        </div>
                        <HorizontalRule />
                      </div>
                    }

                    {/* Work History */}
                    {workHistory.length > 0 &&
                      <div ref={sectionRefs.section2} className="flex flex-col w-full m-auto ">
                        <div className="text-xl font-semibold text-neutral-800 max-w-full 	">
                          Work history
                        </div>
                        {workHistory?.map((item, index) => {
                          return <>
                            <WorkHistoryItem data={item} index={index} showControls={false} />
                          </>
                        })}
                      </div>
                    }

                    <HorizontalRule />

                    {/* SKILL EXPERTISE */}
                    {skills?.length > 0 &&
                      <div ref={sectionRefs.section3} className="flex flex-col items-start text-base rounded-xl text-neutral-600 max-md:max-w-full">
                        <div className="self-stretch text-lg font-semibold text-neutral-800 max-md:max-w-full">
                          Skills & Expertise
                        </div>
                        <div className="flex flex-wrap mt-3">
                          {
                            skills?.map((item, index) => {
                              return <Bubble showControls={false} title={item.name} color='bg-[#F3F5FD] border text-base' />
                            })
                          }
                        </div>
                      </div>
                    }
                    <HorizontalRule />

                    {/* Educational Cert */}
                    {educationAndCertifications?.length > 0 &&
                      <div ref={sectionRefs.section4} className="flex flex-col text-base rounded-xl text-neutral-600 max-md:max-w-full">
                        <div className="text-lg font-semibold mb-4 text-neutral-800 max-md:max-w-full">
                          Educational & Certification
                        </div>
                        {educationAndCertifications?.map((item, index) => {
                          return <EducationCertItem data={item} index={index} showControls={false} />
                        })}
                      </div>
                    }

                    <HorizontalRule />

                    {/* Honors and Awards */}
                    {awards?.length > 0 &&
                      <div ref={sectionRefs.section5} className="flex flex-col text-base rounded-xl text-neutral-600 w-full">
                        <div className="text-lg font-semibold mb-4 text-neutral-800 w-full">
                          Honors & awards
                        </div>
                        {awards?.map((item, index) => {
                          return <HonorAwardsItems data={item} index={index} showControls={false} />
                        })}
                      </div>
                    }

                    {/* Admin's feedback */}
                    <div className={`mb-32 flex flex-col text-base w-full ${ResponsiveValues.cardSectionStyle}`}>
                      <div className="text-base font-semibold text-neutral-800 w-full flex flex-row justify-between items-center">
                        <span>Admin's Feedback</span>
                        <button><FaRegQuestionCircle size={15} /></button>
                      </div>
                      <div className="m-4 flex flex-row space-x-2">
                        {
                          Array.from({ length: overview?.admin_rating || 0 }).map((_, index) => (
                            <FaStar class="fill-amber-500" size={25}></FaStar>
                          ))
                        }
                        {
                          Array.from({ length: 5 - overview?.admin_rating || 0 }).map((_, index) => (
                            <FaRegStar class="fill-amber-500" size={25}></FaRegStar>
                          ))
                        }
                      </div>
                      <div className="flex flex-row space-x-2 text-neutral-800">
                        <FaRegThumbsUp class="fill-neutral-500" size={25} />
                        <span>100% Reliable</span>
                      </div>
                    </div>

                    {/* Give feedback */}
                    {/* <div className={`flex flex-col text-base w-full ${ResponsiveValues.cardSectionStyle}`}>
                      <div class='flex flex-row justify-between items-center'>
                        <span className="text-base font-semibold text-neutral-800">
                          Contact Register Admin
                        </span>
                        {success && <SuccessIndicator message="Account updated." />}
                      </div>
                      <textarea
                        value={addFeedback}
                        onChange={(e) => { setAddFeedback(e.target.value) }}
                        placeholder="Say Something..."
                        name="" id="" rows="4"
                        className="w-full my-4 p-2 rounded-md border" />

                      <div className="w-full">
                        <button
                          onClick={() => { handleAddFeedback() }}
                          className="w-full p-2 bg-[#1C3775] text-white rounded-md ">
                          Send
                        </button>
                      </div>
                      {!success && error && <FailureIndicator message={error || "Account not updated."} />}
                    </div> */}

                    {loading && (<LoadingIndicator loading={loading} styling="px-10 max-md:px-5 max-md:ml-2" />)}

                    {/* Back to top */}
                    <div
                      className={`fixed right-4 bottom-4  px-4 py-2 border text-[#0082BA] bg-[#DEF5FF] border-solid border-[#DEF5FF]	rounded-full transition-transform transform ${(isSticky) ? 'sticky' : 'hidden'
                        }`}
                      style={{
                        position: isSticky ? 'fixed' : 'absolute',
                      }}
                    >
                      <FcUp size={30} onClick={() => scrollToSection('section0')} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`max-w-[1366px] mx-auto`}>
        <div className={ResponsiveValues.forDesktopScreen}>
          {getProfileCover() &&
            <img alt="Profile Banner"
              loading="lazy"
              src={getProfileCover()}
              className="m-auto justify-center items-center w-full text-2xl text-center text-white bg-white max-w-full max-h-[200px] min-h-[200px] max-sm:text-base"
            />
          }
          {!getProfileCover() &&
            <>
              <div className="flex overflow-hidden relative flex-col justify-center text-white max-w-full max-md:max-w-full m-auto">
                {/* <img
                alt="No Profile Banner"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8894b37ebcd17fd7f2eff898d1c991259450ae9e69e79a2ddef3b750e95f966a?"
                className="object-cover absolute inset-0 size-full"
              /> */}
                <div className="flex relative gap-5 max-sm:gap-2 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-5 max-sm:gap-2 justify-between text-sm leading-8 max-md:flex-wrap">
                    <div className="my-auto">GOVERNANCE IN PREVENTING FRAUD</div>
                  </div>
                  <div className="my-auto text-2xl font-semibold max-sm:text-xl">
                    My Profile
                  </div>
                </div>
              </div>
              <div className="m-auto justify-center items-center px-16 pt-24 pb-20 w-full text-2xl text-center text-white bg-[#20396B] max-w-full max-md:px-5 max-md:max-w-full max-sm:text-base">
                Profile Cover / Ad Space (1000px x 200px)
              </div>
            </>
          }
          {/* Profile Info */}
          <div className="justify-between py-9 w-full bg-white rounded-3xl max-w-full max-md:max-w-full m-auto">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[57%] max-md:ml-0 max-md:w-full">
                <div className="max-md:mt-9 max-md:max-w-full">
                  <div className="flex max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col items-start max-md:ml-0 max-md:w-full">
                      <img
                        alt="img"
                        loading="lazy"
                        srcSet={user.profile_photo_path || ProfilePic}
                        className="p-2 w-52 max-w-full rounded-full border border-solid border-white border-2 aspect-square max-md:mt-4"
                      />
                    </div>
                    <div className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
                      <div className={`flex flex-col grow self-stretch max-md:mt-4 max-md:max-w-full`}>
                        <div className="flex gap-4 max-sm:gap-1 max-md:flex-wrap">
                          <div className="text-4xl font-semibold leading-10 text-neutral-800 max-sm:text-center">
                            {`${user.fullname} ${overview?.aka ? `(${overview?.aka})` : ''}`}
                          </div>
                        </div>

                        <div className="flex my-2 gap-4 items-center max-sm:gap-1 max-md:flex-wrap">
                          <div className="text-2xl font-semibold leading-8 text-neutral-600 max-md:max-w-full">
                            {user.position || 'Full Stack Developer'}
                          </div>
                          <div className="flex gap-1 justify-center items-center px-2 py-1.5 my-auto text-sm text-[#0082BA] bg-[#DEF5FF] rounded-[100px]">
                            <img src={CheckIcon} alt="Check Icon" />
                            <div className="align-middle pr-1">Pro Member</div>
                          </div>
                        </div>

                        <div className="flex gap-2 mt-2 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                          <img
                            alt="img"
                            loading="lazy"
                            src={AddressIcon}
                            className="shrink-0 my-auto w-5 aspect-square"
                          />
                          <div className="max-md:max-w-full text-start">
                            {`${user.address.city}, ${user.address.state}, ${user.address.country}, ${user.address.postal_code}`}
                          </div>
                        </div>
                        <div className="flex gap-2 mt-2 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                          <img
                            alt="img"
                            loading="lazy"
                            src={LanguagesIcon}
                            className="shrink-0 my-auto w-5 aspect-square"
                          />
                          <div className="max-md:max-w-full">
                            {`I speak ${getAllLanguages()}`}
                          </div>
                        </div>
                        <div className="flex gap-2 mt-2 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                          <img
                            alt="img"
                            loading="lazy"
                            src={SalaryIcon}
                            className="shrink-0 my-auto w-5 aspect-square"
                          />
                          <div className="max-md:max-w-full">
                            {`${overview?.salary_rate_currency ? overview?.salary_rate_currency : 'USD'} ${overview?.salary_rate}/${overview?.salary_rate_frequency ? overview?.salary_rate_frequency : 'hr'} (hidden)`}
                          </div>
                        </div>
                        <div className="flex gap-2 mt-2 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                          <img
                            alt="img"
                            loading="lazy"
                            src={TradeLicenseIcon}
                            className="shrink-0 my-auto w-5 aspect-square"
                          />
                          <div className="max-md:max-w-full">
                            {`Trade License: ${overview?.trade_license_number || 'n/a'} `}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[43%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow items-end pl-20 max-sm:pl-0 max-md:mt-9 max-md:max-w-full">
                  <img
                    alt="img"
                    loading="lazy"
                    srcSet={profileRegisteredIcon}
                    className="max-w-full aspect-[1.64] w-[152px] max-sm:mr-auto"
                  />
                  <div className="mt-2.5 text-xl font-semibold leading-8 text-sky-600 max-sm:mx-auto">
                    {user.serial_number}
                  </div>

                  <div className="flex flex-wrap items-center justify-center gap-4 pr-3.5 mt-10 max-sm:mx-auto">
                    {
                      socialLinks.instagram.link !== '' &&
                      <a href={socialLinks.instagram.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d7e3633d257a7a7e332af5855b08b3bfd4cc547dd711e3078ddf2eb6e2ae2ae?"
                          className="shrink-0 w-6 aspect-square"
                        />
                      </a>
                    }

                    {socialLinks.facebook.link !== '' &&
                      <a href={socialLinks.facebook.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ef7d187c435b319a4d45266d8072a816b3823db115ef1616858f850369e12e7?"
                          className="shrink-0 self-start w-6 aspect-[1.04]"
                        />
                      </a>
                    }

                    {socialLinks.linkedIn.link !== '' &&
                      <a href={socialLinks.linkedIn.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1004beebd7f5c42c5945575d17b03265ae09c02b044c20d167d690203913db5?"
                          className="shrink-0 w-6 aspect-square"
                        />
                      </a>
                    }

                    {socialLinks.youtube.link !== '' &&
                      <a href={socialLinks.youtube.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7b594e53a34401e179038ba3b890886f32acd350f572ec9ffc25ba70991b73b?"
                          className="shrink-0 my-auto w-6 aspect-[1.41]"
                        />
                      </a>
                    }

                    {socialLinks.discord.link !== '' &&
                      <a href={socialLinks.discord.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c01eff91790244c9d1897a0ad8dd370f731f543b57cadcac817d10352c7f5894?"
                          className="shrink-0 my-auto w-6 aspect-[1.27]"
                        />
                      </a>
                    }

                    {socialLinks.tiktok.link !== '' &&
                      <a href={socialLinks.tiktok.link} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbb2e53d8c766350182b7229306fbd6d264bcbc85d009bfc4e467ecd6069ff57?"
                          className="shrink-0 w-6 aspect-square"
                        />
                      </a>
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* ---------------------------------------- */}
          <div className="justify-start mt-1 mb-[200px] w-full  w-full max-md:mt-1 max-md:max-w-full">
            <div className="flex gap-10 max-md:flex-col max-md:gap-0 ">
              <div className="flex flex-col w-[80%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch px-2 max-md:mt-10 max-md:max-w-full">
                  {/* Menu */}
                  <div ref={sectionRefs.section0} className="m-auto flex gap-5 justify-between w-full py-2 pr-2 max-w-full text-xl leading-8  border-b border-solid border-neutral-400 text-neutral-700  max-md:flex-wrap">
                    <div className="font-bold text-blue-900"><button onClick={() => scrollToSection('section1')}>Overview</button></div>
                    <div><button onClick={() => scrollToSection('section6')}>Features</button></div>
                    <div><button onClick={() => scrollToSection('section2')}>Work History</button></div>
                    <div><button onClick={() => scrollToSection('section3')}>Skill & Expertise</button></div>
                    <div><button onClick={() => scrollToSection('section4')}>Education</button></div>
                    <div className="max-md:max-w-full" onClick={() => scrollToSection('section5')}><button>Awards</button></div>
                  </div>

                  {/* INFO */}
                  <div className="flex gap-3 justify-between max-md:flex-wrap mt-5 px-4 items-center">
                    <div className="flex gap-2 items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="shrink-0 my-auto w-9 aspect-square "
                      />
                      <div className="flex-col leading-4 ">
                        <div className="flex-1 font-bold">
                          {`${overview?.year_of_experience || 0} Years`}
                        </div>
                        <div className="flex-1 text-neutral-400">
                          Work Experienced
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="shrink-0 my-auto w-9 aspect-square "
                      />
                      <div className="flex-col leading-4 ">
                        <div className="flex-1 font-bold">
                          {`${overview?.projects_completed || 0} Projects`}
                        </div>
                        <div className="flex-1 text-neutral-400">Completed</div>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="shrink-0 my-auto w-9 aspect-square "
                      />
                      <div className="flex-col leading-4 ">
                        <div className="flex-1 font-bold">
                          {`${awards?.length || 0}`} Awards
                        </div>
                        <div className="flex-1 text-neutral-400">
                          Achievements
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <img
                        alt="img"
                        loading="lazy"
                        src={trophyProfile}
                        className="shrink-0 my-auto w-9 aspect-square "
                      />
                      <div className="flex-col leading-4 ">
                        <div className="flex-1 font-bold">
                          {`${educationAndCertifications?.length || 0}`} Certificates
                        </div>
                        <div className="flex-1 text-neutral-400">
                          Accomplishment
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-4">
                    {/* Featured Project */}
                    {featuredProject !== null &&
                      <div ref={sectionRefs.section6} className="flex flex-col py-4 mt-6  leading-10 bg-white rounded-xl text-neutral-800 max-md:max-w-full">
                        <div className="text-3xl font-semibold max-md:max-w-full">Features</div>
                        <a href={featuredProject?.link || '#'}>
                          <img
                            alt="img"
                            loading="lazy"
                            srcSet={featuredProject?.banner || featuredProj}
                            className="mt-2 mb-5 w-full aspect-[2.38] max-md:max-w-full"
                          />
                        </a>
                        <div className="text-2xl font-semibold max-md:max-w-full">Title: {featuredProject?.title || ''}</div>
                        <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                          {`${featuredProject?.content || ''}`}
                        </div>
                        <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                          {splitKeywords?.length > 0 && `Keywords: `}
                          {splitKeywords?.map((item) => {
                            return <div className="inline-block justify-center mx-1 px-3 rounded-2xl bg-neutral-100">{item}</div>
                          })}
                        </div>
                      </div>
                    }

                    {/* ABOUT USER */}
                    <div ref={sectionRefs.section1} className="flex flex-col max-md:mt-2 py-4 mt-6 bg-white rounded-xl max-md:max-w-full">
                      <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
                        {`About ${user?.first_name}`}
                      </div>
                      <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                        {`${overview?.about}`}
                      </div>
                    </div>

                    {/* Work History */}
                    {workHistory.length > 0 &&
                      <div ref={sectionRefs.section2} className="flex flex-col py-4 mt-6 w-full m-auto">
                        <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
                          Work history
                        </div>
                        {workHistory?.map((item, index) => {
                          return <>
                            <WorkHistoryItem data={item} index={index} showControls={false} />
                            {index !== workHistory.length - 1 && <div className="mt-6  border border-neutral-200"></div>}
                          </>
                        })}
                      </div>
                    }


                    {/* SKILL EXPERTISE */}
                    {skills?.length > 0 &&
                      <div ref={sectionRefs.section3} className="mt-6 flex flex-col items-start text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                        <div className="self-stretch text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                          Skills & Expertise
                        </div>
                        <div className="flex flex-wrap mt-3">
                          {
                            skills?.map((item, index) => {
                              return <Bubble showControls={false} title={item.name} color='bg-[#F3F5FD]' />
                            })
                          }
                        </div>
                      </div>
                    }

                    {/* Educational Cert */}
                    {educationAndCertifications?.length > 0 &&
                      <div ref={sectionRefs.section4} className="flex flex-col py-4 mt-6 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                        <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                          Educational & Certification
                        </div>
                        {educationAndCertifications?.map((item, index) => {
                          return <EducationCertItem data={item} index={index} showControls={false} />
                        })}
                      </div>
                    }


                    {awards?.length > 0 &&
                      <div ref={sectionRefs.section5} className="flex flex-col py-4 mt-6 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                        <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                          Honors & awards
                        </div>
                        {awards?.map((item, index) => {
                          return <HonorAwardsItems data={item} index={index} showControls={false} />
                        })}
                      </div>
                    }

                    {/* Back to top */}
                    <div
                      className={`fixed right-4 bottom-4 lg:bottom-10 px-4 py-2 border text-[#0082BA] bg-[#DEF5FF] border-solid border-[#DEF5FF]	rounded-full transition-transform transform ${(isSticky) ? 'sticky' : 'hidden'
                        }`}
                      style={{
                        position: isSticky ? 'fixed' : 'absolute',
                      }}
                    >
                      <FcUp size={30} onClick={() => scrollToSection('section0')} />
                    </div>
                  </div>

                </div>
              </div>
              <ProfileSidePanel data={
                {
                  availability: overview?.availability || '',
                  work_arrangement: overview?.work_setup_preferences || '',
                  work_arrangement_other: overview?.work_arrangement_other,
                  // languages: languages,
                }
              } />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Profile;


const HorizontalRule = ({ withPadding = true }) => {
  return (
    <div className={`${withPadding === true ? 'my-6' : ''} border border-neutral-200`}></div>
  )
}

