import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";
import { PiCaretUpBold } from "react-icons/pi";

function SecuritySettings() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const uData = JSON.parse(localStorage.getItem('user'));

    setEmail(uData.email);
    setPhoneNumber(uData.phone_number);

  }, [email, phoneNumber]);

  return (
    <div className="p-2 min-h-[89vh]">
      <div className={`${ResponsiveValues.cardSectionStyle} shadow-md`}>
        <div className="p-0 md:p-4">
          <div className=" flex flex-row justify-between items-start pb-4 md:pb-0">
            <PageTitle title='Security Settings'
              customTextStyleDesktop={TextStyling.pageTitle}
              customTextStyleMobile={TextStyling.pageBodyBold} />
            {/* <PiCaretUpBold size={25} /> */}
          </div>
          <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1 p-0 m-0 w-full`} />

          <div className="flex flex-col w-full ">
            <div className={`mt-2 md:mt-8 ${TextStyling.inputLabel}`}>
              Password
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4">
              <div className={`${TextStyling.pageBody}`}>
                ••••••
              </div>
              <button
                onClick={() => {
                  navigate("/dashboard/account-settings/change-password");
                }}
                className={`mt-1 ${ResponsiveValues.forDesktopScreen} ${ResponsiveValues.addMore}`}>
                Change Password
              </button>

              <button
                onClick={() => {
                  navigate("/dashboard/account-settings/change-password");
                }}
                className={` ${ResponsiveValues.forMobileScreen} ${ResponsiveValues.buttonFull} mt-0`}>
                Change Password
              </button>
            </div>

            <div className={`mt-8 ${TextStyling.inputLabel}`}>
              Phone
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4">
              <div className={`${TextStyling.pageBody}`}>
                {phoneNumber}
              </div>
              <button
                onClick={() => {
                  navigate("/dashboard/account-settings/change-number");
                }}
                className={`mt-1 ${ResponsiveValues.forDesktopScreen} ${ResponsiveValues.addMore}`}>
                Change Number
              </button>
              <button
                onClick={() => {
                  navigate("/dashboard/account-settings/change-number");
                }}
                className={` ${ResponsiveValues.forMobileScreen} ${ResponsiveValues.buttonFull} mt-0`}>
                Change Number
              </button>
            </div>

            <div className={`mt-8 ${TextStyling.inputLabel}`}>
              Email
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4">
              <div className={`${TextStyling.pageBody}`}>
                {email}
              </div>
              <button
                onClick={() =>
                  navigate("/dashboard/account-settings/change-email")
                }
                className={`mt-1 ${ResponsiveValues.forDesktopScreen} ${ResponsiveValues.addMore}`}>
                Change Email
              </button>
              <button
                onClick={() =>
                  navigate("/dashboard/account-settings/change-email")
                }
                className={` ${ResponsiveValues.forMobileScreen} ${ResponsiveValues.buttonFull} mt-0`}>
                Change Email
              </button>
            </div>

            <div className="w-full md:w-fit self-center md:self-end">
              <button
                className="mt-12 w-full  flex justify-center items-center px-4 py-2 text-base font-semibold text-blue-900 border-blue-900 rounded-3xl border-[1px]"
                onClick={() =>
                  navigate("/dashboard/account-settings/recovery-codes")
                }
              >
                Generate New Recovery Codes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;
