import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, setChangePasswordError } from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";

function ChangePassword() {
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isFading, setIsFading] = useState(false);

  const dispatch = useDispatch();
  const securitySettings = useSelector((state) => state.settings.changePassword || {
    success: null,
    error: null,
    loading: false
  });

  useEffect(() => {

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isFading]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value));
      setPasswordsMatch(value === confirmPassword);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const handleChangePasswordRequest = async (e) => {
    e.preventDefault();

    if (oldPassword === password) {
      await dispatch(setChangePasswordError("New password cannot be the same with old password"));
      return;
    }

    const formData = {
      password: oldPassword,
      new_password: password,
      new_password_confirmation: confirmPassword,
    };

    const uData = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await dispatch(changePassword({ id: uData.id, data: formData }));
      if (response.payload?.message === 'Password changed successfully') {
        setIsFading(true);
        setshowNotif(true)
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [showNotif, setshowNotif] = useState(true);

  return (
    <>
      <div className={`p-2 min-h-[89vh] ${ResponsiveValues.forMobileScreen}`}>

        {/* title */}
        <div className=" flex flex-col justify-between items-start">
          <PageTitle title='Change Password'
            hasSpacing='false'
            customTextStyleDesktop={TextStyling.pageHeader1}
            customTextStyleMobile={TextStyling.pageBodyBold} />
          {/* <PiCaretUpBold size={25} /> */}
        </div>

        {/* Alerts */}
        <div className="flex justify-end h-4">
          {
            (showNotif === true && securitySettings.success) && <SuccessIndicator message="Password changed successfully" setShowNotif={setshowNotif} />
          }

          {(showNotif === true && securitySettings.error) && (
            <FailureIndicator message={securitySettings.error} setShowNotif={setshowNotif} />
          )}
          {securitySettings.loading && (
            <LoadingIndicator />
          )}
        </div>

        {/* Form */}
        <div className="flex flex-col w-full">
          <form onSubmit={handleChangePasswordRequest}>
            <div className={`${TextStyling.inputLabel}`}>
              Old Password
            </div>
            <input
              className={`mt-1 ${InputStyling.text}`}
              placeholder="Old Password"
              value={oldPassword}
              type="password"
              name="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
              autoComplete="on"
            />
            <div className={`mt-4 ${TextStyling.inputLabel}`}>
              New Password
            </div>
            <input
              className={`mt-1 ${InputStyling.text}`}
              placeholder="New Password"
              value={password}
              type="password"
              name="password"
              onChange={handleChange}
              autoComplete="off"
            />
            <div className={`mt-4 ${TextStyling.inputLabel}`}>
              Confirm New Password
            </div>
            <input
              className={`mt-1 ${InputStyling.text} ${passwordsMatch ? "border-neutral-400" : "border-red-600"}`}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={handleChange}
              type="password"
              name="confirmPassword"
              autoComplete="off"
            />

            <div className="flex flex-col mt-4 justify-end">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                disabled={!passwordsMatch || securitySettings.loading}
              >
                Save Password
              </button>
              <button
                type="button"
                onClick={() => navigate("/dashboard/account-settings")}
                className={`${ResponsiveValues.buttonFullSecondary}`}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>

        {/* Your password should: */}
        <div className="flex flex-col w-full mt-4">
          <div className={`mt-2 md:mt-4 ${TextStyling.pageBodyBold}`}>
            Your password should:
          </div>
          <div
            className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? "text-green-700" : "text-neutral-500"
              }`}
          >
            {isLengthValid ? (
              <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
            ) : (
              <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
            )}
            <div className={` ${TextStyling.pageBody}`}>
              Be at least 8 characters in length
            </div>
          </div>
          <div
            className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase ? "text-green-700" : "text-neutral-500"
              }`}
          >
            {hasUpperCase && hasLowerCase ? (
              <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
            ) : (
              <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
            )}
            <div className={` ${TextStyling.pageBody}`}>
              Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)
            </div>
          </div>
          <div
            className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? "text-green-700" : "text-neutral-500"
              }`}
          >
            {hasNumber ? (
              <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
            ) : (
              <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
            )}
            <div className={` ${TextStyling.pageBody}`}>
              Have at least one numerical character (e.g. 0-9)
            </div>
          </div>
          <div
            className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasSpecialChar ? "text-green-700" : "text-neutral-500"
              }`}
          >
            {hasSpecialChar ? (
              <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
            ) : (
              <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
            )}
            <div className={` ${TextStyling.pageBody}`}>
              Have at least one special character (e.g. ~!@#$%^&*()_-+=)
            </div>
          </div>
        </div>
      </div>

      <div className={`p-2 hidden md:flex justify-center`}>
        <div className={`min-w-[90vh] ${ResponsiveValues.cardSectionStyle} shadow-md`}>
          <div className="px-4 pb-1">

            {/* title */}
            <div className=" flex flex-col justify-between items-start pb-4 md:pb-2">
              <PageTitle title='Change Password'
                hasSpacing='false'
                customTextStyleDesktop={TextStyling.pageHeader1}
                customTextStyleMobile={TextStyling.pageBodyBold} />
              {/* <PiCaretUpBold size={25} /> */}
            </div>
            <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1 p-0 m-0 w-full`} />

            {/* Alerts */}
            <div className="flex justify-end h-4">
              {
                (showNotif === true && securitySettings.success) && <SuccessIndicator message="Password changed successfully" setShowNotif={setshowNotif} />
              }
              {(showNotif === true && securitySettings.error) && (
                <FailureIndicator message={securitySettings.error} setShowNotif={setshowNotif} />
              )}
              {securitySettings.loading && (
                <LoadingIndicator />
              )}
            </div>

            {/* Your password should: */}
            <div className="flex flex-col w-full">
              <div className={`mt-2 md:mt-4 ${TextStyling.pageBodyBold}`}>
                Your password should:
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? "text-green-700" : "text-neutral-500"
                  }`}
              >
                {isLengthValid ? (
                  <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className={` ${TextStyling.pageBody}`}>
                  Be at least 8 characters in length
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase ? "text-green-700" : "text-neutral-500"
                  }`}
              >
                {hasUpperCase && hasLowerCase ? (
                  <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className={` ${TextStyling.pageBody}`}>
                  Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? "text-green-700" : "text-neutral-500"
                  }`}
              >
                {hasNumber ? (
                  <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className={` ${TextStyling.pageBody}`}>
                  Have at least one numerical character (e.g. 0-9)
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasSpecialChar ? "text-green-700" : "text-neutral-500"
                  }`}
              >
                {hasSpecialChar ? (
                  <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className={` ${TextStyling.pageBody}`}>
                  Have at least one special character (e.g. ~!@#$%^&*()_-+=)
                </div>
              </div>
            </div>

            {/* Form */}
            <div className="flex flex-col w-full mt-12">
              <form onSubmit={handleChangePasswordRequest}>
                <div className={`${TextStyling.inputLabel}`}>
                  Old Password
                </div>
                <input
                  className={`mt-1 ${InputStyling.text}`}
                  placeholder="Old Password"
                  value={oldPassword}
                  type="password"
                  name="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                  autoComplete="on"
                />
                <div className={`mt-4 ${TextStyling.inputLabel}`}>
                  New Password
                </div>
                <input
                  className={`mt-1 ${InputStyling.text}`}
                  placeholder="New Password"
                  value={password}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  autoComplete="off"
                />
                <div className={`mt-4 ${TextStyling.inputLabel}`}>
                  Confirm New Password
                </div>
                <input
                  className={`mt-1 ${InputStyling.text} ${passwordsMatch ? "border-neutral-400" : "border-red-600"}`}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={handleChange}
                  type="password"
                  name="confirmPassword"
                  autoComplete="off"
                />

                <div className="flex gap-4 mt-4 justify-end">
                  <button
                    type="button"
                    onClick={() => navigate("/dashboard/account-settings")}
                    className={`${ResponsiveValues.buttonFitSecondary}`}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`${ResponsiveValues.buttonFit}`}
                    disabled={!passwordsMatch || securitySettings.loading}
                  >
                    Save Password
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </>

  );
}

export default ChangePassword;
