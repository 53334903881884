import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../../../authSlice";
import { workHistoryActions as action, getAllWorkHistory, getWorkHistory, addWorkHistory, updateWorkHistory, removeWorkHistory } from "./workHistorySlice";
import { workHistoryActions, getProjects, addProjectToWorkHistory, updateProjectThumbnailToWorkHistory } from "./workHistorySlice";
import imageCheck, { imageCheckSquare, imageCheckSquareFromUrl } from "../../../../../utils/imageCheck";
import dateHelper from "../../../../../utils/dateHelper";
import isValidImageUrl from "../../../../../utils/isValidImageUrl";
import useWorkHistoryValidation from "./useWorkHistoryValidation";


const useWorkHistory = () => {
	const dispatch = useDispatch();
	// const userData = useSelector(state => state.auth.user);
	const { success, error, loading, values, currentWorkProjects } = useSelector(state => state.workHistory);

	const { validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
		validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
		validateDescription, validateProjectTitle, validateProjectCategory } = useWorkHistoryValidation();

	const [displayedWorkHistories, setDisplayedWorkHistories] = useState(values);

	const postWorkHistoryDefaultVlues = {
		thumbnail: "",
		company_name: "",
		position: "",
		work_arrangement: "",
		date_started: "",
		date_ended: "",
		job_description: ""
	};
	const [postWorkHistory, setPostWorkHistory] = useState(postWorkHistoryDefaultVlues);
	const [workhistoryIdUpdate, setWorkhistoryIdUpdate] = useState(0);


	const [thumbnail, setThumbnail] = useState('');
	const [screenshots, setScreenshots] = useState([]);

	const [showAddButton, setShowAddButton] = useState(true);
	const [showEditButton, setShowEditButton] = useState(false);

	const [myError, setMyError] = useState('');

	const work_arrangements = [
		{
			name: "Freelance",
			value: "Freelance"
		},
		{
			name: "Fulltime",
			value: "Fulltime"
		},
		{
			name: "Part-time",
			value: "Part-time"
		},
		{
			name: "Contract",
			value: "Contract"
		},
		{
			name: "Others",
			value: "Other"
		},
	]

	useEffect(() => {
		clearInputFields();
		displayAddButton();
	}, []);

	useEffect(() => {
		if (error !== null) {
			setDisplayedWorkHistories(values);
		}
	}, [error, values]);

	const stringWorkHistoriesJSON = JSON.stringify(values);
	useEffect(() => {
		setDisplayedWorkHistories(values);
	}, [values, stringWorkHistoriesJSON]);

	const handleOnload = async () => {
		getData();
	}

	const getData = async () => {
		try {
			const result = await dispatch(getAllWorkHistory({}));
			const workHistoryData = result?.payload;
			if (workHistoryData.length > 0) {
				workHistoryData.forEach((item) => {
					getProjectsData(item.id);
				});
			}
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const getProjectsData = async (workHistoryId) => {
		try {
			await dispatch(getProjects({ workHistoryId: workHistoryId }));
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const handleImageUpload = (event) => {

		const result = imageCheckSquare(event, 2, setMyError);

		if (result) {
			const { image: thisImage, file } = result;
			setThumbnail(thisImage.src);
			setPostWorkHistory({ ...postWorkHistory, thumbnail: file });
		}
	};

	const handleAddbutton = async () => {
		try {
			const promises = await Promise.all([
				validateCompany(postWorkHistory.company_name),
				validatePostion(postWorkHistory.position),
				validateStarted(postWorkHistory.date_started),
				validateEnded(postWorkHistory.date_ended),
				validateWorkArrangement(postWorkHistory.work_arrangement),
				validateDescription(postWorkHistory.job_description),
				// () => {
				// 	validateProjectTitle(postWorkHistory.title);
				// },
				// () => {
				// 	validateProjectCategory(postWorkHistory.category);
				// }
			]);

			if (isSafeToSubmit) {
				const data = {
					thumbnail: postWorkHistory.thumbnail,
					position: postWorkHistory.position,
					work_arrangement: postWorkHistory.work_arrangement,
					company_name: postWorkHistory.company_name,
					date_started: postWorkHistory.date_started,
					date_ended: postWorkHistory.date_ended,
					job_description: postWorkHistory.job_description,
				};

				setTemporaryDisplayedWorkHistories(data);

				const result = await dispatch(addWorkHistory({ data: data }));
				const newlyAddedHistory = JSON.parse(result?.payload).data;

				if (newlyAddedHistory && newlyAddedHistory.id) {
					const projectPromises = await addProjectsToWorkHistory(newlyAddedHistory.id, currentWorkProjects);
					await Promise.all(projectPromises);
				}

				getData();
				clearInputFields();
			}

		} catch (err) {
			await dispatch(workHistoryActions.setError(`Error: ${err}`));
		}
	}

	const setTemporaryDisplayedWorkHistories = (data) => {
		// For date to be displayed early and not wait for server return values.
		const tempId = (displayedWorkHistories && displayedWorkHistories.length > 0)
			? (displayedWorkHistories[displayedWorkHistories.length - 1].id + 1)
			: 1;

		setDisplayedWorkHistories(
			[...displayedWorkHistories,
			{ ...data, id: tempId, }
			]
		);
	}

	const addProjectsToWorkHistory = async (workHistoryId, projects) => {
		try {
			const projectRequests = projects.map(async (project, projectIndex) => {
				if (project.title !== "" && project.category !== "") {
					const projectResult = await dispatch(addProjectToWorkHistory(
						{
							workHistoryId: workHistoryId,
							data: {
								title: project.title,
								description: project.description || "Project Description",
								category: project.category,
								is_featured: 0,
								thumbnail: screenshots[projectIndex].file
							}
						}
					));
					return JSON.parse(projectResult?.payload).data;
				}
			});

			return projectRequests;
		} catch (err) {
			throw err;
		}
	}

	const updateProjectsToWorkHistory = async (workHistoryId, projects) => {
		try {

			// console.log(`projects : ${JSON.stringify(projects)}`);
			// return;
			const promises = await Promise.all([
				validateCompany(postWorkHistory.company_name),
				validatePostion(postWorkHistory.position),
				validateStarted(postWorkHistory.date_started),
				validateEnded(postWorkHistory.date_ended),
				validateWorkArrangement(postWorkHistory.work_arrangement),
				validateDescription(postWorkHistory.job_description),
				// () => {
				// 	validateProjectTitle(postWorkHistory.title);
				// },
				// () => {
				// 	validateProjectCategory(postWorkHistory.category);
				// }
			]);

			if (isSafeToSubmit) {
				const projectRequests = projects.map(async (project, projectIndex) => {

					let data = {};
					let projectResult = null;

					if (project.title !== "" && project.category !== "") {
						if (!project.id) {
							data = {
								workHistoryId: workHistoryId,
								data: {
									title: project.title,
									description: project.description || "Project Description",
									category: project.category,
									is_featured: 0,
									thumbnail: screenshots[projectIndex]?.file
								}
							};
							projectResult = await dispatch(addProjectToWorkHistory(data));
						} else {

							if (screenshots[projectIndex]?.file && !isValidImageUrl(screenshots[projectIndex].file)) {
								data = {
									workHistoryId: workHistoryId,
									projectId: project.id,
									data: {
										title: project.title,
										description: project.description || "Project Description",
										category: project.category,
										is_featured: 0,
										thumbnail: screenshots[projectIndex]?.file
									}
								};
							} else {
								data = {
									workHistoryId: workHistoryId,
									projectId: project.id,
									data: {
										title: project.title,
										description: project.description || "Project Description",
										category: project.category,
										is_featured: 0,
									}
								};
							}


							projectResult = await dispatch(updateProjectThumbnailToWorkHistory(data));
						}
						return JSON.parse(projectResult?.payload).data;
					}

				});

				return projectRequests;
			}
		} catch (err) {
			throw err;
		}
	}

	const clearInputFields = () => {
		dispatch(workHistoryActions.clearWorkHistoryProjects());
		setPostWorkHistory(postWorkHistoryDefaultVlues);
		setThumbnail("");
		setScreenshots([]);
	}

	const handleCancelEdit = () => {
		clearInputFields();
		showAddButton();
	}

	const handleEditButton = async (editWorkHostoryId) => {
		displayUpdateButton();
		clearInputFields();
		setWorkhistoryIdUpdate(editWorkHostoryId);

		await rePopulateForEditing(displayedWorkHistories, editWorkHostoryId);
	}

	const handleUpdateButton = async () => {
		try {

			let data = {
				position: postWorkHistory.position,
				work_arrangement: postWorkHistory.work_arrangement,
				company_name: postWorkHistory.company_name,
				date_started: dateHelper.isDatetimeChecker(postWorkHistory.date_started) ? dateHelper.datetimeToInputDate(postWorkHistory.date_started) : postWorkHistory.date_started,
				date_ended: dateHelper.isDatetimeChecker(postWorkHistory.date_ended) ? dateHelper.datetimeToInputDate(postWorkHistory.date_ended) : postWorkHistory.date_ended,
				job_description: postWorkHistory.job_description,
			};

			if ((thumbnail && !isValidImageUrl(thumbnail))) {
				data = { ...data, thumbnail: postWorkHistory.thumbnail };
			}

			await dispatch(updateWorkHistory({ workHistoryId: workhistoryIdUpdate, data: data }));
			const projectPromises = await updateProjectsToWorkHistory(workhistoryIdUpdate, currentWorkProjects);
			await Promise.all(projectPromises);
			getData();

			clearInputFields();
			displayAddButton();
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const displayAddButton = () => {
		setShowAddButton(true);
		setShowEditButton(false);
	}
	const displayUpdateButton = () => {
		setShowAddButton(false);
		setShowEditButton(true);
	}

	const rePopulateForEditing = async (workHistories, editWorkHostoryId) => {
		const workHistory = await workHistories.filter((item) => item.id === editWorkHostoryId)[0];

		await setPostWorkHistory(workHistory);
		await setThumbnail(workHistory.company_logo);

		const tempScreenshots = [];
		await workHistory?.projects?.forEach((item, index) => {
			handlePopulateContainer(index, "id", item.id)
			handlePopulateContainer(index, "title", item.title)
			handlePopulateContainer(index, "category", item.category);

			dispatch(workHistoryActions.setEmptyProjectToWorkHistory());

			tempScreenshots.push({
				index: index,
				imageSrc: item.thumbnail_path,
			});

		});

		await setScreenshots(tempScreenshots);
		await dispatch(workHistoryActions.removeBlankWorkHistoryProjects());

	}

	const handleRemoveButton = async (intId) => {
		try {

			const newDisplayedWorkHistories = displayedWorkHistories.filter((item) => item.id !== intId);
			setDisplayedWorkHistories(newDisplayedWorkHistories);

			await dispatch(removeWorkHistory({ workHistoryId: intId }));
			getData();

		} catch (err) {
			await dispatch(workHistoryActions.setError(`Error: ${err}`))
		}
	}

	const handleAddContainer = () => {
		dispatch(workHistoryActions.setEmptyProjectToWorkHistory());
	}

	const handlePopulateContainer = (index, field, value) => {
		dispatch(workHistoryActions.updateEmptyProjectToWorkHistory({ index: index, field: field, value: value }));
	}

	const handleProjectImages = (event, index) => {

		const result = imageCheck(event, 2, 1024, 687, setMyError);
		if (result) {
			const { image: thisImage, file } = result;

			const screenshotToBeUpdated = screenshots.filter((item) => item.index === index);

			if (screenshotToBeUpdated.length > 0) {
				const updatedScreenshots = [...screenshots];

				updatedScreenshots[index] = {
					index: index,
					imageSrc: thisImage.src,
					file: file,
				}
				setScreenshots(updatedScreenshots);
			} else {
				setScreenshots([...screenshots, {
					index: index,
					imageSrc: thisImage.src,
					file: file,
				}]);
			}
		}
	}


	return {
		success, error, loading,
		myError,
		showAddButton, showEditButton,
		displayedWorkHistories,
		postWorkHistory, setPostWorkHistory,
		currentWorkProjects, screenshots, handleAddContainer, handlePopulateContainer, handleProjectImages,
		thumbnail,
		work_arrangements,
		handleOnload, getProjectsData,
		handleAddbutton,
		handleEditButton, handleCancelEdit, handleUpdateButton,
		handleRemoveButton,
		handleImageUpload,
		validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
		validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
		validateDescription, validateProjectTitle, validateProjectCategory
	}

}

export default useWorkHistory;