import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import helpApi from './helpApi';
import formDataHelper, { formUrlEncoded } from '../../../../../utils/formDataHelper';


export const addFeedback = createAsyncThunk('/help/addFeedback', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await helpApi.postFeedback(formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addCompliment = createAsyncThunk('/help/addCompliment', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await helpApi.postCompliment(formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addComplaint = createAsyncThunk('/help/addComplaint', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await helpApi.postComplaint(formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const helpSlice = createSlice({
  name: 'help',
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // addFeedback
      .addCase(addFeedback.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addFeedback.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addFeedback.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // addCompliment
      .addCase(addCompliment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addCompliment.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addCompliment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // add
      .addCase(addComplaint.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addComplaint.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addComplaint.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
  }
});

export const helpAction = helpSlice.actions;
export default helpSlice;