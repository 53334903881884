import { useState, useEffect } from 'react';

import Debounce from '../../../utils/Debounce';
import { DebounceDelay } from '../../../utils/Debounce';
import ValidationHelper from '../../../utils/ValidationHelper';

const useMessagesValidation = () => {
  const itemDefaultValue = { isValid: false, errorMessage: null };
  const [validationErrorMessage, setValidationErrorMessage] = useState({
    message: itemDefaultValue,
  });
  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState('false');

  const [showNotif, setShowNotif] = useState(true);
  useEffect(() => {
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
      }, 15000);
    } else {
      setShowNotif(true);
    }
  }, [showNotif, strValidationErrorMessage]);

  const validateMessage = Debounce((str) => {

    const { isValid, errorMessage } = ValidationHelper.stringCountMin({ str: str, intCount: 3 });

    if (!str || str === '') {
      setIsSafeToSubmit(false);
      setValidationErrorMessage(prevState => ({ ...prevState, message: { isValid: false, errorMessage: null } }));
    }

    if (!isValid) {
      setIsSafeToSubmit(false);
      setValidationErrorMessage(prevState => ({ ...prevState, message: { isValid: false, errorMessage: errorMessage } }));
    }

    if (isValid) {
      setIsSafeToSubmit(true);
      setValidationErrorMessage(prevState => ({ ...prevState, message: { isValid: true, errorMessage: null } }));
    }

  }, 1);


  return {
    showNotif, isSafeToSubmit,
    validationErrorMessage,
    validateMessage,
  };

}

export default useMessagesValidation;
