import React, { useEffect, useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { FaCoins } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import stripe_logo from "../../assets/stripe_logo.svg";
import visa_logo from "../../assets/visa_logo.svg";
import mastercard_logo from "../../assets/mastercard_logo.svg";


import useStripePayment from "../../api/customHook/useStripePayment";
import { useSelector } from "react-redux";
import { FormattedNumber } from "react-intl";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";

import FailureIndicator from "../../components/FailureIndicator";

const StripePayment = () => {
  const [showCardElement, setShowCardElement] = useState(false);

  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);

  const {
    handleSubmit,
    handleCardChange, cardComplete, cardError,
    showConfirmation, setShowConfirmation, clientSecret,
    handlConfirmPayment,
    errorMessage,
    coupon,
    couponValue,
    setCoupon,
    handleValidateCoupon,
    handleMembershipValues,
    membershipCountryValues,
    ismodalOpen,
  } = useStripePayment();

  const handleAddPaymentMethod = () => {
    setShowCardElement(true);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: 400,
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
      invalid: {
        iconColor: "#E25950",
        color: "#E25950",
      },
    },
  };

  useEffect(() => {
    if (membershipCountryValues.updated === false) {
      handleMembershipValues();
    }
  });

  useEffect(() => {
    if (auth.coupon.error) {
      setIsFading(true);

      var tm = setTimeout(() => {
        setIsFading(false);
        setCoupon("");
      }, 3000);

      return () => {
        clearTimeout(tm);
      };
    }
  }, [auth.coupon.error]);

  return (
    <>
      <div className={`flex flex-col mx-auto md:max-w-[900px] md:px-10`}>
        <div className={`container mx-auto flex flex-wrap lg:flex-nowrap justify-center ${ResponsiveValues.cardScreenStyle}`}>
          <div className="section-1 md:pr-8 min-w-[20rem] md:border-r">
            <div className={`${TextStyling.pageSubTitle}`}>
              Payment Method
            </div>
            <div className='my-6 flex flex-row justify-center md:justify-start items-center space-x-2'>
              <div className={`p-2 h-[40px] w-[80px] flex justify-center items-center md:border md:border-solid md:border-blue-900 md:rounded-md`}>
                <img src={stripe_logo} alt="" />
              </div>

              <div className={`p-2 h-[40px] w-[80px] flex justify-center items-center md:border md:border-solid md:border-blue-900 md:rounded-md`}>
                <img src={visa_logo} alt="" />
              </div>

              <div className={`p-2 h-[40px] w-[80px] flex justify-center items-center md:border md:border-solid md:border-blue-900 md:rounded-md`}>
                <img src={mastercard_logo} alt="" />
              </div>
            </div>
            <button
              className="font-semibold text-neutral-600"
              onClick={handleAddPaymentMethod}
            >
              + Add Payment Method
            </button>
            {showCardElement && (
              <div className='pt-2'>
                {/* <div className="border-black border-2 w-[100px] my-5 text-center py-1 font-bold text-2xl text-[#635bff]">
                  Stripe
                </div> */}

                <CardElement
                  options={CARD_ELEMENT_OPTIONS}
                  className="StripeElement"
                  onChange={handleCardChange}
                />
                <div id="card-errors" role="alert">
                  {errorMessage && <span>{errorMessage}</span>}
                </div>
              </div>
            )}
            {(cardError) && <FailureIndicator message={cardError} />}

          </div>

          <div className={`section-2 md:min-w-[24rem]`}>
            <div className="bg-white rounded-3xl md:px-10">
              <div className="flex justify-between">
                <div className={`${TextStyling.pageHeader1}`}>
                  Order Summary
                </div>
                <div className="flex items-center justify-center gap-2 text-neutral-400">
                  <span><FaCoins color="#facc26" /></span>
                  <span className={`${TextStyling.pageBodyBold}`}>
                    {membershipCountryValues.currencyName}
                  </span>
                </div>
              </div>

              <div className={`flex flex-row justify-between items-center`}>
                <div className={`${TextStyling.inputLabel}`}>
                  Membership Fee
                </div>
                <div className={`flex-1 flex justify-end w-fit font-bold ${TextStyling.pageBody}`}>
                  {membershipCountryValues.membershipFee}
                </div>
              </div>
              <div className={`text-xs text-neutral-400`}>{`${membershipCountryValues.currencySymbol} ${membershipCountryValues.membershipRenewalFee} next year (01/15/2025)`}</div>


              <div className="mt-8 py-2 border-y">
                <div className={`${TextStyling.pageBodyBold}`}>
                  Coupon Code
                </div>
                <div className="flex flex-row items-center w-full mt-2 gap-y-1  md:justify-start md:items-center">
                  <input
                    type="text"
                    className={`${InputStyling.text}`}
                    placeholder="Coupon Code"
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                    }}
                  />
                  <button
                    onClick={handleValidateCoupon}
                    disabled={auth.loading || !membershipCountryValues.updated}
                    className={`ml-4 ${ResponsiveValues.buttonFit} ${!membershipCountryValues.updated ? "disabled" : ""
                      }`}
                  >
                    Apply
                  </button>
                </div>
                {auth.coupon.loading && (
                  <p className="mt-1 text-blue-900">Validating coupon...</p>
                )}
                {auth.coupon.error && isFading && (
                  <p className="mt-1 text-red-900">{auth.coupon.error}</p>
                )}

                <div className="flex justify-between mt-4">
                  <div className={`${TextStyling.note}`}>Coupon Discount (FREESTAFF)</div>
                  <div className="text-neutral-500">
                    -{membershipCountryValues.currencySymbol}
                    {couponValue}
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between mt-4">
                <div className={`${TextStyling.pageBodyBold}`}>
                  Total Due
                </div>
                <div className={`flex-1 ${TextStyling.pageBodyBold}`}>
                  <FormattedNumber
                    value={(
                      membershipCountryValues.membershipFeeForComputation -
                      couponValue
                    ).toFixed(2)}
                    style="currency"
                    currency={membershipCountryValues.currencyName}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmit}
                disabled={auth.loading || !membershipCountryValues.updated}
                className={`w-full mt-3 bg-[#1c3775] text-white px-7 py-2 rounded-full font-semibold text-lg 
                  ${!membershipCountryValues.updated ? "disabled" : ""}`}
              >
                Pay now
              </button>
              {/* {auth.loading && (
                <p className="mt-1 text-blue-900">Processing...</p>
              )} */}
              {(auth.error) && <FailureIndicator message={auth.error} />}
              {(errorMessage) && <FailureIndicator message={errorMessage} />}

              <div className="flex justify-between items-center mt-4">
                <MdLockOutline size={30} />
                <div className="flex-1 ml-4 space-x-1">
                  <span className={`${TextStyling.pageBodyBold}`}>
                    Secure Checkout.
                  </span>
                  <span className={`${TextStyling.pageBody}`}>
                    For your convenience, we will save your encrypted payment
                    information for future payments.
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto">
            <h2 className="text-xl font-bold mb-4">Confirm Payment</h2>
            <p>Are you sure you want to proceed with the payment?</p>
            <div className="mt-6 flex justify-end gap-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handlConfirmPayment}
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <Loader loadingText={"Processing..."} isOpen={ismodalOpen} />
    </>
  );
};

export default StripePayment;
