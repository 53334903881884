import React from "react";
import faqIcon from "../../assets/faqIcon.png";
import { useNavigate } from "react-router-dom";

const titles = [
  { title: "General Information", link: "?tab=general-information" },
  {
    title: "Registration and Membership",
    link: "?tab=registration-and-membership",
  },
  { title: "Profile Management", link: "?tab=profile-management" },
  { title: "Verification Process", link: "?tab=verification-process" },
  { title: "Privacy and Security", link: "?tab=privacy-and-security" },
  { title: "Support and Community", link: "?tab=support-and-community" },
];

const descriptions = [
  "Questions related to the overall purpose, accessibility, and general use of the fullidcheck.com platform.",
  "Questions about the registration process, membership costs, and what happens if there are issues with verification or membership.",
  "Questions regarding how to manage, update, and maintain your profile information to ensure it remains accurate and active.",
  "Questions detailing the steps and requirements for verifying your identity, including the interview process and document checks.",
  "Questions addressing how fullidcheck.com protects your personal information and the measures taken to prevent fraud and unauthorized access.",
  "Questions on how to get help with any issues, provide feedback, and participate in community discussions on the platform.",
];

function Faq() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#F9FAFB]  pb-[100px]">
      <div className="bg-devRegDarkBlue flex flex-col py-20 items-center justify-center">
        <div className="text-white font-bold text-[33px] pb-4">
          How we can help you
        </div>
        <input
          type="search"
          placeholder="Search for queries"
          className="w-full max-w-[500px] rounded-md px-3 py-2"
        />
      </div>

      <div className="max-w-[1366px] m-auto px-5 py-14">
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {titles.map((item, index) => (
            <div
              onClick={() => navigate(`/faq/category${item.link}`)}
              key={index}
              className="flex cursor-pointer hover:bg-[#c1d5e91f] bg-white rounded-md flex-col items-center justify-center min-h-[252px] p-4 m-auto"
            >
              <img src={faqIcon} alt="" />
              <div className="text-[#111827] mt-3 font-bold text-[20px] text-center">
                {item.title}
              </div>
              <div className="text-center mt-3 text-[#6B7280] text-[16px] ">
                {descriptions[index]}
              </div>
            </div>
          ))}
        </div>

        <div className="flex max-md:flex-col max-md:space-x-0 max-md:px-0  space-x-14 mt-6">
          <div
            onClick={() =>
              navigate(`/faq/category?tab=features-and-referral-program`)
            }
            className="flex bg-white w-full rounded-md hover:bg-[#c1d5e91f]  cursor-pointer flex-col items-center justify-center min-h-[252px]  p-4 m-auto"
          >
            <img src={faqIcon} alt="" />
            <div className="text-[#111827] mt-3 font-bold text-[20px] text-center">
              Features & Referral Program
            </div>
            <div className="text-center mt-3  text-[#6B7280] text-[16px] px-4 pb-3">
              Questions about the various features available to users, including
              the referral program, badge draw, and benefits of being a verified
              member.
            </div>
          </div>
          <div
            onClick={() => navigate(`/faq/category?tab=report-and-dispute`)}
            className="flex max-md:mt-5 bg-white w-full rounded-md hover:bg-[#c1d5e91f]  cursor-pointer flex-col items-center justify-center min-h-[252px] p-4 m-auto"
          >
            <img src={faqIcon} alt="" />
            <div className="text-[#111827] mt-3 font-bold text-[20px] text-center">
              Report and Dispute
            </div>
            <div className="text-center mt-3 text-[#6B7280] text-[16px] px-4 pb-3">
              Questions on how to handle complaints, disputes, and what actions
              to take if you suspect fraudulent activity on the platform.
            </div>
          </div>
        </div>
        <div className="bg-[#E3E3E3] w-full mt-20 rounded-md m-auto h-[2px]"></div>
      </div>

      <div className=" w-[80%] m-auto justify-between px-12 py-8 mt-16 rounded-2xl max-w-[1307px] max-md:px-5 max-md:mt-10 max-md:max-w-full bg-gradient-to-br from-[#28b9bb] to-[#1c3775]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
            <div className="text-3xl font-semibold tracking-tight leading-9 text-white max-md:mt-10 max-md:max-w-full">
              Still have questions?
            </div>
            <span className="text-sm leading-6 text-white">
              Still have question in mind? Please get in touch with our support
              team or write us an email.
            </span>
          </div>
          <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
            <div
              onClick={() => {
                navigate("/contact");
                window.scrollTo(0, 0);
              }}
              className="max-lg:text-[18px] cursor-pointer max-lg:px-0 justify-center self-stretch px-9 py-3 my-auto w-full text-xl font-semibold text-blue-900 bg-zinc-100 rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center "
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
