import TextStyling from "../../../styling/TextStyling";
import FormattedDateComponent from "../../FormattedDateComponent";

const EducationCertItem = ({ data, onEdit, onDelete, showControls = true }) => {
  return (
    <>
      <div className="flex flex-row justify-between  md:mt-4 max-md:flex-wrap">
        <div className="flex flex-row items-center gap-4">
          <img
            alt="img"
            loading="lazy"
            src={data.thumbnail_path}
            className="shrink-0 self-start aspect-square w-[77px]"
          />

          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-base md:text-xl font-semibold max-md:max-w-full">
              {data.name}
            </div>
            <div className="md:mt-1 max-md:max-w-full">
              {data.degree_or_course}
            </div>
            <div className="md:mt-1 max-md:max-w-full"><FormattedDateComponent datetime={data.date_started} /> - <FormattedDateComponent datetime={data.date_graduated} /></div>
          </div>
        </div>


        {showControls &&
          <div className="w-full md:w-fit flex justify-end self-end">
            <div><button className={TextStyling.textLink} onClick={() => onEdit(data.id)}>Edit</button> | <button className={`text-red-500`} onClick={() => onDelete(data.id)}>Delete</button></div>
          </div>
        }

      </div>
    </>
  )
}

export default EducationCertItem