import { authAxios } from '../../../../../config/common';

const get = async () => {
  try {
    const response = await authAxios().get(`/api/profile/overview`);
    return response;
  } catch (error) {
    throw error;
  }
};

const post = async (data) => {
  try {
    const response = await authAxios().post(`/api/profile/overview`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getDocuments = async () => {
  try {
    const response = await authAxios().get(`/api/documents`);
    return response;
  } catch (error) {
    throw error;
  }
};

const postDocument = async (data) => {
  try {
    const response = await authAxios().post(`/api/documents`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const delDocument = async (documentId) => {
  try {
    const response = await authAxios().delete(`/api/documents/${documentId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const overviewApi = {
  get, post,
  getDocuments, postDocument, delDocument,
};

export default overviewApi;