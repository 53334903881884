import Loader from "./Loader";
const LoadingIndicator = ({ loading = false, message = 'Processing', styling = "" }) => {
  return (
    // <div className={`flex justify-end text-blue-900 ${styling}`}>
    //   Processing...
    // </div>
    <Loader loadingText={message} isOpen={loading} />

  )
}

export default LoadingIndicator;
