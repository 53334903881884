import { authAxios } from "../../../../../config/common";

// Work History
const getAll = async () => {
	try {
		const response = await authAxios().get(`/api/profile/work-histories`);
		return response;
	} catch (error) {
		throw error;
	}
};

const get = async (workHistoryId) => {
	try {
		const response = await authAxios().get(`/api/profile/work-histories/${workHistoryId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (data) => {
	try {
		const response = await authAxios().post(`/api/profile/work-histories`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const put = async (workHistoryId, data) => {
	try {
		const response = await authAxios().post(`/api/profile/work-histories/${workHistoryId}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const del = async (workHistoryId) => {
	try {
		const response = await authAxios().delete(`/api/profile/work-histories/${workHistoryId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

// Work History Projects
const getAllWorkHistoryProjects = async (workHistoryId) => {
	try {
		const response = await authAxios().get(`/api/profile/work-histories/${workHistoryId}/projects`);
		return response;
	} catch (error) {
		throw error;
	}
};

const getWorkHistoryProject = async (workHistoryId, projectId) => {
	try {
		const response = await authAxios().get(`/api/profile/work-histories/${workHistoryId}/projects/${projectId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

const postWorkHistoryProject = async (workHistoryId, data) => {
	try {
		const response = await authAxios().post(`/api/profile/work-histories/${workHistoryId}/projects`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const putWorkHistoryProject = async (workHistoryId, projectId, data) => {
	try {
		const response = await authAxios().post(`/api/profile/work-histories/${workHistoryId}/projects/${projectId}`, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const delWorkHistoryProject = async (workHistoryId, projectId) => {
	try {
		const response = await authAxios().delete(`/api/profile/work-histories/${workHistoryId}/projects/${projectId}`);
		return response;
	} catch (error) {
		throw error;
	}
};

// Project Screenshots

// const getAllProjectScreenshots = async (projectId) => {
// 	try {
// 		const response = await authAxios().get(`/api/projects/${projectId}/screenshots`);
// 		return response;
// 	} catch (error) {
// 		throw error;
// 	}
// };

// const getProjectScreenshot = async (projectId, screenshotId) => {
// 	try {
// 		const response = await authAxios().get(`/api/projects/${projectId}/screenshots/${screenshotId}`);
// 		return response;
// 	} catch (error) {
// 		throw error;
// 	}
// };

// const postProjectScreenshot = async (projectId, data) => {
// 	try {
// 		const response = await authAxios().post(`/api/projects/${projectId}/screenshots`, data);
// 		return response;
// 	} catch (error) {
// 		throw error;
// 	}
// };

// const delProjectScreenshot = async (projectId, screenshotId) => {
// 	try {
// 		const response = await authAxios().delete(`api/projects/${projectId}/screenshots/${screenshotId}`);
// 		return response;
// 	} catch (error) {
// 		throw error;
// 	}
// };

const workHistoryApi = {
	getAll,
	get,
	post,
	put,
	del,
	getAllWorkHistoryProjects,
	getWorkHistoryProject,
	postWorkHistoryProject,
	putWorkHistoryProject,
	delWorkHistoryProject,
	// getAllProjectScreenshots,
	// getProjectScreenshot,
	// postProjectScreenshot,
	// delProjectScreenshot,
};

export default workHistoryApi;