import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This is important for styling

const AboutProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    "/FullIDCheck_about_profile.jpg",
  ];

  return (
    <div className="flex overflow-hidden flex-col justify-center items-center pb-16 bg-gray-50 w-full max-w-full">
      <div className="flex overflow-hidden flex-col justify-center items-center md:px-28 md:py-16 max-w-full bg-[#0D2476] min-h-[220px] md:min-h-[254px] w-full max-md:px-6">
        <div className="flex flex-col w-full">
          <div className="text-2xl md:text-4xl font-extrabold text-gray-50 max-md:max-w-full">
            About Profile
          </div>
          <div className="mt-3 text-sm leading-2 md:mt-6 md:text-base md:leading-6 text-white max-md:max-w-full">
            This sample profile showcases what your completed profile will look
            like. Simply follow the prompts provided on the side of the screen;{" "}
            <br />
            they will guide you through each step of creating your profile,
            providing helpful tips on what information to include and how to
            best present yourself.
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 px-8 md:mt-10 max-w-full w-[1200px] ">
        <div className="flex flex-col max-w-full w-[850px]">
          <div className="flex gap-2 justify-center items-center self-start px-3 py-1.5 text-sm font-medium leading-none text-center text-green-500 bg-green-100 rounded-3xl">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bea892b10e36bf92eb1dc90df2c97aa031f329de577c2db247ca6f3514e3927b?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
              className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
            />
            <div className="self-stretch my-auto">Full Id Check Sample Profile</div>
          </div>
          <div className="mt-4 text-xl md:text-2xl font-bold text-gray-900 max-md:max-w-full">
            Profile Setup Guide
          </div>
        </div>
        <img
          loading="lazy"
          src={images[0]}
          className=" mt-8 md:mt-16 object-contain w-full rounded-xl cursor-pointer"
          onClick={() => {
            setIsOpen(true);
          }}
        />
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </div>
  );
}

export default AboutProfile;