import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workHistoryActions as action } from './workHistorySlice';
import Debounce from '../../../../../utils/Debounce';
import { DebounceDelay } from '../../../../../utils/Debounce';
import ValidationHelperChain from '../../../../../utils/ValidationHelperChain';

const useWorkHistoryValidation = () => {
  const dispatch = useDispatch();

  const itemDefaultValue = { isValid: false, errorMessage: [] };
  const validationErrorMessageDefault = {
    company: itemDefaultValue,
    position: itemDefaultValue,
    started: itemDefaultValue,
    ended: itemDefaultValue,
    workArrangement: itemDefaultValue,
    description: itemDefaultValue,
    projectTitle: itemDefaultValue,
    projectCategory: itemDefaultValue,
  }

  const [validationErrorMessage, setValidationErrorMessage] = useState(validationErrorMessageDefault);

  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState(false);

  const { success, error, loading, } = useSelector(state => state.workHistory);
  const strNotifiers = JSON.stringify(`${success} ${error} ${loading}`);

  const [showNotif, setShowNotif] = useState(true);
  useEffect(() => {
    setIsSafeToSubmit(hasValidationError() === true || validationErrorMessage === validationErrorMessageDefault ? false : true);
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
        //setErrorsToEmpty();
        dispatch(action.setError(null));
        dispatch(action.setSuccess(false));
      }, 15000);
    } else {
      setShowNotif(true);
    }
  },
    [strValidationErrorMessage, strNotifiers]);

  const hasValidationError = () => {
    return Object.values(validationErrorMessage).some(
      (item) => item.errorMessage?.length > 0
    );
  };

  const setErrorsToEmpty = () => {
    setValidationErrorMessage(validationErrorMessageDefault);
  }

  const validateCompany = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 3 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, company: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, company: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validatePostion = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 4 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, position: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, position: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateStarted = Debounce((str) => {
    const validation = ValidationHelperChain().dateHasPassed({ str }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, started: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, started: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateEnded = Debounce((str) => {
    const validation = ValidationHelperChain().dateHasPassed({ str }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, ended: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, ended: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateWorkArrangement = Debounce((str) => {
    const validation = ValidationHelperChain().selectOption({ str: str });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, workArrangement: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, workArrangement: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateDescription = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, description: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, description: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateProjectTitle = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 3 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, projectTitle: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, projectTitle: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateProjectCategory = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, projectCategory: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, projectCategory: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);


  return {
    validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
    validateDescription, validateProjectTitle, validateProjectCategory
  };
}

export default useWorkHistoryValidation;


// const { validationErrorMessage, showNotif, isSafeToSubmit,
//   validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
//   validateDescription, validateProjectTitle, validateProjectCategory } = useWorkHistoryValidation();
// { 

/* 
  <input
    value={postMessage.message}
    onChange={(e) => {
      setPostMessage({ ...postMessage, message: e.target.value });
      validateMessage(e.target.value);
    }}
  />

  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.message?.errorMessage} />

  <button className={`${isSafeToSubmit === true ? '' : 'disabled'}`} onClick={onClickSend}>
    Send
  </button>

{
  showNotif && (
    <>
      {loading && (<LoadingIndicator styling='' />)}
      {!loading && success && <SuccessIndicator message='Account updated.' />}
      {!loading && !success && error && <FailureIndicator message={error || 'Account not updated.'} />}
    </>
  )
}
*/