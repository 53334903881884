import { useDispatch, useSelector } from "react-redux";
import { getLanguages, addLanguage, removeLanguage, languageAction as action } from "./languageSlice";
import { useEffect, useState } from "react";

const useLanguage = () => {
	const dispatch = useDispatch();
	const { success, error, loading, languages } = useSelector(state => state.language);

	const [displayedLanguages, setDisplayedLanguages] = useState(languages);
	const [typed, setTyped] = useState("");

	useEffect(() => {
		if (error !== null) {
			setDisplayedLanguages(languages);
		}
	}, [error, languages]);

	useEffect(() => {
		setDisplayedLanguages(languages);
	}, [languages]);

	const handleTyping = (e) => {
		setTyped(e);
	}

	const handleOnload = async () => {
		try {
			await dispatch(getLanguages({}));
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`))
		}
	}

	const handleAddbutton = async (str) => {
		try {
			const data = {
				language: str,
			};

			const tempId = (displayedLanguages && displayedLanguages.length > 0) ? (displayedLanguages[displayedLanguages.length - 1].id + 1) : 1;

			setDisplayedLanguages(
				[...displayedLanguages,
				{ ...data, id: tempId, }
				]
			);

			await dispatch(addLanguage({ data: data }));
			await dispatch(getLanguages({}));

		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const handleRemoveButton = async (intId) => {
		try {

			const newDisplayedLanguages = displayedLanguages.filter((item) => item.id !== intId);
			setDisplayedLanguages(newDisplayedLanguages);

			await dispatch(removeLanguage({ languageId: intId }));
			await dispatch(getLanguages({}));

		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`))
		}
	}

	return {
		success, error, loading,
		languages,
		typed, setTyped,
		displayedLanguages, setDisplayedLanguages,
		handleOnload,
		handleAddbutton,
		handleRemoveButton,
		handleTyping,
	}

}

export default useLanguage;