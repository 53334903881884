import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import featureApi from "./featureApi";
import formDataHelper from "../../../../../utils/formDataHelper";

export const getFeature = createAsyncThunk('/feature/get', async ({ }, { rejectWithValue }) => {
	try {
		const result = await featureApi.get();
		if (result.status === 200) {
			return JSON.parse(result.data);
		} else {
			const error = JSON.parse(result.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const postFeature = createAsyncThunk('/feature/post', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const result = await featureApi.post(formData);
		if (result.status === 200) {
			return JSON.parse(result.data);
		} else {
			const error = JSON.parse(result.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const featureSlice = createSlice({
	name: 'feature',
	initialState: {
		success: null,
		loading: false,
		error: null,
		values: null,
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getFeature.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(getFeature.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getFeature.fulfilled, (state, action) => {
				state.loading = false;
				state.error = false;
				state.values = action?.payload;
			})
			// postFeature
			.addCase(postFeature.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(postFeature.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(postFeature.fulfilled, (state, action) => {
				state.loading = false;
				state.error = false;
				state.success = true;
			})
	}
});

export const featureAction = featureSlice.actions;
export default featureSlice;