import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkPassword,
  resendOTPViaPhone,
  sendOTPViaPhone,
  setChangePasswordError,
  setCheckPasswordSuccess,
  setResendOTPViaPhoneSuccess,
  setSendOTPViaPhoneSuccess,
  setVerifyOTPViaPhoneSuccess,
  verifyOTPViaPhone,
  verifyOTPViaNewPhone,
  resendOTPViaEmail,
  setSendOTPViaNewPhoneSuccess,
  setVerifyOTPViaNewPhoneSuccess,
  setResendOTPViaEmailSuccess,
  sendOTPViaNewPhone,
} from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import { maskPhoneNumber } from "../../../config/common";
import PhoneNumberWithFlag from "../../../components/customInputFields/PhoneNumberWithFlag";

import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { FaCircleCheck, FaRegCircleCheck } from "react-icons/fa6";


function ChangeNumber() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [newPhoneOtp, setNewPhoneOtp] = useState(new Array(6).fill(""));
  const [newPhone, setNewPhone] = useState("");
  const [maskedNewPhone, setMaskedNewPhone] = useState("");
  const [isFading, setIsFading] = useState(false);

  const otpRefs = useRef([]);
  const newPhoneOtpRefs = useRef([]);
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const maskedPhone = userData?.phone_number ? maskPhoneNumber(userData.phone_number) : "";

  const [showNotif, setShowNotif] = useState(true);
  const [currentView, setCurrentView] = useState("verify-pass-phone");

  const checkPasswordStates = useSelector((state) => state.settings.checkPassword) || { success: null, error: null, loading: false };
  const sendOTPViaPhoneStates = useSelector((state) => state.settings.sendOTPViaPhone) || { success: null, error: null, loading: false };
  const resendOTPViaPhoneStates = useSelector((state) => state.settings.resendOTPViaPhone) || { success: null, error: null, loading: false };
  const resendOTPViaEmailStates = useSelector((state) => state.settings.resendOTPViaEmail) || { success: null, error: null, loading: false };
  const verifyOTPViaPhoneStates = useSelector((state) => state.settings.verifyOTPViaPhone) || { success: null, error: null, loading: false };
  const sendOTPViaNewPhoneStates = useSelector((state) => state.settings.sendOTPViaNewPhone) || { success: null, error: null, loading: false };
  const verifyOTPViaNewPhoneStates = useSelector((state) => state.settings.verifyOTPViaNewPhone) || { success: null, error: null, loading: false };


  useEffect(() => {
    if (checkPasswordStates.success === "Password is correct!") {
      dispatch(setCheckPasswordSuccess(null));
      setCurrentView("send-otp-to-phone");
    }

    if (sendOTPViaPhoneStates.success === "OTP sent") {
      setCurrentView("verify-otp-phone");
      dispatch(setSendOTPViaPhoneSuccess(null));
    }

    if (resendOTPViaPhoneStates.success === "OTP sent") {
      setIsFading(true);
      const resendTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaPhoneSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendTimer);
    }

    if (resendOTPViaEmailStates.success === "OTP sent") {
      setIsFading(true);
      const resendTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaEmailSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendTimer);
    }

    if (verifyOTPViaPhoneStates.success === "OTP is valid!") {
      setCurrentView("input-new-phone");
      dispatch(setVerifyOTPViaPhoneSuccess(null));
    }

    if (sendOTPViaNewPhoneStates.success === "OTP sent") {
      setCurrentView("verify-new-otp-phone");
      dispatch(setSendOTPViaNewPhoneSuccess(null));
    }

    if (verifyOTPViaNewPhoneStates.success === "Phone Number changed successfully!") {
      setCurrentView("success");
      dispatch(setVerifyOTPViaNewPhoneSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }

    setMaskedNewPhone(maskPhoneNumber(newPhone));
  }, [
    checkPasswordStates.success,
    sendOTPViaPhoneStates.success,
    resendOTPViaPhoneStates.success,
    verifyOTPViaPhoneStates.success,
    sendOTPViaNewPhoneStates.success,
    verifyOTPViaNewPhoneStates.success,
    resendOTPViaEmailStates.success,
    isFading,
    dispatch,
    newPhone,
  ]);

  const handleCheckPasswordClick = async (e) => {
    e.preventDefault();

    if (password.trim() === "") {
      dispatch(setChangePasswordError("Password cannot be blank"));
      return;
    }

    try {
      await dispatch(checkPassword({ id: userData.id, data: { password } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaPhoneClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaPhone({ email: userData.email, phone_number: userData.phone_number }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaNewPhoneClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaNewPhone({ email: userData.email, phone_number: newPhone }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaPhoneClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaPhone({ email: userData.email, phone_number: userData.phone_number }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaEmail({ id: userData.id }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleOtpChange = (e, index, setOtpFunc, otpRefsArray, otpArray) => {
    const { value } = e.target;
    const newOtp = [...otpArray];

    if (e.key === "Backspace") {
      if (newOtp[index] === "") {
        if (index > 0) {
          otpRefsArray.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      if (index < 5) {
        otpRefsArray.current[index + 1].focus();
      }
    }

    setOtpFunc(newOtp);
  };

  const handleOtpPaste = (event, setOtpFunc, otpRefsArray, otpArray) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6).split("").filter(char => /^[0-9]$/.test(char));
    const newOtp = [...otpArray];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefsArray.current[index]) {
        otpRefsArray.current[index].value = char;
      }
    });

    setOtpFunc(newOtp);

    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefsArray.current[nextIndex].focus();
  };

  const handleVerifyOTP = (otpArray, verifyAction) => {
    const code = otpArray.join("");

    if (code.length !== 6 || isNaN(code)) return;

    dispatch(verifyAction({ otp: code, email: userData.email }));
  };

  const handleVerifyOTPNew = (otpArray, verifyAction) => {
    const code = otpArray.join("");

    if (code.length !== 6 || isNaN(code)) return;

    dispatch(verifyAction({ id: userData.id, data: { otp: code, new_phone_number: newPhone } }));
  };

  const renderOtpInputFields = (otpArray, setOtpFunc, otpRefsArray) => (
    <div className="flex justify-center gap-4 mt-4 w-full md:w-[362px]">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="shrink-0 p-2.5 rounded-lg border border-solid border-neutral-400 h-[58px] w-[45px] text-center"
          value={otpArray[i]}
          onChange={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onKeyDown={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onPaste={(e) => handleOtpPaste(e, setOtpFunc, otpRefsArray, otpArray)}
          ref={(el) => (otpRefsArray.current[i] = el)}
        />
      ))}
    </div>
  );

  useEffect(() => {
    setShowNotif(true)
  }, [
    checkPasswordStates.error,
    sendOTPViaPhoneStates.error,
    verifyOTPViaPhoneStates.error,
    resendOTPViaPhoneStates.error, resendOTPViaEmailStates.error,
    resendOTPViaPhoneStates.success, resendOTPViaEmailStates.success,
    sendOTPViaNewPhoneStates.error,
  ]);

  const renderView = () => {

    switch (currentView) {
      case "verify-pass-phone":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && checkPasswordStates.error) && <FailureIndicator message={checkPasswordStates.error} setShowNotif={setShowNotif} />}
            {checkPasswordStates.loading && <LoadingIndicator />}

            <form onSubmit={handleCheckPasswordClick} className="w-full">
              <div className={`${TextStyling.inputLabel}`}>
                Enter Password
              </div>
              <input
                placeholder="Enter Password"
                className={`mt-1 ${InputStyling.text}`}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="on"
              />
              <div className="hidden md:flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  className={`${ResponsiveValues.buttonFitSecondary}`}
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${ResponsiveValues.buttonFit}`}
                  disabled={checkPasswordStates.loading}
                >
                  Continue
                </button>
              </div>

              <div className="md:hidden flex flex-col justify-end mt-2">
                <button
                  type="submit"
                  className={`${ResponsiveValues.buttonFull}`}
                  disabled={checkPasswordStates.loading}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className={`${ResponsiveValues.buttonFullSecondary}`}
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Cancel
                </button>
              </div>
            </form>

          </div>
        );
      case "send-otp-to-phone":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && sendOTPViaPhoneStates.error) && (
              <FailureIndicator message={sendOTPViaPhoneStates.error} setShowNotif={setShowNotif} />
            )}

            {(sendOTPViaPhoneStates.loading) && (
              <LoadingIndicator />
            )}

            <div className="flex flex-col justify-center gap-2 md:gap-0">
              <div className={`${TextStyling.pageBody} text-center md:text-start`}> An OTP (One-Time Passcode) will be sent to phone number ending in <span className={`${TextStyling.pageBodyBody}`}>{maskedPhone}</span>.</div>
              <span className={`${TextStyling.pageBodyBody} text-center md:text-start`}>Would you like to Continue?</span>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Back
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={handleSendOtpViaPhoneClick}
                disabled={sendOTPViaPhoneStates.loading}

              >
                Yes, Send me OTP
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={handleSendOtpViaPhoneClick}
                disabled={sendOTPViaPhoneStates.loading}
              >
                Yes, Send me OTP
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Back

              </button>
            </div>

          </div>
        );
      case "verify-otp-phone":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && verifyOTPViaPhoneStates.error) && (
              <FailureIndicator message={verifyOTPViaPhoneStates.error} setShowNotif={setShowNotif} />
            )}

            {(showNotif === true && resendOTPViaPhoneStates.error)
              && <FailureIndicator message={resendOTPViaPhoneStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaEmailStates.error)
              && <FailureIndicator message={resendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(resendOTPViaPhoneStates.success || resendOTPViaEmailStates.success) && isFading
              && <SuccessIndicator message='OTP resent successfully' setShowNotif={setShowNotif} />
            }

            {(resendOTPViaPhoneStates.loading || resendOTPViaEmailStates.loading || verifyOTPViaPhoneStates.loading)
              && <LoadingIndicator />
            }

            <div className={`${TextStyling.pageBody}`}>
              Enter OTP (One-Time Passcode) we've sent to phone number ending in {` `}
              <span className={`${TextStyling.pageBodyBold}`}>{maskedPhone}.</span>
            </div>

            {renderOtpInputFields(otp, setOtp, otpRefs)}

            <div className={`mt-6 ${TextStyling.pageBody} flex justify-between md:justify-start gap-2`}>
              <span>Did not receive the code?</span>
              <div>
                <span
                  id="resend-otp-link"
                  onClick={handleResendOtpViaPhoneClick}
                  className={TextStyling.textLink}
                >
                  Resend
                </span> or{" "}
                <span
                  id="resend-otp-email-link"
                  onClick={handleResendOtpViaEmailClick}
                  className={TextStyling.textLink}
                >
                  via Email
                </span>
              </div>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={() => handleVerifyOTP(otp, verifyOTPViaPhone)}
                disabled={verifyOTPViaPhoneStates.loading}
              >
                Apply
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={() => handleVerifyOTP(otp, verifyOTPViaPhone)}
                disabled={verifyOTPViaPhoneStates.loading}
              >
                Apply
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "input-new-phone":
        return (
          <div className="flex flex-col items-start pt-4  w-full">
            {(showNotif === true && sendOTPViaNewPhoneStates.error) && <FailureIndicator message={sendOTPViaNewPhoneStates.error} setShowNotif={setShowNotif} />}
            {sendOTPViaNewPhoneStates.loading && <LoadingIndicator />}

            <div className={`${TextStyling.inputLabel}`}>
              Enter New Phone Number
            </div>
            <PhoneNumberWithFlag
              className={`mt-1 ${InputStyling.phone} `}
              onPhoneNumberChange={(phoneNumber) => {
                setNewPhone(phoneNumber);
              }}
            />

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={handleSendOtpViaNewPhoneClick}
                disabled={sendOTPViaNewPhoneStates.loading}
              >
                Continue
              </button>
            </div>

            <div className="md:hidden flex flex-col mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={handleSendOtpViaNewPhoneClick}
                disabled={sendOTPViaNewPhoneStates.loading}
              >
                Continue
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "verify-new-otp-phone":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">

            {(showNotif === true && verifyOTPViaNewPhoneStates.error)
              && <FailureIndicator message={verifyOTPViaNewPhoneStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaPhoneStates.error)
              && <FailureIndicator message={resendOTPViaPhoneStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaEmailStates.error)
              && <FailureIndicator message={resendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(resendOTPViaPhoneStates.success || resendOTPViaEmailStates.success) && isFading
              && <SuccessIndicator message='OTP resent successfully' setShowNotif={setShowNotif} />
            }

            {(resendOTPViaPhoneStates.loading || resendOTPViaEmailStates.loading || verifyOTPViaNewPhoneStates.loading)
              && <LoadingIndicator />
            }

            <div className={`${TextStyling.pageBody}`}>
              Enter OTP (One-Time Passcode) we've sent to phone number ending in{" "}
              <span className={`${TextStyling.pageBodyBold}`}>{maskedNewPhone}.</span>
            </div>

            {renderOtpInputFields(newPhoneOtp, setNewPhoneOtp, newPhoneOtpRefs)}

            <div className={`mt-6 ${TextStyling.pageBody} flex justify-between md:justify-start gap-2`}>
              <span>Did not receive the code?</span>
              <div>
                <span
                  id="resend-otp-link"
                  onClick={handleResendOtpViaPhoneClick}
                  className={TextStyling.textLink}
                >
                  Resend
                </span> or{" "}
                <span
                  id="resend-otp-email-link"
                  onClick={handleResendOtpViaEmailClick}
                  className={TextStyling.textLink}
                >
                  via Email
                </span>
              </div>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={() => handleVerifyOTPNew(newPhoneOtp, verifyOTPViaNewPhone)}
                disabled={verifyOTPViaNewPhoneStates.loading}
              >
                Apply
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={() => handleVerifyOTPNew(newPhoneOtp, verifyOTPViaNewPhone)}
                disabled={verifyOTPViaNewPhoneStates.loading}
              >
                Apply
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "success":
        return (
          <div className="flex flex-col items-start pt-4  w-full">

            <div className='flex flex-col text-center md:text-start w-full'>
              <div className="flex flex-row gap-2 items-center justify-center md:justify-start">
                <FaCircleCheck size={16} className={`${ResponsiveValues.forDesktopScreen} text-[#0082BA]`} />
                <FaRegCircleCheck size={16} className={`${ResponsiveValues.forMobileScreen} text-green-500`} />

                <span className={`${TextStyling.pageHeader2} w-fit`}>Phone Number Updated</span>
              </div>
              <span className={`${TextStyling.pageBody} justify-center md:justify-start`}>You successfully changed your Phone Number</span>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">

              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Continue
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Continue
              </button>
            </div>

          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`p-2 min-h-[89vh] ${ResponsiveValues.forMobileScreen}`}>

        {/* title */}
        <div className=" flex flex-col justify-between items-start">
          <PageTitle title='Change Phone'
            hasSpacing='false'
            customTextStyleDesktop={TextStyling.pageHeader1}
            customTextStyleMobile={TextStyling.pageBodyBold} />
        </div>

        {renderView()}

      </div>

      <div className={`p-2 hidden md:flex justify-center`}>
        <div className={`min-w-[90vh] ${ResponsiveValues.cardSectionStyle} shadow-md`}>
          <div className="px-4 pb-1">

            {/* title */}
            <div className=" flex flex-col justify-between items-start pb-4 md:pb-2">
              <PageTitle title='Change Phone'
                hasSpacing='false'
                customTextStyleDesktop={TextStyling.pageHeader1}
                customTextStyleMobile={TextStyling.pageBodyBold} />
              {/* <PiCaretUpBold size={25} /> */}
            </div>
            <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1 p-0 m-0 w-full`} />

            {renderView()}
          </div>
        </div>
      </div>
    </>

  );
}

export default ChangeNumber;
