const getArrayHelper = (state) => {
  var arr = state.split(",");
  return arr;
}


const addStringArrayHelper = (word, state, setState) => {
  var arr = state === '' ? [] : state.split(",");
  arr.push(word);
  setState(arr.join(","));
}

const removeStringArrayHelper = (word, state, setState) => {
  var arr = state.split(",");
  arr.pop(word);
  setState(arr.join(","));
}


const stringArrayHelper = {
  getArrayHelper,
  addStringArrayHelper,
  removeStringArrayHelper,
}

export default stringArrayHelper;
