import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Bubble from "./Bubble";
import WorkArrangementIcon from "../assets/Profile/work_arrangement_icon.svg";
import AvailabilityIcon from "../assets/Profile/availablity_icon.svg";

function ProfileSidePanel({ data }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (value) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className="flex flex-col items-end text-base leading-6 bg-white rounded-xl text-neutral-400">
        <div className="flex flex-col mt-10 bg-white rounded-lg border border-neutral-200">
          <div className="flex flex-col w-full px-4 my-6 text-xl leading-8 bg-white  rounded-xl text-neutral-800">
            <div className="flex flex-row gap-2">
              <img
                alt="img"
                loading="lazy"
                src={AvailabilityIcon}
                className="shrink-0 my-auto w-5 aspect-square"
              />
              <span className="text-lg font-semibold text-[#1C3775]">Availability</span>
            </div>
            <span className=" text-base text-black">{data.availability}</span>
            <div className="w-[98%] h-[2px] mt-4 bg-neutral-200 m-auto"></div>
            <div className="mt-4">
              <div className="flex flex-row gap-2">
                <img
                  alt="img"
                  loading="lazy"
                  src={WorkArrangementIcon}
                  className="shrink-0 my-auto w-5 aspect-square"
                />
                <span className="text-lg font-semibold text-[#1C3775]">Work Arrangement</span>
              </div>
              <span className=" text-base text-black">{data.work_arrangement}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSidePanel;
