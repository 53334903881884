import React, { useEffect, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import PassUpdateSuccess from "./PassUpdateSuccess";
import { CiCircleCheck } from "react-icons/ci";
import { IoKeyOutline, IoMailOutline } from "react-icons/io5";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPasswordReset,
  setConfirmPasswordResetSuccess,
} from "../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TopBarLogo from "./TopBarLogo";
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";

function UpdatePassword({ email, session_key }) {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const confirmPasswordResetStates = useSelector(
    (state) => state.settings.confirmPasswordReset
  ) || { success: null, error: null, loading: false };

  useEffect(() => {
    if (
      confirmPasswordResetStates.success ===
      "Password has been reset successfully. All related access tokens are cleared."
    ) {
      setUpdateSuccess(true);
      dispatch(setConfirmPasswordResetSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [confirmPasswordResetStates.success, isFading, dispatch]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setIsModalOpen(true);

    if (!passwordsMatch || confirmPassword.length <= 0) {
      setIsModalOpen(false); // Close the modal if validations fail
      return;
    }

    const data = {
      email,
      session_key,
      new_password: password,
      new_password_confirmation: confirmPassword,
    };

    try {
      await dispatch(confirmPasswordReset({ data }));
    } catch (error) {
      console.error("Password reset failed:", error);
    } finally {
      setIsModalOpen(false); // Ensure modal is closed after dispatch
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));

      // eslint-disable-next-line
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const [toggleEye, setToggleEye] = useState(true);
  const handleToggleEye = () => {
    setToggleEye(!toggleEye);
  };

  const [toggleEye2, setToggleEye2] = useState(true);
  const handleToggleEye2 = () => {
    setToggleEye2(!toggleEye2);
  };

  return (
    <>
      {!updateSuccess ? (
        <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">
          <div className={`min-w-[50vh] max-w-[50vh] max-h-[80vh]
            ${ResponsiveValues.cardScreenStyleNoSpacing}
            flex flex-col grow`}>

            <TopBarLogo children={<BacktoLogin />} logoImg={"https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"} />

            <div className="p-6">
              <div className={`mt-2 ${TextStyling.pageHeader1}`}>
                Reset Password
              </div>
              <form onSubmit={handleUpdatePassword}>
                <div className={`mt-2 ${TextStyling.pageBody}`}>
                  Enter your new password
                </div>

                <div className="relative w-full">
                  <div
                    className="absolute left-3 top-[50%] cursor-pointer "
                    onClick={handleToggleEye}
                  >
                    <IoKeyOutline size={16} color="#BCBCBC" />
                  </div>

                  <div
                    className="absolute right-3 top-[50%] cursor-pointer "
                    onClick={handleToggleEye}
                  >
                    {toggleEye ? (
                      <FaEye size={16} color="#BCBCBC" />
                    ) : (
                      <FaEyeSlash size={16} color="#BCBCBC" />
                    )}
                  </div>
                  <input
                    placeholder="New Password"
                    value={password}
                    type={toggleEye === false ? 'password' : 'text'}
                    name="password"
                    onChange={handleChange}
                    autoComplete="on"
                    className={`mt-4 pl-10 ${InputStyling.text}`}
                  />
                </div>

                <div className="relative w-full">
                  <div
                    className="absolute left-3 top-[50%] cursor-pointer "
                    onClick={handleToggleEye}
                  >
                    <IoKeyOutline size={16} color="#BCBCBC" />
                  </div>

                  <div
                    className="absolute right-3 top-[50%] cursor-pointer "
                    onClick={handleToggleEye2}
                  >
                    {toggleEye2 ? (
                      <FaEye size={16} color="#BCBCBC" />
                    ) : (
                      <FaEyeSlash size={16} color="#BCBCBC" />
                    )}
                  </div>
                  <input
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={handleChange}
                    autoComplete="off"
                    disabled={
                      !isLengthValid ||
                      !hasUpperCase ||
                      !hasLowerCase ||
                      !hasNumber ||
                      !hasSpecialChar
                    }
                    type={toggleEye2 === false ? 'password' : 'text'}
                    name="confirmPassword"
                    className={`mt-4 pl-10 ${InputStyling.text}`}
                  />
                </div>



                {!passwordsMatch && (
                  <div className={`mt-2 ${TextStyling.pageBody}`}>
                    Password doesn't match
                  </div>
                )}

                <button
                  type="submit"
                  disabled={confirmPasswordResetStates.loading}
                  className={ResponsiveValues.buttonFull}
                >
                  Update
                </button>
                {confirmPasswordResetStates.loading && (
                  <p className="mt-2 text-center text-blue-600">Processing...</p>
                )}
                {confirmPasswordResetStates.error && (
                  <p className="mt-2 text-center text-red-600">
                    {confirmPasswordResetStates.error}
                  </p>
                )}

                <div className="w-full mt-4 text-base leading-8 text-zinc-600">
                  Your password should:
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? "text-green-700" : "text-zinc-600"
                    }`}
                >
                  {isLengthValid ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Be at least 8 characters in length
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase
                    ? "text-green-700"
                    : "text-zinc-600"
                    }`}
                >
                  {hasUpperCase && hasLowerCase ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Contain both upper and lowercase alphabetic characters (e.g.
                    A-Z, a-z)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? "text-green-700" : "text-zinc-600"
                    }`}
                >
                  {hasNumber ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Have at least one numerical character (e.g. 0-9)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasSpecialChar ? "text-green-700" : "text-zinc-600"
                    }`}
                >
                  {hasSpecialChar ? (
                    <CiCircleCheck
                      size={21}
                      className="min-w-[20px] max-w-[20px]"
                    />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Have at least one special character (e.g. ~!@#$%^&*()_-+=)
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      ) : (
        <PassUpdateSuccess />
      )}
      <Loader loadingText={"Updating..."} isOpen={ismodalOpen} />
    </>
  );
}

export default UpdatePassword;
