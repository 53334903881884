import React, { useEffect, useState } from "react";
import useRegistrationForm from "../../api/customHook/useRegistrationForm ";
import { CiCircleCheck } from "react-icons/ci";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../../components/Loader";
import InputStyling from "../../styling/InputStyling";
import TextStyling from "../../styling/TextStyling";

import PhoneNumberWithFlag from "../../components/customInputFields/PhoneNumberWithFlag";
import CustomSelect from "../../components/customInputFields/CustomSelect";
import CustomInputText from "../../components/customInputFields/CustomTextInput";
import CustomInputPassword from "../../components/customInputFields/CustomPasswordInput";
import ResponsiveValues from "../../styling/ResponsiveValues";

function Registration() {
  const navigate = useNavigate();
  const {
    professionsForSelectedJobType,
    handleProfessionChange,
    handleJobTypeChange,
    selectedJobType,
    uniqueJobTypes,
    description,
    selectedProfession1,
    setCheckboxChecked,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    setFullName,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    ismodalOpen,
  } = useRegistrationForm();
  const [otherProfesion, setOtherProfesion] = React.useState("");

  const auth = useSelector((state) => state.auth);
  const { shouldRedirect, redirectTo } = useInspectRoute("/register");
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    setIsFading(true);
    setTimeout(() => {
      setIsFading(false);
    }, 7000);
  }, [auth.error]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className={`flex flex-col mx-auto max-w-[600px] max-sm:px-10 ${ResponsiveValues.cardScreenStyle}`}>
        <div className={TextStyling.pageTitle}>
          Registration
        </div>

        <div className=" text-[#4A4A4A]">
          Create your account by filling in the details below.
        </div>
        <div className={`${TextStyling.note}`}>
          <div>
            <span className="">Note:</span> If you are a software or
            hardware development professional wishing to register and get
            verified, please visit
            <a
              href="http://devreg.org"
              target="_blank"
              className={TextStyling.textLink}
              rel="noreferrer"
            >
              {" "}
              devreg.org
            </a>
          </div>
        </div>
        <CustomInputText
          className={`${InputStyling.text} `}
          label="Full name"
          placeholder="Full name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <div className={`space-y-5 gap-4 pb-5 md:pb-6 md:space-y-0 md:flex md:justify-between md:flex-row`}>
          <div className={`w-full md:flex-1`}>
            <CustomSelect
              label="Industry"
              className={`w-full md:flex-1 ${InputStyling.select}`}
              hasSelectedState={selectedJobType}
              value={selectedJobType}
              onChange={handleJobTypeChange}
            >
              <option disabled selected value="">
                Type of Job
              </option>
              {uniqueJobTypes.map((jobType, index) => (
                <option key={index} value={jobType}>
                  {jobType}
                </option>
              ))}
            </CustomSelect>
          </div>

          <div className={`w-full md:flex-1`}>
            <CustomSelect
              label="Profession"
              className={`w-full md:flex-1 ${InputStyling.select}`}
              hasSelectedState={selectedProfession1}
              value={selectedProfession1}
              onChange={handleProfessionChange}
            >
              <option disabled selected value="">
                Profession
              </option>
              {professionsForSelectedJobType.map((profession, index) => (
                <option key={index} value={profession}>
                  {profession}
                </option>
              ))}
              {
                professionsForSelectedJobType.length !== 0 &&
                <option key={professionsForSelectedJobType.length + 1} value={'Others'}>
                  Others
                </option>
              }
            </CustomSelect>
          </div>
        </div>

        {description && (
          <div className="bg-[#F6F6F6] p-2 rounded-lg  mt-2 mb-4 text-[#4B4B4B]">
            <div>
              <div>
                <p className="text-center ">{description}</p>
              </div>
            </div>
          </div>
        )}

        {selectedProfession1 === "Others" && (
          <CustomInputText
            className={InputStyling.text}
            label="Other Profession"
            placeholder="Other Profession"
            value={otherProfesion}
            onChange={(e) => setOtherProfesion(e.target.value)}
          />
        )}

        <CustomInputText
          className={InputStyling.text}
          label="Email"
          placeholder="Email"
          value={email}
          onChange={validateEmail}
          showErrorMessage={!isValidEmail}
          errorMessage="Please enter a valid email address."
        />

        <div className="mb-5">
          <PhoneNumberWithFlag label='Phone number' onPhoneNumberChange={handlePhoneNumberChange} />
        </div>

        <CustomInputPassword
          className={InputStyling.text}
          label="Password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleChange}
        />

        {/* <div className="w-full mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
          Your password should:
        </div> */}
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {isLengthValid ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto">Be at least 8 characters in length</div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasUpperCase && hasLowerCase ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto ">
            Contain both upper and lowercase alphabetic characters (e.g. A-Z,
            a-z)
          </div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasNumber ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto max-md:max-w-full">
            Have at least one numerical character (e.g. 0-9)
          </div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 mb-4 text-sm ${hasSpecialChar ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasSpecialChar ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto ">
            Have at least one special character (e.g. ~!@#$%^&*()_-+=)
          </div>
        </div>

        <CustomInputPassword
          className={`${passwordsMatch ? "border-neutral-400" : "border-red-600"} ${InputStyling.text}`}
          label="Confirm Password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleChange}
          disabled={
            !isLengthValid ||
            !hasUpperCase ||
            !hasLowerCase ||
            !hasNumber ||
            !hasSpecialChar
          }
        />

        <div className="flex self-start items-center pr-5 mt-4">
          <div className="flex justify-center">
            <input
              type="checkbox"
              checked={checkboxChecked}
              className="shrink-0 mt-[2px] rounded-md bg-zinc-300"
              onChange={() => {
                setCheckboxChecked(!checkboxChecked);
              }}
            />
          </div>
          <div className="m-2 text-sm leading-4 tracking-tight text-neutral-600">
            I have read and accept the{" "}
            <span
              className={TextStyling.textLink}
              onClick={() => navigate("/terms-and-conditions")}
            >
              terms and conditions
            </span>
          </div>
        </div>
        <button
          onClick={handleApply}
          disabled={!checkboxChecked || (checkboxChecked && auth.loading)}
          className={ResponsiveValues.buttonFull}
        >
          Apply
        </button>
        {/* {auth.loading && (
          <p className="mt-2 text-center text-teal-400">Processing...</p>
        )} */}
        {auth.error && isFading && (
          <p className="mt-2 text-center text-red-400">{auth.error}</p>
        )}

        <div className="self-center mt-4 mb-4 text-base text-center text-stone-600">
          Already have an account?&nbsp;
          <span
            className={TextStyling.textLink}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Log-in
          </span>
        </div>
      </div>

      <Loader loadingText={"Registering..."} isOpen={ismodalOpen} />
    </>
  );
}

export default Registration;
