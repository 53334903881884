// src/FaceScanner.js
import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import { useNavigate, useParams } from 'react-router-dom';
import { scanAndVerify } from '../../feature/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const FaceScanner = () => {
  const { key } = useParams();
  const webcamRef = useRef(null);
  const [initializing, setInitializing] = useState(true);
  const [capturedImages, setCapturedImages] = useState([]);
  const [lastCapture, setLastCapture] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const logoImg = "https://cdn.builder.io/api/v1/image/assets/TEMP/0fa4a55e76d2b3d29c123fbc8cb288186f69c33a12fe61eff618d75a8254a6c4?apiKey=3ae96302e69649f5be368f89230b0596&";

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';
      await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
      setInitializing(false);
    };
    loadModels();

    localStorage.setItem('upload_processing', 'false');
  }, []);

  useEffect(() => {
    if (processing) {
      const video = webcamRef.current.video;
      if (video) {
        video.pause();
      }
    }
  }, [processing]);

  const handleVideoOnPlay = () => {
    const video = webcamRef.current.video;
    const canvas = faceapi.createCanvasFromMedia(video);
    document.body.append(canvas);

    const displaySize = { width: video.width, height: video.height };
    faceapi.matchDimensions(canvas, displaySize);

    const captureImage = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImages((prev) => {
        const updatedImages = [...prev, imageSrc];
        if (updatedImages.length >= 5) {
          clearInterval(intervalId);
          setProcessing(true);
          uploadImages(updatedImages);
        }
        return updatedImages;
      });
    };

    const checkFaceMovement = (landmarks) => {
      if (lastCapture) {
        const nose = landmarks.getNose();
        const lastNose = lastCapture.getNose();

        const movement = Math.sqrt(
          Math.pow(nose[0].x - lastNose[0].x, 2) + Math.pow(nose[0].y - lastNose[0].y, 2)
        );

        return movement >= 5;
      }
      return true;
    };

    const intervalId = setInterval(async () => {
      if (!processing && capturedImages.length < 5) {
        const detections = await faceapi.detectAllFaces(video).withFaceLandmarks().withFaceDescriptors();
        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        faceapi.draw.drawDetections(canvas, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

        if (resizedDetections.length > 0 && capturedImages.length < 5) {
          const landmarks = resizedDetections[0].landmarks;
          if (capturedImages.length < 3 || checkFaceMovement(landmarks)) {
            captureImage();
            setLastCapture(landmarks);
          }
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  };

  const uploadImages = async (images) => {
    if (localStorage.getItem('upload_processing') === 'true') return;

    localStorage.setItem('upload_processing', 'true');
    try {
      const formData = { images: images.slice(0, 5), key: key };
      const response = await dispatch(scanAndVerify({ data: formData }));

      if (response?.payload?.message !== 'Face Verification Success!') {
        setUploadError(true);
      } else {
        setCapturedImages([]);
        setUploadError(false);
      }
    } catch (error) {
      setUploadError(true);
    } finally {
      setProcessing(false);
      localStorage.setItem('upload_processing', 'false');
    }
  };

  const handleRetake = () => {
    setCapturedImages([]);
    setLastCapture(null);
    setProcessing(false);
    setUploadError(false);
    const video = webcamRef.current.video;
    if (video) {
      video.play();
    }
  };

  return (
    <div className="absolute flex flex-col items-center justify-start min-w-full min-h-screen py-3 overflow-hidden bg-sky-600">
      <div className="flex flex-col items-center justify-center px-3 bg-sky-600">
        <div className="flex flex-col px-7 pb-7 pt-4 w-full mx-3 text-base bg-white rounded-[7px]">
          <div className="flex flex-row items-center justify-between gap-1">
            <img
              onClick={() => navigate("/")}
              alt="img"
              loading="lazy"
              src={logoImg}
              className="self-start cursor-pointer max-w-full aspect-[3.85] w-48"
            />
            <h3 className="font-bold text-blue-600">|Scan</h3>
          </div>
          <div className="flex flex-col items-center justify-center py-5">
            {initializing ? (
              <p className="text-lg text-center text-blue-600">Loading resources...</p>
            ) : (
              <Webcam
                className="rounded-[7px]"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpg"
                width={420}
                height={320}
                videoConstraints={{ facingMode: 'user' }}
                onPlay={handleVideoOnPlay}
              />
            )}
          </div>
          {processing && (
            <div className="mt-1 text-sm text-center text-blue-600">Face recognition is validating...</div>
          )}
          {!initializing && !uploadError && !processing && !auth.scanAndVerify.success && (
            <div className="my-1 text-sm font-bold text-center text-neutral-600">
              Please blink or move slightly.
            </div>
          )}
          {auth.scanAndVerify.success && (
            <div className="my-1 text-3xl font-bold text-center text-green-600">
              Verified Successfully!
            </div>
          )}
          {uploadError && !auth.scanAndVerify.success && (
            <button
              onClick={handleRetake}
              className="p-4 mt-3 text-xl text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Retake
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaceScanner;
