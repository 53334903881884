import React, { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { TiMessages } from "react-icons/ti";
import { TfiSettings } from "react-icons/tfi";
import { CiLogout } from "react-icons/ci";
import { BsInbox, BsPerson, BsTrophy } from "react-icons/bs";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../feature/authSlice";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../Loader";
import TextStyling from "../../styling/TextStyling";

function SidebarDashboard() {
  const navigate = useNavigate();

  // const { logout } = useAuth();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { messages: messagesF, } = useSelector(state => state.fullIdCheckMessages);
  const { messages: messagesR, } = useSelector(state => state.registerMessages);
  const { messages: messagesA, } = useSelector(state => state.adminMessages);

  const handleLogout = async () => {
    // logout();
    await dispatch(logout());

    navigate("/");
    window.scrollTo(0, 0);
  };
  const [isActive, setIsActive] = useState("");
  const [showSecondNav, setShowSecondNav] = useState(false);
  const listTab = [
    {
      title: "Overview",
      link: "?tab=overview",
      isActive: "overview",
    },
    {
      title: "Advertisement/Features",
      link: "?tab=advertisement-features",
      isActive: "advertisement-features",
    },
    {
      title: "Language",
      link: "?tab=language",

      isActive: "language",
    },
    {
      title: "Work History",
      link: "?tab=work-history",
      isActive: "work-history",
    },
    {
      title: "Portfolio",
      link: "?tab=portfolio",
      isActive: "portfolio",
    },
    {
      title: "Skills and Expertise",
      link: "?tab=skills-and-expertise",
      isActive: "skills-and-expertise",
    },
    {
      title: "Education / Certificate",
      link: "?tab=education-certificate",
      isActive: "education-certificate",
    },
    {
      title: "Honor and Awards",
      link: "?tab=honor-and-awards",
      isActive: "honor-and-awards",
    },
    {
      title: "Social Links",
      link: "?tab=social-links",
      isActive: "overview",
    },
    {
      title: "Preview Profile",
      link: "/dashboard/profile",
      isActive: "preview-profile",
    },
  ];

  const [activeTab, setActiveTab] = useState("");
  const [overview, setOverView] = useState("overview");
  const [markTheNav, setMarkTheNav] = useState(0);

  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");
    if (location.pathname === "/dashboard/earnings-and-referrals") {
      setMarkTheNav(0);
      setActiveTab("earnings");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/messages") {
      if (tabParam === "admin") {
        setMarkTheNav(3);
        setActiveTab("messages");
      }
      if (tabParam === "theregister") {
        setMarkTheNav(2);
        setActiveTab("messages");
      }
      if (tabParam === "fullcheckid") {
        setMarkTheNav(1);
        setActiveTab("messages");
      }
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/account-settings/help") {
      setMarkTheNav(6);
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/news") {
      setMarkTheNav(0);
      setActiveTab("news");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/forum") {
      setMarkTheNav(0);
      setActiveTab("forum");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/edit-profile") {
      setMarkTheNav(4);
      setShowSecondNav(true);
      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/certificate") {
      setMarkTheNav(5);
      setShowSecondNav(false);

      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/account-settings") {
      setMarkTheNav(6);
      setShowSecondNav(false);

      setActiveTab("accountSettings");
    } else if (location.pathname === "/dashboard/help") {
      setMarkTheNav(7);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-number"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-password"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-email"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else {
      setShowSecondNav(false);
      setMarkTheNav(0);
      setActiveTab("");
    }

    // Edit Profile

    if (tabParam === "overview") {
      setOverView("overview");
    }
    if (tabParam === "advertisement-features") {
      setOverView("advertisement-features");
    }
    if (tabParam === "language") {
      setOverView("language");
    }
    if (tabParam === "work-history") {
      setOverView("work-history");
    }
    if (tabParam === "portfolio") {
      setOverView("portfolio");
    }
    if (tabParam === "skills-and-expertise") {
      setOverView("skills-and-expertise");
    }
    if (tabParam === "education-certificate") {
      setOverView("education-certificate");
    }
    if (tabParam === "honor-and-awards") {
      setOverView("honor-and-awards");
    }
    if (tabParam === "social-links") {
      setOverView("social-links");
    }
    if (tabParam === "preview-profile") {
      setOverView("preview-profile");
    }
  }, [location]);
  const { shouldRedirect, redirectTo } = useInspectRoute(location.pathname);




  if (shouldRedirect) {
    return <Navigate to={redirectTo} />
  } else {
    return (
      <>
        <div
          className={`min-h-[87vh] flex flex-col justify-between
            ${showSecondNav ? "min-w-[230px]" : "min-w-[310px]"} bg-[#F2F7FF] h-auto py-4 max-[900px]:hidden  
            `}
        >
          <div>
            <div className={`px-4 py-4  flex flex-row gap-2 items-center ${TextStyling.pageBodyDark}`}>
              <RxDashboard size={20} />
              <span> Dashboard </span>
            </div>

            {/* navs */}
            <div>

              {/* Messages */}
              <div
                className={`px-4 py-4 flex items-center gap-2 cursor-pointer  ${TextStyling.pageBodyDark}
                        ${activeTab === "messages" ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                onClick={() => {
                  navigate("/dashboard/messages");
                }}
              >
                <BsInbox size={20} />
                <span>Messages</span>
              </div>

              <div>
                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer  ${TextStyling.pageBody}
                    ${markTheNav === 2 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                  onClick={() => {
                    navigate("/dashboard/messages?tab=theregister");
                  }}
                >
                  <div className="">The Register</div>
                  <div className="">{messagesR?.length || 0}</div>
                </div>

                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer  ${TextStyling.pageBody}
                    ${markTheNav === 3 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                  onClick={() => {
                    // Navigate to the messages route with the admin tab parameter
                    navigate("/dashboard/messages?tab=admin");
                  }}
                >
                  <div className="">Admin</div>
                  <div className="">{messagesA.length || 0}</div>
                </div>

                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer  ${TextStyling.pageBody}
                      ${markTheNav === 1 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                  onClick={() => {
                    navigate("/dashboard/messages?tab=fullcheckid");
                  }}
                >
                  <div className="">FullIDCheck</div>
                  <div className="">{messagesF.length || 0}</div>
                </div>
              </div>

              {/* Earn and Refs */}
              <div
                className={`px-4 py-4 flex items-center gap-2 cursor-pointer ${TextStyling.pageBodyDark}
                ${activeTab === "earnings" ? "font-bold text-white bg-[#1C3775]" : "font-normal"}
                `}
                onClick={() => navigate("/dashboard/earnings-and-referrals")}
              >
                <BsTrophy size={20} />
                <span>
                  Earnings and Referrals
                </span>
              </div>

              {/* Professional Profile */}
              <div
                className={`px-4 py-4 flex items-center gap-2 cursor-pointer ${TextStyling.pageBodyDark}
                ${activeTab === "editProfile" ? "font-bold text-white bg-[#1C3775]" : "font-normal "
                  } 
                `}
              >
                <BsPerson size={20} />

                <div
                  onClick={() => navigate("/dashboard/edit-profile")}
                >
                  Professional Profile
                </div>
              </div>

              <div>
                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer  ${TextStyling.pageBody}
                  ${markTheNav === 4 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                  onClick={() => navigate("/dashboard/edit-profile")}
                >
                  <div className="cursor-pointer">Edit Profile</div>
                </div>

                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer  ${TextStyling.pageBody}
                  ${markTheNav === 5 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                  onClick={() => navigate("/dashboard/certificate")}
                >
                  <div className="cursor-pointer">Cerificate</div>
                </div>
              </div>

              {/* Forums */}
              <div
                className={`px-4 py-4 flex items-center gap-2 cursor-pointer ${TextStyling.pageBodyDark}
                ${activeTab === "forum" ? " font-bold" : "font-normal"}
                `}
                onClick={() => navigate("/dashboard/forum")}
              >
                <TiMessages size={20} />
                <span>
                  Forum
                </span>
              </div>

              {/* Account Settings */}
              <div
                className={`px-4 py-4 flex items-center gap-2 cursor-pointer ${TextStyling.pageBodyDark}
              ${activeTab === "accountSettings"
                    ? " font-bold"
                    : "font-normal"
                  }`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                <TfiSettings size={20} />
                <div className="cursor-pointer ">Account Settings</div>
              </div>

              <div>
                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer
                  ${markTheNav === 6 ? "font-bold text-white bg-[#1C3775]" : "font-normal"}`}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate("/dashboard/account-settings")}
                  >
                    Security Setting
                  </div>
                </div>
                <div
                  className={`px-11 py-2 flex flex-row justify-between items-center gap-2 cursor-pointer
                  ${markTheNav === 7 ? "font-bold text-white bg-[#1C3775]" : "font-normal"} `}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate("/dashboard/help")}
                  >
                    Help
                  </div>
                </div>


                {auth.error && (
                  <div className="flex items-center py-3 text-red-900 px-14">
                    {auth.error}...
                  </div>
                )}
              </div>

            </div>
          </div>

          {/* Logout */}
          <div
            className={`px-4 py-4 flex items-center gap-2 cursor-pointer ${TextStyling.pageBodyDark}
                  ${markTheNav === 8
                ? "font-bold text-white bg-[#1C3775]"
                : "font-normal"
              } `}
          >
            <CiLogout size={20} />

            <div onClick={handleLogout} className="cursor-pointer">
              Log Out
            </div>
          </div>

        </div>

        {showSecondNav && (
          <>
            <div className="min-w-[200px] bg-[#FAFAFA] max-[900px]:hidden">
              {listTab.map((item, index) => (
                <div
                  key={index}
                  className={`p-4 flex items-center justify-start cursor-pointer  
                    ${TextStyling.pageBody} 
                    ${item.title === isActive ? "bg-blue-100 text-blue-700 text-opacity-100" : "bg-[#FAFAFA]"} `}
                  onClick={() => {
                    navigate(item.link);
                    setIsActive(item.title);
                  }}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </>
        )}

        <Loader loadingText={"Logging out..."} isOpen={auth.logout.loading} />
      </>
    );
  }
}

export default SidebarDashboard;
