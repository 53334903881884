import Navbar from "./Navbar";

const ScreenWrapper = ({ children }) => {
  return (
    <>
      <div className="flex flex-col h-full w-full md:bg-[#f9fbfc]">
        {children}
      </ div>
    </>
  )
}

export default ScreenWrapper;