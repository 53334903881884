import React from "react";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import useEducationAndCertifications from "../../../feature/dashboard/professionalProfile/profileEdit/educationAndCertifications/useEducationAndCertifications";
import dateHelper from "../../../utils/dateHelper";
import EducationCertItem from "../../../components/Dashboard/profileEdit/EducationCertItem";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";
import InputStyling from "../../../styling/InputStyling";
import ResponsiveValues from "../../../styling/ResponsiveValues";

function EducationCert() {

  const {
    success, error, loading,
    showAddButton, showEditButton,
    displayedEducationAndCertifications,
    postEducationAndCertifications, setPostEducationAndCertifications,
    thumbnail,
    handleImageUpload,
    handleAddRecord, handleRemoveButton, handleEditButton, handleUpdateButton,
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateSchool, validateCourse, validateStarted, validateGraduated
  } = useEducationAndCertifications();

  return (
    <>
      <div className="flex flex-col max-md:max-w-full">
        <PageTitle title='Add Education / Certificate' description='Showcase your learning! Add your education and certificates to highlight your academic achievements!' />
        <div className="flex justify-between gap-4 px-5">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <div className="mt-4 max-md:max-w-full">
          <div className="mt-1 relative flex flex-col w-[16%] max-md:ml-0 max-md:w-full">
            <div className={` ${TextStyling.inputLabel}`}>
              School
            </div>
            <div className="mt-1 p-4 w-fit flex flex-col justify-center self-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300">
              <img alt="img"
                loading="lazy"
                src={thumbnail?.src || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
                className="self-center"
              />
              {!thumbnail?.src && <div>School / Institution Logo</div>}
            </div>
            <input type="file"
              accept="image/*"
              value=""
              onChange={handleImageUpload}
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            />
          </div>

          <div className={`mt-4 ${TextStyling.inputLabel}`}>
            School Name
          </div>
          <input
            placeholder="Name of School / Institution"
            value={postEducationAndCertifications?.name || ''}
            autoComplete="organization"
            onChange={(e) => {
              setPostEducationAndCertifications({ ...postEducationAndCertifications, name: e.target.value });
              validateSchool(e.target.value);
            }}
            className={`mt-1 ${InputStyling.text}`} />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.school?.errorMessage} />

          <div className={`${TextStyling.inputLabel}`}>Degree / Course</div>
          <input
            placeholder="Degree / Course"
            value={postEducationAndCertifications?.degree_or_course || ''}
            autoComplete="organization"
            onChange={(e) => {
              setPostEducationAndCertifications({ ...postEducationAndCertifications, degree_or_course: e.target.value });
              validateCourse(e.target.value);
            }}
            className={`mt-1 ${InputStyling.text}`}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.course?.errorMessage} />

          <div className="flex flex-row justify-between items-center gap-4">
            <div className="flex-1">
              <div className={`${TextStyling.inputLabel}`}>Date Started</div>
              <input
                type="date"
                placeholder="Date Started"
                autoComplete="on"
                value={
                  dateHelper.isDatetimeChecker(postEducationAndCertifications?.date_started) ? dateHelper.datetimeToInputDate(postEducationAndCertifications?.date_started) : postEducationAndCertifications?.date_started
                }
                onChange={(e) => {
                  setPostEducationAndCertifications({ ...postEducationAndCertifications, date_started: e.target.value });
                  validateStarted(e.target.value);
                }}
                className={`mt-1 ${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.started?.errorMessage} />
            </div>
            <div className="flex-1">
              <div className={`${TextStyling.inputLabel}`}>Date Graduated</div>
              <input
                type="date"
                placeholder="Date Graduated"
                autoComplete="on"
                value={
                  dateHelper.isDatetimeChecker(postEducationAndCertifications?.date_graduated) ? dateHelper.datetimeToInputDate(postEducationAndCertifications?.date_graduated) : postEducationAndCertifications?.date_graduated
                }
                onChange={(e) => {
                  setPostEducationAndCertifications({ ...postEducationAndCertifications, date_graduated: e.target.value });
                  validateGraduated(e.target.value);
                }}
                className={`mt-1 ${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.graduated?.errorMessage} />
            </div>

          </div>
        </div>

        {showAddButton &&
          <div className="flex flex-col justify-center items-end text-base font-semibold text-white max-md:max-w-full">
            <button onClick={() => handleAddRecord()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Add Education
            </button>
            <button onClick={() => handleAddRecord()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Add Education
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-6 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }
          </div>
        }

        {showEditButton &&
          <div className="flex flex-col justify-center items-end text-base font-semibold text-white max-md:max-w-full">
            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Update Education
            </button>
            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Update Education
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-6 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }
          </div>
        }

        {(displayedEducationAndCertifications && displayedEducationAndCertifications.length > 0) &&
          <div className="my-8 md:my-0 flex flex-col text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
            <div className="flex flex-row gap-2 justify-between items-center">
              <div className={`${TextStyling.pageHeader2} w-fit`}>Educational & Certification</div>
              <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1`}></div>
            </div>
            {displayedEducationAndCertifications &&
              displayedEducationAndCertifications?.map((item) => <EducationCertItem data={item} onEdit={handleEditButton} onDelete={handleRemoveButton} />

              )}
          </div>
        }
      </div>
    </>
  );
}

export default EducationCert;
