const imageCheck = (event, mb, widthPx, heightPx, mySetError) => {
  const file = event.target.files[0];
  const { name } = file;
  const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

  mySetError("");

  if (file) {
    // mb size check
    if (file.size > mb * 1024 * 1024) {
      mySetError(`File size should be less than ${mb}MB.`);
      return null;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      // dimension check
      if (img.width !== widthPx || img.height !== heightPx) {
        mySetError(`Image dimensions should be ${widthPx}x${heightPx} pixels.`);
        URL.revokeObjectURL(objectUrl);
        return null;
      }
    };

    img.onerror = () => {
      mySetError('Invalid image file');
      URL.revokeObjectURL(objectUrl);
      return null;
    };

    img.src = objectUrl;
    return { image: img, url: objectUrl, name: fileNameWithoutExtension, file: file };

  }

};

export const imageCheckSquare = (event, mb, mySetError) => {
  const file = event.target.files[0];
  const { name } = file;
  const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

  mySetError("");

  if (file) {
    // mb size check
    if (file.size > mb * 1024 * 1024) {
      mySetError(`File size should be less than ${mb}MB.`);
      return null;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      // dimension check
      if (img.width !== img.height) {
        mySetError(`Image width and height should be equal.`);
        URL.revokeObjectURL(objectUrl);
        return null;
      }
    };

    img.onerror = () => {
      mySetError('Invalid image file');
      URL.revokeObjectURL(objectUrl);
      return null;
    };

    img.src = objectUrl;
    return { image: img, url: objectUrl, name: fileNameWithoutExtension, file: file };

  }

};

export const imageCheckSquareFromUrl = async (url, mb, mySetError) => {
  mySetError("");

  const img = new Image();

  img.onload = () => {
    // Create a canvas to check the image size in MB
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // Convert the canvas data to a Blob and check its size
    canvas.toBlob((blob) => {
      if (blob.size > mb * 1024 * 1024) {
        mySetError(`File size should be less than ${mb}MB.`);
        return null;
      }

      // Dimension check
      if (img.width !== img.height) {
        mySetError(`Image width and height should be equal.`);
        return null;
      }

      // If all checks pass, return the image details
      const fileNameWithoutExtension = url.split("/").pop().split(".").slice(0, -1).join(".");
      return { image: img, url, name: fileNameWithoutExtension, file: blob };
    });
  };

  img.onerror = () => {
    mySetError('Invalid image URL');
    return null;
  };

  img.src = url;

  return new Promise((resolve) => {
    img.onload = () => {
      const fileNameWithoutExtension = url.split("/").pop().split(".").slice(0, -1).join(".");
      resolve({ image: img, url, name: fileNameWithoutExtension });
    };
    img.onerror = () => {
      mySetError('Invalid image URL');
      resolve(null);
    };
  });
};


export const multiImageCheck = ({ event, mb, widthPx = null, heightPx = null, setError }) => {
  const files = [...event.target.files];
  const images = [];

  files?.forEach((file, index) => {
    const { name } = file;
    const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

    setError("");

    if (file) {
      // mb size check
      if (file.size > mb * 1024 * 1024) {
        setError(`File size should be less than ${mb}MB.`);
        return null;
      }

      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      if (widthPx !== null && heightPx !== null) {
        img.onload = () => {
          // dimension check
          if (img.width !== widthPx || img.height !== heightPx) {
            setError(`Image dimensions should be ${widthPx}x${heightPx} pixels.`);
            URL.revokeObjectURL(objectUrl);
            return null;
          }
        };
      }

      img.onerror = () => {
        setError('Invalid image file');
        URL.revokeObjectURL(objectUrl);
        return null;
      };

      img.src = objectUrl;
      images.push({ image: img, url: objectUrl, name: fileNameWithoutExtension, file: file });

    }
  });

  return images;
};

export const imageCheckSize = ({ file, mb = null, mySetError }) => {
  const { name } = file;
  const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

  mySetError("");

  if (file) {
    // mb size check
    // if (file.size > mb * 1024 * 1024) {
    //   mySetError(`File size should be less than ${mb}MB.`);
    //   return null;
    // }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onerror = () => {
      mySetError('Invalid image file');
      URL.revokeObjectURL(objectUrl);
      return null;
    };

    img.src = objectUrl;
    return { image: img, url: objectUrl, name: fileNameWithoutExtension, file: file };

  }

};


export default imageCheck;