import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import check from "../../assets/check.png";


function EmailVerified() {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const status = params.get("verified");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (1 === parseInt(status)) {
      const uData = JSON.parse(localStorage.getItem('user'));
      uData.is_verified = true;

      localStorage.setItem('user', JSON.stringify(uData));

      setMessage("Email Confirmed");
    } else {
      setMessage("Email Confirmation failed");
    }
  }, []);

  return (
    <div className={`${ResponsiveValues.minHeight} flex flex-row justify-center pt-12 md:p-24 `}>
      <div className={`flex flex-col items-center gap-4 ${ResponsiveValues.cardScreenStyle} w-fit h-fit`}>

        <div className={`flex flex-row justify-center gap-4 ${TextStyling.pageBodyBold}`}>
          <img
            alt="img"
            loading="lazy"
            src={check}
            className="w-10 shrink-0 aspect-square "
          />
          <div className="my-auto">Email Confirmed</div>
        </div>

        {message === "Email Confirmed" ? (
          <>
            <div className={`${TextStyling.pageBodyBold}`}>
              Please login with your account to continue setting up personal information
            </div>
            <div className="flex flex-row items-center justify-center my-2">
              <button
                onClick={() => navigate('/login')}
                className={`${ResponsiveValues.buttonFit}`}
              >
                Go to Login
              </button>
            </div>
          </>
        ) : (
          <div className={`${TextStyling.pageBodyBold}`}>
            Something went wrong. Please contact admin support to resolve this issue. Thank you.
          </div>
        )}

      </div>
    </div>
  );
}

export default EmailVerified;
