import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CustomLink = ({ to, children, ...props }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      // Ctrl+Click, Cmd+Click, or middle mouse button click
      window.open(to, '_blank');
    } else {
      event.preventDefault();
      navigate(to);
    }
  };

  return (
    <a href={to} onClick={handleClick} {...props}>
      {children}
    </a>
  );
};

export default CustomLink;
