import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dateHelper from '../../../utils/dateHelper';

import { earningsAction as action } from './earningsSlice';
import { getEarnings, getUserProfile } from './earningsSlice';

const useEarnings = () => {
  const dispatch = useDispatch();
  const { success, error, loading, values: earningsData, userData } = useSelector(state => state.earnings);
  // const userData = useSelector(state => state.auth.user);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      await dispatch(getUserProfile({}));
      await dispatch(getEarnings({}));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  return {
    success, error, loading,
    copied, setCopied,
    userData, earningsData,
  }

}

export default useEarnings;
