import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import App from './App';

const LocaleProvider = () => {
	const [locale, setLocale] = useState('en'); // Default locale

	useEffect(() => {
		// You can determine the locale dynamically here, e.g., from user settings or browser settings
		// const intl = useIntl(); const locale = intl.locale;
		const userLocale = navigator.language.split('-')[0] || 'en';
		setLocale(userLocale);
	}, []);

	return (
		<IntlProvider locale={locale}>
			<App />
		</IntlProvider>
	);
};

export default LocaleProvider;