import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import google2faApi from "./google2faApi";

// Helper function to handle async thunks
const createAsyncThunkWithAPI = (type, apiCall) => {
  return createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const response = await apiCall(args);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  });
};

export const enable2FA = createAsyncThunkWithAPI('/google-2fa/enable', google2faApi.enable2FA);
export const disable2FA = createAsyncThunkWithAPI('/google-2fa/disable', google2faApi.disable2FA);
export const verify2FA = createAsyncThunkWithAPI('/google-2fa/verify', (data) => google2faApi.verify2FA(data));
export const verifyEnabling2FA = createAsyncThunkWithAPI('/google-2fa/verify-enabling-2Fa', (data) => google2faApi.verifyEnabling2FA(data));
export const recoveryCodes2FA = createAsyncThunkWithAPI('/google-2fa/recovery-codes', google2faApi.recoveryCodes2FA);
export const newRecoveryCodes2FA = createAsyncThunkWithAPI('/google-2fa/new-recovery-codes', google2faApi.newRecoveryCodes2FA);

// Initial state
const initialState = {
  enable2FA: {
    success: null,
    loading: false,
    error: null,
  },
  disable2FA: {
    success: null,
    loading: false,
    error: null,
  },
  verify2FA: {
    success: null,
    loading: false,
    error: null,
  },
  verifyEnabling2FA: {
    success: null,
    loading: false,
    error: null,
  },
  recoveryCodes2FA: {
    success: null,
    loading: false,
    error: null,
  },
  newRecoveryCodes2FA: {
    success: null,
    loading: false,
    error: null,
  },
};

// Reusable reducers for pending, fulfilled, and rejected states with payload conditions
const createReducersWithPayloadConditions = (builder, action, name, successMessages) => {
  builder
    .addCase(action.pending, (state) => {
      state[name].loading = true;
      state[name].error = null;
    })
    .addCase(action.rejected, (state, action) => {
      state[name].loading = false;
      state[name].error = action.payload;
    })
    .addCase(action.fulfilled, (state, action) => {
      state[name].loading = false;
      state[name].error = null;
      const { message } = action.payload || {};

      if (successMessages.includes(message)) {
        state[name].success = message;
        state[name].error = null;
      } else {
          state[name].error = message || null;
      }

    });
};

const google2faSlice = createSlice({
  name: 'google2fa',
  initialState,
  reducers: {
    setEnable2FAError: (state, action) => {
      state.enable2FA.error = action.payload || null;
    },
    setEnable2FASuccess: (state, action) => {
      state.enable2FA.success = action.payload || null;
    },
    setDisable2FAError: (state, action) => {
      state.disable2FA.error = action.payload || null;
    },
    setDisable2FASuccess: (state, action) => {
      state.disable2FA.success = action.payload || null;
    },
    setVerify2FAError: (state, action) => {
      state.verify2FA.error = action.payload || null;
    },
    setVerify2FASuccess: (state, action) => {
      state.verify2FA.success = action.payload || null;
    },
    setVerifyEnabling2FAError: (state, action) => {
      state.verifyEnabling2FA.error = action.payload || null;
    },
    setVerifyEnabling2FASuccess: (state, action) => {
      state.verifyEnabling2FA.success = action.payload || null;
    },
    setNewRecoveryCodes2FAError: (state, action) => {
      state.newRecoveryCodes2FA.error = action.payload || null;
    },
    setNewRecoveryCodes2FASuccess: (state, action) => {
      state.newRecoveryCodes2FA.success = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    createReducersWithPayloadConditions(builder, enable2FA, 'enable2FA', []);
    createReducersWithPayloadConditions(builder, disable2FA, 'disable2FA', ['Two-factor authentication disabled successfully.']);
    createReducersWithPayloadConditions(builder, verify2FA, 'verify2FA', ['OTP is valid!']);
    createReducersWithPayloadConditions(builder, verifyEnabling2FA, 'verifyEnabling2FA', []);
    createReducersWithPayloadConditions(builder, recoveryCodes2FA, 'recoveryCodes2FA', []);
    createReducersWithPayloadConditions(builder, newRecoveryCodes2FA, 'newRecoveryCodes2FA', []);
  },
});

export const { 
  setEnable2FAError,
  setEnable2FASuccess,
  setDisable2FAError,
  setDisable2FASuccess,
  setVerify2FAError,
  setVerify2FASuccess,
  setVerifyEnabling2FAError,
  setVerifyEnabling2FASuccess,
  setNewRecoveryCodes2FAError,
  setNewRecoveryCodes2FASuccess,
} = google2faSlice.actions;

export default google2faSlice.reducer;
