import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsBriefcase } from "react-icons/bs";
import profileRegisteredIcon from "../../assets/Profile/profileRegisteredIcon.png";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";

function BannerProfile({ data, styling }) {
  const ProfilePictureDefault = "/ProfilePic.png";
  const profileRegisteredIcon = "/profileRegisteredIcon.png";
  const navigation = useNavigate();
  const [profilePicture, setProfilePicture] = useState(profileRegisteredIcon);

  useEffect(() => {
    if (data !== null && data.profile_photo_path !== null) {
      console.log(`data 1 : ${JSON.stringify(data)}`);

      setProfilePicture(data?.profile_photo_path);
    } else {
      console.log(`data 2 : ${JSON.stringify(data)}`);

    }
  }, [data?.profile_photo_path]);

  return (
    <>
      <div className={`${ResponsiveValues.forMobileScreenFlex} w-full p-5 flex-col rounded-lg border border-neutral-300 shadow-md ${styling}`}>
        <div className="flex flex-row justify-between item-center gap-2  max-sm:gap-0">
          <div className="flex flex-row justify-start item-center gap-2  max-sm:gap-0">

            <img
              alt="profilepicbanner"
              src={profilePicture}
              className="profile-pic w-16 h-16 bg-blue-400  rounded-full border-[2px] border-white my-auto"
            />
            <div className=" p-2 h-fit flex items-center justify-end gap-1 text-neutral-100 bg-blue-900 rounded-full">
              <FaEdit size={12} />
              <button
                className="job max-sm:text-[9px] text-[12px] cursor-pointer"
                onClick={() =>
                  navigation("/dashboard/edit-profile?tab=overview")
                }
              >
                Edit Profile
              </button>
            </div>
          </div>

          <img
            src={profileRegisteredIcon}
            alt="profileRegisteredIcon "
            className="h-16"
          />
        </div>

        <div className="name-job flex flex-col justify-center">
          <div className={TextStyling.pageHeader2NoSpacing}>
            {data?.fullname}
          </div>
          <div className={`flex flex-row gap-4 ${TextStyling.noteNoSpacing}`}>
            <div className="flex flex-row gap-1 items-center justify-center">
              <BsBriefcase size={16} />
              <div className="job ">{data?.profession}</div>
            </div>
            <div className="flex flex-row gap-1 items-center justify-center">
              <MdOutlineLocationOn size={16} />
              <div>{data?.address?.city}, {data?.address?.country}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${ResponsiveValues.forDesktopScreenFlex} justify-between w-full p-5 rounded-lg border border-neutral-300 shadow-md ${styling}`}>
        <div className="pic-name-container flex item-center gap-2  max-sm:gap-0">
          <img
            alt="profilepicbanner"
            src={profilePicture}
            className="profile-pic w-16 h-16 bg-blue-400  rounded-full border-[2px] border-white my-auto"
          />
          <div className="name-job flex flex-col gap-2 justify-center leading-7  max-sm:pl-2">
            <div className="flex gap-3 max-sm:flex-col max-sm:gap-0  ">
              <div className="text-xl font-semibold ">
                {data?.fullname}
              </div>
              <div className="flex items-center justify-center gap-1 text-neutral-100 bg-blue-900 rounded-full px-2 cursor-pointer">
                <FaEdit size={12} />
                <button
                  className="job max-sm:text-[9px] text-[12px] cursor-pointer"
                  onClick={() =>
                    navigation("/dashboard/edit-profile?tab=overview")
                  }
                >
                  Edit Profile
                </button>
              </div>
            </div>

            <div className="flex flex-row text-sm text-neutral-600 gap-4">
              <div className="flex flex-row gap-1 items-center justify-center">
                <BsBriefcase size={16} />
                <div className="job ">{data?.profession}</div>
              </div>
              <div className="flex flex-row gap-1 items-center justify-center">
                <MdOutlineLocationOn size={16} />
                <div>{data?.address?.city}, {data?.address?.country}</div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={profileRegisteredIcon}
          alt="profileRegisteredIcon "
          className="h-16"
        />
      </div>
    </>
  );
}

export default BannerProfile;
