import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import { IoKeyOutline, IoMailOutline } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";

import useLogin from "../../api/customHook/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../feature/authSlice";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import InputStyling from "../../styling/InputStyling";
import TopBarLogo from "./TopBarLogo";

// import useInspectRoute from "../../api/customHook/useInspectRoute";
function Login() {
  const {
    mainImg,
    logoImg,
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    resendVerificationEmail,
    ismodalOpen,
    handleGoogleSignin, handleGoogleLogin,

  } = useLogin();

  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);
  const dispatch = useDispatch();
  // const {shouldRedirect, redirectTo} = useInspectRoute('/login');

  useEffect(() => {
    dispatch(setError(null));
  }, [dispatch]);

  useEffect(() => {
    setIsFading(true);
    const timer = setTimeout(() => {
      setIsFading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [auth.error]);

  // if (shouldRedirect) {
  //   return <Navigate to={redirectTo} />;
  // }

  return (
    <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">

      {/* Login Form */}
      <div className={`
        min-w-[70vh] max-h-[80vh]
        ${ResponsiveValues.cardScreenStyleNoSpacing}
        flex flex-col grow
      `}>

        {/* Top logo */}
        <TopBarLogo logoImg={logoImg} />

        {/* Body */}
        <div className="p-6">
          {/* Title */}
          <div className={`mt-2 ${TextStyling.pageHeader1}`}>
            Welcome to Full ID Check
          </div>
          <div className={`mt-2 ${TextStyling.pageBody}`}>
            Log in to your account by filling in the details below.
          </div>

          {auth.resend.success && (
            <span className="text-sm text-green-900">
              <br />
              &nbsp;{auth.resend.success}
            </span>
          )}

          {((auth.error && auth.error.match(/verified/gi) !== null) ||
            auth.resend.error) &&
            !auth.resend.success && (
              <div className="mt-2 text-sm text-red-500">
                {auth.error}
                <span
                  className="border-b border-gray-500 cursor-pointer text-sky-600 "
                  onClick={resendVerificationEmail}
                >
                  {" "}
                  resend link
                </span>
                {auth.resend.loading && (
                  <span className="text-sky-600">
                    <br />
                    &nbsp;Resending...
                  </span>
                )}
                {auth.resend.error && (
                  <span className="text-red-600">
                    <br />
                    &nbsp;{auth.resend.error}
                  </span>
                )}
              </div>
            )}
          <form onSubmit={handleLogin}>

            <div className="relative w-full">
              <div
                className="absolute left-3 top-[50%] cursor-pointer "
                onClick={handleToggleEye}
              >
                <IoMailOutline size={16} color="#BCBCBC" />

              </div>

              <input
                className={`mt-4 pl-10 ${InputStyling.text}`}
                placeholder="Email"
                value={fullName}
                onChange={(fullName) => setFullName(fullName.target.value)}
              />
            </div>

            <div className="relative w-full">
              <div
                className="absolute left-3 top-[50%] cursor-pointer "
                onClick={handleToggleEye}
              >
                <IoKeyOutline size={16} color="#BCBCBC" />

              </div>

              <div
                className="absolute right-3 top-[50%] cursor-pointer "
                onClick={handleToggleEye}
              >
                {toggleEye ? (
                  <FaEye size={16} color="#BCBCBC" />
                ) : (
                  <FaEyeSlash size={16} color="#BCBCBC" />
                )}
              </div>
              <input
                className={`mt-4 pl-10 ${InputStyling.text}`}
                placeholder="Password"
                value={loginPass}
                onChange={(pass) => setLoginPass(pass.target.value)}
                type={toggleEye ? "text" : "password"}
                autoComplete="on"
              />
            </div>

            <div
              className={`
                my-4
                ${TextStyling.noteSmaller} 
                flex justify-end cursor-pointer`}
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </div>

            <button
              type="submit"
              disabled={auth.loading}
              className={`${ResponsiveValues.buttonFullNoSpacing}`}
            >
              Login
            </button>

            {auth.loading && (
              <p className="mt-1 text-blue-900">Logging in...</p>
            )}
            {auth.error && isFading && (
              <p className="text-red-600">{auth.error}</p>
            )}
          </form>

          {/* spacer */}
          <div className="my-4 flex flex-row justify-center items-center">
            <span className={`flex-1 border-b border-black`}><hr /></span>
            <span className="mx-4">or</span>
            <span className={`flex-1 border-b border-black`}><hr /></span>
          </div>


          <a
            className={`flex flex-row items-center gap-2 ${ResponsiveValues.buttonFullNoColor}`}
            href='https://staging-api.fullidcheck.com/auth/google/signup'
          >
            <FcGoogle /> <span className={`${TextStyling.pageBodyFit} w-fit`}>SignUp with Google</span>
          </a>

          <a
            className={`flex flex-row items-center gap-5 ${ResponsiveValues.buttonFullNoColor}`}
            href='https://staging-api.fullidcheck.com/auth/google/signin'
          >
            <FcGoogle /><span className={`${TextStyling.pageBodyFit} w-fit`}> Login with Google</span>
          </a>


          <div
            onClick={handleSignUp}
            className="mt-3 flex flex-row justify-center items-center gap-2"
          >
            <span className={`${TextStyling.note}`}>Don’t have an account?</span>
            <span className={`text-sm font-bold text-[#0082BA]`}>Sign-up</span>
          </div>

        </div>
      </div>

      <Loader loadingText={"Logging in..."} isOpen={ismodalOpen} />
    </div >
  );
}

export default Login;
