import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AppRoute from "./routes/AppRoute";
import { useSelector } from "react-redux";
import "./App.css";

function App() {
  const auth = useSelector((state) => state.auth);

  return (
    // <Router basename="/fullidcheck">
    <Router>
      <AppRoute />
      {/* {!auth?.user && <BasicPagesNavigation />} */}
    </Router>
  );
}

export default App;
