import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logonav from "../assets/logo-navbar.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/authSlice";

function CompletingRegistrationNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const loc = useLocation();

  return (
    <>
      <header className="max-w-[1366px] m-auto flex  justify-start items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-6 py-3">
        <img
          loading="lazy"
          src={logonav}
          alt="Company logo"
          className=" my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px]"
          onClick={() => {
            if(loc.pathname === '/register/registration-success'){
              dispatch(logout());
              navigate('/');
            }else{
              if(window.confirm('You can come again later to fill out necessary information!')){
                dispatch(logout());
                navigate('/');
              }
            }
          }}
        />
        <nav className="max-md:flex-wrap flex flex-row max-sm:hidden max-[900px]:hidden cursor-pointer font-semibold px-4">
          {""}
        </nav>
        <div className="flex max-sm:gap-2 gap-4 min-[900px]:hidden ">
          <div className="self-stretch my-auto text-lg text-blue-900 font-bold min-[900px]:hidden ">
            {""}
          </div>
        </div>
      </header>
    </>
  );
}

export default CompletingRegistrationNavbar;
