import FileIcons from "../../feature/dashboard/uploadDocuments/accessKey/fileIcons"
import LoadingIndicator from "../../components/LoadingIndicator";
import FailureIndicator from "../../components/FailureIndicator";
import ResponsiveValues from "../../styling/ResponsiveValues";
import InputStyling from "../../styling/InputStyling";
import TextStyling from "../../styling/TextStyling";
import FileUploadedInfo from "../Registration/FileUploadedInfo";

const UploadAdditionalDocument = ({ success, error, loading, uploadedAll, addedIdToInput, addedBillingToInput, handleContinue, }) => {

  const fileList = [];

  return (
    <div className={`${ResponsiveValues.minHeight} flex flex-row justify-center`}>
      {loading && <LoadingIndicator styling="w-full flex justify-end px-16" />}
      <div className={`px-12 flex flex-col justify-start text-start items-start gap-4 
        ${ResponsiveValues.cardScreenStyle} h-fit ${ResponsiveValues.minWidthCard}
        shadow-md
        `}>

        <div className="font-bold text-[#1c3775] text-2xl whitespace-nowrap">
          Additional Document Requirements
        </div>
        <div className={`${TextStyling.pageBody}`}>
          Please upload the following:
          <div className={`px-4 ${TextStyling.pageBody}`}>
            ● National ID
            <br />
            ● Postal ID with Photo
            <br />
            ● Latest Billing Statement
          </div>
        </div>

        <div className={`mt-8 relative hover:opacity-80 ${InputStyling.text}`}>
          <div className="py-16 text-neutral-300">
            <div className="text-center">
              Drag or Upload your documents here
            </div>
            <input
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              type="file"
              accept="application/pdf, image/*, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple="multiple"
              value=""
              onChange={(e) => { addedIdToInput(e) }}
            />
            <div className="text-center">{`jpg, png, pdf, word`}</div>
          </div>
        </div>

        {/* Uploaded Files */}
        <div className="my-4 grid grid-cols-2 gap-4">
          {fileList.length > 0 && fileList.map((item, i) => {
            const filename = item.file.name;
            const index = filename.lastIndexOf('.');
            const type = index !== -1 ? filename.substring(index + 1) : 'File';

            // if (item.type !== "BILLING") {
            return <FileUploadedInfo
              fileName={item.file.name}
              fileType={type}
              fileSize={
                `${(item.file.size / (1024 * 1024)).toFixed(2)} mb`
              }
              onDelete={() => {
                //  onDelete(i, "DOCUMENT", item.id) 
              }}
            />
            // }
          }
          )}
        </div>

        {/* Spacer */}
        <div className="w-full border-b border-neutral-200" />

        {/* 
        {
          uploadedAll?.length > 0 &&
          <div className="font-semibold text-lg mt-10">
            Uploaded Documents
          </div>
        } */}

        {/* <div className="docs-container flex gap-6 justify-start items-center my-3">
          {
            uploadedAll?.map((item, index) => {
              if (item.docType === 'image') {
                return <FileIcons.ImageFile
                  key={index}
                  filename={item.file?.name || 'Image File'}
                />
              } else if (item.docType === 'pdf') {
                return <FileIcons.PdfFile
                  key={index}
                  filename={item.file?.name || 'PDF File'}
                />
              } else {
                return <FileIcons.DocFile
                  key={index}
                  filename={item.file?.name || 'Doc File'}
                />
              }
            })
          }
        </div> */}

        {Array.isArray(error) && error.length > 0 &&
          error?.map((item, index) => {
            return <div className="text-red-500 flex flex-col my-auto text-sm12">
              {item}
            </div>
          })
        }

        <button
          onClick={() => { handleContinue() }}
          className={`${ResponsiveValues.buttonFull}`}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default UploadAdditionalDocument