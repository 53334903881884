import React, { useEffect, useState } from "react";
import SidebarDesktop from "../../../components/Dashboard/SidebarDesktop";
import Overview from "./Overview";
import { useLocation } from "react-router-dom";
import Language from "./Language";
import WorkHistory from "./WorkHistory";
import Portfolio from "./Portfolio";
import SkillsExpertise from "./SkillsExpertise";
import EducationCert from "./EducationCert";
import HonorAwards from "./HonorAwards";
import PreviewProfile from "./PreviewProfile";
import AdvertisingFeatures from "./AdvertisingFeatures";
import SocialLinks from "./SocialLinks";

function EditProfile() {
  const [toggleTabProfile, setToggleTabProfile] = useState("overview");
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");
    if (tabParam === "overview") {
      setToggleTabProfile("overview");
    }
    if (tabParam === "advertisement-features") {
      setToggleTabProfile("advertisement-features");
    }
    if (tabParam === "language") {
      setToggleTabProfile("language");
    }
    if (tabParam === "work-history") {
      setToggleTabProfile("work-history");
    }
    if (tabParam === "portfolio") {
      setToggleTabProfile("portfolio");
    }
    if (tabParam === "skills-and-expertise") {
      setToggleTabProfile("skills-and-expertise");
    }
    if (tabParam === "education-certificate") {
      setToggleTabProfile("education-certificate");
    }
    if (tabParam === "honor-and-awards") {
      setToggleTabProfile("honor-and-awards");
    }
    if (tabParam === "social-links") {
      setToggleTabProfile("social-links");
    }
    if (tabParam === "preview-profile") {
      setToggleTabProfile("preview-profile");
    }
  }, [location]);

  return (
    <div className="min-h-[90vh] ">
      <div className="w-full">
        {toggleTabProfile === "overview" && <Overview />}
        {toggleTabProfile === "advertisement-features" && (
          <AdvertisingFeatures />
        )}
        {toggleTabProfile === "language" && <Language />}
        {toggleTabProfile === "work-history" && <WorkHistory />}
        {toggleTabProfile === "portfolio" && <Portfolio />}
        {toggleTabProfile === "skills-and-expertise" && <SkillsExpertise />}
        {toggleTabProfile === "education-certificate" && <EducationCert />}
        {toggleTabProfile === "honor-and-awards" && <HonorAwards />}
        {toggleTabProfile === "social-links" && <SocialLinks />}
        {toggleTabProfile === "preview-profile" && <PreviewProfile />}
      </div>
    </div>
  );
}

export default EditProfile;
