import PageTitle from "./PageTitle"
import TextStyling from "../styling/TextStyling"
const MobileCardScreen = ({ children, title = '' }) => {

  return (
    <div className={`p-2 min-h-[89vh] w-full md:hidden flex justify-center`}>
      <div className="w-full">
        {/* title */}
        <div className=" flex flex-col justify-between items-start pb-4">
          <PageTitle title={title}
            hasSpacing='false'
            customTextStyleMobile={TextStyling.pageBodyBold} />
          {/* <PiCaretUpBold size={25} /> */}
        </div>
        {children}
      </div>
    </div>
  )
}

export default MobileCardScreen