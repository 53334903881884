import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const navLinks = [
    { text: "Why Register?", to: "/why-register" },
    { text: "Benefit Page", to: "/benefits" },
    { text: "Requirements", to: "/requirements" },
    { text: "About Us", to: "/about-us" },
    { text: "Contact", to: "/contact" },
  ];
  const navLinks2 = [
    { text: "Terms & Conditions", to: "/terms-and-conditions" },
    { text: "Anti-Bribery and Corruption Policy", to: "/antibribe-policy" },
    { text: "Privacy Policy", to: "/privacy" },
  ];

  const handleClick = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex items-center self-stretch justify-center w-full px-16 bg-white border-t border-blue-900 border-solid pt-7 pb-11 mt-32 text-neutral-600 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-0 max-md:flex-wrap max-lg:flex-col max-lg:justify-center max-md:align-center">
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/90549167c072cca20592d7f9d5e8c33ee00baf3dfc51c21b231671ca463bbd86?apiKey=3ae96302e69649f5be368f89230b0596&"
          className="shrink-0 max-w-full aspect-[4] w-[243px] max-lg:m-auto max-lg:mb-6 cursor-pointer"
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
          }}
        />
        <div className="flex flex-col justify-center flex-1 max-lg:max-w-full">
          <div className="flex gap-4 justify-center self-end py-1.5 text-base leading-5 max-lg:flex-wrap max-lg:text-sm max-lg:px-8 max-lg:mb-2 max-lg:m-auto">
            {navLinks.map((link, index) => (
              <div
                key={index}
                className="my-auto cursor-pointer "
                onClick={() => handleClick(link.to)}
              >
                {link.text}
              </div>
            ))}
          </div>
          <div className="flex gap-1 pl-20 text-md max-lg:flex-wrap max-lg:px-5">
            <div className="flex max-lg:justify-center flex-row gap-5 py-1.5 leading-[145%] max-lg:flex-wrap max-lg:text-center max-lg:m-auto">
              {navLinks2.map((link, index) => (
                <div
                  key={index}
                  className="my-auto cursor-pointer "
                  onClick={() => handleClick(link.to)}
                >
                  {link.text}
                </div>
              ))}
            </div>
            <div className="my-auto text-right max-lg:m-auto min-[1000px]:pl-4">
              © 2023 Full ID Check All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
