const ForumCategories = () => {
  return (
    <div className="min-w-[30vh] m-4 bg-[#E5E7EB] bg-opacity-50 border rounded-lg border-neutral-300">
      {/* Title */}
      <div className="px-4 py-2 text-neutral-600 border-b border-neutral-300">
        <span className="text-lg font-semibold">Categories</span>
      </div>

      {/* Items */}
      <div className="flex flex-col text-base">
        <span className="px-4 py-2 text-[#0082BA] bg-[#007BFF] bg-opacity-10 border-b border-neutral-300">General Discussion</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Job Seekers</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Employee and Recruiters</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Developers</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Freelancing</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Success Stories</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">UI / UX Design</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Career Advice</span>
        <span className="px-4 py-2 text-neutral-600 bg-[#E5E7EB] bg-opacity-50 border-b border-neutral-300">Industry Trends</span>
      </div>

    </div>
  )
}

export default ForumCategories;