import usePortfolio from "../../../feature/dashboard/professionalProfile/profileEdit/portfolio/usePortfolio";
import { useEffect, useState } from "react";
import ImageCarousel from "../../ImageCarousel";
import ResponsiveValues from "../../../styling/ResponsiveValues";

const PortfolioCard = ({ data = null, imageData = null, featureEdit = null, onEdit = null, onDelete = null, showControls = true }) => {

  const {
    loading,
    getImageData,
  } = usePortfolio();

  const [images, setImages] = useState(data.images || []);
  const [forceRerender, setForceRerender] = useState(false);


  useEffect(() => {
    if (data) {
      getImageData(data.id);
    }
  }, []);

  const stringData = JSON.stringify(data);
  useEffect(() => {
    if (data?.images?.length > 0) {
      setImages(data.images);
      setForceRerender(!forceRerender);
    }
  }, [stringData, data]);

  return (
    <div className={`flex flex-col w-full h-96 ${ResponsiveValues.cardScreenStyle}  md:my-4 p-0 shadow-md`}>
      <div className="flex flex-col h-full">
        {images.length > 0 && <ImageCarousel key={stringData} images={images} imageHeight="h-52" />}

        <div className="p-4 flex flex-col justify-between flex-grow">
          <div className="">
            <div className="mt-2 text-base font-bold leading-6 text-neutral-800">
              {data.title}
            </div>
            <div className="mt-2 text-base leading-6 text-neutral-600">
              {data.description}
            </div>
          </div>

          <div className="flex flex-row justify-between items-center gap-5 mt-auto"> {/* mt-auto pushes it to the bottom */}
            <div className="flex gap-2">
              <div className="my-auto text-sm font-medium text-neutral-600">
                Featured Project
              </div>
              <div className="flex flex-col justify-center">
                <input
                  type="checkbox"
                  checked={typeof data.is_featured === "boolean" ? data.is_featured : (data.is_featured === 1 ? true : false)}
                  onChange={(e) => featureEdit(data.id, e.target.checked)}
                  className="appearance-none h-4 w-4 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none"
                />
              </div>
            </div>

            <div className="flex flex-1 justify-end gap-1 my-auto text-sm font-medium text-right text-blue-900">
              {showControls && (
                <>
                  <button onClick={() => onEdit(data.id)}>Edit</button> |
                  <button className="text-red-500" onClick={() => onDelete(data.id)}>Delete</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default PortfolioCard;