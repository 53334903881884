import React from "react";

function HandleNoRoutes() {
  return (
    <div className="text-3xl p-20 text-neutral-400 text-center">
      ERROR 404: Not Found
    </div>
  );
}

export default HandleNoRoutes;
