import { authAxios } from "../../../../../config/common";
import formDataHelper from "../../../../../utils/formDataHelper";

const get = async () => {
	try {
		const response = await authAxios().get(`/api/profile/features`);
		return response;
	} catch (error) {
		throw error;
	}
};

const post = async (pData) => {
	try {
		const response = await authAxios().post(`/api/profile/features`, pData);
		return response;
	} catch (error) {
		throw error;
	}
};

const featureApi = {
	post, get,
};

export default featureApi;