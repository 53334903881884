import React from "react";
import usePortfolio from "../../../feature/dashboard/professionalProfile/profileEdit/portfolio/usePortfolio";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import { CiCircleMinus } from "react-icons/ci";
import PortfolioCard from "../../../components/Dashboard/profileEdit/PortfolioCard";
import PortfolioItem from "../../../components/Dashboard/profileEdit/PortfolioItem";
import PageTitle from "../../../components/PageTitle";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import TextStyling from "../../../styling/TextStyling";
import InputStyling from "../../../styling/InputStyling";

function Portfolio() {

  const {
    success, error, loading,
    displayed,
    showAddButton, showEditButton,
    handleAddButton, handleRemoveButton, handleEditButton, handleUpdateButton,
    postPortfolio, postImages, setPostPortfolio,
    addImage, removeImageFromArray, handleFeaturedUpaded,
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateTitle, validateMessage, validateDescription,
  } = usePortfolio();

  return (
    <>
      {/* ADD PORTFOLIO  */}
      <div className="flex flex-col pb-6 max-md:max-w-full">
        <PageTitle title='Add Item to Portfolio' description='Show off your best work! Upload projects, presentations, or any examples that highlight your skills and achievements. Let your creativity shine!' />

        <div className="flex gap-2 pr-px pl-20 font-medium text-green-700 leading-[120%] max-md:flex-wrap max-md:pl-5">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>
        <div className={`mt-4 ${TextStyling.inputLabel}`}>
          Title
        </div>
        <input
          className={`mt-1 ${InputStyling.text}`}
          placeholder="Title"
          value={postPortfolio.title}
          onChange={(e) => {
            setPostPortfolio({ ...postPortfolio, title: e.target.value });
            validateTitle(e.target.value);
          }}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.title?.errorMessage} />

        <div className={`mt-4  ${TextStyling.inputLabel}`}>
          Description
        </div>
        <textarea
          className={`mt-1 ${InputStyling.text}`}
          placeholder="Description"
          value={postPortfolio.description}
          rows={5}
          onChange={(e) => {
            setPostPortfolio({ ...postPortfolio, description: e.target.value });
            validateDescription(e.target.value);
          }}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.description?.errorMessage} />

        <div className={`mt-4  ${TextStyling.inputLabel}`}>
          Add Images
        </div>
        <div
          className="mt-1 relative p-6 flex flex-col justify-center items-center  text-center rounded-lg border border-dashed border-neutral-400 text-stone-300 max-md:px-5 max-md:max-w-full">
          <div className={`${TextStyling.pageBodyBold}`}>Drag or Upload images here</div>
          <span className={`${TextStyling.note} my-0`}>Add Image</span>
          <span className={`${TextStyling.note} my-0`}>PNG, JPG up to 2MB</span>
          <span className={`${TextStyling.textLink}`}>Upload</span>
          <input
            className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            placeholder="Drag or Upload images here"
            type="file"
            accept="image/*"
            multiple="multiple"
            value=""
            onChange={addImage}
          />
        </div>

        {postImages?.length > 0 &&
          <div className="grid grid-cols-4 gap-4 content-center p-6 mt-4 rounded-2xl border border-solid border-neutral-400 max-md:px-5 max-md:max-w-full">
            {postImages.map((item, index) => {
              return <div key={index} className="relative inline-block">
                <button className="absolute right-16 top-2 text-blue-900 rounded-2xl hover:text-red-400 hover:bg-neutral-200"
                  onClick={() => { removeImageFromArray(item, index) }}>
                  <CiCircleMinus className="shrink-0 my-auto w-4 aspect-square align-middle text-red-500" />
                </button>
                <img
                  alt="img"
                  loading="lazy"
                  src={item.src}
                  className="w-20 aspect-square"
                />
              </div>
            })
            }
          </div>
        }

        {showAddButton &&
          <>
            {loading && <LoadingIndicator loading={loading} styling='justify-center self-end px-6 py-3' />}

            <button onClick={() => handleAddButton()}
              className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Publish Portfolio
            </button>

            <button onClick={() => handleAddButton()}
              className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Publish Portfolio
            </button>
          </>
        }

        {showEditButton &&
          <>
            {loading && <LoadingIndicator loading={loading} styling='justify-center self-end px-6 py-3' />}
            <button onClick={() => handleUpdateButton()}
              className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Update Portfolio
            </button>

            <button onClick={() => handleUpdateButton()}
              className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Update Portfolio
            </button>
          </>
        }
      </div>

      {/* PREVIEW PORTFOLIO  */}
      {displayed?.length > 0 &&
        <div className={`flex flex-col p-4 max-md:max-w-full`}>
          <div className="flex flex-row gap-2 justify-between items-center">
            <div className={`${TextStyling.pageHeader2} w-fit`}>My Projects</div>
            <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1`}></div>
          </div>

          <div className={`hidden md:grid md:grid-cols-3 gap-4 w-full`}>
            {
              displayed.map((item, index) => {
                const imageArray = [];
                item?.portfolio_images?.forEach((x, y) => {
                  imageArray.push(x.upload_path);
                });

                return <div key={index} className="col-span-1">
                  <PortfolioCard
                    data={item}
                    imageData={imageArray}
                    featureEdit={() => { handleFeaturedUpaded(item, !item.is_featured) }}
                    onEdit={handleEditButton}
                    onDelete={handleRemoveButton}
                    showControls={true}
                  />
                </div>
              })
            }
          </div>
          <div className={`${ResponsiveValues.forMobileScreen} grid grid-cols-1 gap-4 w-full `}>
            {
              displayed.map((item, index) => {
                const imageArray = [];
                item?.portfolio_images?.forEach((x, y) => {
                  imageArray.push(x.upload_path);
                });

                return <div key={index} className="col-span-1">
                  <PortfolioItem
                    data={item}
                    imageData={imageArray}
                    featureEdit={() => { handleFeaturedUpaded(item, !item.is_featured) }}
                    onEdit={handleEditButton}
                    onDelete={handleRemoveButton}
                    showControls={true}
                  />
                </div>
              })
            }
          </div>
        </div>
      }
    </>
  );
}

export default Portfolio;
