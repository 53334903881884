import ForumMain from '../../feature/dashboard/forum/forum-main/ForumMain.js';
const Forum = () => {
  return (
    <>
      <ForumMain></ForumMain>
    </>
  )
}

export default Forum;
