import { authAxios } from '../../../../../config/common';

const postFeedback = async (data) => {
  try {
    const response = await authAxios().post(`/api/send/feedbacks`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const postComplaint = async (data) => {
  try {
    const response = await authAxios().post(`/api/send/complaints`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const postCompliment = async (data) => {
  try {
    const response = await authAxios().post(`/api/send/compliments`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const helpApi = {
  postFeedback,
  postComplaint,
  postCompliment
};

export default helpApi;