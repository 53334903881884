import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import socialLinksApi from './socialLinksApi';
import formDataHelper, { formUrlEncoded } from '../../../../../utils/formDataHelper';

export const getSocialLinks = createAsyncThunk('/social-links/getAll', async ({ }, { rejectWithValue }) => {
  try {
    const response = await socialLinksApi.get();
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addSocialLinks = createAsyncThunk('/social-links/add', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await socialLinksApi.post(formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateSocialLinks = createAsyncThunk('/social-links/update', async ({ socialLinksId, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await socialLinksApi.put(socialLinksId, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removeSocialLinks = createAsyncThunk('/social-links/remove', async ({ socialLinksId }, { rejectWithValue }) => {
  try {
    const response = await socialLinksApi.del(socialLinksId);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const socialLinksSlice = createSlice({
  name: 'socialLinks',
  initialState: {
    success: false,
    loading: false,
    error: null,
    values: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getSocialLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getSocialLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getSocialLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.values = action?.payload;
      })
      // add
      .addCase(addSocialLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addSocialLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addSocialLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // update
      .addCase(updateSocialLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(updateSocialLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(updateSocialLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // remove
      .addCase(removeSocialLinks.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removeSocialLinks.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removeSocialLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
  }
});

export const socialLinksAction = socialLinksSlice.actions;
export default socialLinksSlice;