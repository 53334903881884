import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import accessKeyApi from './accessKeyApi';
import formDataHelper from '../../../../utils/formDataHelper';

export const addAccessKey = createAsyncThunk('/access-key/add', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await accessKeyApi.post(id, formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const uploadDocument = createAsyncThunk('/access-key/uploadDocument', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await accessKeyApi.uploadDocument(id, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const accessKeySlice = createSlice({
  name: 'accessKey',
  initialState: {
    success: false,
    loading: false,
    error: [],
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSucccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // add
      .addCase(addAccessKey.pending, (state) => {
        state.loading = true;
        state.error = [];
        state.success = false;
      }).addCase(addAccessKey.rejected, (state, action) => {
        state.loading = false;
        state.error = [...state.error, action?.payload];
        state.success = false;
      }).addCase(addAccessKey.fulfilled, (state, action) => {
        state.loading = false;
        state.error = [];
        state.success = true;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.loading = true;
        state.error = [];
        state.success = false;
      }).addCase(uploadDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = [...state.error, action?.payload];
        state.success = false;
      }).addCase(uploadDocument.fulfilled, (state, action) => {
        state.loading = false;
        // state.error = [];
        state.success = true;
      })
  }
});

export const accessKeyAction = accessKeySlice.actions;
export default accessKeySlice;