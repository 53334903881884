import { useDispatch, useSelector } from "react-redux";
import { getSkills, addSkill, removeSkill, skillsAction as action } from "./skillsSlice";
import { useEffect, useState } from "react";

const useSkills = () => {
	const dispatch = useDispatch();
	const { success, error, loading, skills } = useSelector(state => state.skills);

	const [displayedSkills, setDisplayedSkills] = useState(skills);
	const [typed, setTyped] = useState("");

	useEffect(() => {
		if (error !== null) {
			setDisplayedSkills(skills);
		}
	}, [error, skills]);

	useEffect(() => {
		setDisplayedSkills(skills);
	}, [skills]);

	const handleTyping = (e) => {
		setTyped(e);
	}

	const handleOnload = async () => {
		try {
			await dispatch(getSkills({}));
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`))
		}
	}

	const handleAddbutton = async (str) => {
		try {
			const data = {
				name: str,
			};

			const tempId = (displayedSkills && displayedSkills?.length > 0) ? (displayedSkills[displayedSkills?.length - 1].id + 1) : 1;

			setDisplayedSkills(
				[...displayedSkills,
				{ ...data, id: tempId, }
				]
			);

			await dispatch(addSkill({ data: data }));
			await dispatch(getSkills({}));

		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const handleRemoveButton = async (intId) => {
		try {

			const newdisplayedSkills = displayedSkills?.filter((item) => item.id !== intId);
			setDisplayedSkills(newdisplayedSkills);

			await dispatch(removeSkill({ skillId: intId }));
			await dispatch(getSkills({}));

		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`))
		}
	}

	return {
		success, error, loading,
		skills,
		typed, setTyped,
		displayedSkills, setDisplayedSkills,
		handleOnload,
		handleAddbutton,
		handleRemoveButton,
		handleTyping,
	}

}

export default useSkills;