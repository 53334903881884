import { FaCircleCheck } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import ResponsiveValues from "../styling/ResponsiveValues";

const SuccessIndicator = ({ message, styling = "", max = null, setShowNotif }) => {
  return (
    <>
      {/* <div className={ResponsiveValues.forMobileScreen}>
        <div className={`flex ml-auto gap-2 pr-px items-center text-base font-medium tracking-tight leading-5 text-green-700 max-md:flex-wrap max-md:pl-5 ${styling}`}>
          <FaRegCheckCircle className="shrink-0 my-auto w-4 aspect-square" />
          <div>{(max !== null && message.length >= max) ? message.slice(0, max) + '...' : message}</div>
        </div>
      </div> */}

      {/* <div className={ResponsiveValues.forDesktopScreen}> */}
      <div className={`fixed top-0 left-1/2 transform -translate-x-1/2  text-base text-[#22C55E] shadow-md ${ResponsiveValues.cardSectionStyle} p-0  ${styling} `}>
        <div className="p-2 flex flex-row gap-2 items-center">
          <button onClick={() => { setShowNotif(false) }}>
            <MdOutlineClose className="shrink-0 w-4 aspect-square text-neutral-700" />
          </button>
          <div>{(max !== null && message.length >= max) ? message.slice(0, max) + '...' : message}</div>
          <FaCircleCheck className="shrink-0 w-4 aspect-square" />
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default SuccessIndicator;