import InputStyling from "../../styling/InputStyling"
import TextStyling from "../../styling/TextStyling"

const CustomInputPassword = ({
  outerClassName = '',
  className = InputStyling.text,
  label = '',
  placeholder = '',
  name = "",
  value = null,
  onChange = null,
  disabled = false,
}) => {
  return (
    <div className={`${outerClassName}`}>
      <span className={TextStyling.inputLabel}>
        {label}
      </span>
      <input
        className={`mt-1 ${className}`}
        type='password'
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

export default CustomInputPassword;