import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import previewProfileApi from './previewProfileApi';
import formDataHelper from '../../../../../utils/formDataHelper';

export const getUser = createAsyncThunk('/preview-profile/user', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getUser(id);
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEducationAndCertifications = createAsyncThunk('/preview-profile/educationAndCertifications', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getEducations();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFeature = createAsyncThunk('/preview-profile/feature', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getFeatures();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getHonorsAndAwards = createAsyncThunk('/preview-profile/honors-and-awards', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getAwards();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getLanguages = createAsyncThunk('/preview-profile/languages', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getLanguages();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSkills = createAsyncThunk('/preview-profile/skills', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getSkills();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getOverview = createAsyncThunk('/preview-profile/overview', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getOverview();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPortfolio = createAsyncThunk('/preview-profile/portfolio', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getPortfolios();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSocials = createAsyncThunk('/preview-profile/social-links', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getSocialLinks();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getWorkHistory = createAsyncThunk('/preview-profile/work-history', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getWorkHistories();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProjects = createAsyncThunk('/preview-profile/work-history/project', async ({ workHistoryId }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getWorkHistoryProjects(workHistoryId);

    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getFeedbacks = createAsyncThunk('/preview-profile/feedbacks/get', async ({ }, { rejectWithValue }) => {
  try {
    const response = await previewProfileApi.getFeedbacks();

    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addRegisterFeedback = createAsyncThunk('/preview-profile/feedbacks/add', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await previewProfileApi.postFeedbacks(formData);

    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }

  } catch (err) {
    return rejectWithValue(err);
  }
});

export const previewProfileSlice = createSlice({
  name: 'previewProfile',
  initialState: {
    success: false,
    loading: false,
    error: null,
    user: (!!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || null) : null) || null,
    educationAndCertifications: [],
    feature: null,
    awards: [],
    languages: [],
    skills: [],
    overview: null,
    portfolio: [],
    socials: [],
    workHistory: [],
    adminRating: 0,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSucccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getUser
      .addCase(getUser.pending, (state) => {
        state.error = null;
      }).addCase(getUser.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getUser.fulfilled, (state, action) => {
        state.error = null;
        if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
      })
      // getEducationAndCertifications all
      .addCase(getEducationAndCertifications.pending, (state) => {
        state.error = null;
      }).addCase(getEducationAndCertifications.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getEducationAndCertifications.fulfilled, (state, action) => {
        state.error = null;
        state.educationAndCertifications = action?.payload;
      })
      // getFeature
      .addCase(getFeature.pending, (state) => {
        state.error = false;
      }).addCase(getFeature.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getFeature.fulfilled, (state, action) => {
        state.error = false;
        state.feature = action?.payload;
      })
      // getHonorsAndAwards
      .addCase(getHonorsAndAwards.pending, (state) => {
        state.error = null;
      }).addCase(getHonorsAndAwards.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getHonorsAndAwards.fulfilled, (state, action) => {
        state.error = null;
        state.awards = action?.payload;
      })
      // getLanguages
      .addCase(getLanguages.pending, (state) => {
        state.error = null;
      }).addCase(getLanguages.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getLanguages.fulfilled, (state, action) => {
        state.error = null;
        state.languages = action?.payload;
      })
      // get Skill
      .addCase(getSkills.pending, (state) => {
        state.error = null;
      }).addCase(getSkills.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getSkills.fulfilled, (state, action) => {
        state.error = null;
        state.skills = action?.payload
      })
      // get overview
      .addCase(getOverview.pending, (state) => {
        state.error = null;
      }).addCase(getOverview.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getOverview.fulfilled, (state, action) => {
        state.error = null;
        state.overview = action?.payload;
      })
      // get portfolios
      .addCase(getPortfolio.pending, (state) => {
        state.error = null;
      }).addCase(getPortfolio.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getPortfolio.fulfilled, (state, action) => {
        state.error = null;
        state.portfolio = action?.payload;
      })
      // get social links
      .addCase(getSocials.pending, (state) => {
        state.error = null;
      }).addCase(getSocials.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getSocials.fulfilled, (state, action) => {
        state.error = null;
        state.socials = action?.payload;
      })
      // getWorkHistory all
      .addCase(getWorkHistory.pending, (state) => {
        state.error = null;
      }).addCase(getWorkHistory.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getWorkHistory.fulfilled, (state, action) => {
        state.error = null;
        state.workHistory = action?.payload;
      })
      // getProject
      .addCase(getProjects.pending, (state) => {
        state.error = null;
      }).addCase(getProjects.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getProjects.fulfilled, (state, action) => {
        state.error = null;

        const projects = action?.payload;
        console.log(`projects : ${JSON.stringify(projects)}`);
        if (projects.length > 0) {
          const index = state.workHistory.findIndex(item => item.id === projects[0].work_history_id);

          if (index !== -1) {
            state.workHistory[index] = {
              ...state.workHistory[index],
              projects: projects,
            }
          }
        }
      })
      // getFeedbacks
      .addCase(getFeedbacks.pending, (state) => {
        state.error = null;
      }).addCase(getFeedbacks.rejected, (state, action) => {
        state.error = action?.payload;
      }).addCase(getFeedbacks.fulfilled, (state, action) => {
        state.error = null;
        state.adminRating = action?.payload?.admin_rating;
      })
      // add Feedback
      .addCase(addRegisterFeedback.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.success = null;
      }).addCase(addRegisterFeedback.rejected, (state, action) => {
        state.error = action?.payload;
        state.loading = false;
        state.success = false;
      }).addCase(addRegisterFeedback.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.success = true;
      })
  }
});

export const previewProfileAction = previewProfileSlice.actions;
export default previewProfileSlice;