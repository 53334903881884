import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";

function UploadDocumentSuccess() {
  const navigate = useNavigate();

  const handleContinueRegistartion = () => {
    navigate("/");
  };

  return (
    <>
      <div className={`${ResponsiveValues.minHeight} flex flex-row justify-center pt-12 md:p-24 `}>
        <div className={`flex flex-col justify-center text-center items-center gap-4 ${ResponsiveValues.cardScreenStyle} h-fit ${ResponsiveValues.minWidthCard}`}>

          <div className={`flex flex-row items-center justify-center gap-4 ${TextStyling.pageTitle}`}>
            <FaRegCircleCheck size={21} className="text-blue-900" />
            <div className="my-auto">
              Additional Requirements Uploaded
            </div>
          </div>

          <div className={`${TextStyling.pageBody}`}>
            You successfully uploaded your additional requirements.
          </div>

          <div className="mt-4">
            <button
              onClick={handleContinueRegistartion}
              className={`${ResponsiveValues.buttonFit}`}
            >
              Go to Homepage
            </button>
          </div>
        </div>
      </div>

    </>
  );
}

export default UploadDocumentSuccess;
