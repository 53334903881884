import { useState } from "react";
import { FaRegBookmark, } from "react-icons/fa6";
import { RiMessage2Line } from "react-icons/ri";
import TruncateText from "../../../../utils/TruncateText";
import { useNavigate } from "react-router-dom";

const ForumItemCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-2 p-4 text-neutral-600  bg-opacity-10 rounded-lg border border-neutral-300 shadow-sm"
    >
      <div className="flex flex-row justify-between font-semibold">
        <button
          onClick={() => {
            navigate("/dashboard/forum-discussion");
          }}
        >
          <span className="text-base">{data?.title || "How to effectively get a client?"}</span>
        </button>
        <div className="text-neutral-400 flex flex-row justify-end items-center gap-2">
          <span className="text-xs ">{data?.dateTime || "1 day ago"}</span>
          <FaRegBookmark />
        </div>
      </div>
      <span className="text-sm">
        <TruncateText maxLength={200} showMore={true} text={data?.message || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec cursus convallis mauris, non facilisis neque placerat sed. Integer mi sapien, elementum id aliquam sit amet, placerat non lorem. Nullam at scelerisque purus. Cras imperdiet at nunc nec malesuada. Etiam metus libero, aliquet vitae turpis ut, tempus tincidunt felis. Curabitur egestas tempor orci sed dictum. Praesent vestibulum euismod nibh sed ornare. Morbi tempus gravida mattis. Sed id auctor sapien, at posuere massa. In faucibus sollicitudin nisl. Sed porta scelerisque porta."} />
      </span>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row justify-start">
          <KeywordBubble title='Dev' />
          <KeywordBubble title='Client' />
          <KeywordBubble title='Professional Work' />
        </div>
        <div className="flex flex-row justify-end items-center gap-1 text-neutral-400">
          <RiMessage2Line size={16} />
          <span className="text-xs">{data?.total || '20'}</span>
        </div>
      </div>
    </div>
  )
}

export default ForumItemCard

const KeywordBubble = ({ title }) => {
  return (
    <div className="w-fit px-2 py-1 flex justify-center items-center text-xs text-white bg-blue-700 rounded-md border border-neutral-200">
      {title || "Keyword"}
    </div>
  )
}