import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/authContext"; // Import the AuthProvider
import "./index.css";
import { store } from "./feature/store";
import Modal from "react-modal";
import LocaleProvider from "./LocaleProvider";

import '@fortawesome/fontawesome-free/css/all.min.css';


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    {/* <AuthProvider> */}
    <React.StrictMode>
    <LocaleProvider />,
    {/* <App /> */}
    </React.StrictMode>
    {/* </AuthProvider> */}
  </Provider>
);

// Bind the modal to the app element for accessibility
Modal.setAppElement("#root");
