import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import portfolioApi from './portfolioApi';
import formDataHelper from '../../../../../utils/formDataHelper';

export const getPortfolio = createAsyncThunk('/portfolio/getAll', async ({ }, { rejectWithValue }) => {
  try {
    const response = await portfolioApi.get();
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addPortfolio = createAsyncThunk('/portfolio/add', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await portfolioApi.post(formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePortfolio = createAsyncThunk('/portfolio/update', async ({ portfolioId, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await portfolioApi.put(portfolioId, formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removePortfolio = createAsyncThunk('/portfolio/remove', async ({ portfolioId }, { rejectWithValue }) => {
  try {
    const response = await portfolioApi.del(portfolioId);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPortfolioImage = createAsyncThunk('/portfolio-iamge/getAll', async ({ portfolioId }, { rejectWithValue }) => {
  try {
    const response = await portfolioApi.getImage(portfolioId);
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const addPortfolioImage = createAsyncThunk('/portfolio-iamge/add', async ({ portfolioId, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await portfolioApi.postImage(portfolioId, formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePortfolioImage = createAsyncThunk('/portfolio-iamge/update', async ({ portfolioId, imageId, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await portfolioApi.putImage(portfolioId, imageId, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removePortfolioImage = createAsyncThunk('/portfolio-iamge/remove', async ({ portfolioId, imageId, }, { rejectWithValue }) => {
  try {
    const response = await portfolioApi.delImage(portfolioId, imageId);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const portfolioSlice = createSlice({
  name: 'portfolios',
  initialState: {
    success: false,
    loading: false,
    error: null,
    values: [],
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.values = action?.payload;
      })
      // add
      .addCase(addPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // update
      .addCase(updatePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(updatePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(updatePortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // remove
      .addCase(removePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removePortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // get-image
      .addCase(getPortfolioImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getPortfolioImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getPortfolioImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const images = action?.payload;
        const index = state.values.findIndex(item => item.id === images?.[0].portfolio_id);
        if (index !== -1) {
          state.values[index] = {
            ...state.values[index],
            images: images,
          }
        }

      })
      // add-image
      .addCase(addPortfolioImage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(addPortfolioImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(addPortfolioImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // update-image
      .addCase(updatePortfolioImage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(updatePortfolioImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(updatePortfolioImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // remove-image
      .addCase(removePortfolioImage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removePortfolioImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removePortfolioImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
  }
});

export const portfolioAction = portfolioSlice.actions;
export default portfolioSlice;