import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import { FaRegCircleCheck } from "react-icons/fa6";
import TextStyling from "../../styling/TextStyling";

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className={`${ResponsiveValues.minHeight} mt-24 flex flex-row justify-center`}>
      <div className={`h-full w-fit shadow-md ${ResponsiveValues.cardScreenStyle}  ${ResponsiveValues.minWidthCard} `}>

        <div className="flex justify-center items-center gap-2 ">
          <FaRegCircleCheck size={30} className="text-green-500" />
          <div className={`${TextStyling.pageTitle} w-auto`} >
            Additional Requirements Uploaded
          </div>
        </div>

        <div className={`${TextStyling.pageBody} flex justify-center items-center gap-2 `} >
          You successfully uploaded your additional requirements.
        </div>

        <div
          onClick={() => navigate("/")}
          className={`${ResponsiveValues.buttonFull}`}        >
          Go to Homepage
        </div>
      </div>
    </div >

  )
}

export default SuccessMessage