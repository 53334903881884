import ResponsiveValues from "../styling/ResponsiveValues";
import TextStyling from "../styling/TextStyling";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const PageTitle = ({ title, description, hasSpacing = true, showBackButton = false, customTextStyleDesktop = null, customTextStyleMobile = null }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={`${ResponsiveValues.forDesktopScreen} ${hasSpacing === true && 'mb-8'}`}>
        <div className={`flex flex-col text-neutral-600 max-md:max-w-full`}>
          <div className="flex flex-row">
            {showBackButton === true && <button onClick={() => { handleBack() }}><MdArrowBackIos size={16} /></button>}
            <span className={customTextStyleDesktop ? customTextStyleDesktop : TextStyling.pageHeader1}>{title}</span>
          </div>
          <span className="">{description}</span>
        </div>
      </div>

      <div className={`${ResponsiveValues.forMobileScreen}`}>
        <div className={`flex flex-row ${customTextStyleMobile ? customTextStyleMobile : TextStyling.pageBody} max-md:max-w-full`}>
          {showBackButton === true && <button onClick={() => { handleBack() }}><MdArrowBackIos /></button>}
          <span>{title}</span>
        </div>
      </div>
    </>
  )
}

export default PageTitle