
import { Axios } from "axios";

const websocket_url = 'wss://wykdxhhnj1j5.share.zrok.io';
// const base_api_url = 'https://devreg.co.nz/api'; // Old API;
// const base_api_url = 'https://fullidcheck.com/backend-server'; // Old API;
const base_api_url = 'https://api.fullidcheck.com'; // Prod -> https://fullidcheck.com/
//const base_api_url = 'https://staging-api.fullidcheck.com'; // Staging -> https://staging.fullidcheck.com/  
// const base_api_url = 'http://localhost';

const basicAxios = new Axios({
    baseURL: base_api_url,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    }
});

const authAxios = () => {
    const token = localStorage.getItem('token');
    return new Axios({
        baseURL: base_api_url,
        headers: {
            'Authorization': `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
        }
    });
};

const authAxiosPut = () => {
    const token = localStorage.getItem('token');
    return new Axios({
        baseURL: base_api_url,
        headers: {
            'Authorization': `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
};

const authAxiosPdf = () => {
    const token = localStorage.getItem('token');
    return new Axios({
        baseURL: base_api_url,
        headers: {
            'Authorization': `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/pdf',
        }
    });
};

// Utility function to mask email
const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = `${localPart.substring(0, 3)}****`;
    return `${maskedLocalPart}@${domain}`;
};

// Utility function to mask phone number
const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length <= 4) {
        return phoneNumber; // If the phone number is very short, just return it as is
    }
    const lastFourDigits = phoneNumber.slice(-4);
    const maskedPart = '*'.repeat(phoneNumber.length - 4);
    return `${maskedPart}${lastFourDigits}`;
};

export {
    base_api_url,
    basicAxios,
    authAxios,
    authAxiosPut,
    authAxiosPdf,
    maskEmail,
    maskPhoneNumber,
};