import dateHelper from "./dateHelper";

const ValidationHelperChain = () => {

  const isEmpty = (str) => {
    return str === null || str === undefined || str === '';
  };

  return {
    errorMessage: {
      notEmpty: null,
      selectOption: null,
      stringCountMin: null,
      stringIsNumber: null,
      stringIsAlphaNumeric: null,
      URL: null,
      email: null,
      dateHasPassed: null,
    },
    hasError() {
      return Object.values(this.errorMessage).some(error => error !== null);
    },
    getErrorMessages() {
      const errorMessages = [];
      Object.entries(this.errorMessage).forEach(([key, value]) => {
        if (value !== null) {
          errorMessages.push(value);
        }
      });
      return errorMessages;
    },
    notEmpty({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.notEmpty = 'Please enter a value.';
        return this;
      }
      this.errorMessage.notEmpty = null;
      return this;
    },
    selectOption({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.selectOption = 'Please select a value.';
        return this;
      }
      this.errorMessage.selectOption = null;
      return this;
    },
    stringCountMin({ str, intCount }) {
      if (isEmpty(str)) {
        this.errorMessage.stringCountMin = `Text should be at least ${intCount} characters long.`;
        return this;
      }
      if (str.length >= intCount) {
        this.errorMessage.stringCountMin = null;
      } else {
        this.errorMessage.stringCountMin = `Text should be at least ${intCount} characters long.`;
      }
      return this;
    },
    stringIsNumber({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.stringIsNumber = 'Value should be a number.';
        return this;
      }
      if (!isNaN(+str)) {
        this.errorMessage.stringIsNumber = null;
      } else {
        this.errorMessage.stringIsNumber = 'Value should be a number.';
      }
      return this;
    },
    stringIsAlphaNumeric({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.stringIsAlphaNumeric = 'Value should be numbers or a combination of numbers and letters.';
        return this;
      }
      const regex = /^[a-z0-9]+$/i;
      if (regex.test(str)) {
        this.errorMessage.stringIsAlphaNumeric = null;
      } else {
        this.errorMessage.stringIsAlphaNumeric = 'Value should be numbers or a combination of numbers and letters.';
      }
      return this;
    },
    URL({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.URL = 'Enter a valid URL/Website Link.';
        return this;
      }
      const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      if (regex.test(str)) {
        this.errorMessage.URL = null;
      } else {
        this.errorMessage.URL = 'Enter a valid URL/Website Link.';
      }
      return this;
    },
    email({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.email = 'Enter a valid email.';
        return this;
      }
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(str)) {
        this.errorMessage.email = null;
      } else {
        this.errorMessage.email = 'Enter a valid email.';
      }
      return this;
    },
    dateHasPassed({ str }) {
      if (isEmpty(str)) {
        this.errorMessage.dateHasPassed = 'Enter a valid date.';
        return this;
      }
      const hasPassed = dateHelper.dataHasPassed(str);
      if (hasPassed) {
        this.errorMessage.dateHasPassed = null;
      } else {
        this.errorMessage.dateHasPassed = 'Enter a valid date.';
      }
      return this;
    },
  }
};

export default ValidationHelperChain;
