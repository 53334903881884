import { authAxios } from '../../../../../config/common';

const get = async () => {
  try {
    const response = await authAxios().get(`/api/profile/portfolios`);
    return response;
  } catch (error) {
    throw error;
  }
};

const post = async (data) => {
  try {
    const response = await authAxios().post(`/api/profile/portfolios`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const put = async (portfolioId, data) => {
  try {
    const response = await authAxios().post(`/api/profile/portfolios/${portfolioId}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const del = async (portfolioId) => {
  try {
    const response = await authAxios().delete(`/api/profile/portfolios/${portfolioId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getImage = async (portfolioId) => {
  try {
    const response = await authAxios().get(`/api/profile/portfolios/${portfolioId}/images`);
    return response;
  } catch (error) {
    throw error;
  }
};

const postImage = async (portfolioId, data) => {
  try {
    const response = await authAxios().post(`/api/profile/portfolios/${portfolioId}/images`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const putImage = async (portfolioId, imageId, data) => {
  try {
    const response = await authAxios().post(`/api/profile/portfolios/${portfolioId}/images/${imageId}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const delImage = async (portfolioId, imageId) => {
  try {
    const response = await authAxios().delete(`/api/profile/portfolios/${portfolioId}/images/${imageId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const portfolioApi = {
  get,
  post,
  put,
  del,
  getImage,
  postImage,
  putImage,
  delImage,
};

export default portfolioApi;