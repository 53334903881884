import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo } from '../../../../authSlice';

import imageCheck, { imageCheckSquare } from '../../../../../utils/imageCheck';
import isValidImageUrl from '../../../../../utils/isValidImageUrl';
import dateHelper from '../../../../../utils/dateHelper';

import { helpAction as action } from './helpSlice';
import { addFeedback, addCompliment, addComplaint } from './helpSlice';
import { data } from '../../../../../data/JobListData';

const useHelp = () => {
  const dispatch = useDispatch();
  const { success, error, loading } = useSelector(state => state.help);
  const { serial_number } = useSelector(state => state.auth.user);

  const defaultValue = {
    serial_number: '',
    feedback_type: '',
    message: ''
  };
  const [helpValue, setHelpValue] = useState(defaultValue);
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    clearInputFields();
  }, []);

  useEffect(() => {
    if (success === true || error !== null) {
      setShowNotif(true);
    }
  }, [success, error]);

  const clearInputFields = async () => {
    setHelpValue(defaultValue);
  }

  const handleAddButton = async () => {
    try {

      switch (helpValue.feedback_type) {
        case 'Feedback':
          await dispatch(addFeedback({ data: helpValue }));
          break;

        case 'Complaint':
          await dispatch(addComplaint({ data: { ...helpValue, subject: serial_number } }));
          break;

        case 'Compliment':
          await dispatch(addCompliment({ data: helpValue }));
          break;

        default:
          await dispatch(addFeedback({ data: helpValue }));
          break;
      }

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  return {
    success, error, loading,
    showNotif, setShowNotif,
    helpValue, setHelpValue,
    handleAddButton,
  }

}

export default useHelp;


// const { success, error, loading, handleAddButton, handleRemoveButton, handleEditButton, handleUpdateButton } = useHelp();