import React, { useEffect, useState } from "react";
import PhoneNumberWithFlag from "../../components/customInputFields/PhoneNumberWithFlag";
import { Country, State, City } from "country-state-city";

function RequirementsManagerPos() {
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState(""); // New state for storing ISO code
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [files, setFiles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    const country = countries.find((c) => c.name === countryName);
    setSelectedCountryIsoCode(country ? country.isoCode : "");
  };

  useEffect(() => {
    if (selectedCountryIsoCode !== "") {
      setStates(State.getStatesOfCountry(selectedCountryIsoCode));
      setCities([]);
      setSelectedState("");
      setSelectedCity("");
    } else {
      setStates([]);
    }
  }, [selectedCountryIsoCode]);

  useEffect(() => {
    if (selectedState !== "") {
      setCities(City.getCitiesOfState(selectedCountryIsoCode, selectedState));
      setSelectedCity("");
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountryIsoCode]);

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("fullname", fullName);
    formData.append("country1", selectedCountry);
    formData.append("country2", selectedCountry);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("city", selectedCity);
    formData.append("state", selectedState);
    formData.append("postal_code", postalCode);
    formData.append("cover_letter", coverLetter);

    for (let i = 0; i < Math.min(files.length, 2); i++) {
      formData.append("file_uploads[]", files[i]);
    }

    try {
      const response = await fetch(
        "https://devreg.co.nz/api/api/apply-as-senior-country-manager",
        {
          method: "POST",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Application submitted successfully");
        setErrorMsg("");
      } else {
        const errorData = await response.json();
        console.error("Failed to submit application:", errorData.message);
        setErrorMsg(errorData.message);
      }
    } catch (error) {
      console.error("Error submitting application:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-[860px] mx-auto px-5">
      <div className="w-full flex flex-col justify-center items-center mt-24">
        <div className="text-3xl m-auto font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
          SENIOR COUNTRY MANAGER POSITION
        </div>
        <div className="flex flex-col m-auto justify-center item-start py-4 mt-4 w-full max-md:max-w-full">
          <div className="flex flex-col m-auto py-4 max-w-full text-base leading-6 bg-white rounded-xl text-neutral-400 w-[554px]">
            <div className="flex m-auto flex-col pb-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                Responsibilities:
              </div>
              <div className="mt-4 text-base leading-6 text-zinc-600 max-md:max-w-full">
                Marketing, management, advertising and employing country
                managers.
              </div>
            </div>
            <div className="flex w-full m-auto flex-col py-10 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                Qualification:
              </div>
              <div className="mt-4 text-base leading-6 text-zinc-600 max-md:max-w-full">
                Can speak English and your country native language
              </div>
            </div>

            <div className="text-3xl font-semibold leading-8 text-black max-md:max-w-full">
              Senior Country Manager Application
            </div>
            <div className="mt-6 leading-[162%] text-zinc-600 max-md:max-w-full">
              Personal Information
            </div>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
              className="justify-center placeholder:text-neutral-400 text-black px-3 py-2 mt-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
            />

            <div>
              <select
                className={`w-full mt-4 py-3 border-2 rounded-xl px-3 ${selectedCountry === "" ? "text-neutral-400" : "text-black"
                  }`}
                id="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option disabled value="">
                  Country
                </option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <input
              placeholder="Email"
              value={email}
              onChange={validateEmail}
              className="justify-center placeholder:text-neutral-400 text-black px-3 py-2 mt-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
            />
            {!isValidEmail && (
              <div className="mt-1 text-sm text-red-600">
                Please enter a valid email address.
              </div>
            )}
            <div className="py-2">
              <PhoneNumberWithFlag
                onPhoneNumberChange={handlePhoneNumberChange}
              />
            </div>

            <div className="mt-6 leading-[162%] text-zinc-600 max-md:max-w-full">
              Address:
            </div>
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              className="justify-center placeholder:text-neutral-400 text-black px-3 py-2 mt-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
            />
            <div className="flex text-black space-x-2 max-md:space-x-0 justify-evenly mt-4 max-md:flex-wrap max-w-[860px]">
              <select
                id="state"
                className={`${selectedState === "" ? "text-neutral-400" : "text-black"
                  } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5`}
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                disabled={!selectedCountryIsoCode}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
              <select
                id="city"
                className={`${selectedState === "" ? "text-neutral-400" : "text-black"
                  } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5 max-md:mt-4`}
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedState}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              <input
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                type="number"
                placeholder="Zip/Postal Code"
                className="max-md:max-w-[100%] max-md: max-md:mt-4 w-full flex max-w-[26%] placeholder:text-neutral-400 p-2 rounded-xl border border-solid border-neutral-300"
              />
            </div>
            <div className="mt-6 leading-[162%] text-zinc-600 max-md:max-w-full">
              Upload Resume and Passport size photo
            </div>
            <div className="relative justify-center py-6 mt-4 text-lg leading-6 text-center rounded-2xl border border-solid border-neutral-300 text-stone-300 max-md:max-w-full">
              Drag or Upload Resume and passport size photo
              <br />
              <span className="text-base">(jpg, png, pdf)</span>
              <input
                type="file"
                value=""
                onChange={handleFileChange}
                multiple
                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>
            <div className="uploaded-files mt-4">
              {files.length > 0 && (
                <ul>
                  {files.map((file, index) => (
                    <li
                      key={index}
                      className="file-item flex justify-between items-center"
                    >
                      <span>{file.name}</span>
                      <button
                        onClick={() => handleDelete(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="mt-6 leading-[162%] text-zinc-600 max-md:max-w-full">
              Cover letter:
            </div>
            <textarea
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
              placeholder="Tell us why you want to be a manager and what can you do for the company...."
              className="justify-center px-2 pt-2 pb-14 mt-4 leading-6 rounded-xl border border-solid border-neutral-300 max-md:pb-10 max-md:max-w-full"
            ></textarea>
            <button
              type="submit"
              className="text-center items-center p-2 mt-6 text-white bg-blue-900 rounded-3xl max-md:px-5 max-md:max-w-full"
            >
              Send Application
            </button>
            {errorMsg !== "" && (
              <div className="mt-1 text-sm text-red-600">{errorMsg}</div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default RequirementsManagerPos;
