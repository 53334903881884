const InputStyling = {

  text: "justify-center items-start p-2.5 focus:outline-blue-900 w-full text-base text-black leading-5 rounded-lg border border-solid border-neutral-400 max-md:pr-5 max-md:max-w-full ",
  textNoSpacing: "p-2.5 focus:outline-blue-900 w-full text-base text-black leading-5 rounded-lg border border-solid border-neutral-400 ",

  select: "justify-center items-start p-2.5 focus:outline-blue-900 w-full text-base text-black leading-5 rounded-lg border border-solid border-neutral-400 max-md:pr-5 max-md:max-w-full",
  select_none: "text-neutral-400",
  select_item: "text-black",

  phone: "p-2.5",

  border_testing: 'border border-solid border-red-400',
}

export default InputStyling;