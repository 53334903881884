import { authAxios } from '../../../../config/common';

// POST       api/user/{id}/validate-access-key
// payload:   access_key

const post = async (id, data) => {
  try {
    const response = await authAxios().post(`/api/user/${id}/validate-access-key`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadDocument = async (id, data) => {
  try {
    // const keys = Object.keys(data);
    // const formData = new FormData();
    // keys.forEach(item => {
    //   formData.append(item, data[item]);
    // });

    const response = await authAxios().post(`/api/user/${id}/file-uploads`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const accessKeyApi = {
  post,
  uploadDocument,
};

export default accessKeyApi;