import { authAxios } from '../../../../../config/common';

// GET | HEAD  api/user/{ id }/profile

const getUser = async () => {
  try {
    const response = await authAxios().get(`/api/profile`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getEducations = async () => {
  try {
    const response = await authAxios().get(`/api/profile/educations`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getFeatures = async () => {
  try {
    const response = await authAxios().get(`/api/profile/features`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getAwards = async () => {
  try {
    console.log("HELLO 3");

    const response = await authAxios().get(`/api/profile/awards`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getLanguages = async () => {
  try {
    const response = await authAxios().get(`/api/profile/languages`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getSkills = async () => {
  try {
    const response = await authAxios().get(`/api/profile/skills`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getOverview = async () => {
  try {
    const response = await authAxios().get(`/api/profile/overview`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getPortfolios = async () => {
  try {
    const response = await authAxios().get(`/api/profile/portfolios`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getSocialLinks = async () => {
  try {
    const response = await authAxios().get(`/api/profile/social-links`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getWorkHistories = async () => {
  try {
    const response = await authAxios().get(`/api/profile/work-histories`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getWorkHistoryProjects = async (workHistoryId) => {
  try {
    const response = await authAxios().get(`/api/profile/work-histories/${workHistoryId}/projects`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getFeedbacks = async () => {
  try {
    const response = await authAxios().get(`/api/client/search?search_key=nelson&country=`);
    return response;
  } catch (error) {
    throw error;
  }
};

const postFeedbacks = async (data) => {
  try {
    const response = await authAxios().post(`/api/send/feedbacks`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const previewProfileApi = {
  getUser,
  getEducations,
  getFeatures,
  getAwards,
  getLanguages,
  getSkills,
  getOverview,
  getPortfolios,
  getSocialLinks,
  getWorkHistories,
  getWorkHistoryProjects,
  getFeedbacks,
  postFeedbacks,
};

export default previewProfileApi;