import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import certificateSlice from "./dashboard/professionalProfile/certificates/certificatesSlice";
import featureSlice from "./dashboard/professionalProfile/profileEdit/features/featureSlice";
import languageSlice from "./dashboard/professionalProfile/profileEdit/language/languageSlice";
import skillsSlice from "./dashboard/professionalProfile/profileEdit/skills/skillsSlice";
import workHistorySlice from "./dashboard/professionalProfile/profileEdit/workHistory/workHistorySlice";
import securitySettingsSlice from "./dashboard/professionalProfile/settings/securitySettingsSlice";
import google2faSlice from "./dashboard/professionalProfile/google2fa/google2faSlice";
import educationAndCertificationsSlice from "./dashboard/professionalProfile/profileEdit/educationAndCertifications/educationAndCertificationsSlice";
import honorsAndAwardsSlice from "./dashboard/professionalProfile/profileEdit/honorsAndAwards/honorsAndAwardsSlice";
import previewProfileSlice from "./dashboard/professionalProfile/profileEdit/previewProfile/previewProfileSlice";
import portfolioSlice from "./dashboard/professionalProfile/profileEdit/portfolio/portfolioSlice";
import earningsSlice from "./dashboard/earningsAndReferrals/earningsSlice";
import fullIdCheckMessagesSlice from "./dashboard/messages/fullIdCheck/fullIdCheckMessagesSlice";
import registerMessagesSlice from "./dashboard/messages/theRegister/registerMessagesSlice";
import adminMessagesSlice from "./dashboard/messages/admin/adminMessagesSlice";
import messagesSlice from "./dashboard/messages/messagesSlice";
import accessKeySlice from "./dashboard/uploadDocuments/accessKey/accessKeySlice";
import socialLinksSlice from "./dashboard/professionalProfile/profileEdit/socialLinks/socialLinksSlice";
import overviewSlice from "./dashboard/professionalProfile/profileEdit/overview/overviewSlice";
import helpSlice from "./dashboard/professionalProfile/settings/help/helpSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		certificate: certificateSlice.reducer,
		feature: featureSlice.reducer,
		language: languageSlice.reducer,
		skills: skillsSlice.reducer,
		workHistory: workHistorySlice.reducer,
		settings: securitySettingsSlice,
		google2fa: google2faSlice,
		educationAndCertifications: educationAndCertificationsSlice.reducer,
		honorsAndAwards: honorsAndAwardsSlice.reducer,
		previewProfile: previewProfileSlice.reducer,
		portfolios: portfolioSlice.reducer,
		socialLinks: socialLinksSlice.reducer,
		earnings: earningsSlice.reducer,
		fullIdCheckMessages: fullIdCheckMessagesSlice.reducer,
		registerMessages: registerMessagesSlice.reducer,
		adminMessages: adminMessagesSlice.reducer,
		messages: messagesSlice.reducer,
		accessKey: accessKeySlice.reducer,
		overview: overviewSlice.reducer,
		help: helpSlice.reducer,
	},
})