import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaqAccordion from "../../components/FaqAccordion";
import {
  listTab,
  generalInformation,
  registrationAndMembership,
  profileManagement,
  verificationProcess,
  privacyAndSecurity,
  supportAndCommunity,
  featuresAndReferralProgram,
  reportAndDispute,
} from "../../data/AccordionFaqs";

function CategoryFaqs() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("general-information");
  const [titleAccordion, setTitleAccordion] = useState("General Information");
  const [activeTab, setActiveTab] = useState("");
  const [overview, setOverView] = useState("general-information");
  const location = useLocation();
  const [accordionData, setAccordionData] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");

    if (tabParam === "general-information") {
      setOverView("general-information");
      setAccordionData(generalInformation);
      setTitleAccordion("General Information");
    }
    if (tabParam === "registration-and-membership") {
      setOverView("registration-and-membership");
      setAccordionData(registrationAndMembership);
      setTitleAccordion("Registration and Membership");
    }
    if (tabParam === "profile-management") {
      setOverView("profile-management");
      setAccordionData(profileManagement);
      setTitleAccordion("Profile Management");
    }
    if (tabParam === "verification-process") {
      setOverView("verification-process");
      setAccordionData(verificationProcess);
      setTitleAccordion("Verification Process");
    }
    if (tabParam === "privacy-and-security") {
      setOverView("privacy-and-security");
      setAccordionData(privacyAndSecurity);
      setTitleAccordion("Privacy and Security");
    }
    if (tabParam === "support-and-community") {
      setOverView("support-and-community");
      setAccordionData(supportAndCommunity);
      setTitleAccordion("Support and Community");
    }
    if (tabParam === "features-and-referral-program") {
      setOverView("features-and-referral-program");
      setAccordionData(featuresAndReferralProgram);
      setTitleAccordion("Features and Referral Program");
    }
    if (tabParam === "report-and-dispute") {
      setOverView("report-and-dispute");
      setAccordionData(reportAndDispute);
      setTitleAccordion("Report and Dispute");
    }
  }, [location]);

  return (
    <div>
      <div className="bg-devRegDarkBlue flex flex-col py-20 items-center justify-center">
        <div className="text-white font-bold text-[33px] pb-4">
          How we can help you
        </div>
        <input
          type="search"
          placeholder="Search for queries"
          className="w-full max-w-[500px] rounded-md px-3 py-2"
        />
      </div>

      <div className="flex  max-[900px]:flex-col  justify-between max-w-[1366px] m-auto px-5 py-10">
        <div className="container-3  min-[900px]:hidden mb-20 ">
          <div className="min-w-[220px] bg-white ">
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-4 lg:grid-cols-3">
              {listTab.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-start cursor-pointer border-b-2 pb-2 ${
                    item.isActive === isActive ? "bg-white font-extrabold" : ""
                  }  ${
                    isActive === item.isActive
                      ? "border-[#0082BA]"
                      : "border-[#ebebeb]"
                  }`}
                  onClick={() => {
                    navigate(item.link);
                    setIsActive(item.isActive);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="pl-4 text-sm text-neutral-700">
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container-1 ">
          <div className="font-bold text-[#111827] text-[24px]">
            {titleAccordion}
          </div>

          <div className="w-full">
            <FaqAccordion items={accordionData} />
          </div>
        </div>

        <div className="container-2  max-[900px]:hidden">
          <div className="min-w-[220px] bg-white ">
            {listTab.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-start cursor-pointer ${
                  item.isActive === isActive ? "bg-white font-extrabold" : ""
                }`}
                onClick={() => {
                  navigate(item.link);
                  setIsActive(item.isActive);
                  window.scrollTo(0, 0);
                }}
              >
                <div
                  className={`h-12 w-[4px] ${
                    isActive === item.isActive ? "bg-[#0082BA]" : "bg-[#ebebeb]"
                  }`}
                ></div>
                <div className="pl-4 text-sm text-neutral-700">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryFaqs;
