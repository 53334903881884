import { FaMagnifyingGlass } from "react-icons/fa6";

const SearchBar = ({ value, onChange }) => {
  return (
    <div className="flex flex-row items-center">
      <FaMagnifyingGlass className={'text-neutral-300'} />
      <input
        type="search"
        // name="search"
        // id="search"
        placeholder="Search"
        className="w-full py-3 px-2"
        value={value}
        onChange={(e) => { onChange(e.target.value) }}
      />
    </div>
  )
}

export default SearchBar;