import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Debounce from '../../../../../utils/Debounce';
import { DebounceDelay } from '../../../../../utils/Debounce';
import ValidationHelperChain from '../../../../../utils/ValidationHelperChain';
import { socialLinksAction as action } from './socialLinksSlice';

const useSocialLinksValidation = () => {
  const dispatch = useDispatch();

  const itemDefaultValue = { isValid: false, errorMessage: [] };
  const validationErrorMessageDefault = {
    instagram: itemDefaultValue,
    facebook: itemDefaultValue,
    youtube: itemDefaultValue,
    linkedIn: itemDefaultValue,
    discord: itemDefaultValue,
    tiktok: itemDefaultValue,
  };
  const [validationErrorMessage, setValidationErrorMessage] = useState(validationErrorMessageDefault);

  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState(false);

  const { success, error, loading, } = useSelector(state => state.socialLinks);
  const strNotifiers = JSON.stringify(`${success} ${error} ${loading}`);

  const [showNotif, setShowNotif] = useState(true);
  useEffect(() => {

    setIsSafeToSubmit(hasValidationError() === true || validationErrorMessage === validationErrorMessageDefault ? false : true);
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
        //setErrorsToEmpty();
        dispatch(action.setError(null));
        dispatch(action.setSuccess(false));
      }, 15000);
    } else {
      setShowNotif(true);
    }
  },
    [strValidationErrorMessage, strNotifiers]);

  const hasValidationError = () => {
    return Object.values(validationErrorMessage).some(
      (item) => item.errorMessage?.length > 0
    );
  };

  const setErrorsToEmpty = () => {
    setValidationErrorMessage(validationErrorMessageDefault);
  }

  const validateInstagram = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, instagram: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, instagram: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateFacebook = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, facebook: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, facebook: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateYoutube = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, youtube: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, youtube: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateLinkedIn = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, linkedIn: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, linkedIn: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateDiscord = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, discord: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, discord: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateTiktok = Debounce((str) => {
    if (str === null || str === '') return;

    const validation = ValidationHelperChain().URL({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, tiktok: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, tiktok: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);


  return {
    validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateInstagram,
    validateFacebook,
    validateYoutube,
    validateLinkedIn,
    validateDiscord,
    validateTiktok,
  };
}

export default useSocialLinksValidation;


// const { validationErrorMessage, showNotif, isSafeToSubmit, validateMessage, } = useSocialLinksValidation();
{ /* 
  <input
    value={postMessage.message}
    onChange={(e) => {
      setPostMessage({ ...postMessage, message: e.target.value });
      validateMessage(e.target.value);
    }}
  />

  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.message?.errorMessage} />

  <button className={`${isSafeToSubmit === true ? '' : 'disabled'}`} onClick={onClickSend}>
    Send
  </button>

  <button className={`${(loading === false) ? '' : 'disabled' }`}
    onClick={() => {
      validateMessage(postMessage.message);
      if (validationErrorMessage.message.isValid) onClickSend();
    }}
  >
*/}