import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import formDataHelper, { formUrlEncoded, formUrlEncodedWithImage } from "../../../../../utils/formDataHelper";
import workHistoryApi from "./workHistoryApi";

export const getAllWorkHistory = createAsyncThunk('/work-history/getAll', async ({ }, { rejectWithValue }) => {
	try {
		const response = await workHistoryApi.getAll();
		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getWorkHistory = createAsyncThunk('/work-history/get', async ({ workHistoryId }, { rejectWithValue }) => {
	try {
		const response = await workHistoryApi.get(workHistoryId);
		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addWorkHistory = createAsyncThunk('/work-history/add', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await workHistoryApi.post(formData);

		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const updateWorkHistory = createAsyncThunk('/work-history/update', async ({ workHistoryId, data }, { rejectWithValue }) => {
	try {

		const formData = await formDataHelper(data);
		const response = await workHistoryApi.put(workHistoryId, formData);

		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const removeWorkHistory = createAsyncThunk('/work-history/remove', async ({ workHistoryId }, { rejectWithValue }) => {
	try {
		const response = await workHistoryApi.del(workHistoryId);

		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getProjects = createAsyncThunk('/work-history/project/get', async ({ workHistoryId }, { rejectWithValue }) => {
	try {
		const response = await workHistoryApi.getAllWorkHistoryProjects(workHistoryId);

		if (response.status === 200 || response.status === 201) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addProjectToWorkHistory = createAsyncThunk('/work-history/project/add', async ({ workHistoryId, data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await workHistoryApi.postWorkHistoryProject(workHistoryId, formData);

		if (response.status === 200 || response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addProjectThumbnailToWorkHistory = createAsyncThunk('/work-history/project/thumbnail/add',
	async ({ projectId, data }, { rejectWithValue }) => {
		try {
			const formData = await formDataHelper(data);
			const response = await workHistoryApi.postProjectScreenshot(projectId, formData);

			if (response.status === 200 || response.status === 201) {
				return response.data;
			} else {
				const error = JSON.parse(response.data);
				return rejectWithValue(error.message);
			}

		} catch (err) {
			return rejectWithValue(err);
		}
	});

export const updateProjectThumbnailToWorkHistory = createAsyncThunk('/work-history/project/thumbnail/update',
	async ({ workHistoryId, projectId, data }, { rejectWithValue }) => {
		try {
			const formData = await formDataHelper(data);
			const response = await workHistoryApi.putWorkHistoryProject(workHistoryId, projectId, formData);

			if (response.status === 200 || response.status === 201) {
				return response.data;
			} else {
				const error = JSON.parse(response.data);
				return rejectWithValue(error.message);
			}

		} catch (err) {
			return rejectWithValue(err);
		}
	});

export const workHistorySlice = createSlice({
	name: 'workHistory',
	initialState: {
		success: null,
		loading: false,
		error: null,
		workHistory: [],
		thumbnailFile: null,
		currentWorkProjects: [
			{
				title: "",
				description: "",
				thumbnail: "",
				is_featured: "",
				category: ""
			}
		],
		values: [],
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
		setWorkHistory: (state, action) => {
			state.workHistory = action.payload;
		},
		setEmptyProjectToWorkHistory: (state, action) => {
			state.currentWorkProjects.push({
				title: "",
				description: "",
				thumbnail: "",
				is_featured: "",
				category: ""
			});
		},
		updateEmptyProjectToWorkHistory: (state, action) => {
			const { index, field, value } = action.payload;
			state.currentWorkProjects[index][field] = value;
		},
		clearWorkHistoryProjects: (state, action) => {
			state.currentWorkProjects = [{
				title: "",
				description: "",
				thumbnail: "",
				is_featured: "",
				category: ""
			}];
		},
		removeBlankWorkHistoryProjects: (state, action) => {
			state.currentWorkProjects = state.currentWorkProjects.filter((item) => item.title !== "");
		},
	},
	extraReducers: (builder) => {
		builder
			// getWorkHistory all
			.addCase(getAllWorkHistory.pending, (state) => {
				// state.loading = true;
				state.error = null;
			}).addCase(getAllWorkHistory.rejected, (state, action) => {
				// state.loading = false;
				state.error = action?.payload;
			}).addCase(getAllWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.values = action?.payload;
			})
			// getWorkHistory
			.addCase(getWorkHistory.pending, (state) => {
				// state.loading = true;
				state.error = null;
			}).addCase(getWorkHistory.rejected, (state, action) => {
				// state.loading = false;
				state.error = action?.payload;
			}).addCase(getWorkHistory.fulfilled, (state, action) => {
				// state.loading = false;
				state.error = null;
				// state.workHistory = action?.payload;
			})
			// addWorkHistory
			.addCase(addWorkHistory.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addWorkHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// updateWorkHistory
			.addCase(updateWorkHistory.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(updateWorkHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(updateWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// removeWorkHistory
			.addCase(removeWorkHistory.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(removeWorkHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(removeWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// getProject
			.addCase(getProjects.pending, (state) => {
				// state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(getProjects.rejected, (state, action) => {
				// state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(getProjects.fulfilled, (state, action) => {
				// state.loading = false;
				state.error = null;

				const projects = action?.payload;
				if (projects.length > 0) {
					const index = state.values.findIndex(item => item.id === projects[0].work_history_id);

					if (index !== -1) {
						state.values[index] = {
							...state.values[index],
							projects: projects,
						}
					}
				}
			})
			// addProjectToWorkHistory
			.addCase(addProjectToWorkHistory.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addProjectToWorkHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addProjectToWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// addProjectThumbnailToWorkHistory
			.addCase(addProjectThumbnailToWorkHistory.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addProjectThumbnailToWorkHistory.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addProjectThumbnailToWorkHistory.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
	}
});

export const workHistoryActions = workHistorySlice.actions;
export default workHistorySlice;