import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import overviewApi from './overviewApi';
import formDataHelper from '../../../../../utils/formDataHelper';

export const getOverview = createAsyncThunk('/overview/getAll', async ({ }, { rejectWithValue }) => {
  try {
    const response = await overviewApi.get();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateOverview = createAsyncThunk('/overview/update', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await overviewApi.post(formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDocuments = createAsyncThunk('/overview/getDocuments', async ({ }, { rejectWithValue }) => {
  try {
    const response = await overviewApi.getDocuments();
    if (response.status === 200 || response.status === 201) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateDocument = createAsyncThunk('/overview/updateDocument', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await overviewApi.postDocument(formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removeDocument = createAsyncThunk('/overview/removeDocument', async ({ documentId }, { rejectWithValue }) => {
  try {
    const response = await overviewApi.postDocument(documentId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    success: false,
    loading: false,
    error: null,
    values: null,
    documents: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getOverview.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getOverview.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.values = action?.payload;
      })
      // update
      .addCase(updateOverview.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(updateOverview.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
        console.log(`updateOverview.rejected : ${JSON.stringify(action?.payload)}`);
      }).addCase(updateOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // get document
      .addCase(getDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.documents = action?.payload;
      })
      // update documents
      .addCase(updateDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // delete documents
      .addCase(removeDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removeDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removeDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
  }
});

export const overviewAction = overviewSlice.actions;
export default overviewSlice;