import usePortfolio from "../../../feature/dashboard/professionalProfile/profileEdit/portfolio/usePortfolio";
import { useEffect, useState } from "react";
import ImageCarousel from "../../ImageCarousel";

const PortfolioItem = ({ data = null, imageData = null, featureEdit = null, onEdit = null, onDelete = null, showControls = true }) => {

  const {
    loading,
    getImageData,
  } = usePortfolio();

  const [images, setImages] = useState(data.images || []);
  const [forceRerender, setForceRerender] = useState(false);


  useEffect(() => {
    if (data) {
      getImageData(data.id);
    }
  }, []);

  const stringData = JSON.stringify(data);
  useEffect(() => {
    if (data?.images?.length > 0) {
      setImages(data.images);
      setForceRerender(!forceRerender);
    }
  }, [stringData, data]);

  return (
    <div className="flex flex-col ml-5 w-full max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow py-4 max-md:mt-6">
        {images.length > 0 && <ImageCarousel key={stringData} images={images} />}
        <div className="mt-2 text-base font-bold leading-6 text-neutral-800">
          {data.title}
        </div>
        <div className="mt-2 text-base leading-6 text-neutral-600">
          {data.description}
        </div>
        <div className="flex gap-5 mt-2 w-full">
          <div className="flex gap-2">
            <div className="my-auto text-sm font-medium text-neutral-600">
              Featured Project
            </div>
            <div className="flex flex-col justify-center">
              <input
                type="checkbox"
                checked={typeof data.is_featured === "boolean" ? data.is_featured : (data.is_featured === 1 ? true : false)}
                onChange={(e) => featureEdit(data.id, e.target.checked)}
                className="appearance-none h-6 w-6 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none"
              />
            </div>
          </div>
          <div className="flex-1 my-auto text-base font-medium text-right text-blue-900">
            {showControls &&
              <><button onClick={() => onEdit(data.id)}>Edit</button> | <button onClick={() => onDelete(data.id)}>Delete</button></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioItem;