import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import educationAndCertificationsApi from "./educationAndCertificationsApi";
import formDataHelper from "../../../../../utils/formDataHelper";


export const getAllEducationAndCertifications = createAsyncThunk('/education-and-certifications/getAll', async ({ }, { rejectWithValue }) => {
	try {
		const response = await educationAndCertificationsApi.getAll();
		if (response.status === 200) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getEducationAndCertifications = createAsyncThunk('/education-and-certifications/get', async ({ educationAndCertificationId }, { rejectWithValue }) => {
	try {
		const response = await educationAndCertificationsApi.get(educationAndCertificationId);
		if (response.status === 200) {
			return JSON.parse(response.data);
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const addEducationAndCertifications = createAsyncThunk('/education-and-certifications/add', async ({ data }, { rejectWithValue }) => {
	try {
		const formData = await formDataHelper(data);
		const response = await educationAndCertificationsApi.post(formData);

		if (response.status === 201) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const updateEducationAndCertifications = createAsyncThunk('/education-and-certifications/update', async ({ educationAndCertificationId, data }, { rejectWithValue }) => {
	try {

		const formData = await formDataHelper(data);
		const response = await educationAndCertificationsApi.put(educationAndCertificationId, formData);

		if (response.status === 200) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}

	} catch (err) {
		return rejectWithValue(err);
	}
});

export const removeEducationAndCertifications = createAsyncThunk('/education-and-certifications/remove', async ({ educationAndCertificationId }, { rejectWithValue }) => {
	try {
		const response = await educationAndCertificationsApi.del(educationAndCertificationId);

		if (response.status === 200) {
			return response.data;
		} else {
			const error = JSON.parse(response.data);
			return rejectWithValue(error.message);
		}
	} catch (err) {
		return rejectWithValue(err);
	}
});


export const educationAndCertificationsSlice = createSlice({
	name: 'educationAndCertifications',
	initialState: {
		success: false,
		loading: false,
		error: null,
		values: [],
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// getEducationAndCertifications all
			.addCase(getAllEducationAndCertifications.pending, (state) => {
				state.loading = true;
				state.error = null;
			}).addCase(getAllEducationAndCertifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getAllEducationAndCertifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.values = action?.payload;
			})
			.addCase(getEducationAndCertifications.pending, (state) => {
				state.loading = true;
				state.error = null;
			}).addCase(getEducationAndCertifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
			}).addCase(getEducationAndCertifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			// addEducationAndCertifications
			.addCase(addEducationAndCertifications.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(addEducationAndCertifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(addEducationAndCertifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// updateEducationAndCertifications
			.addCase(updateEducationAndCertifications.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(updateEducationAndCertifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(updateEducationAndCertifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
			// removeEducationAndCertifications
			.addCase(removeEducationAndCertifications.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.success = false;
			}).addCase(removeEducationAndCertifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload;
				state.success = false;
			}).addCase(removeEducationAndCertifications.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.success = true;
			})
	}
});

export const educationAndCertificationsAction = educationAndCertificationsSlice.actions;
export default educationAndCertificationsSlice;