import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { personalInfo, emergencyContact } from "../../feature/authSlice";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import { Country, State, City } from "country-state-city";
import PhoneNumberWithFlag from "../../components/customInputFields/PhoneNumberWithFlag";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import CustomInputText from "../../components/customInputFields/CustomTextInput";
import CustomSelect from "../../components/customInputFields/CustomSelect";
import InputStyling from "../../styling/InputStyling";
import FailureIndicator from "../../components/FailureIndicator";

function PersonalInfo() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const serial_number = userData?.serial_number;

  const [showNotif, setShowNotif] = useState(false);

  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLname] = useState("");
  const [toggleSex, setToggleSex] = useState("male");
  const [birthDate, setBirthDate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [unit, setUnit] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [stateOrProvince, setStateOrProvince] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [backupEmail, setBackUpEmail] = useState("");
  const [isValidBackupEmail, setIsValidBackupEmail] = useState(true);
  const [statesToSelect, setStatesToSelect] = useState([]);
  const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState("");
  const [selectedStateIsoCode, setSelectedStateIsoCode] = useState("");
  const [countriesToSelect, setCountriesToSelect] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const fullName = `${fName} ${mName} ${lName}`;
  //Emergency Contact
  const [fullNameEmergency, setFullNameEmergency] = useState("");
  const [unitEmergency, setUnitEmergency] = useState("");
  const [streetEmergency, setStreetEmergency] = useState("");
  const [postalCodeEmergency, setPostalCodeEmergency] = useState("");
  const [cityEmergency, setCityEmergency] = useState("");
  const [phoneEmergency, setPhoneEmergency] = useState("");
  const [emailEmergency, setEmailEmergency] = useState("");
  const [isValidEmergencyEmail, setIsValidEmergencyEmail] = useState(true);
  const [countryEmergency, setCountryEmergency] = useState("");

  const [statesToSelectEmergency, setStatesToSelectEmergency] = useState([]);
  const [selectedCountryIsoCodeEmergency, setSelectedCountryIsoCodeEmergency] =
    useState("");
  const [selectedStateIsoCodeEmergency, setSelectedStateIsoCodeEmergency] =
    useState("");
  const [countriesToSelectEmergency, setCountriesToSelectEmergency] = useState(
    []
  );
  const [statesEmergency, setStatesEmergency] = useState([]);
  const [citiesEmergency, setCitiesEmergency] = useState([]);
  const [selectedStateEmergency, setSelectedStateEmergency] = useState("");
  const [selectedCityEmergency, setSelectedCityEmergency] = useState("");
  const [
    registrationPhoneNumberEmergency,
    setRegistrationPhoneNumberEmergency,
  ] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const handleUpdateInfo = async () => {
    const _formData = {
      fullname: fullName,
      first_name: fName,
      middle_name: mName,
      last_name: lName,
      sex: toggleSex,
      dob: birthDate,
      place_of_birth: birthPlace,
      unit_number: unit,
      street_name: street,
      city: selectedCity,
      state: selectedState,
      country: country,
      postal_code: postalCode,
      backup_email: backupEmail,
      is_profile_info_done: 1,
    };
    setIsModalOpen(true);

    // const efname = fullNameEmergency.split(" ")[0];
    // const elname = fullNameEmergency
    //   .substring(efname.length, fullNameEmergency.length)
    //   .trim();

    const _formDataEmergencyContact = {
      city: selectedCityEmergency,
      country: countryEmergency,
      email: emailEmergency,
      fullname: fullNameEmergency,
      // first_name: efname,
      // last_name: elname,
      phone_number: registrationPhoneNumberEmergency,
      postal_code: postalCodeEmergency,
      state: selectedStateEmergency,
      street_name: streetEmergency,
    };

    if (userData) {
      const { id } = userData || { id: 0 };
      await dispatch(personalInfo({ id, data: { ..._formData } })).then(
        (data) => {
          if (data?.payload?.user) {
            dispatch(
              emergencyContact({ id, data: { ..._formDataEmergencyContact } })
            ).then((ndata) => {
              if (ndata?.payload?.data) {
                setIsModalOpen(false);
                navigate("/register/process-payment");
              }
            });
          }
        }
      );
    }
  };

  const validateBackupEmail = (e) => {
    const newEmail = e.target.value;
    setBackUpEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidBackupEmail(emailRegex.test(newEmail));
  };
  const validateEmergencyEmail = (e) => {
    const newEmail = e.target.value;
    setEmailEmergency(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmergencyEmail(emailRegex.test(newEmail));
  };

  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/personal-information"
  );

  useEffect(() => {
    setCountriesToSelect(Country.getAllCountries());
    setStatesToSelect(State.getAllStates());
    setCountriesToSelectEmergency(Country.getAllCountries());
    setStatesToSelectEmergency(State.getAllStates());
  }, []);


  useEffect(() => {
    if (auth.error) {
      setShowNotif(true);
    }
  }, [auth.error]);

  useEffect(() => {
    if (selectedCountryIsoCode !== "") {
      setStates(State.getStatesOfCountry(selectedCountryIsoCode));
      setCities([]);
      setSelectedState("");
      setSelectedCity("");
    } else {
      setStates([]);
    }
    if (selectedCountryIsoCodeEmergency !== "") {
      setStatesEmergency(
        State.getStatesOfCountry(selectedCountryIsoCodeEmergency)
      );
      setCitiesEmergency([]);
      setSelectedStateEmergency("");
      setSelectedCityEmergency("");
    } else {
      setStatesEmergency([]);
    }
  }, [selectedCountryIsoCode, selectedCountryIsoCodeEmergency]);

  useEffect(() => {
    if (selectedState !== "") {
      setCities(
        City.getCitiesOfState(selectedCountryIsoCode, selectedStateIsoCode)
      );
      setSelectedCity("");
    } else {
      setCities([]);
    }
    if (selectedStateEmergency !== "") {
      setCitiesEmergency(
        City.getCitiesOfState(
          selectedCountryIsoCodeEmergency,
          selectedStateIsoCodeEmergency
        )
      );
      setSelectedCityEmergency("");
    } else {
      setCitiesEmergency([]);
    }
  }, [
    selectedState,
    selectedCountryIsoCode,
    selectedStateEmergency,
    selectedCountryIsoCodeEmergency,
  ]);

  const test = {
    fullname: fullName,
    first_name: fName,
    middle_name: mName,
    last_name: lName,
    sex: toggleSex,
    dob: birthDate,
    place_of_birth: birthPlace,
    unit_number: unit,
    street_name: street,
    city: selectedCity,
    state: selectedState,
    country: country,
    postal_code: postalCode,
    backup_email: backupEmail,
    is_profile_info_done: 1,
  };

  console.log(test, "data");

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setCountry(countryName);
    const countryIso = countriesToSelect.find((c) => c.name === countryName);
    setSelectedCountryIsoCode(countryIso ? countryIso.isoCode : "");
  };
  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setSelectedState(stateName);
    const stateIsoIso = statesToSelect.find((c) => c.name === stateName);
    setSelectedStateIsoCode(stateIsoIso ? stateIsoIso.isoCode : "");
  };
  const handleCountryChangeEmergency = (e) => {
    const countryName = e.target.value;
    setCountryEmergency(countryName);
    const countryIso = countriesToSelectEmergency.find(
      (c) => c.name === countryName
    );
    setSelectedCountryIsoCodeEmergency(countryIso ? countryIso.isoCode : "");
  };

  const handleStateChangeEmergency = (e) => {
    const stateName = e.target.value;
    setSelectedStateEmergency(stateName);
    const stateIsoIso = statesToSelectEmergency.find(
      (c) => c.name === stateName
    );
    setSelectedStateIsoCodeEmergency(stateIsoIso ? stateIsoIso.isoCode : "");
  };
  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumberEmergency(phoneNumber);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        const data = await response.json();
        let countryName = data.countryName;

        countryName = countryName.replace(/\s*\(the\)$/, "");
        setCountry(countryName);
        setCountryEmergency(countryName);

        const countryIso = countriesToSelect.find(
          (c) => c.name === countryName
        );
        setSelectedCountryIsoCode(countryIso ? countryIso.isoCode : "");
      });
    }
  }, [countriesToSelect]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className={`${ResponsiveValues.cardScreenStyle} flex flex-col mx-auto leading-[121%] max-w-[800px] text-stone-300`}>
        <div className="flex gap-4 justify-center  py-1  text-blue-900 leading-[120%] max-md:flex-wrap">
          <div className={`${TextStyling.pageSubTitle} flex-1 font-bold text-2xl tracking-tight max-md:max-w-full`}>
            Personal Information
          </div>
          {
            serial_number !== null &&
            <div className="text-3xl tracking-tight text-right">
              <span className="text-base leading-5 tracking-tight ">
                Serial Code
              </span>
              <span className="text-lg leading-6 tracking-tight">
                {" "}
                {serial_number}
              </span>
            </div>
          }
        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
          <input
            className={`${InputStyling.text}`}
            placeholder="Firstname"
            value={fName}
            onChange={(e) => setFName(e.target.value)}
          />

          <input
            placeholder="MiddleName"
            className={`${InputStyling.text}`}
            value={mName}
            onChange={(e) => setMName(e.target.value)}
          />

          <input
            placeholder="Lastname"
            className={`${InputStyling.text}`}
            value={lName}
            onChange={(e) => setLname(e.target.value)}
          />
        </div>
        <div className="flex justify-between w-full gap-6 mt-4 text-base max-sm:gap-4 max-md:flex-wrap max-md:max-w-full">
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            className={`${birthDate !== "" ? " text-black" : " text-zinc-300"} ${InputStyling.text} flex-1`}
          />

          <input
            value={birthPlace}
            onChange={(e) => {
              setBirthPlace(e.target.value);
            }}
            placeholder="Birthplace"
            className={`${InputStyling.text} flex-1`}
          />
        </div>
        <div className="flex items-center justify-start gap-10 px-1 py-4 my-auto text-lg max-sm:gap-4 whitespace-nowrap">
          <label className="flex items-center gap-2 py-1 cursor-pointer">
            <input
              type="radio"
              name="sex"
              value="male"
              checked={toggleSex === "male"}
              onChange={() => setToggleSex("male")}
            />
            <span className={` ${toggleSex === "male" ? "text-black" : ""}`}>
              Male
            </span>
          </label>
          <label className="flex items-center gap-2 py-1 cursor-pointer">
            <input
              type="radio"
              name="sex"
              value="female"
              checked={toggleSex === "female"}
              onChange={() => setToggleSex("female")}
            />
            <span className={` ${toggleSex === "female" ? "text-black" : ""}`}>
              Female
            </span>
          </label>
        </div>

        <div className="flex w-full flex-col-3 items-center text-base leading-6 text-neutral-600 max-md:max-w-full">
          <div className={`pr-2 ${TextStyling.inputLabel}`}> Address </div>
          <hr className="flex-1 w-full border border-neutral-200" />
        </div>
        <div className="w-full gap-4 mt-2 text-base text-black space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:divide-x max-md:flex-wrap max-md:max-w-full">
          <CustomSelect
            className={`${InputStyling.select} ${country === "" ? "text-neutral-400" : "text-black"}`}
            // id="country"
            value={country}
            onChange={handleCountryChange}
          >
            <option disabled value="">
              Country
            </option>
            {countriesToSelect.map((country) => (
              <option key={country.isoCode} value={country.name}>
                {country.name}
              </option>
            ))}
          </CustomSelect>

          <CustomSelect
            // id="state"
            className={`${InputStyling.select} ${selectedState === "" ? "text-neutral-400" : "text-black"}`}
            value={selectedState}
            onChange={handleStateChange}
            disabled={!selectedCountryIsoCode}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.name}>
                {state.name}
              </option>
            ))}
          </CustomSelect>

          <CustomSelect
            // id="city"
            className={`${InputStyling.select} ${selectedState === "" ? "text-neutral-400" : "text-black"} `}
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            disabled={!selectedState}
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </CustomSelect>

        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <input
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            placeholder="Unit#"
            className={`${InputStyling.text}`}
          />

          <input
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder="Street"
            className={`${InputStyling.text}`}
          />
          <input
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="Postal Code"
            // type="number"
            className={`${InputStyling.text}`}
          />
        </div>

        <div className="flex w-full flex-col-3 items-center text-base leading-6 mb-2 mt-6 text-neutral-600 max-md:max-w-full">
          <div className={`pr-2 ${TextStyling.inputLabel}`}> Backup Email Address </div>
          <hr className="flex-1 w-full border border-neutral-200" />
        </div>
        <input
          value={backupEmail}
          onChange={validateBackupEmail}
          placeholder="Email Address"
          className={`${InputStyling.text}`}
        />
        {!isValidBackupEmail && (
          <div className="mt-1 text-sm text-red-600">
            Please enter a valid email address.
          </div>
        )}

        <div className={` my-8 md:my-6  p-8 md:my-12 bg-neutral-100 md:border md:border-solid md:border-neutral-200 md:rounded-lg`}>
          <div className="max-md:max-w-full">
            <span className={`${TextStyling.pageHeader3}`}>
              Next Of Kin or Emergency Person:
            </span>
            <br />
            <br />
            <span className={`${TextStyling.pageBody}`}>
              As part of your membership sign-up, we ask you to provide the
              contact details of a family member or friend.
            </span>
            <br />
            <br />
            <span className={`${TextStyling.pageBodyBold} pr-1`}>
              Why is this important?
            </span>
            <span className={`${TextStyling.pageBody}`}>
              This contact will only be used in one specific situation: if you
              have unclaimed funds in your account. It's a precautionary measure
              to ensure that any unclaimed benefits can be appropriately managed
              and directed in case you're not reachable.
            </span>
            <br />
            <br />
            <span className={`${TextStyling.pageBodyBold}`}>
              Respecting Your Privacy
            </span>
            <br />
            <span className={`${TextStyling.pageBody}`}>
              Please be assured, this information is strictly for the purpose of
              handling unclaimed funds and will be kept confidential.
            </span>
            <br />
            <br />
            <span className={`${TextStyling.pageBody}`}>
              Thank you for taking this step to secure your benefits and
              interests as a member of our community.
            </span>
          </div>
          <div className="mt-4 max-md:max-w-full">
            <span className={`${TextStyling.pageBodyBold}`}>
              Commitment to Data Privacy and Security
            </span>
            <br />
            <br />
            <span className={`${TextStyling.pageBody}`}>
              At Full ID Check, we prioritize your privacy. Once your
              application is approved, your personal information is removed from
              our online servers and securely stored offline. This practice
              ensures the utmost security and confidentiality of your data.
            </span>
            <br /><br />
            <span className={`${TextStyling.pageBody}`}>
              For any questions about our data management, please contact us.
            </span>
          </div>
        </div>

        <CustomInputText
          label="Fullname (Firstname, Middle, Lastname)"
          placeholder="Fullname"
          value={fullNameEmergency}
          onChange={(e) => setFullNameEmergency(e.target.value)}
        />
        <div className={`${TextStyling.inputLabel}`}>
          Address
        </div>
        <div className="w-full gap-4 text-base text-black space-y-4 md:space-y-0 md:grid md:grid-cols-3 md:divide-x max-md:flex-wrap max-md:max-w-full">
          <CustomSelect
            className={`${InputStyling.select} ${countryEmergency === "" ? "text-neutral-400" : "text-black"}`}
            id="country"
            value={countryEmergency}
            onChange={handleCountryChangeEmergency}
          >
            <option disabled value="">
              Country
            </option>
            {countriesToSelectEmergency.map((country) => (
              <option key={country.isoCode} value={country.name}>
                {country.name}
              </option>
            ))}
          </CustomSelect>
          <CustomSelect
            id="state"
            className={`${InputStyling.select} ${selectedStateEmergency === "" ? "text-neutral-400" : "text-black"}`}
            value={selectedStateEmergency}
            onChange={handleStateChangeEmergency}
            disabled={!selectedCountryIsoCodeEmergency}
          >
            <option value="">Select State</option>
            {statesEmergency.map((state) => (
              <option key={state.isoCode} value={state.name}>
                {state.name}
              </option>
            ))}
          </CustomSelect>
          <CustomSelect
            id="city"
            className={`${InputStyling.select} ${selectedStateEmergency === "" ? "text-neutral-400" : "text-black"}`}
            value={selectedCityEmergency}
            onChange={(e) => setSelectedCityEmergency(e.target.value)}
            disabled={!selectedStateEmergency}
          >
            <option value="">Select City</option>
            {citiesEmergency.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <input
            value={unitEmergency}
            onChange={(e) => setUnitEmergency(e.target.value)}
            placeholder="Unit#"
            className={`${InputStyling.text}`}
          />

          <input
            value={streetEmergency}
            onChange={(e) => setStreetEmergency(e.target.value)}
            placeholder="Street"
            className={`${InputStyling.text}`}
          />
          <input
            value={postalCodeEmergency}
            onChange={(e) => setPostalCodeEmergency(e.target.value)}
            placeholder="Postal Code"
            // type="number"
            className={`${InputStyling.text}`}
          />
        </div>

        <div className={`${TextStyling.inputLabel} mt-6`}>
          Phone Number
        </div>

        <div className="">
          <PhoneNumberWithFlag onPhoneNumberChange={handlePhoneNumberChange} />
        </div>

        <div className={`mt-6 mb-1 ${TextStyling.inputLabel}`}>
          Email Address
        </div>

        <input
          value={emailEmergency}
          onChange={validateEmergencyEmail}
          placeholder="Email Address"
          className={`${InputStyling.text}`}
        />
        {!isValidEmergencyEmail && (
          <div className="mt-1 text-sm text-red-600">
            Please enter a valid email address.
          </div>
        )}
        {/* {auth.loading && (
          <p className="mt-2 text-left text-blue-900">Processing...</p>
        )} */}
        {auth.error && (
          <FailureIndicator message={auth.error} setShowNotif={setShowNotif} />
        )}


        <button
          onClick={handleUpdateInfo}
          disabled={auth.loading}
          className={ResponsiveValues.buttonFull}
        >
          Next
        </button>
      </div>
      <Loader loadingText={"Processing..."} isOpen={ismodalOpen} />
    </>
  );
}

export default PersonalInfo;
