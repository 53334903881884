import React, { useEffect, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import { useDispatch, useSelector } from "react-redux";
import { newRecoveryCodes2FA, setNewRecoveryCodes2FAError } from "../../feature/dashboard/professionalProfile/google2fa/google2faSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TopBarLogo from "./TopBarLogo";
import TextStyling from "../../styling/TextStyling";

const RecoveryCodes = ({ codes }) => {
  const dispatch = useDispatch();
  const newRecoveryCodes2FAStates = useSelector((state) => state.google2fa.newRecoveryCodes2FA) || { success: null, error: null, loading: false };
  const [recoveryCodes, setRecoveryCodes] = useState(codes || []);
  const [isFading, setIsFading] = useState(false);

  const navigate = useNavigate();

  const handleGenerateNewRecoveryCodes = async () => {
    await dispatch(newRecoveryCodes2FA()).then(({ payload }) => {

      setIsFading(true);

      if (payload && payload.recovery_codes) {
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  };

  const handleCopy = () => {
    const text = recoveryCodes.join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Recovery codes copied to clipboard!');
    });
  };

  const handleDownload = () => {
    const text = recoveryCodes.join('\n');
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'fullidcheck-recovery-codes.txt';
    document.body.appendChild(element);
    element.click();
  };

  const handlePrint = () => {
    const text = recoveryCodes.join('\n');
    const newWindow = window.open('', '', 'width=600,height=400');
    newWindow.document.write(`<pre>${text}</pre>`);
    newWindow.document.close();
    newWindow.print();
  };

  useEffect(() => {
    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
        setNewRecoveryCodes2FAError(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isFading]);

  return (
    <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">
      <div className={`min-w-[70vh] max-w-[90vh] max-h-[100vh] ${ResponsiveValues.cardScreenStyleNoSpacing} flex flex-col grow`}>
        <TopBarLogo children={<BacktoLogin />} logoImg={"https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"} />

        <div className="p-6">

          <div className={`mt-2 ${TextStyling.pageHeader1}`}>
            Two-factor recovery codes
          </div>
          <div className={`mt-2 ${TextStyling.pageBody}`}>
            Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.
          </div>
          <div className="mt-4 p-2 bg-gray-100 rounded-lg">
            <p className={`m-2 ${TextStyling.note}`}>
              Keep your recovery codes in a safe spot. These codes are the last resort for accessing your account in case you lose your password and second factors. If you cannot find these codes, you will lose access to your account.
            </p>
          </div>
          <div className="flex flex-col justify-between px-5 my-4 min-h-[30vh] border border-gray-200 rounded-lg">
            <ul className="grid grid-cols-2 gap-2 px-5 mt-4 text-center list-disc">
              {recoveryCodes.map((code, index) => (
                <li key={index} className="p-1 text-sm font-bold text-start">
                  {code}
                </li>
              ))}
            </ul>
            <div className="flex justify-start gap-4 my-4">
              <button onClick={handleDownload}
                className={`flex items-center ${ResponsiveValues.buttonFitNoColor}`}>
                <i className="mr-2 fas fa-download"></i>
                <span>Download</span>
              </button>
              <button onClick={handlePrint}
                className={`flex items-center ${ResponsiveValues.buttonFitNoColor}`}>
                <i className="mr-2 fas fa-print"></i>
                <span>Print</span>
              </button>
              <button onClick={handleCopy}
                className={`flex items-center ${ResponsiveValues.buttonFitNoColor}`}>
                <i className="mr-2 fas fa-copy"></i>
                <span>Copy</span>
              </button>
            </div>
          </div>
          {newRecoveryCodes2FAStates.error && isFading && (
            <div className="my-2 text-center text-red-600">
              {newRecoveryCodes2FAStates.error}
            </div>
          )}
          <div className="flex flex-row jusfitfy-between items-center gap-4">
            <button
              onClick={handleGenerateNewRecoveryCodes}
              className={`flex items-center ${ResponsiveValues.buttonFull}`}>
              Generate New Recovery Code
            </button>

            <button onClick={() => navigate('/login')}
              className={`flex items-center ${ResponsiveValues.buttonFullSecondaryLightBlue}`}>
              Go to Login
            </button>
          </div>
        </div>
      </div>

      <Loader loadingText={"Generating..."} isOpen={newRecoveryCodes2FAStates.loading} />
    </div>
  );
};

export default RecoveryCodes;
