import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import registerMessagesApi from './registerMessagesApi';
import formDataHelper from '../../../../utils/formDataHelper';

export const getMessages = createAsyncThunk('/register-dev-messages/getAll', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await registerMessagesApi.get(id);
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getConversation = createAsyncThunk('/register-dev-messages/getConversation', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await registerMessagesApi.getConversation(id);
    if (response.status === 200) {
      return JSON.parse(response.data);
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const sendReply = createAsyncThunk('/register-dev-messages/sendReply', async ({ id, messageId, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await registerMessagesApi.postReply(id, messageId, formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateReadUnRead = createAsyncThunk('/register-dev-messages/update', async ({ data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await registerMessagesApi.postMarkAsReadUnread(formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removeMessage = createAsyncThunk('/register-dev-messages/removeMessage', async ({ id, messageId }, { rejectWithValue }) => {
  try {
    const response = await registerMessagesApi.del(id, messageId);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const removeMessageMultiple = createAsyncThunk('/register-dev-messages/removeMessageMultiple', async ({ id, data }, { rejectWithValue }) => {
  try {
    const formData = await formDataHelper(data);
    const response = await registerMessagesApi.delMultiple(id, formData);
    if (response.status === 201) {
      return response.data;
    } else {
      const error = JSON.parse(response.data);
      return rejectWithValue(error.message);
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const defaultMessageValues = {
  id: 0,
  subject: '',
  email_from: '',
  email_to: '',
  message: '',
  is_seen: 0,
  is_seen_by_admin: 0,
  is_deleted_by_receiver: 0,
  is_deleted_by_sender: 0,
  is_reply: 0,
  reply_from_message_id: 0,
  created_at: "2024-07-05T13:02:28.000000Z",
  updated_at: "2024-07-05T13:02:28.000000Z",
  deleted_at: null,
  receiver_id: 0,
  sender_id: 0,
  from: {
    id: 0,
    fullname: '',
    serial_number: '',
    profile_photo_url: '',
    age: 0
  }
}

export const registerMessagesSlice = createSlice({
  name: 'registerMessages',
  initialState: {
    success: false,
    loading: false,
    error: null,
    message: { defaultMessageValues },
    messages: [],
    searchedMessages: [],
    conversation: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSucccess: (state, action) => {
      state.success = action.payload;
    },
    setSearchedMessages: (state, action) => {
      state.searchedMessages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get All
      .addCase(getMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.messages = action?.payload?.data;
        state.searchedMessages = action?.payload?.data;
      })
      .addCase(getConversation.pending, (state) => {
        state.loading = true;
        state.error = null;
      }).addCase(getConversation.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }).addCase(getConversation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.conversation = action?.payload;
      })
      // send reoly
      .addCase(sendReply.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(sendReply.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(sendReply.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // update - Read / Unread
      .addCase(updateReadUnRead.pending, (state) => {
        // state.loading = true;
        // state.error = null;
        // state.success = false;
      }).addCase(updateReadUnRead.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action?.payload;
        // state.success = false;
      }).addCase(updateReadUnRead.fulfilled, (state, action) => {
        // state.loading = false;
        // state.error = null;
        // state.success = true;
      })
      // remove
      .addCase(removeMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removeMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removeMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      // removeMessageMultiple
      .addCase(removeMessageMultiple.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      }).addCase(removeMessageMultiple.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.success = false;
      }).addCase(removeMessageMultiple.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
  }
});

export const registerMessagesAction = registerMessagesSlice.actions;
export default registerMessagesSlice;