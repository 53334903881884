import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newRecoveryCodes2FA, setNewRecoveryCodes2FAError } from "../../../feature/dashboard/professionalProfile/google2fa/google2faSlice";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DestopCardScreen from "../../../components/DesktopCardScreen";
import MobileCardScreen from "../../../components/MobileCardScreen";

function ChangeRecoveryCodes() {
  const dispatch = useDispatch();
  const newRecoveryCodes2FAStates = useSelector((state) => state.google2fa.newRecoveryCodes2FA) || { success: null, error: null, loading: false };
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [isFading, setIsFading] = useState(false);
  const [showNotif, setshowNotif] = useState(true);

  const navigate = useNavigate();

  const handleGenerateNewRecoveryCodes = async () => {
    await dispatch(newRecoveryCodes2FA()).then(({ payload }) => {

      setIsFading(true);

      if (payload && payload.recovery_codes) {
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  };

  const handleCopy = () => {
    const text = recoveryCodes.join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Recovery codes copied to clipboard!');
    });
  };

  const handleDownload = () => {
    const text = recoveryCodes.join('\n');
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'fullidcheck-recovery-codes.txt';
    document.body.appendChild(element);
    element.click();
  };

  const handlePrint = () => {
    const text = recoveryCodes.join('\n');
    const newWindow = window.open('', '', 'width=600,height=400');
    newWindow.document.write(`<pre>${text}</pre>`);
    newWindow.document.close();
    newWindow.print();
  };

  useEffect(() => {
    dispatch(newRecoveryCodes2FA()).then(({ payload }) => {

      setIsFading(true);

      if (payload && payload.recovery_codes) {
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
        setNewRecoveryCodes2FAError(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isFading]);

  useEffect(() => {
    setshowNotif(true);
  }, [newRecoveryCodes2FAStates.error]);

  return (
    <>
      <MobileCardScreen
        title='Two-factor Recovery Codes'

        children={
          <>
            <div className="mt-4">
              Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.
            </div>

            <div className="p-4 mt-4 bg-gray-100 rounded-lg">
              Keep your recovery codes in a safe spot. These codes are the last resort for accessing your account in case you lose your password and second factors. If you cannot find these codes, you will lose access to your account.
            </div>

            <div className="px-8 py-4 my-4 border border-gray-200 rounded-lg">
              <ul className=" grid grid-cols-2 gap-2 text-center list-disc">
                {recoveryCodes.map((code, index) => (
                  <li key={index} className="p-1 text-sm font-bold text-start">
                    {code}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center gap-4 mt-4">
                <button onClick={handleDownload} className="flex items-center px-4 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-download"></i>
                  <span>Download</span>
                </button>
                <button onClick={handlePrint} className="flex items-center px-4 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-print"></i>
                  <span>Print</span>
                </button>
                <button onClick={handleCopy} className="flex items-center px-4 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-copy"></i>
                  <span>Copy</span>
                </button>
              </div>
            </div>

            <div className="flex gap-4 mt-4 justify-end">

              <button
                disabled={newRecoveryCodes2FAStates.loading}
                onClick={handleGenerateNewRecoveryCodes}
                className={`${ResponsiveValues.buttonFull} mt-4 mb-2`}
              >
                Generate New Recovery Code
              </button>
            </div>
            {newRecoveryCodes2FAStates.loading && (
              <LoadingIndicator />
            )}

            {(showNotif === true && isFading && newRecoveryCodes2FAStates.error) && (
              <FailureIndicator message={newRecoveryCodes2FAStates.error} setShowNotif={setshowNotif} />
            )}
          </>}
      />

      <DestopCardScreen
        title='Two-factor Recovery Codes'
        children={
          <>
            {newRecoveryCodes2FAStates.loading && (
              <LoadingIndicator />
            )}

            {(showNotif === true && isFading && newRecoveryCodes2FAStates.error) && (
              <FailureIndicator message={newRecoveryCodes2FAStates.error} setShowNotif={setshowNotif} />
            )}

            <div className="mt-4">
              Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.
            </div>

            <div className="p-4 mt-4 bg-gray-100 rounded-lg">
              Keep your recovery codes in a safe spot. These codes are the last resort for accessing your account in case you lose your password and second factors. If you cannot find these codes, you will lose access to your account.
            </div>

            <div className="px-8 py-4 my-4 border border-gray-200 rounded-lg">
              <ul className="grid grid-cols-2 gap-2 text-center list-disc">
                {recoveryCodes.map((code, index) => (
                  <li key={index} className="p-1 text-sm font-bold text-start">
                    {code}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center gap-4 mt-4">
                <button onClick={handleDownload} className="flex items-center px-5 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-download"></i>
                  <span>Download</span>
                </button>
                <button onClick={handlePrint} className="flex items-center px-5 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-print"></i>
                  <span>Print</span>
                </button>
                <button onClick={handleCopy} className="flex items-center px-5 py-2 border border-gray-200 rounded-lg text-devRegBlack">
                  <i className="mr-2 fas fa-copy"></i>
                  <span>Copy</span>
                </button>
              </div>
            </div>

            <div className="flex gap-4 mt-4 justify-end">

              <button
                disabled={newRecoveryCodes2FAStates.loading}
                onClick={handleGenerateNewRecoveryCodes}
                className={`${ResponsiveValues.buttonFit} mt-4 mb-2`}
              >
                Generate New Recovery Code
              </button>
            </div>
          </>
        }
      />

    </>
  );
}

export default ChangeRecoveryCodes;