import React, { useState } from "react";
import { RiDashboardLine } from "react-icons/ri";
import { BiMessageSquare } from "react-icons/bi";
import earningsIcon from "../../assets/Profile/earningsIcon.png";
import { IoPersonOutline } from "react-icons/io5";
import { TbMessage } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { logout } from "../../feature/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";

function SidebarDesktop({ markTheNav, activeTab, showSecondNav, overview }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLogout = async () => {
    await dispatch(logout());

    navigate("/");
  };
  const [isActive, setIsActive] = useState("");
  const listTab = [
    {
      title: "Overview",
      link: "?tab=overview",
      isActive: "overview",
    },
    {
      title: "Advertisement/Features",
      link: "?tab=advertisement-features",
      isActive: "advertisement-features",
    },
    {
      title: "Language",
      link: "?tab=language",

      isActive: "language",
    },
    {
      title: "Work History",
      link: "?tab=work-history",
      isActive: "work-history",
    },
    {
      title: "Portfolio",
      link: "?tab=portfolio",
      isActive: "portfolio",
    },
    {
      title: "Skills and Expertise",
      link: "?tab=skills-and-expertise",
      isActive: "skills-and-expertise",
    },
    {
      title: "Education / Certificate",
      link: "?tab=education-certificate",
      isActive: "education-certificate",
    },
    {
      title: "Honor and Awards",
      link: "?tab=honor-and-awards",
      isActive: "honor-and-awards",
    },
    {
      title: "Social Links",
      link: "?tab=social-links",
      isActive: "overview",
    },
    {
      title: "Preview Profile",
      link: "/dashboard/profile",
      isActive: "preview-profile",
    },
  ];
  return (
    <>
      <div
        className={`pb-14  ${
          showSecondNav ? "w-[490px]" : "w-[350px]"
        } bg-[#F2F7FF] h-auto   py-2 max-[900px]:hidden`}
      >
        <div className="title flex items-center gap-1  mb-2 text-[#1C3775] px-5 pb-2">
          <RiDashboardLine size={30} />
          <div className="font-bold text-[25px] ">Dashboard</div>
        </div>
        <div className="h-[2px] w-full bg-[#e4e7fb]"></div>
        {/* navs */}
        <div className="pl-1 dasboard-nav-cointainter ">
          {/* Messages */}
          <div
            className={`title flex items-center gap-1    ${
              activeTab === "messages"
                ? "text-[#1C3775] font-semibold"
                : "text-[#7E82A3]"
            } text-lg py-3 cursor-pointer px-5 `}
            onClick={() => {
              navigate("/dashboard/messages");
            }}
          >
            <BiMessageSquare color="#1C3775" />
            <div className="pl-1 ">Messages</div>
          </div>
          <div className=" text-[#7E82A3]">
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${
                markTheNav === 2
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=theregister");
              }}
            >
              <div className="">The Register</div>
              <div className="">99</div>
            </div>
            <div className="h-[2px] w-full bg-[#e4e7fb] "></div>
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${
                markTheNav === 3
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
              onClick={() => {
                // Navigate to the messages route with the admin tab parameter
                navigate("/dashboard/messages?tab=admin");
              }}
            >
              <div className="">Admin</div>
              <div className="">99</div>
            </div>
            <div className="h-[2px] w-full bg-[#e4e7fb]"></div>
            <div
              className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${
                markTheNav === 1
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
              onClick={() => {
                navigate("/dashboard/messages?tab=fullcheckid");
              }}
            >
              <div className="">FullIDCheck</div>
              <div className="">99</div>
            </div>
          </div>
          <div className="h-[2px] w-full bg-[#e4e7fb]"></div>

          {/* Earn and Refs */}
          <div
            className="title flex items-center gap-1  text-[#7E82A3] text-lg py-4  px-5 cursor-pointer"
            onClick={() => navigate("/dashboard/earnings-and-referrals")}
          >
            <img
              loading="lazy"
              src={earningsIcon}
              alt="earning logo"
              className={`w-4 h-4 text-[#1C3775] `}
            />
            <div
              className={`pl-1 ${
                activeTab === "earnings"
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }  `}
            >
              Earnings and Referrals
            </div>
          </div>

          <div className="h-[2px] w-full bg-[#e4e7fb]"></div>

          {/* Professional Profile */}
          <div className="title flex items-center gap-1  text-[#7E82A3] text-lg py-3 px-5 cursor-pointer">
            <IoPersonOutline color="#1C3775" />

            <div
              className={`pl-1  ${
                activeTab === "editProfile"
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              } `}
              onClick={() => navigate("/dashboard/profile")}
            >
              Professional Profile
            </div>
          </div>
          <div>
            <div
              className={`flex items-center  px-14 py-3 ${
                markTheNav === 4
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
              onClick={() => navigate("/dashboard/edit-profile")}
            >
              <div className="cursor-pointer">Edit Profile</div>
            </div>
            <div className="h-[2px] w-full bg-[#e4e7fb] "></div>
            <div
              className={`flex items-center  py-3 px-14 ${
                markTheNav === 5
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
              onClick={() => navigate("/dashboard/certificate")}
            >
              <div className="cursor-pointer">Cerificate</div>
            </div>
          </div>
          <div className="h-[2px] w-full bg-[#e4e7fb]"></div>

          {/* Forums */}
          <div className="title flex items-center gap-1  text-[#7E82A3] text-lg py-4 cursor-pointer px-5 ">
            <TbMessage color="#1C3775" />

            <div className="pl-1 cursor-pointer">Forum</div>
          </div>

          <div className="h-[2px] w-full bg-[#e4e7fb]"></div>

          {/* Account Settings */}
          <div
            className={`title flex items-center gap-1 text-lg py-3 cursor-pointer px-5 
            ${
              activeTab === "accountSettings"
                ? "text-[#1C3775] font-semibold"
                : "text-[#7E82A3]"
            }`}
            onClick={() => navigate("/dashboard/account-settings")}
          >
            <IoPersonOutline color="#1C3775" />

            <div className="pl-1 cursor-pointer ">Account Settings</div>
          </div>
          <div className="">
            <div
              className={`flex items-center  px-14 py-3 ${
                markTheNav === 6
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              }`}
            >
              <div
                className="cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Security Setting
              </div>
            </div>
            <div className="h-[2px] w-full bg-[#e4e7fb] "></div>
            <div
              className={`flex items-center  py-3 px-14 ${
                markTheNav === 7
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              } `}
            >
              <div
                className="cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings/help")}
              >
                Help
              </div>
            </div>
            <div className="h-[2px] w-full bg-[#e4e7fb] "></div>
            <div
              className={`flex items-center  py-3 px-14 ${
                markTheNav === 8
                  ? "text-[#1C3775] font-semibold"
                  : "text-[#7E82A3]"
              } `}
            >
              <div onClick={handleLogout} className="cursor-pointer">
                Log Out
              </div>
            </div>

            {auth.error && (
              <div className="flex items-center py-3 text-red-900 px-14">
                {auth.error}...
              </div>
            )}
          </div>
          <div className="h-[2px] w-full bg-[#e4e7fb]"></div>
        </div>
      </div>
      {showSecondNav && (
        <>
          <div className="w-[300px] bg-[#FAFAFA]  shadow-[rgba(0,0,15,0.1)_-10px_0px_20px_2px]">
            {listTab.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-start cursor-pointer ${
                  item.title === isActive ? "bg-white" : "bg-[#FAFAFA]"
                } `}
                onClick={() => {
                  navigate(item.link);
                  setIsActive(item.title);
                }}
              >
                <div
                  className={`h-12 w-[7px] ${
                    overview === item.isActive ? "bg-[#0082BA]" : "bg-[#FAFAFA]"
                  } `}
                ></div>
                <div className="pl-4 text-sm text-neutral-700">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      
      <Loader loadingText={"Logging out..."} isOpen={auth.logout.loading} />
    </>
  );
}

export default SidebarDesktop;
