import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkPassword,
  resendOTPViaEmail,
  resendOTPViaNewEmail,
  sendOTPViaEmail,
  sendOTPViaNewEmail,
  setChangePasswordError,
  setCheckPasswordSuccess,
  setResendOTPViaEmailSuccess,
  setResendOTPViaNewEmailSuccess,
  setSendOTPViaEmailSuccess,
  setSendOTPViaNewEmailSuccess,
  setVerifyOTPViaEmailSuccess,
  setVerifyOTPViaNewEmailSuccess,
  verifyOTPViaEmail,
  verifyOTPViaNewEmail,
} from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import { maskEmail } from "../../../config/common";
import { logout } from "../../../feature/authSlice";

import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { FaCircleCheck, FaRegCircleCheck } from "react-icons/fa6";

function ChangeEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState("verify-pass-email");
  const [showNotif, setShowNotif] = useState(true);

  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [newEmailOtp, setNewEmailOtp] = useState(new Array(6).fill(""));
  const [newEmail, setNewEmail] = useState("");
  const [maskedNewEmail, setMaskedNewEmail] = useState("");
  const [isFading, setIsFading] = useState(false);

  const otpRefs = useRef([]);
  const newEmailOtpRefs = useRef([]);
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const maskedEmail = userData?.email ? maskEmail(userData.email) : "";

  const auth = useSelector((state) => state.auth);
  const checkPasswordStates = useSelector((state) => state.settings.checkPassword) || { success: null, error: null, loading: false };
  const sendOTPViaEmailStates = useSelector((state) => state.settings.sendOTPViaEmail) || { success: null, error: null, loading: false };
  const resendOTPViaEmailStates = useSelector((state) => state.settings.resendOTPViaEmail) || { success: null, error: null, loading: false };
  const verifyOTPViaEmailStates = useSelector((state) => state.settings.verifyOTPViaEmail) || { success: null, error: null, loading: false };
  const sendOTPViaNewEmailStates = useSelector((state) => state.settings.sendOTPViaNewEmail) || { success: null, error: null, loading: false };
  const resendOTPViaNewEmailStates = useSelector((state) => state.settings.resendOTPViaNewEmail) || { success: null, error: null, loading: false };
  const verifyOTPViaNewEmailStates = useSelector((state) => state.settings.verifyOTPViaNewEmail) || { success: null, error: null, loading: false };

  useEffect(() => {

    if (checkPasswordStates.success === "Password is correct!") {
      dispatch(setCheckPasswordSuccess(null));

      setCurrentView("send-otp-to-email");
    }

    if (sendOTPViaEmailStates.success === "OTP sent") {
      setCurrentView("verify-otp-email");
      dispatch(setSendOTPViaEmailSuccess(null));
    }

    if (resendOTPViaEmailStates.success === "OTP sent") {
      setIsFading(true);
      const resendTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaEmailSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendTimer);
    }

    if (verifyOTPViaEmailStates.success === "OTP is valid!") {
      setCurrentView("input-new-email");
      dispatch(setVerifyOTPViaEmailSuccess(null));
    }

    // New Email
    if (sendOTPViaNewEmailStates.success === "OTP sent") {
      setCurrentView("verify-new-otp");

      dispatch(setSendOTPViaNewEmailSuccess(null));
    }

    if (resendOTPViaNewEmailStates.success === "OTP sent") {
      setIsFading(true);
      const resendNewTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaNewEmailSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendNewTimer);
    }

    if (verifyOTPViaNewEmailStates.success === "Email changed successfully!") {

      setCurrentView("success");

      dispatch(setVerifyOTPViaNewEmailSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }

    setMaskedNewEmail(maskEmail(newEmail));
  }, [
    checkPasswordStates.success,
    sendOTPViaEmailStates.success,
    resendOTPViaEmailStates.success,
    verifyOTPViaEmailStates.success,
    sendOTPViaNewEmailStates.success,
    resendOTPViaNewEmailStates.success,
    verifyOTPViaNewEmailStates.success,
    isFading,
    dispatch,
    newEmail,
  ]);

  const handleCheckPasswordClick = async (e) => {
    e.preventDefault();

    if (password.trim() === "") {
      dispatch(setChangePasswordError("Password cannot be blank"));
      return;
    }

    try {
      await dispatch(checkPassword({ id: userData.id, data: { password } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaEmail({ id: userData.id }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaNewEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaNewEmail({ id: userData.id, data: { new_email: newEmail, new_email_confirmation: newEmail } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaEmail({ id: userData.id }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaNewEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaNewEmail({ id: userData.id, data: { new_email: newEmail, new_email_confirmation: newEmail } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleOtpChange = (e, index, setOtpFunc, otpRefsArray, otpArray) => {
    const { value } = e.target;
    const newOtp = [...otpArray];

    if (e.key === "Backspace") {
      if (newOtp[index] === "") {
        if (index > 0) {
          otpRefsArray.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      if (index < 5) {
        otpRefsArray.current[index + 1].focus();
      }
    }

    setOtpFunc(newOtp);
  };

  const handleOtpPaste = (event, setOtpFunc, otpRefsArray, otpArray) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6).split("").filter(char => /^[0-9]$/.test(char));
    const newOtp = [...otpArray];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefsArray.current[index]) {
        otpRefsArray.current[index].value = char;
      }
    });

    setOtpFunc(newOtp);

    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefsArray.current[nextIndex].focus();
  };

  const handleVerifyOTP = (otpArray, verifyAction) => {
    const code = otpArray.join("");

    if (code.length !== 6 || isNaN(code)) return;

    dispatch(verifyAction({ id: userData.id, data: { otp: code, new_email: newEmail } }));
  };

  const handleSuccessContinueClicked = async () => {
    await dispatch(logout()).then((data) => {
      navigate('/login');
    });

  }

  const renderOtpInputFields = (otpArray, setOtpFunc, otpRefsArray) => (
    <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="shrink-0 p-2.5 border border-solid border-neutral-400 h-[58px] w-[45px] text-center"
          value={otpArray[i]}
          onChange={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onKeyDown={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onPaste={(e) => handleOtpPaste(e, setOtpFunc, otpRefsArray, otpArray)}
          ref={(el) => (otpRefsArray.current[i] = el)}
        />
      ))}
    </div>
  );


  useEffect(() => {
    setShowNotif(true)
  }, [
    checkPasswordStates.error,
    sendOTPViaEmailStates.error,
    verifyOTPViaEmailStates.error, resendOTPViaEmailStates.error, resendOTPViaEmailStates.success,
    verifyOTPViaNewEmailStates.error, resendOTPViaNewEmailStates.error, resendOTPViaNewEmailStates.success,
  ]);

  const renderView = () => {
    switch (currentView) {
      case "verify-pass-email":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && checkPasswordStates.error) && <FailureIndicator message={checkPasswordStates.error} setShowNotif={setShowNotif} />}
            {checkPasswordStates.loading && <LoadingIndicator />}

            <form onSubmit={handleCheckPasswordClick} className="w-full">
              <div className={`${TextStyling.inputLabel}`}>
                Enter Password
              </div>
              <input
                placeholder="Enter Password"
                className={`mt-1 ${InputStyling.text}`}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="on"
              />
              <div className="hidden md:flex justify-end gap-4 mt-6">
                <button
                  type="button"
                  className={`${ResponsiveValues.buttonFitSecondary}`}
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${ResponsiveValues.buttonFit}`}
                  disabled={checkPasswordStates.loading}
                >
                  Continue
                </button>
              </div>

              <div className="md:hidden flex flex-col justify-end mt-2">
                <button
                  type="submit"
                  className={`${ResponsiveValues.buttonFull}`}
                  disabled={checkPasswordStates.loading}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className={`${ResponsiveValues.buttonFullSecondary}`}
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Cancel
                </button>
              </div>
            </form>

          </div>
        );
      case "send-otp-to-email":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && sendOTPViaEmailStates.error) && (
              <FailureIndicator message={sendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            )}

            {(sendOTPViaEmailStates.loading) && (
              <LoadingIndicator />
            )}

            <div className="flex flex-col justify-center gap-2 md:gap-0">
              <div className={`${TextStyling.pageBody} text-center md:text-start`}> An OTP (One-Time Passcode) will be sent to email address <span className={`${TextStyling.pageBodyBody}`}>{maskedEmail}</span>.</div>
              <span className={`${TextStyling.pageBodyBody} text-center md:text-start`}>Would you like to Continue?</span>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Back
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={handleSendOtpViaEmailClick}
                disabled={sendOTPViaEmailStates.loading}

              >
                Yes, Send me OTP
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={handleSendOtpViaEmailClick}
                disabled={sendOTPViaEmailStates.loading}
              >
                Yes, Send me OTP
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Back

              </button>
            </div>

          </div>
        );
      case "verify-otp-email":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">
            {(showNotif === true && verifyOTPViaEmailStates.error) && (
              <FailureIndicator message={verifyOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            )}

            {(showNotif === true && resendOTPViaEmailStates.error)
              && <FailureIndicator message={resendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaEmailStates.error)
              && <FailureIndicator message={resendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(resendOTPViaEmailStates.success) && isFading
              && <SuccessIndicator message='OTP resent successfully' setShowNotif={setShowNotif} />
            }

            {(resendOTPViaEmailStates.loading || verifyOTPViaEmailStates.loading)
              && <LoadingIndicator />
            }


            <div className={`${TextStyling.pageBody}`}>
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className={`${TextStyling.pageBodyBold}`}>{maskedEmail}.</span>
            </div>

            {renderOtpInputFields(otp, setOtp, otpRefs)}

            <div className={`mt-6 ${TextStyling.pageBody} flex justify-between md:justify-start gap-2`}>
              <span>Did not receive the code?</span>
              <div>
                <span
                  id="resend-otp-link"
                  onClick={handleResendOtpViaEmailClick}
                  className={TextStyling.textLink}
                >
                  Resend
                </span> or{" "}
              </div>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={() => handleVerifyOTP(otp, verifyOTPViaEmail)}
                disabled={verifyOTPViaEmailStates.loading}
              >
                Apply
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={() => handleVerifyOTP(otp, verifyOTPViaEmail)}
                disabled={verifyOTPViaEmailStates.loading}
              >
                Apply
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "input-new-email":
        return (
          <div className="flex flex-col items-start mt-4 md:mt-8  w-full">
            {(showNotif === true && sendOTPViaNewEmailStates.error) && <FailureIndicator message={sendOTPViaNewEmailStates.error} setShowNotif={setShowNotif} />}
            {sendOTPViaNewEmailStates.loading && <LoadingIndicator />}

            <div className={`${TextStyling.inputLabel}`}>
              Enter new Email Address
            </div>

            <input
              placeholder="New Email"
              type="email"
              className={`mt-1 ${InputStyling.text}`}
              // className="justify-center items-start px-4 py-3 max-w-full text-base leading-5 rounded-xl border border-solid border-neutral-400 text-devRegBlack w-[346px] max-md:px-5 max-md:mt-10"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={handleSendOtpViaNewEmailClick}
                disabled={sendOTPViaNewEmailStates.loading}
              >
                Continue
              </button>
            </div>

            <div className="md:hidden flex flex-col mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={handleSendOtpViaNewEmailClick}
                disabled={sendOTPViaNewEmailStates.loading}
              >
                Continue
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "verify-new-otp":
        return (
          <div className="flex flex-col items-start pt-4 md:pt-8 w-full">

            {(showNotif === true && verifyOTPViaNewEmailStates.error)
              && <FailureIndicator message={verifyOTPViaNewEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaNewEmailStates.error)
              && <FailureIndicator message={resendOTPViaNewEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(showNotif === true && resendOTPViaEmailStates.error)
              && <FailureIndicator message={resendOTPViaEmailStates.error} setShowNotif={setShowNotif} />
            }

            {(resendOTPViaNewEmailStates.success || resendOTPViaEmailStates.success) && isFading
              && <SuccessIndicator message='OTP resent successfully' setShowNotif={setShowNotif} />
            }

            {(resendOTPViaNewEmailStates.loading || resendOTPViaEmailStates.loading || verifyOTPViaNewEmailStates.loading)
              && <LoadingIndicator />
            }

            <div className={`${TextStyling.pageBody}`}>
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className={`${TextStyling.pageBodyBold}`}>{maskedNewEmail}.</span>
            </div>

            {renderOtpInputFields(newEmailOtp, setNewEmailOtp, newEmailOtpRefs)}

            <div className={`mt-6 ${TextStyling.pageBody} flex justify-between md:justify-start gap-2`}>
              <span>Did not receive the code?</span>
              <div>
                <span
                  id="resend-otp-link"
                  onClick={handleResendOtpViaNewEmailClick}
                  className={TextStyling.textLink}
                >
                  Resend
                </span>
              </div>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="button"
                className={`${ResponsiveValues.buttonFitSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={() => handleVerifyOTP(newEmailOtp, verifyOTPViaNewEmail)}
                disabled={verifyOTPViaNewEmailStates.loading}
              >
                Apply
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={() => handleVerifyOTP(newEmailOtp, verifyOTPViaNewEmail)}
                disabled={verifyOTPViaNewEmailStates.loading}
              >
                Apply
              </button>
              <button
                type="button"
                className={`${ResponsiveValues.buttonFullSecondary}`}
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
            </div>

          </div>
        );
      case "success":
        return (
          <div className="flex flex-col items-start pt-4  w-full">
            <div className='flex flex-col text-center md:text-start w-full'>
              <div className="flex flex-row gap-2 items-center justify-center md:justify-start">
                <FaCircleCheck size={16} className={`${ResponsiveValues.forDesktopScreen} text-[#0082BA]`} />
                <FaRegCircleCheck size={16} className={`${ResponsiveValues.forMobileScreen} text-green-500`} />
                <span className={`${TextStyling.pageHeader2} w-fit`}>Email Address Updated</span>
              </div>
              <span className={`${TextStyling.pageBody} justify-center md:justify-start`}>You had successfully changed your Email Address</span>
            </div>

            <div className="hidden md:flex justify-end gap-4 mt-6 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFit}`}
                onClick={handleSuccessContinueClicked}
              >
                Continue
              </button>
            </div>

            <div className="md:hidden flex flex-col justify-end mt-2 w-full">
              <button
                type="submit"
                className={`${ResponsiveValues.buttonFull}`}
                onClick={handleSuccessContinueClicked}
              >
                Continue
              </button>
            </div>

          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`p-2 min-h-[89vh] ${ResponsiveValues.forMobileScreen}`}>

        {/* title */}
        <div className=" flex flex-col justify-between items-start">
          <PageTitle title='Change Email'
            hasSpacing='false'
            customTextStyleDesktop={TextStyling.pageHeader1}
            customTextStyleMobile={TextStyling.pageBodyBold} />
        </div>

        {renderView()}

      </div>

      <div className={`p-2 hidden md:flex justify-center`}>
        <div className={`min-w-[90vh] ${ResponsiveValues.cardSectionStyle} shadow-md`}>
          <div className="px-4 pb-1">

            {/* title */}
            <div className=" flex flex-col justify-between items-start pb-4 md:pb-2">
              <PageTitle title='Change Email'
                hasSpacing='false'
                customTextStyleDesktop={TextStyling.pageHeader1}
                customTextStyleMobile={TextStyling.pageBodyBold} />
              {/* <PiCaretUpBold size={25} /> */}
            </div>
            <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1 p-0 m-0 w-full`} />

            {renderView()}
          </div>
        </div>
      </div>
    </>

  );
}

export default ChangeEmail;
