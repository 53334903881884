import React from "react";
import ProfilePic from "../../assets/Profile/ProfilePic.jpg";
import useCertificates from "../../feature/dashboard/professionalProfile/certificates/useCertificates";
import PageTitle from "../../components/PageTitle";
import FailureIndicator from "../../components/FailureIndicator";
import LoadingIndicator from "../../components/LoadingIndicator";
import ResponsiveValues from "../../styling/ResponsiveValues";


function Certificate() {
  const { handlesDownloadButton, error, loading, userData, showNotif, setShowNotif, } = useCertificates();

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-1 max-md:max-w-full">
        <PageTitle title='Certificate' description='Download Certificate of Verification' />

        <div className="flex justify-end h-4">
          {showNotif && (
            <>
              {!loading && error && <FailureIndicator message={error} setShowNotif={setShowNotif} />}
            </>
          )}
        </div>

        <div className="flex gap-5 justify-center pr-3.5 mt-16 max-md:mt-10">
          <img
            alt="img"
            loading="lazy"
            srcSet={userData.profile_photo_url || ProfilePic}
            className="shrink-0 max-w-full rounded-full border-white border-solid aspect-square border-[3px] w-[100px]"
          />
          <img
            alt="img"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fbfdcb20d374b08cb7f8143365274d58abbb40898a0df8ffc7b6f58e65f70159?"
            className="shrink-0 aspect-[0.93] w-[93px]"
          />
        </div>

        <div className="mt-6 flex justify-center text-base tracking-tight leading-4 text-neutral-600">
          Download Certificate of Verification
        </div>

        <button onClick={handlesDownloadButton}
          className={`
                ${ResponsiveValues.buttonFit}
                mt-4 justify-center self-center
              `}>
          Download
        </button>

        {loading && (
          <LoadingIndicator loading={loading} />
        )}
      </div>
    </div>
  );
}

export default Certificate;
