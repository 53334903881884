import React, { useRef } from "react";
import useOverview from "../../../feature/dashboard/professionalProfile/profileEdit/overview/useOverview";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import ResponsiveValues from "../../../styling/ResponsiveValues";
import InputStyling from "../../../styling/InputStyling";
import TextStyling from "../../../styling/TextStyling";
import PageTitle from "../../../components/PageTitle";

function Overview() {

  const defaultProfilePhoto = "https://cdn.builder.io/api/v1/image/assets/TEMP/dbdb59e529344fd004b928608480af2aa91b9d0ae6b51eddb7d4695a84fa07b0?";

  const {
    success, loading, error, errorProfile, updateSuccess,
    image,
    imageProfile,
    currentProfileCover,
    currentProfilePhoto,
    akai, setAkai,
    about, setAbout,
    yearsOfExperience, setYearsOfExperience,
    noProjectsCompleted, setNoProjectsCompleted,
    city, setCity,
    state, setState,
    country, setCountry,
    postalCode, setPostalCode,
    profession, setProfession,
    salaryRate, setSalaryRate,
    isAvailableToWork, setIsAvailableToWork,
    availability, setAvailability,
    workSetupPreferences, setWorkSetupPreferences,
    hasTradeLicense, setHasTradeLicense, tradeLicenseNumber, setTradeLicenseNumber,
    handleUpdate,
    handleImageUpload,
    handleImageUploadProfile,
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit,
    validateAkai, validateAbout, validateYearsOfExperience, validateNoProjectsCompleted, validateCity,
    validateState, validateCountry, validatePostalCode, validateProfession, validateSalaryRate,
    validateIsAvailableToWork, validateAvailability, validateWorkSetupPreferences,
    validateHasTradeNumber, validateTradeLicenseNumber,
    countries, cities, states, populateCities, populateStates,
  } = useOverview();


  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <div className="flex flex-col grow text-base md:mb-8 max-md:max-w-full">
        <PageTitle title='Edit Profile' description='Make your profile your own! Update your details to reflect who you are.' />

        <div className="flex justify-end h-4">
          {showNotif && (
            <>
              {!loading && (updateSuccess || success) && <SuccessIndicator message="Update Success" setShowNotif={setShowNotif} />}
              {!loading && error && <FailureIndicator message={error} setShowNotif={setShowNotif} />}
            </>
          )}
        </div>

        {/* Profile picture  */}
        <div className="order-1 lg:order-2">
          <div className="flex gap-4 self-start mt-4 text-base leading-4  relative">
            <img
              loading="lazy"
              src={imageProfile ? imageProfile : (currentProfilePhoto ? currentProfilePhoto : defaultProfilePhoto)}
              className="shrink-0 max-w-full aspect-square w-[120px] rounded-full"
            />
            <div className="flex flex-col my-auto">
              <div className={TextStyling.inputLabel}>
                Profile picture
              </div>
              <div className="mt-2  text-sm tracking-tight text-zinc-400">
                Size: 320px x 320px
              </div>
              <div className="text-sm tracking-tight text-zinc-400">
                PNG, JPG up to 2MB
              </div>
              <div className="mt-2 font-bold tracking-tight text-sky-600">
                Upload
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUploadProfile}
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            />
          </div>
          {(errorProfile) && <div className="text-red-500 flex flex-col my-auto text-sm12">{errorProfile}</div>}
        </div>

        {/* profile banner  */}
        <div className={`${!image && !currentProfileCover ? 'block' : 'hidden'} container order-2 lg:order-1 bg-gray-200 max-w-[1000px] max-h-[200px] border-dashed border-neutral-300  border-2 py-7 rounded-lg mt-2 relative hover:opacity-80`}>
          <div className={`py-4 text-neutral-400`}>
            <div className="text-center flex flex-col justify-center items-center text-lg">
              <div className={TextStyling.inputLabel}>
                Profile Banner
              </div>
              <div className="text-sm15">Add banner</div>
              <div className="text-xs leading-4">
                {`(Profile Cover / Ad Space (1000px x 200px) and MB up to 2MB`}
              </div>
              <div className="mt-2  text-sm15 font-bold tracking-tight text-devRegBlue">
                Upload
              </div>
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImageUpload}
            className={`opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer`}
          />
        </div>
        <img
          src={image ? image : (currentProfileCover ? currentProfileCover : defaultProfilePhoto)}
          onClick={handleClick} alt="Uploaded" className={`${(image || currentProfileCover) ? 'block' : 'hidden'} max-w-[1000px] max-h-[200px] py-7 cursor-pointer`} />
        {(error) && <div className="text-red-500 mb-4 text-sm12">{error}</div>}

        {/* profile details  */}
        <div className="flex order-3 flex-col grow text-base max-md:mt-8 max-md:max-w-full">
          <div className={`mt-8 ${TextStyling.inputLabel}`}>
            Aka/Alias Name (Hidden)
          </div>
          <input
            placeholder="Aka/Alias"
            className={`mt-1 ${InputStyling.text}`}
            autoComplete="name"
            value={akai}
            onChange={(e) => {
              setAkai(e.target.value);
              validateAkai(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.akai.errorMessage} />

          <div className={`${TextStyling.inputLabel}`}>
            About Me
          </div>
          <textarea
            placeholder="About Me"
            // className=" p-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
            className={`mt-1 ${InputStyling.text}`}
            autoComplete="on"
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
              validateAbout(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.about.errorMessage} />

          <div className="flex flex-wrap gap-4 content-start mt-4">
            <div className="flex flex-col flex-1 justify-center">
              <div className={`${TextStyling.inputLabel}`}>
                Years of Experience
              </div>
              <input
                placeholder="Years of Experience"
                className={`mt-1 ${InputStyling.text}`}
                value={yearsOfExperience}
                onChange={(event) => {
                  setYearsOfExperience(event.target.value);
                  validateYearsOfExperience(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.yearsOfExperience.errorMessage} />
            </div>
            <div className="flex flex-col flex-1 justify-center">
              <div className={`${TextStyling.inputLabel}`}>
                No of Project Completed
              </div>
              <input
                placeholder="No of Project Completed"
                className={`mt-1 ${InputStyling.text}`}
                value={noProjectsCompleted}
                onChange={(event) => {
                  setNoProjectsCompleted(event.target.value);
                  validateNoProjectsCompleted(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.noProjectsCompleted.errorMessage} />
            </div>
          </div>
          <div className="flex gap-2 mt-4 flex-wrap md:flex-nowrap ">
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className={`${TextStyling.inputLabel}`}>
                City
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${city === "" ? "text-neutral-400" : "text-black"}`}
                id="city"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                  validateCity(event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  City
                </option>
                {cities?.map((city) => (
                  <option key={city.isoCode} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.city.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className={`${TextStyling.inputLabel}`}>
                State
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${state === "" ? "text-neutral-400" : "text-black"}`}
                id="state"
                value={state}
                onChange={(event) => {
                  setState(event.target.value);
                  validateState(event.target.value);
                  populateCities(country, event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  State
                </option>
                {states?.map((state) => (
                  <option key={state.isoCode} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.state.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className={`${TextStyling.inputLabel}`}>
                Country
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${country === "" ? "text-neutral-400" : "text-black"}`}
                id="country"
                value={country}
                onChange={(event) => {
                  setCountry(event.target.value);
                  validateCountry(event.target.value);
                  populateStates(event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  Country
                </option>
                {countries?.map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.country.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className={`${TextStyling.inputLabel}`}>
                Postal Code
              </div>
              <input
                placeholder="Postal Code"
                className={`mt-1 ${InputStyling.text}`}
                maxLength="16"
                autoComplete="postal-code"
                value={postalCode}
                onChange={(event) => {
                  setPostalCode(event.target.value);
                  validatePostalCode(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.postalCode.errorMessage} />
            </div>
          </div>

          <div className="flex gap-4 mt-4 max-md:flex-wrap">
            <div className="flex flex-col flex-1 whitespace-nowrap">
              <div className={`${TextStyling.inputLabel}`}>
                Profession
              </div>
              <input
                placeholder="Profession"
                className={`mt-1 ${InputStyling.text}`}
                autoComplete="organization-title"
                value={profession}
                onChange={(event) => {
                  setProfession(event.target.value);
                  validateProfession(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.profession.errorMessage} />
            </div>
            <div className="flex flex-col flex-1">
              <div className={`${TextStyling.inputLabel}`}>
                Hourly rate
              </div>
              <input
                placeholder="Hourly rate"
                className={`mt-1 ${InputStyling.text}`}
                autoComplete="transaction-amount"
                value={salaryRate}
                onChange={(event) => {
                  setSalaryRate(event.target.value);
                  validateSalaryRate(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.salaryRate.errorMessage} />
            </div>
          </div>

          <div className="flex gap-4 my-4 flex-col md:flex-row w-full">
            <div className="flex flex-col flex-1">
              <div className={`${TextStyling.inputLabel}`}>
                Available to Work
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${isAvailableToWork === "" ? "text-neutral-300" : "text-black"}`}
                value={isAvailableToWork === null ? "" : isAvailableToWork}
                onChange={(event) => {
                  setIsAvailableToWork(event.target.value);
                  validateIsAvailableToWork(event.target.value);
                }}
              >
                <option disabled value="" readOnly>
                  Available to Work
                </option>
                <option value="1" readOnly>Yes</option>
                <option value="0" readOnly>No</option>
              </select>
            </div>
            <div className="flex flex-col flex-1 mt-4 md:mt-0 ">
              <div className={`${TextStyling.inputLabel}`}>
                Availability
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${availability === "" ? "text-neutral-300" : "text-black"}`}
                value={availability === null ? "" : availability}
                onChange={(event) => {
                  setAvailability(event.target.value);
                  validateAvailability(event.target.value);
                }}
              >
                <option disabled value="">
                  Availability
                </option>

                <option value="Part Time" readOnly>Fulltime</option>
                <option value="Part-time" readOnly>Part-time</option>
                <option value="Project-based" readOnly>Project-based</option>
                <option value="Freelancer" readOnly>Freelancer</option>
                <option value="Contractor" readOnly>Contractor</option>
              </select>
            </div>
            <div className="flex flex-col flex-1 mt-4 md:mt-0">
              <div className={`${TextStyling.inputLabel}`}>
                Work Arrangement
              </div>
              <select
                // className={`max-h-[45px] mt-3 flex flex-1 px-2 py-2 whitespace-nowrap rounded-lg border border-solid border-neutral-300 ${workSetupPreferences === ""
                //   ? "text-neutral-300"
                //   : "text-black"
                //   }`}
                className={`mt-1 ${InputStyling.select} ${workSetupPreferences === "" ? "text-neutral-300" : "text-black"}`}

                value={workSetupPreferences === null ? "" : workSetupPreferences}
                onChange={(event) => {
                  setWorkSetupPreferences(event.target.value);
                  validateWorkSetupPreferences(event.target.value);
                }}
              >
                <option disabled value="">
                  Work Arrangement
                </option>

                <option value="Work From Home" readOnly>Work From Home</option>
                <option value="Field Work" readOnly>Field Work</option>
                <option value="Hybrid" readOnly>Hybrid</option>
                <option value="Office Work" readOnly>Office Work</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 mt-4 ">
            <div className="flex flex-col flex-1 whitespace-nowrap">
              <div className={`${TextStyling.inputLabel}`}>
                Trade/Registration License
              </div>
              <select
                className={`mt-1 ${InputStyling.select} ${hasTradeLicense === "" ? "text-neutral-300" : "text-black"}`}
                value={hasTradeLicense === null ? "" : hasTradeLicense}
                onChange={(event) => {
                  setHasTradeLicense(event.target.value);
                  if (hasTradeLicense === '0' || hasTradeLicense === 0) {
                    setTradeLicenseNumber('');
                  }
                  // validateHasTradeNumber(event.target.value);
                }}
              >
                <option disabled value="" readOnly>
                  Do you have a trade/registration license
                </option>
                <option value="1" readOnly>Yes</option>
                <option value="0" readOnly>No</option>
              </select>
            </div>
            <div className="flex flex-col flex-1 mt-4 md:mt-0">
              <div className={`${TextStyling.inputLabel}`}>
                Trade/Registration License Number
              </div>
              <input
                placeholder="License Number"
                className={`mt-1 ${InputStyling.text}`}
                autoComplete="number"
                value={hasTradeLicense === '0' || hasTradeLicense === 0 ? '' : tradeLicenseNumber}
                disabled={hasTradeLicense === '1' || hasTradeLicense === 1 ? false : true}
                onChange={(event) => {
                  if (hasTradeLicense === '0' || hasTradeLicense === 0) {
                    setTradeLicenseNumber('');
                  } else {
                    setTradeLicenseNumber(event.target.value);
                  }
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.tradeLicenseNumber.errorMessage} />
            </div>
          </div>

          <button onClick={handleUpdate}
            className={`${(error || errorProfile || loading || isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
              `}>
            Update
          </button>

          <button onClick={handleUpdate}
            className={`${(error || errorProfile || loading || isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
            Update
          </button>

          {loading && (<LoadingIndicator loading={loading} styling="text-base" />)}
        </div>
      </div>
    </>
  );
}

export default Overview;
