import { authAxios } from "../../../../config/common";

// GET | HEAD   api / user / { id } / admin - messages
// POST         api / user / { id } / admin - messages
// DELETE       api / user / { id } / admin - messages
// POST         api / user / { id } / admin - messages / mark - as - read - unread
// GET | HEAD   api / user / { id } / admin - messages / { messageId }
// DELETE       api / user / { id } / admin - messages / { messageId }
// GET | HEAD   api / user / { id } / admin - messages / { messageId } / replies
// POST         api / user / { id } / admin - messages / { messageId } / replies
// GET | HEAD   api / user / { id } / admin - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / admin - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / admin - sent--messages
// GET | HEAD   api / user / { id } / admin - sent - messages
// POST         api / user / { id } / admin - sent - messages
// POST         api / user / { id } / admin - sent - messages / mark - as - read - unread
// GET | HEAD   api / user / { id } / admin - sent - messages / { messageId }
// DELETE       api / user / { id } / admin - sent - messages / { messageId }
// GET | HEAD   api / user / { id } / admin - sent - messages / { messageId } / replies
// POST         api / user / { id } / admin - sent - messages / { messageId } / replies
// GET | HEAD   api / user / { id } / admin - sent - messages / { messageId } / replies / { replyId }
// DELETE       api / user / { id } / admin - sent - messages / { messageId } / replies / { replyId }


const get = async (id) => {
  try {
    // const response = await authAxios().get(`/api/user/${id}/admin-messages`);
    const response = await authAxios().get(`/api/messages/received`, {
      params: {
        platform: 'admin'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const getConversation = async (conversationId) => {
  try {
    const response = await authAxios().get(`/api/conversations/${conversationId}`, {
      params: {
        platform: 'admin'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const postMarkAsReadUnread = async (data) => {
  try {
    const response = await authAxios().post(`/api/messages/received/mark-read-unread`, data, {
      params: {
        platform: 'admin'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const postReply = async (id, messageId, data) => {
  try {
    const response = await authAxios().post(`/api/messages`, data, {
      params: {
        platform: 'admin'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

const del = async (id, messageId) => {
  try {
    const response = await authAxios().delete(`/api/messages/received/${messageId}`, {
      params: {
        platform: 'admin'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const delMultiple = async (id, data) => {
  try {
    // const response = await authAxios().delete(`/api/user/${id}/admin-messages`, data);
    const response = await authAxios().delete(`/api/messages/received`, data, {
      params: {
        platform: 'admin'
      }
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const adminMessagesApi = {
  get, getConversation,
  postMarkAsReadUnread, postReply,
  del, delMultiple,
};

export default adminMessagesApi;