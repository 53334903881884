import { authAxios } from '../../../../config/common';

// Helper function to create FormData from an object
const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
};

// Helper function to handle Axios requests
const handleAxiosRequest = async (axiosInstance, method, url, data = null) => {
  try {
    const params = data ? toFormData(data) : null;
    const response = await axiosInstance[method](url, params);
    return JSON.parse(response.data);
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Enable 2FA
const enable2FA = async () => handleAxiosRequest(authAxios(), 'post', 'api/2fa/enable');

// Disable 2FA
const disable2FA = async () => handleAxiosRequest(authAxios(), 'post', 'api/2fa/disable');

// Verify OTP from Authenticator APP
const verify2FA = async (data) => handleAxiosRequest(authAxios(), 'post', 'api/otp/verify', data);

const verifyEnabling2FA = async (data) => handleAxiosRequest(authAxios(), 'post', 'api/2fa/recovery-codes', data);

// Get Recovery Codes
const recoveryCodes2FA = async () => handleAxiosRequest(authAxios(), 'get', 'api/2fa/recovery-codes');

// Generate new Recovery Codes
const newRecoveryCodes2FA = async () => handleAxiosRequest(authAxios(), 'post', 'api/2fa/recovery-codes');

const google2faApi = {
  enable2FA,
  disable2FA,
  verify2FA,
  verifyEnabling2FA,
  recoveryCodes2FA,
  newRecoveryCodes2FA,
};

export default google2faApi;
