import { useSelector } from 'react-redux';

const useNavbar = () => {
  const userData = useSelector(state => state.auth.user);
  const credit = null;
  const currencyName = null;

  return {
    userData,
    credit,
    currencyName,
  }

}

export default useNavbar;
