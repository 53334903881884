import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faceRecognitionStatus, qrCode as getQR, personalInfo, setFaceRecognitionStatusError, setFaceRecognitionStatusLoading } from "../../feature/authSlice";
// import { websocket_url } from "../../config/common";
import { Navigate, useNavigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TopBarLogo from "../Login/TopBarLogo";
import TextStyling from "../../styling/TextStyling";

function FacialRecognition({ qrCode, handleBackBtn }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { shouldRedirect, redirectTo } = useInspectRoute('/register/face-recognition');
  const [gotoNext, setGotoNext] = useState(null);
  const [isFading, setIsFading] = useState(false);

  const [qr, setQR] = useState(null);
  const handleRequestQR = async () => {

    const { id } = auth?.user || { id: 0 };
    await dispatch(getQR({ id, data: { used_for: 'APPLICANT' } })).then(data => {
      let res = null;
      try {
        res = JSON.parse(data.payload);
      } catch (err) {

      }
      if (!res?.message) {
        const qrCodeData = data.payload;
        setQR(qrCodeData);
      } else {
        const user = res.data;
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          setGotoNext('/register/schedule-interview');
        }
      }
    });

  }

  const handleContinueClick = async () => {
    dispatch(setFaceRecognitionStatusLoading(true));
    const stat = await dispatch(faceRecognitionStatus({})).then((data) => {
      if (data?.payload?.message === 'Face Recognition Done!') {
        const user = data?.payload?.data;
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        }
        setGotoNext('/register/schedule-interview');
      }
    })
  }

  useEffect(() => {
    if (!qrCode) {
      handleRequestQR()
    };
  }, []);

  useEffect(() => {
    if (auth.faceRecognitionStatus.error) {

      setIsFading(true);

      const timeOut = setTimeout(() => {
        setIsFading(false);
        setFaceRecognitionStatusError(null);

        clearTimeout(timeOut);
      }, 7000);

      return () => {
        clearTimeout(timeOut);
      }
    }
  }, [auth.faceRecognitionStatus.loading, auth.faceRecognitionStatus.error]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />
  }

  if (gotoNext) {
    return <Navigate to={gotoNext} />
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">
        <div className={`min-w-[50vh] max-w-[5vh] max-h-[70vh]
            ${ResponsiveValues.cardScreenStyleNoSpacing}
            flex flex-col grow`}>

          <TopBarLogo
            children={<div
              onClick={() => {

                const uData = JSON.parse(localStorage.getItem('user'));
                if (uData) {
                  uData.is_document_uploaded = 0;
                  localStorage.setItem('user', JSON.stringify(uData));
                }
                navigate('/register/upload-documents');
              }}
              className={`flex flex-row items-center gap-2`}
            >
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/427a6dfb50348309db0c360c9956276b741614a052067d396517a639612574c7?apiKey=3ae96302e69649f5be368f89230b0596&"
                className="w-2 fill-sky-600 m-auto "
              />
              Back
            </div>}
            logoImg={"https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"}
          />

          <div className="p-6 flex flex-col gap-6" >

            <div className={`${TextStyling.pageHeader1} flex flex-row justify-center`}>
              {/* Facial Recognition */} Scan QR Code
            </div>

            <div className="flex flex-row items-center self-center justify-center">
              <div className="h-[265px] w-[265px] border border-8 border-[#2AD2C9]">
                {qrCode && (
                  <iframe
                    className="h-full w-full"
                    title="QR Code"
                    src={
                      "data:image/svg+xml;charset=utf-8," +
                      encodeURIComponent(qrCode)
                    }
                  />
                )}
                {qr && (

                  <iframe
                    className="h-full w-full"
                    title="QR Code"
                    src={
                      "data:image/svg+xml;charset=utf-8," +
                      encodeURIComponent(qr)
                    }
                  />
                )}
              </div>
            </div>

            <div className=" flex flex-row justify-center px-2.5 py-0.5 mt-1 text-base leading-5 text-center text-stone-500">
              {/* Scan this code using your mobile phone to start the facial
              scanning feature */}
              Scan this code using your authenticator app to get the authentication code
            </div>

            {/* <div className="flex flex-col justify-center items-center self-center px-16 mt-5 max-w-full w-[544px] max-md:px-5 max-md:mt-10">
              <div className="flex flex-col max-w-full w-80">
                <div className="flex items-center self-center justify-center">
                  {qrCode && (
                    <div className="ml-10 h-[250px]">
                      <iframe
                        className="h-full "
                        title="QR Code"
                        src={
                          "data:image/svg+xml;charset=utf-8," +
                          encodeURIComponent(qrCode)
                        }
                      />
                    </div>
                  )}
                  {qr && (
                    <div className="ml-10 h-[250px]">
                      <iframe
                        className="h-full "
                        title="QR Code"
                        src={
                          "data:image/svg+xml;charset=utf-8," +
                          encodeURIComponent(qr)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="justify-center px-2.5 py-0.5 mt-1 text-base leading-5 text-center text-stone-500">
                  Scan this code using your mobile phone to start the facial
                  scanning feature
                </div>
                <div className="flex flex-col items-center justify-center">
                  <button
                    onClick={handleContinueClick}
                    className="cursor-pointer text-center justify-center items-center self-center px-9 py-3 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5"
                  >
                    Continue
                  </button>
                  {auth.faceRecognitionStatus.loading && <p className="my-2 text-sm text-center text-blue-600">Checking Face Verification Status...</p>}
                  {auth.faceRecognitionStatus.error && isFading && <p className="my-2 text-sm text-center text-orange-600">{auth.faceRecognitionStatus.error}</p>}
                </div>
              </div>
            </div> */}

          </div>

        </div>
      </div>
    </>
  );
}

export default FacialRecognition;
