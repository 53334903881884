import { useNavigate } from "react-router-dom";

const TopBarLogo = ({ logoImg, children = null }) => {
  const navigate = useNavigate();



  return (
    <div className={`border-b`}>
      <div className={`p-6 flex flex-row justify-between items-center border-b`}>
        {children === null ?
          <div
            className="flex flex-row justify-start items-center gap-2 cursor-pointer text-sky-600"
            onClick={() => {
              navigate('/');
              window.scrollTo(0, 0);
            }}
          >
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/427a6dfb50348309db0c360c9956276b741614a052067d396517a639612574c7?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="w-2 fill-sky-600 m-auto "
            />
            <div className="flex-1">Back to Homepage</div>
          </div>
          : <div className="flex flex-row justify-start items-center gap-2 cursor-pointer text-sky-600">
            {children}
          </div>
        }

        <img
          onClick={() => navigate("/")}
          alt="img"
          loading="lazy"
          src={logoImg}
          className="h-10"
        />
      </div>
    </div>
  )
}

export default TopBarLogo;