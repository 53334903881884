import moment from "moment";

const dateFormats = {
  server: 'DD/MM/YYYY HH:mm:ss A',
  newServer: 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  input: 'YYYY-MM-DD',
};

const isDatetimeChecker = (str) => {
  if (moment(str, dateFormats.server, true).isValid()) {
    return true;
  } else if (moment(str, dateFormats.input, true).isValid()) {
    return false;
  }
  return null;
}

const isDatetimeCheckerNewApi = (str) => {
  if (moment(str, dateFormats.newServer, true).isValid()) {
    return true;
  } else if (moment(str, dateFormats.input, true).isValid()) {
    return false;
  }

  return null;
}

const datetimeToInputDate = (datetime) => {
  const parsedDate = moment(datetime, dateFormats.server).format(dateFormats.input);
  return parsedDate;
}

const datetimeToInputDateNewApi = (datetime) => {
  const parsedDate = moment(datetime, dateFormats.newServer).format(dateFormats.input);
  return parsedDate;
}

const dataHasPassed = (date) => {
  return moment(date).isSameOrBefore(moment(), date);
}

const dateHelper = {
  dateFormats,
  isDatetimeChecker, isDatetimeCheckerNewApi,
  datetimeToInputDate, datetimeToInputDateNewApi,
  dataHasPassed,
};

export default dateHelper;