import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialLinksAction as action } from './socialLinksSlice';
import { getSocialLinks, addSocialLinks, updateSocialLinks, removeSocialLinks } from './socialLinksSlice';
import useSocialLinksValidation from "./useSocialLinksValidation";

const useSocialLinks = () => {
  const dispatch = useDispatch();
  const { success, error, loading, values: serverSocialLinks } = useSelector(state => state.socialLinks);

  const { validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateInstagram,
    validateFacebook,
    validateYoutube,
    validateLinkedIn,
    validateDiscord,
    validateTiktok,
  } = useSocialLinksValidation();

  const [socials, setSocials] = useState([
    {
      social: SocialsList.instagram,
      link: '',
    },
    {
      social: SocialsList.facebook,
      link: '',
    },
    {
      social: SocialsList.youtube,
      link: '',
    },
    {
      social: SocialsList.linkedIn,
      link: '',
    },
    {
      social: SocialsList.discord,
      link: '',
    },
    {
      social: SocialsList.tiktok,
      link: '',
    }
  ]);

  useEffect(() => {
    getServerSocials();
  }, []);

  useEffect(() => {
    refreshValues();
  }, [serverSocialLinks]);

  const getServerSocials = async () => {
    try {
      await dispatch(getSocialLinks({}));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const refreshValues = async () => {
    serverSocialLinks?.forEach((item) => {
      setSocialsList(item?.social, item?.link);
    });
  }

  const getServerSocialsFromList = (socialName) => {
    return serverSocialLinks?.find(item =>
      item?.social === socialName
    );
  }

  const getSocialsList = (socialName) => {
    return socials.find(item =>
      item?.social === socialName
    );
  }

  const setSocialsList = (socialName, newLink) => {
    setSocials(prevSocials =>
      prevSocials.map(item =>
        item?.social === socialName ? { ...item, link: newLink?.trim() } : item
      )
    );
  }

  const handleAddButton = async () => {
    try {

      const promises = await Promise.all([
        validateInstagram(getSocialsList(SocialsList?.instagram)?.link),
        validateFacebook(getSocialsList(SocialsList?.facebook)?.link),
        validateYoutube(getSocialsList(SocialsList?.youtube)?.link),
        validateLinkedIn(getSocialsList(SocialsList?.linkedIn)?.link),
        validateDiscord(getSocialsList(SocialsList?.discord)?.link),
        validateTiktok(getSocialsList(SocialsList?.tiktok)?.link),
      ]);

      if (isSafeToSubmit) {
        const promises = socials.map(async (item) => {
          const hasRecord = getServerSocialsFromList(item?.social);

          if (!hasRecord && item?.link !== '') {
            // record not found but post data is available
            return await addRecord(item);
          }

          if (hasRecord && hasRecord?.link !== item?.link) {
            // record is found and link has been changed
            return await updateRecord(hasRecord?.id, item);
          }

          if (hasRecord && item?.link === '') {
            // record is found and link is blank, user wants to remove the particular social media.
            return await removeRecord(hasRecord?.id);
          }

        });

        const results = await Promise.all(promises);
        getServerSocials();
      }

    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const addRecord = async (socials) => {
    try {
      return await dispatch(addSocialLinks({ data: socials }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const updateRecord = async (socialLinksId, socials) => {
    try {
      return await dispatch(updateSocialLinks({ socialLinksId: socialLinksId, data: socials }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const removeRecord = async (socialLinksId) => {
    try {
      return await dispatch(removeSocialLinks({ socialLinksId: socialLinksId }));
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  return {
    success, error, loading,
    getSocialsList, setSocialsList,
    handleAddButton,
    validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateInstagram,
    validateFacebook,
    validateYoutube,
    validateLinkedIn,
    validateDiscord,
    validateTiktok,
  }

}

export const SocialsList = {
  instagram: 'instagram',
  facebook: 'facebook',
  youtube: 'youtube',
  linkedIn: 'linkedin',
  discord: 'discord',
  tiktok: 'tiktok',
}

export default useSocialLinks;
