import React, { useState, useEffect } from "react";
import useWorkHistory from "../../../feature/dashboard/professionalProfile/profileEdit/workHistory/useWorkHistory";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import WorkHistoryItemEdit from "../../../components/Dashboard/profileEdit/WorkHistoryItemEdit";
import dateHelper from "../../../utils/dateHelper";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";
import InputStyling from "../../../styling/InputStyling";
import ResponsiveValues from "../../../styling/ResponsiveValues";

function WorkHistory() {

  const {
    success, error, loading,
    showAddButton, showEditButton,
    displayedWorkHistories,
    postWorkHistory, setPostWorkHistory,
    currentWorkProjects, screenshots, handleAddContainer, handlePopulateContainer, handleProjectImages,
    thumbnail,
    work_arrangements,
    handleOnload,
    handleAddbutton,
    handleEditButton, handleUpdateButton,
    handleRemoveButton,
    handleImageUpload,
    validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
    validateDescription, validateProjectTitle, validateProjectCategory
  } = useWorkHistory();

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <>
      {/* ADD WORK SECTION */}
      <div className="flex flex-col pb-6 max-md:max-w-full">
        <div className="flex justify-end items-center">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message='Account updated.' setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || 'Account not updated.'} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <PageTitle title='Add Work Experience' description='Describe your past jobs, responsibilities, and achievements. Highlight the skills and experiences that helped you grow.' />

        {/* ADD company logo */}
        <div className="mt-4 max-md:max-w-full ">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col relative">
              <div className={`${ResponsiveValues.forDesktopScreen} ${TextStyling.inputLabel}`}>
                Company Logo
              </div>
              <div className="mt-1 p-4 w-fit flex flex-col justify-center self-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300">
                <img alt="img"
                  loading="lazy"
                  src={thumbnail || postWorkHistory.company_logo || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
                  className="self-center"
                />
                {!thumbnail && !postWorkHistory.company_logo && <span>Company Logo</span>}
              </div>
              <input type="file"
                accept="image/*"
                value=""
                onChange={handleImageUpload}
                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* ---------------- */}
        <div className="mt-8 flex flex-col justify-center self-stretch my-auto text-base leading-5 placeholder-text-stone-300 max-md:mt-5 max-md:max-w-full">
          <div className={`${ResponsiveValues.forDesktopScreen} ${TextStyling.inputLabel}`}>
            Company Name
          </div>
          <input
            placeholder="Name of Company"
            value={postWorkHistory.company_name || ''}
            autoComplete="organization"
            onChange={(e) => {
              setPostWorkHistory({ ...postWorkHistory, company_name: e.target.value });
              validateCompany(e.target.value);
            }}
            className={`mt-1 ${InputStyling.text}`}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.company?.errorMessage} />

          <div className="flex md:gap-4 items-center md:mt-4 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col md:flex-row flex-wrap flex-1 md:gap-4 content-start">

              <div className="flex flex-col w-full md:w-fit md:flex-1">
                <div className={`${ResponsiveValues.forDesktopScreen} ${TextStyling.inputLabel}`}> Postion</div>
                <input
                  placeholder="Position"
                  value={postWorkHistory.position || ''}
                  autoComplete="organization-title"
                  onChange={(e) => {
                    setPostWorkHistory({ ...postWorkHistory, position: e.target.value });
                    validatePostion(e.target.value);
                  }}
                  className={`md:mt-1 ${InputStyling.text}`}
                />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.position?.errorMessage} />

              </div>

              <div className="flex flex-col w-full md:w-fit md:flex-1">
                <div className={`${ResponsiveValues.forDesktopScreen} ${TextStyling.inputLabel}`}> Date Started</div>
                <input
                  type="date"
                  placeholder="Date Started"
                  autoComplete="on"
                  value={
                    dateHelper.isDatetimeCheckerNewApi(postWorkHistory.date_started) ? dateHelper.datetimeToInputDateNewApi(postWorkHistory.date_started) : postWorkHistory.date_started
                  }
                  onChange={(e) => {
                    setPostWorkHistory({ ...postWorkHistory, date_started: e.target.value });
                    validateStarted(e.target.value);
                  }}
                  className={`md:mt-1 ${InputStyling.text}`}
                />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.started?.errorMessage} />
              </div>

              <div className="flex flex-col w-full md:w-fit md:flex-1">
                <div className={`${ResponsiveValues.forDesktopScreen} ${TextStyling.inputLabel}`}> Date Ended</div>
                <input
                  type="date"
                  placeholder="Date Ended"
                  autoComplete="on"
                  value={dateHelper.isDatetimeCheckerNewApi(postWorkHistory.date_ended) ? dateHelper.datetimeToInputDateNewApi(postWorkHistory.date_ended) : postWorkHistory.date_ended}
                  onChange={(e) => {
                    setPostWorkHistory({ ...postWorkHistory, date_ended: e.target.value });
                    validateEnded(e.target.value);
                  }}
                  className={`md:mt-1 ${InputStyling.text}`}
                />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.ended?.errorMessage} />
              </div>

            </div>
          </div>
        </div>

        {/* <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full"> */}
        <div className={`${TextStyling.inputLabel}`}>
          Work Arrangement
        </div>
        <div className="flex gap-5 pr-2 mt-1 max-md:flex-wrap max-md:pr-5">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={postWorkHistory.work_arrangement === work_arrangements[0].value}
              onChange={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[0].value })}
              className="form-checkbox text-blue-600"
            />
            <span className={`ml-2 ${TextStyling.inputLabel}`}>Freelance</span>
          </label>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={postWorkHistory.work_arrangement === work_arrangements[1].value}
              onChange={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[1].value })}
              className="form-checkbox text-blue-600"
            />
            <span className={`ml-2 ${TextStyling.inputLabel}`}>Fulltime</span>
          </label>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={postWorkHistory.work_arrangement === work_arrangements[2].value}
              onChange={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[2].value })}
              className="form-checkbox text-blue-600"
            />
            <span className={`ml-2 ${TextStyling.inputLabel}`}>Part-time</span>
          </label>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={postWorkHistory.work_arrangement === work_arrangements[3].value}
              onChange={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[3].value })}
              className="form-checkbox text-blue-600"
            />
            <span className={`ml-2 ${TextStyling.inputLabel}`}>Contract</span>
          </label>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={postWorkHistory.work_arrangement === work_arrangements[4].value}
              onChange={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[4].value })}
              className="form-checkbox text-blue-600"
            />
            <span className={`ml-2 ${TextStyling.inputLabel}`}>Others</span>
          </label>
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.workArrangement?.errorMessage} />

        <div className={`${TextStyling.inputLabel}`}>
          Job Description
        </div>
        <textarea
          placeholder="Responsibilities"
          autoComplete="on"
          rows="5"
          value={postWorkHistory.job_description}
          onChange={(e) => {
            setPostWorkHistory({ ...postWorkHistory, job_description: e.target.value });
            validateDescription(e.target.value);
          }}
          className={`mt-1 ${InputStyling.text}`}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.description?.errorMessage} />

        <div className={`border border-neutral-200 ${ResponsiveValues.forDesktopScreen}`}></div>

        <div className="md:mt-2 flex flex-row justify-between items-center w-full">
          <div className={`${TextStyling.pageBodyBold} w-fit`}>Add Projects / Portfolio</div>
          <button className={`${ResponsiveValues.forDesktopScreen} ${ResponsiveValues.addMore} self-center`}
            onClick={() => handleAddContainer()}>
            + Add More
          </button>
          <button className={`${ResponsiveValues.forMobileScreen} ${TextStyling.textLink} self-center`}
            onClick={() => handleAddContainer()}>
            Add More
          </button>
        </div>

        {/* ADD MORE WORK FORM */}
        {currentWorkProjects.map((container, index) => (
          <div key={index} className="mb-8 md:mb-0 md:mt-4  max-md:max-w-full">
            <div className="flex flex-col md:flex-row w-full md:gap-2 ">
              <input
                placeholder="Project title"
                type="text"
                autoComplete="on"
                value={currentWorkProjects[index].title}
                onChange={(e) => {
                  handlePopulateContainer(index, "title", e.target.value);
                  validateProjectTitle(e.target.value);
                }}
                className={` ${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.projectTitle?.errorMessage} />

              <input
                placeholder="Category"
                type="text"
                autoComplete="on"
                value={currentWorkProjects[index].category}
                onChange={(e) => {
                  handlePopulateContainer(index, "category", e.target.value);
                  validateProjectCategory(e.target.value);
                }}
                className={`${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.projectCategory?.errorMessage} />

            </div>

            <div className="md:mt-8 flex flex-col w-full relative">
              {(!screenshots[index] || !screenshots[index].imageSrc) &&
                <div className=" flex flex-col justify-center items-center self-stretch p-2.5 text-lg leading-6 text-center rounded-lg border border-dashed border-neutral-300 text-stone-300 max-md:px-5 max-md:mt-4">
                  <span className={TextStyling.pageBodyBold}>Drag or Upload images here</span>
                  <span className="text-base">Add Image</span>
                  <span className="text-base">{`(Image size: 1024px x 687px)`} {`PNG, JPG up to 2MB`}</span>
                  <span className={`mt-2 ${TextStyling.textLink}`}>Upload</span>
                </div>
              }
              <input
                type="file"
                accept="image/*"
                value=""
                onChange={(e) => handleProjectImages(e, index)}
                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>
          </div>
        ))}

        {/* Submit */}
        {showAddButton &&
          <>
            {showNotif && (
              <>
                {loading && (<LoadingIndicator loading={loading} styling='px-16 max-md:pl-5' />)}
              </>
            )}

            <button onClick={() => handleAddbutton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Add Work Experience
            </button>

            <button onClick={() => handleAddbutton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Add Work Experience
            </button>
          </>
        }

        {showEditButton &&
          <>
            {showNotif && (
              <>
                {loading && (<LoadingIndicator loading={loading} styling='px-16 max-md:pl-5' />)}
              </>
            )}

            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Update Work Experience
            </button>

            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Update Work Experience
            </button>
          </>
        }

      </div >

      {/* WORK PREVIEW */}
      < div className="flex flex-col p-4 pb-[250px]" >
        {displayedWorkHistories?.length > 0 &&
          <div className="flex flex-row gap-2 justify-center items-center">
            <div className={`${TextStyling.pageHeader2} w-fit`}>My Work history</div>
            <div className={`h-1/2 border border-neutral-200 flex-1`}></div>
          </div>
        }

        {
          displayedWorkHistories?.map((item, index) => {
            return <WorkHistoryItemEdit data={item} index={index} onEdit={handleEditButton} onDelete={handleRemoveButton} />
          })
        }
      </div >

    </>
  );
}

export default WorkHistory;
