import React from 'react';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css'; // Import custom styles

function MyCalendar({ value, onChange }) {

  // Function to check if a date is today
  const isToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date (remove time part)
    return date.toDateString() === today.toDateString();
  };

  // Function to check if a date is after today
  const isAfterToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date (remove time part)
    return date > today; // Check if date is after today
  };

  // Function to highlight specific dates
  const isHighlightedDate = (date) => {
    const highlightedDates = [
      // new Date(2024, 8, 20),  // Example date: September 20, 2024
      // new Date(2024, 8, 25)   // Example date: September 25, 2024
    ];
    return isToday(date) || highlightedDates.some(
      (highlightedDate) => date.toDateString() === highlightedDate.toDateString()
    );
  };

  // Function to disable past dates
  const isDisabledDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date (remove time part)
    return date < today; // Disable dates before today
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue); // Update the selected date state
    if (onChange) onChange(newValue); // Optionally call onChange prop
  };

  // Function to check if a date is selected
  const isSelectedDate = (date) => {
    if (!selectedDate) return false; // No selected date
    const selected = new Date(selectedDate);
    const compareDate = new Date(date);
    // Normalize dates to remove time
    selected.setHours(0, 0, 0, 0);
    compareDate.setHours(0, 0, 0, 0);

    return compareDate.toDateString() === selected.toDateString();
  };


  return (
    <div>
      <Calendar
        onChange={handleDateChange}
        value={value}
        tileContent={({ date, view }) => {
          if (view === 'month') {
            let className = '';

            if (isToday(date)) {
              className = 'today';
            } else if (isHighlightedDate(date)) {
              className = 'highlighted';
            } else if (isAfterToday(date)) {
              className = 'future';
            } else if (isDisabledDate(date)) {
              className = 'disabled';
            }

            if (isSelectedDate(date)) {
              className += ' selected';
            }

            if (!isDisabledDate(date)) {
              return (
                <div className={`custom-highlight ${className}`}>
                  <span className="inner-circle"></span>
                  <span className="date-number">{date.getDate()}</span>
                </div>
              );
            } else {
              return null;
            }

          }

          return null; // No additional content for non-highlighted tiles
        }}
        tileClassName={({ date, view }) => {
          if (view === 'month' && (isHighlightedDate(date) || isAfterToday(date) || isSelectedDate(date))) {
            return 'hide-default'; // Add class to hide default date number
          }
          return null;
        }}
        tileDisabled={({ date, view }) => {
          return view === 'month' && isDisabledDate(date); // Disable past dates
        }}
      />
    </div>
  );
}

export default MyCalendar;
