import InputStyling from "../../styling/InputStyling";
import TextStyling from "../../styling/TextStyling";

const CustomSelect = ({
  outerClassName = '',
  className = InputStyling.select,
  label = '',
  children,
  hasSelectedState = false,
  value = null,
  onChange = null,
}) => {
  return (
    <div children={`${outerClassName}`}>
      {label !== '' &&
        <span className={`${TextStyling.inputLabel} `}>
          {label}
        </span>
      }
      <div className={`mt-1 relative items-center`}>
        <select
          className={`appearance-none text-ellipsis ${className} ${hasSelectedState === "" ? InputStyling.select_none : InputStyling.select_item}`}
          value={value}
          onChange={onChange}
        >
          {children}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center m-2 pointer-events-none">
          <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
    </div>

  );
}

export default CustomSelect;
