import React from "react";
import PersonalInfo from "../pages/Registration/PersonalInfo";
import Stripe from "../pages/Registration/Stripe";
import { Route, Routes } from "react-router-dom";
import CompletingRegistrationNavbar from "../components/CompletingRegistrationNavbar";
import ThankyouForPayment from "../pages/Registration/ThankyouForPayment";
import UploadDocuments from "../pages/Registration/UploadDocuments";
import MainDashboard from "../pages/Dashboard/MainDashboard";
import FacialRecognition from "../pages/Registration/FacialRecognition";
import ScheduleInterview from "../pages/Registration/ScheduleInterview";
import NavbarDash from "../components/Dashboard/NavbarDash";
import FooterDash from "../components/Dashboard/FooterDash";
import Login from "../pages/Login/Login";
import ForgotPass from "../pages/Login/ForgotPass";
import Registration from "../pages/Registration/Registration";
import HandleNoRoutes from "../components/HandleNoRoutes";
import Messages from "../pages/Dashboard/messages/Messages";
import Profile from "../pages/Basic/Profile";
import Certificate from "../pages/Dashboard/Certificate";
import ChangePassword from "../pages/Dashboard/AccountSettings/ChangePassword";
import ChangeNumber from "../pages/Dashboard/AccountSettings/ChangeNumber";
import ChangeEmail from "../pages/Dashboard/AccountSettings/ChangeEmail";
import EditProfile from "../pages/Dashboard/Editprofile/EditProfile";
import EarningAndReferrals from "../pages/Dashboard/EarningAndReferrals";
import Help from "../pages/Dashboard/AccountSettings/Help";
import SecuritySettings from "../pages/Dashboard/AccountSettings/SecuritySettings";
import AccessKey from "../pages/AccessKey/AccessKey";
import ApplicationSent from "../pages/Registration/ApplicationSent";
import Navbar from "../components/Navbar";
import Home from "../pages/Basic/Home";
import Footer from "../components/Footer";
import WhyRegister from "../pages/Basic/WhyRegister";
import BenefitPage from "../pages/Basic/BenefitPage";
import Requirements from "../pages/Basic/Requirements";
import AboutUs from "../pages/Basic/AboutUs";
import Contact from "../pages/Basic/Contact";
import Privacy from "../pages/Basic/Privacy";
import AntibribePolicy from "../pages/Basic/AntibribePolicy";
import SidebarDashboard from "../components/Dashboard/SidebarDashboard";
import TermsAndConditions from "../pages/Basic/TermsAndConditions";
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess";
import RequirementsManagerPos from "../pages/Basic/RequirementsManagerPos";
import Google2FA from "../pages/Login/Google2FA";
import Verify2FA from "../pages/Login/Verify2FA";
import ChangeRecoveryCodes from "../pages/Dashboard/AccountSettings/ChangeRecoveryCodes";
import Faq from "../pages/Basic/Faq";
import CategoryFaqs from "../pages/Basic/CategoryFaqs";
import Viewseemore from "../pages/Basic/Viewseemore";
import Forum from "../pages/Dashboard/Forum";
import DashboardScreenWrapper from "../components/DashboardScreenWrapper";
import FaceScanner from "../pages/FaceScan/FaceScanner";
import EmailVerified from "../pages/Registration/EmailVerified";
import ScreenWrapper from "../components/ScreenWrapper";
import AboutProfile from "../pages/Basic/AboutProfile";
import ForumTopicMain from "../feature/dashboard/forum/forum-topic/ForumTopicMain";
import ForumNew from "../feature/dashboard/forum/forum-new/ForumNew";
import UploadDocumentIDs from "../pages/Registration/UploadDocumentIDs";
import UploadDocumentBill from "../pages/Registration/UploadDocumentBill";
import UploadDocumentSelfie from "../pages/Registration/UploadDocumentSelfie";
import UploadDocumentSuccess from "../pages/Registration/UploadDocumentSuccess";
import RecoveryCodes from "../pages/Login/RecoveryCodes";
import TwoStepVerification from "../pages/Login/TwoStepVerification";
import UpdatePassword from "../pages/Login/UpdatePassword";
import PassUpdateSuccess from "../pages/Login/PassUpdateSuccess";
import Payment from "../pages/Registration/Payment";


function AppRoute() {
  return (
    <>
      {/* <CompletingRegistrationNavbar /> */}
      <Routes>
        {/* Basic Nav */}
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />
        {/* Registration */}
        <Route
          path="/register"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <Registration />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/application-sent"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <ApplicationSent />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/email-verification"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <EmailVerified />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/personal-information"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <PersonalInfo />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/process-payment"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <Stripe />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/payment-success"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <ThankyouForPayment />
            </ScreenWrapper>
          }
        />

        <Route
          path="/register/upload-documents"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <UploadDocuments />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/face-recognition"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <FacialRecognition />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/schedule-interview"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <ScheduleInterview />
            </ScreenWrapper>
          }
        />
        <Route
          path="/register/registration-success"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <RegistrationSuccess />
            </ScreenWrapper>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ScreenWrapper>
              <Navbar hideNavLinks={true} />
              <MainDashboard />
            </ScreenWrapper>
          }
        />

        {/* Dashboard */}
        <Route
          path="/*"
          element={
            <>
              <NavbarDash />
              <HandleNoRoutes />
              <FooterDash />
            </>
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <NavbarDash />
              <MainDashboard />
              <FooterDash />
            </>
          }
        />
        <Route
          path="/dashboard/messages"
          element={
            <DashboardScreenWrapper>
              <Messages />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/profile"
          element={
            <>
              <NavbarDash />
              <Profile />
              <FooterDash />
            </>
            // <DashboardScreenWrapper
            //   withPadding='false'
            //   backgroundColor='bg-neutral-100 bg-opacity-50'>
            //   
            // </DashboardScreenWrapper>
          }
        />
        {/* backgroundColor='bg-neutral-100 bg-opacity-20'> */}

        <Route
          path="/dashboard/certificate"
          element={
            <DashboardScreenWrapper>
              <Certificate />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/account-settings/change-password"
          element={
            <DashboardScreenWrapper>
              <ChangePassword />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/account-settings/change-number"
          element={
            <DashboardScreenWrapper>
              <ChangeNumber />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/account-settings/change-email"
          element={
            <DashboardScreenWrapper>
              <ChangeEmail />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/account-settings/recovery-codes"
          element={
            <DashboardScreenWrapper>
              <ChangeRecoveryCodes />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/edit-profile"
          element={
            <DashboardScreenWrapper>
              <EditProfile />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/earnings-and-referrals"
          element={
            <DashboardScreenWrapper>
              <EarningAndReferrals />
            </DashboardScreenWrapper>
          }
        />

        <Route
          path="/dashboard/forum"
          element={
            <DashboardScreenWrapper>
              <Forum />
            </DashboardScreenWrapper>
          }
        />

        <Route
          path="/dashboard/forum-discussion"
          element={
            <DashboardScreenWrapper>
              <ForumTopicMain />
            </DashboardScreenWrapper>
          }
        />

        <Route
          path="/dashboard/forum-new"
          element={
            <DashboardScreenWrapper>
              <ForumNew />
            </DashboardScreenWrapper>
          }
        />

        <Route
          path="/dashboard/help"
          element={
            <DashboardScreenWrapper>
              <Help />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/account-settings"
          element={
            <DashboardScreenWrapper>
              <SecuritySettings />
            </DashboardScreenWrapper>
          }
        />
        <Route
          path="/dashboard/access-key"
          element={
            <>
              <ScreenWrapper >
                <Navbar hideNavLinks={true} />
                <AccessKey />
              </ScreenWrapper >
            </>
          }
        />

        <Route
          path="/apply-country-manager"
          element={
            <>
              <Navbar />
              <RequirementsManagerPos />
              <Footer />
            </>
          }
        />
        <Route
          path="/why-register"
          element={
            <>
              <Navbar />
              <WhyRegister />
              <Footer />
            </>
          }
        />
        <Route
          path="/benefits"
          element={
            <>
              <Navbar />
              <BenefitPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/requirements"
          element={
            <>
              <Navbar />
              <Requirements />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq"
          element={
            <>
              <Navbar />
              <Faq />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq/category"
          element={
            <>
              <Navbar />
              <CategoryFaqs />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq/category/view"
          element={
            <>
              <Navbar />
              <Viewseemore />
              <Footer />
            </>
          }
        />
        <Route
          path="/about-us"
          element={
            <>
              <Navbar />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/about-profile"
          element={
            <>
              <Navbar />
              <AboutProfile />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Navbar />
              <Privacy />
              <Footer />
            </>
          }
        />
        <Route
          path="/antibribe-policy"
          element={
            <>
              <Navbar />
              <AntibribePolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <>
              <Navbar />
              <TermsAndConditions />
              <Footer />
            </>
          }
        />

        {/* App Starting Point */}
        <Route path="/login"
          element={
            <Login />
          }
        />
        <Route path="/enable-2fa" element={<Google2FA />} />
        <Route path="/verify-2fa" element={<Verify2FA />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route
          path="/register"
          element={
            <>
              <Navbar hideNavLinks={true} />
              <Registration />
            </>
          }
        />


        <Route path="/face-scan/:key" element={<FaceScanner />} />
      </Routes>
    </>
  );
}

export default AppRoute;
