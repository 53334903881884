import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from 'moment';
import dateHelper
  from '../utils/dateHelper';
const FormattedDateComponent = ({ datetime }) => {
  const formatString = dateHelper.isDatetimeChecker(datetime) ? dateHelper.dateFormats.server : dateHelper.dateFormats.input;
  const parsedDate = moment(datetime, formatString).toDate();

  return (
    <>
      {(moment(parsedDate).isValid()) &&
        <FormattedDate
          value={parsedDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
      }
    </>
  );
}
export default FormattedDateComponent;

export const FormattedTimeComponent = ({ datetime }) => {
  const formatString = dateHelper.isDatetimeChecker(datetime) ? dateHelper.dateFormats.server : dateHelper.dateFormats.input;
  const parsedDate = moment(datetime, formatString).toDate();

  return (
    <>
      {(moment(parsedDate).isValid()) &&
        <FormattedDate
          value={parsedDate}
          hour="numeric"
          minute="numeric"
          hour12={true}
        />
      }
    </>
  );
}

export const FormattedTimeComponentWithinTheDayISO8601ToLocal = ({ datetime }) => {
  const momentObj = moment(datetime);
  const parsedDate = momentObj.local().toDate();

  if (momentObj.isSame(moment(), 'day')) {
    // if within the day, show time only.
    return (
      <>
        {(moment(parsedDate).isValid()) &&
          <FormattedDate
            value={parsedDate}
            hour="numeric"
            minute="numeric"
            hour12={true}
          />
        }
      </>
    );
  } else {
    return <>
      {(moment(parsedDate).isValid()) &&
        <FormattedDate
          value={parsedDate}
          year="numeric"
          month="long"
          day="2-digit"
        />
      }
    </>;
  }
}

export const ElepasedTime = ({ datetime }) => {
  const momentObj = moment(datetime);
  const now = moment();
  const duration = moment.duration(now.diff(momentObj));

  if (momentObj.isSame(moment(), 'day')) {
    // if within the day, show time only.
    return (
      <>
        {`${duration.hours() >= 1 ? duration.hours() + ' hour(s)' : ''} ${duration.minutes() >= 1 ? duration.minutes() + ' minute(s)' : ''}`}
      </>
    );
  } else {
    return (
      <>
        {`${duration.days() >= 1 ? duration.days() + ' day(s)' : ''}`}
      </>
    );
  }
}
