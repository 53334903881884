import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo, personalInfo } from '../../../authSlice';

import { accessKeyAction as action } from './accessKeySlice';
import { addAccessKey, uploadDocument } from './accessKeySlice';
import { imageCheckSize } from '../../../../utils/imageCheck';
import FileUploadHelper from '../../../../utils/FileUploadHelper';

const useAccessKey = () => {
  const dispatch = useDispatch();
  const { success, error, loading } = useSelector(state => state.accessKey);
  const userData = useSelector(state => state?.auth?.user);
  const hasAccessKey = useSelector(state => state?.auth?.user?.has_access_key);

  const [accessKey, setAccessKey] = useState('');
  const [accessKeyAccepted, setAccessKeyAccepted] = useState(false);
  const [uploadedIDs, setUploadedIDs] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadedAll, setUploadedAll] = useState([]);

  useEffect(() => {
    clearInputFields();
  }, []);

  const clearInputFields = async () => {
    action.setError = [];
    action.setSucccess = false;
    setUploadedIDs([]);
    setUploadedDocuments([]);
    setUploadedAll([]);
  }

  const handleApplyButton = async () => {
    try {
      await dispatch(addAccessKey({ id: userData.id, data: { access_key: accessKey } }));
      setAccessKeyAccepted(true);
      clearInputFields();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const addFileToInput = async (event, type) => {
    // { image, src, name, file, docType, type }
    const files = [...event.target.files];
    const arrId = [...uploadedIDs];
    const arrDocs = [...uploadedDocuments];
    const arrAll = [...uploadedAll];

    files.forEach((file, index) => {
      const result = FileUploadHelper.uploadFileUtil({ file: file });
      let newData = null;

      if (file.type.includes('image')) {
        newData = { ...result, docType: 'image', type: type };
      } else if (file.type.includes('pdf')) {
        newData = { ...result, docType: 'pdf', type: type };
      } else {
        newData = { ...result, docType: 'doc', type: type };
      }

      arrAll.push(newData);

      if (type === 'VALID_ID') {
        arrId.push(newData);
        setUploadedIDs(arrId);
      }

      if (type === 'DOCUMENT') {
        arrDocs.push(newData);
        setUploadedDocuments(arrDocs);
      }

    });
    setUploadedAll(arrAll);
  }

  const addedIdToInput = (event) => {
    addFileToInput(event, 'VALID_ID');
  }

  const addedBillingToInput = (event) => {
    addFileToInput(event, 'DOCUMENT');
  }

  const uploadCounter = (arr, ctr) => {
    return arr.length === ctr;
  }

  const handleContinueButton = async () => {
    try {
      const arrFiles = [...uploadedAll];
      const fileUploadPromises = arrFiles.map(async (item, index) => {
        const formData = {
          name: item.name,
          file: item.file,
          type: item.type,
        }
        return await dispatch(uploadDocument({ id: userData.id, data: formData }));
      });
      const results = await Promise.all(fileUploadPromises);
      clearInputFields();
    } catch (err) {
      await dispatch(action.setError(`Error: ${err}`));
    }
  }

  const getDataFromResults = (results) => {
    const arrAll = [...uploadedAll];

    results.map((item, index) => {
      const matchDataIndex = arrAll.find((item) => item.name === item.meta.arg.data.name);

      if (matchDataIndex !== -1) {
        const updateRecord = arrAll[matchDataIndex];
        arrAll[matchDataIndex] = { ...updateRecord, requestStatus: item.meta.requestStatus }
      }

      return item;
    });

    setUploadedAll(arrAll);
  }

  return {
    hasAccessKey, accessKeyAccepted,
    success, error, loading,
    accessKey, setAccessKey,
    uploadedIDs, uploadedDocuments, uploadedAll,
    addedIdToInput, addedBillingToInput,
    handleApplyButton, handleContinueButton
  }

}

export default useAccessKey;
