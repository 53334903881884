import React from "react";
import { useNavigate } from "react-router-dom";

function BacktoLogin() {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="flex flex-row gap-2 justify-between items-center">
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/427a6dfb50348309db0c360c9956276b741614a052067d396517a639612574c7?apiKey=3ae96302e69649f5be368f89230b0596&"
          className="w-2 fill-sky-600 m-auto "
        />
        <div className="mr-auto cursor-pointer" onClick={redirectToLogin}>
          Back to Login
        </div>
      </div>
    </>
  );
}

export default BacktoLogin;
