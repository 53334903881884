import React, { useEffect, useState } from "react";
import TopBanner from "../../components/TopBanner";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { countryManagers } from "../../feature/authSlice";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import debounce from "lodash.debounce";
function AboutUs() {
  const bannerImg =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cba35674e37ec6e91af53d2c7774140d1660282588ed35d54b1ead02a199bc5c?apiKey=3ae96302e69649f5be368f89230b0596&";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.state);
  const [managers, setManagers] = useState([]);

  const [activePage, setActivePage] = useState(1);
  // const itemsPerPage = 8; // 4 columns * 2 rows = 8 items per page
  // const totalPages = Math.ceil(data.length / itemsPerPage);

  // const [itemsPerPage, setItemsPerPage] = useState(8);
  // const [totalPages, setTotalPages] = useState(1);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryManager, setCountryManager] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);

  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showContent, setShowContent] = useState(false);
  const itemsPerPage = 8;
  const [selectedTab, setSelectedTab] = useState(0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(currentData.length / itemsPerPage);

  useEffect(() => {
    setCountries(Country.getAllCountries());
    dispatch(countryManagers()).then((data) => {
      if (data?.payload) {
        setManagers(data.payload);
        setCurrentData(data.payload);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const filteredData = managers.filter((user) => {
      const matchesSearchQuery = user.fullname
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesCountry =
        selectedCountry === "" || user.country === selectedCountry;
      return matchesSearchQuery && matchesCountry;
    });
    setCurrentData(filteredData);
  }, [searchQuery, selectedCountry, managers]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedFilter(e.target.value.toLowerCase());
  };

  const debouncedFilter = debounce((query) => {
    const filteredData = managers.filter((user) => {
      const matchesSearchQuery = user.fullname.toLowerCase().includes(query);
      const matchesCountry =
        selectedCountry === "" || user.country === selectedCountry;
      return matchesSearchQuery && matchesCountry;
    });
    setCurrentData(filteredData);
  }, 300); // Adjust the debounce delay as needed

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevNext = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4); // Limiting to maximum 5 pagination buttons

    if (startPage > 1) {
      paginationItems.push(
        <button
          key={1}
          className={`px-4 py-2 mx-1 border rounded ${
            1 === currentPage ? "bg-black text-white" : "bg-white"
          }`}
          onClick={() => handleClick(1)}
        >
          1
        </button>
      );

      if (startPage > 2) {
        paginationItems.push(
          <span key="start-ellipsis" className="px-4 py-2 mx-1">
            ...
          </span>
        );
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      paginationItems.push(
        <button
          key={number}
          className={`px-4 py-2 mx-1 border rounded ${
            number === currentPage ? "bg-black text-white" : "bg-white"
          }`}
          onClick={() => handleClick(number)}
        >
          {number}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationItems.push(
          <span key="end-ellipsis" className="px-4 py-2 mx-1">
            ...
          </span>
        );
      }

      paginationItems.push(
        <button
          key={totalPages}
          className={`px-4 py-2 mx-1 border rounded ${
            totalPages === currentPage ? "bg-blue-500 text-white" : "bg-white"
          }`}
          onClick={() => handleClick(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return paginationItems;
  };

  // const handleSearchChange = (e) => {
  //   const query = e.target.value.toLowerCase();
  //   setSearchQuery(query);

  //   // Filter data based on search query in real-time
  //   if (countryManager.length === 0) {
  //     // If no country is selected, filter all managers
  //     const filtered = managers.filter((user) =>
  //       user.fullname.toLowerCase().includes(query)
  //     );
  //     setCurrentData(filtered);
  //   }

  //   if (selectedCountry !== "") {
  //     // If a country is selected, filter within the selected country's managers
  //     const filteredCountry = countryManager.filter((user) =>
  //       user.fullname.toLowerCase().includes(query)
  //     );
  //     setCurrentData(filteredCountry);
  //   }
  // };

  const handleSearchClick = () => {
    const filtered = currentData.filter(
      (user) =>
        user.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.position.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUser(filtered);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div>
      <TopBanner bannerImg={bannerImg} />
      <div className="max-w-[1366px] m-auto p-[20px]">
        {/* Section 1 */}
        <div className="mt-16 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full m-auto">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
              <img
                alt="img"
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&"
                className=" w-full aspect-[0.85] max-md:mt-10 max-sm:w-[210px] max-sm:aspect-[0.90] m-auto max-sm:mt-20 max-md:w-[280px] max-md:aspect-[0.87] "
              />
            </div>
            <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col items-start self-stretch justify-center px-5 py-8 my-auto text-2xl tracking-tight max-sm:py-4 max-md:mt-0 max-sm:mt-0 max-md:max-w-full">
                <div className="self-stretch text-base leading-6 tracking-tighter text-neutral-600 max-md:max-w-full ">
                  <span className="text-3xl font-bold leading-10 tracking-tighter text-blue-900 max-md:text-center">
                    Our Vision for Clients and Professionals
                  </span>
                  <br />
                  <br />
                  <span className="leading-7 text-neutral-600">
                    It is our aim to unite clients and professionals on a
                    foundation of integrity, honesty, and authenticity. We
                    aspire to cultivate an environment where every user, whether
                    providing or seeking services, is treated with respect,
                    care, and professionalism. Our mission is to foster genuine,
                    prosperous relationships between clients and professionals
                    through mutual trust and shared success. We invite you to
                    join us in realizing this vision.
                  </span>
                </div>
                <div className="mt-9 font-bold text-blue-900 leading-[120%]">
                  Jeffrey Lawson
                </div>
                <div className="mt-2 font-semibold text-sky-600 leading-[100%]">
                  CEO
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section 2 */}
        <div className="mt-20 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full m-auto">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col px-5 font-bold grow max-md:mt-10 max-md:max-w-full">
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8bd403e55f3a7ef70025029308f823a53804116bd284ebdf31788bf97eef19a?apiKey=3ae96302e69649f5be368f89230b0596&"
                  className="max-w-full aspect-[4] w-[314px]"
                />
                <div className="mt-4 text-3xl leading-9 tracking-tight text-black max-md:max-w-full">
                  Your Global Industry Professional Trust Platform
                </div>
                <div className="mt-6 text-2xl text-sky-600 max-md:max-w-full">
                  Why Choose Us?
                </div>
                <div className="mt-2 text-base leading-7 font-normal text-neutral-600 max-md:max-w-full">
                  Full ID Check is a global identity verification platform
                  serving professionals worldwide. Leveraging cutting-edge data
                  science and machine learning techniques, we have built a
                  network of verified professional profiles by carefully
                  curating authoritative data sources. Our goal is to provide
                  clients with confidence that only authentic and qualified
                  individuals can access services or platforms requiring
                  identity authentication.
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full p-12 rounded-3xl bg-neutral-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/15e4a8d42785287fcd915cd180cbc568f988fc1abc28e5f195cbc74d3bd5ed59?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="self-start w-12 shrink-0 aspect-square"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
                      The Mission
                    </div>
                    <div className="mt-2 text-base leading-7 text-neutral-600 max-md:max-w-full">
                      Committed to empowering all individuals with certified
                      identity verification, we place honesty and integrity at
                      the forefront of our values. Our mission is to ensure that
                      everyone, not just professionals, can demonstrate their
                      authenticity in an increasingly digital world.
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 mt-4 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f40f4164dd56632e2cdaa1112bbb30c45e5cea2a2f6e250f0b3c4a1adfdedbdf?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="self-start w-12 shrink-0 aspect-square"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
                      The Vision
                    </div>
                    <div className="mt-2 text-base leading-7 text-neutral-600 max-md:max-w-full">
                      <span className="text-neutral-600">
                        Our vision is to offer everyone, from professionals to
                        the general public worldwide, the opportunity to prove
                        that ‘They Are Who They Say They Are.’ We strive to
                        eliminate the anonymity of digital interactions,
                        ensuring that all users, irrespective of their
                        professional background, are recognized as competent and
                        trustworthy individuals with a strong sense of integrity
                        and accountability.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section-3 */}
        <div className="flex flex-col p-12 mt-10 w-full rounded-3xl border border-sky-500 border-solid max-w-[1200px] max-md:px-5 max-md:max-w-full m-auto">
          <div className="text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
            Why is FullIDCheck Essential?
          </div>
          <div className="mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col self-stretch p-4 grow text-neutral-600 max-md:pl-0 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc6f91873035db27b838f443bab86cd963219c2e99fe74f6e284f76fa2c9c910?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="aspect-[0.8] w-[85px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-sm:mx-auto max-md:text-center">
                    Digital Trust
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    In the modern digital landscape, verifying identity
                    authenticity is of paramount importance. Full ID Check
                    assists professionals and their clients in establishing
                    sincere confidence, facilitating seamless commercial
                    engagements and providing a safeguard against deceitful
                    behaviors.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col p-4 text-neutral-600 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fb55ba0cf96e0e2916e25fd9ae80793a27cee655f9240bf8fa03de6cbc83837?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[1.03] w-[109px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-md:text-center max-sm:mx-auto">
                    Combatting Fraud
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    Fraudulent activities by pseudo-Professionals tarnish the
                    reputation of genuine Professionals globally. Our platform
                    addresses this by providing a shield against these deceptive
                    actions, safeguarding both clients and legitimate
                    Professionals.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col p-4 text-neutral-600 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2cfea7ae03c79722e8875308d8eac44666ab8051dcc82645382edb019342499a?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[1.03] w-[109px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-sm:text-center max-md:text-center">
                    Boosting Credibility
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    With FullIDCheck's worldwide registration, clients can
                    confidently partner with Professionals, knowing they've been
                    vetted. This transparency benefits authentic Professionals
                    by enhancing their employability and fostering secure
                    relationships with clients.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-5 */}
        <div className="flex flex-col py-12 pr-12 pl-6 mt-10 w-full rounded-3xl bg-neutral-100 max-w-[1200px] max-md:px-5 max-md:max-w-full m-auto">
          <div className="text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
            Key Features
          </div>
          <div className="p-4 mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-neutral-600 max-md:mt-10 max-md:max-w-full">
                  <img
                    alt="img"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[3.33] w-[247px]"
                  />
                  <div className="mt-2 text-2xl font-bold leading-9 tracking-tight max-md:max-w-full">
                    AML & eIDAS Compliance
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:max-w-full">
                    We house a database of certified professionals compliant
                    with Anti-Money Laundering (AML) and Electronic
                    Identification, Authentication, and Trust Services (eIDAS)
                    protocols.
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-6/12 ml-5 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-neutral-600 max-md:mt-10 max-md:max-w-full">
                  <div className="flex items-start gap-2 text-sm font-medium tracking-tight pt-7 text-sky-600 max-md:flex-wrap">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4a730471a69379d6f705892538662343793aa7ecfdf5adc2aec12d8b30bbb06?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="aspect-[4.55] w-[238px]"
                    />
                    <div className="mt-2.5">
                      The key to finding
                      <br />
                      professionals
                    </div>
                  </div>
                  <div className="mt-2 text-2xl font-bold leading-8 tracking-tight max-md:max-w-full">
                    Connecting Clients to Professionals
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:max-w-full">
                    Clients can quickly identify and collaborate with verified
                    Professionals, ensuring a seamless, safe, and efficient
                    project cycle.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section-6 */}
        {/* <div className="flex gap-5 px-5 mt-16 w-full leading-[121%] max-w-[1200px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
          <div className="flex-1 my-auto text-2xl font-semibold text-right text-neutral-600 max-md:max-w-full max-sm:text-2xl max-sm:leading-6">
            Affirm Your Identity with Confidence
          </div>
          <div className="flex-1 text-4xl font-bold text-sky-600 max-md:max-w-full max-md:leading-10 max-sm:text-2xl max-sm:leading-8 max-sm:text-center">
            'I AM WHO I SAY I AM.'{" "}
          </div>
        </div> */}
        <div className=" w-[90%] max-lg:w-[100%] m-auto justify-between px-12 py-8 mt-16 rounded-2xl max-w-[1307px] max-md:px-5 max-md:mt-10 max-md:max-w-full bg-gradient-to-br from-[#28b9bb] to-[#1c3775]">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
              <div className="text-3xl font-semibold leading-9 tracking-tight text-white max-md:mt-10 max-md:max-w-full">
                Are you interested in a management position?
              </div>
              <span className="text-sm leading-6 text-white">
                Situations vacant for country managers, check and see if your
                country that you live in is available
              </span>
            </div>
            <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
              <button
                onClick={() => navigate("/apply-country-manager")}
                className="max-lg:text-[18px] max-lg:px-0 justify-center self-stretch px-9 py-3 my-auto w-full text-xl font-semibold text-blue-900 bg-zinc-100 rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center "
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div className="mt-10 text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
          Meet The Country Managers and B.M.C Staff
        </div>
        <div className="w-full">
          <div className="flex items-center justify-start mt-5 search max-md:flex-col">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input my-auto border-2 max-w-[35%] w-[35%] rounded-md px-2 py-1.5 max-md:max-w-[95%] max-md:mb-2"
              placeholder="Search name, position ,etc..."
            />
            <select
              className={`w-full max-w-[20%] py-2 ml-4 max-md:ml-0 border-2 rounded-xl px-3  ${
                selectedCountry === "" ? "text-neutral-400" : "text-black"
              }`}
              id="country"
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
              }}
            >
              <option disabled value="">
                Country
              </option>
              {countries.map((country) => (
                <option key={country.isoCode} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-[80%] max-md:max-w-[95%] m-auto mt-10">
            {auth && auth.loading && (
              <div className="flex flex-col items-center justify-center p-4 rounded-lg">
                <div className="text-sm text-center mt-4 font-semibold text-[21px] max-md:text-[16px] text-[#1C3775]">
                  No results found
                </div>
              </div>
            )}

            {currentItems.map((item) => (
              <div
                key={item.id}
                className="flex flex-col items-center justify-center p-4 rounded-lg"
              >
                <img
                  src={item.profile_photo_url}
                  alt={item.fullname}
                  className="w-[60%] rounded-full h-auto mb-2"
                />
                <div className="text-sm text-center mt-4 font-semibold text-[21px] max-md:text-[16px] text-[#1C3775]">
                  {item.fullname}
                </div>
                <div className="text-sm16 max-md:text-sm14 text-center min-h-[30px] text-[#1C3775]">
                  {item.position}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-end px-5 mt-4">
            <button
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-white"
              }`}
              onClick={() => handlePrevNext("prev")}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft size={22} />
            </button>
            {renderPaginationItems()}
            <button
              className={`px-3 py-1 mx-1 border rounded ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-white"
              }`}
              onClick={() => handlePrevNext("next")}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>

          {/* <div className="flex justify-center mt-10 space-x-1">
            <button
              onClick={prev}
              disabled={activePage === 1}
              className="px-2 py-1 text-xs bg-white border border-gray-300 rounded-md sm:px-3 sm:py-1 sm:text-sm md:px-4 md:py-2 md:text-sm hover:bg-gray-100"
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => setActivePage(i + 1)}
                className={`px-2 py-1 text-xs sm:px-3 sm:py-1 sm:text-sm md:px-4 md:py-2 md:text-sm rounded-md border ${
                  activePage === i + 1
                    ? "bg-gray-100 text-gray-900"
                    : "bg-white"
                } hover:bg-gray-100`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={next}
              disabled={activePage === totalPages}
              className="px-2 py-1 text-xs bg-white border border-gray-300 rounded-md sm:px-3 sm:py-1 sm:text-sm md:px-4 md:py-2 md:text-sm hover:bg-gray-100"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
