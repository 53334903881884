import * as React from "react";
import { useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaClock } from "react-icons/fa6";
import { BsCameraVideoFill } from "react-icons/bs";
import "./CalendarGlobal.css";
import { scheduleInterview } from "../../feature/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../../components/Loader";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TextStyling from "../../styling/TextStyling";
import MyCalendar from "./MyCalendar.js";

function ScheduleInterview({ backBtn }) {
  const [value, onChange] = React.useState(new Date());

  const [selectedTime, setSelectedTime] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const dateValue = new Date(value);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = dateValue.toLocaleDateString("en-US", options);
  const combinedDateAndTime = formattedDate + " " + selectedTime;
  const [ismodalOpen, setIsModalOpen] = React.useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleTimeBtnClick = (e) => {
    setSelectedTime(e.target.innerText);
  };

  //convert to date time
  const dateTimeString = combinedDateAndTime;
  const date = new Date(dateTimeString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const second = date.getSeconds().toString().padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  const handleSetInterviewSchedule = () => {
    if (selectedTime === "") {
      setErrorMessage("Please select a preferred time");
      return;
    }

    sendScheduleData();
  };
  const sendScheduleData = async () => {
    setIsModalOpen(true);

    const scheduleData = {
      scheduled_at: formattedDateTime,
      duration: 15,
      via: "Zoom App",
      interview_type: `One-on-One`,
    };

    const { id } = auth?.user || { id: 0 };
    if (id > 0) {
      await dispatch(scheduleInterview({ id, data: { ...scheduleData } })).then(
        (data) => {
          if (data?.payload?.message === "Interview scheduled successfully") {
            const uData = JSON.parse(localStorage.getItem("user"));

            if (uData) {
              uData.is_interviewing = "1";
              localStorage.setItem("user", JSON.stringify(uData));
              setIsModalOpen(false);

              navigate("/register/registration-success");
            }
          }
        }
      );
    }
  };

  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/schedule-interview"
  );

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      {auth.loading && (
        <p className="mt-1 text-blue-900">Processing...</p>
      )}
      {auth.error && (
        <p className="mt-1 text-red-900">{auth.error}</p>
      )}

      {errorMessage !== "" && (
        <div className="mt-2 text-red-500">{errorMessage}</div>
      )}

      <div className="flex flex-col items-center bg-white">
        <div className={`mt-16 max-w-full w-[900px] max-md:mt-10 ${ResponsiveValues.cardScreenStyle} shadow-lg`}>
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">

            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col px-5 py-1 text-sm font-bold leading-4 grow max-md:mt-10">
                <button
                  onClick={() => {
                    const uData = JSON.parse(localStorage.getItem("user"));
                    if (uData) {
                      uData.is_face_recognized = 0;
                      localStorage.setItem("user", JSON.stringify(uData));
                    }
                    navigate("/register/face-recognition");
                  }}
                  className="flex flex-row justify-start items-center gap-2 cursor-pointer text-sky-600"
                >
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/427a6dfb50348309db0c360c9956276b741614a052067d396517a639612574c7?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="w-2 fill-sky-600"
                  />
                  Back
                </button>

                <div className={`${TextStyling.pageTitle}`}>
                  Set Interview Schedule
                </div>

                <div className="mt-2 mb-4 flex gap-5 font-light items-center justify-between text-sm leading-5 text-[#A4A4A4]">
                  <div className="flex gap-2 ">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e16fae841bee600ee2cdfd708c51ad53a4af563ec2c020bf617c0d835eeffe69?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="self-start w-4 my-auto shrink-0 aspect-square"
                    />
                    <div>One to one</div>
                  </div>
                  <div className="flex gap-2 ">
                    <FaClock className="my-auto " />

                    <div>15 min</div>
                  </div>
                  <div className="flex gap-2">
                    <BsCameraVideoFill className="my-auto text-[#A4A4A4]" />

                    <div>Zoom Meeting</div>
                  </div>
                </div>

                <MyCalendar value={value} onChange={onChange} />
              </div>
            </div>

            <div className="border-r h-auto" />

            <div className="flex flex-col w-6/12 ml-5 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col px-5 text-sm leading-4 grow max-md:mt-10">
                <div className="flex flex-row justify-start items-center font-semibold text-sky-600">
                  Australia Time
                </div>
                <div className={`mt-4 ${TextStyling.note}`}>
                  Select your preferred time on {formattedDate}
                </div>
                <div className="h-px my-2 border border-solid shrink-0 bg-neutral-100 border-neutral-100" />

                {/* insert time here  */}
                <div className="flex gap-10 mt-4 text-[#3B3B3B]">

                  <div className="flex flex-col gap-4 ">
                    <button
                      className={`${selectedTime === "10:00 AM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      10:00 AM
                    </button>
                    <button
                      className={`${selectedTime === "11:00 AM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      11:00 AM
                    </button>
                    <button
                      className={`${selectedTime === "1:00 PM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      1:00 PM
                    </button>
                    <button
                      className={`${selectedTime === "3:00 PM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      3:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col gap-4">
                    <button
                      className={`${selectedTime === "4:00 PM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      4:00 PM
                    </button>
                    <button
                      className={`${selectedTime === "5:30 PM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      5:30 PM
                    </button>
                    <button
                      className={`${selectedTime === "6:00 PM"
                        ? "bg-[#0082BA] text-white"
                        : "bg-[#EFF4FF]"
                        } px-7 py-2.5 rounded-lg text-md font-semibold `}
                      onClick={(e) => handleTimeBtnClick(e)}
                    >
                      6:00 PM
                    </button>
                  </div>

                </div>

                <div className="my-12">
                  <button
                    onClick={handleSetInterviewSchedule}
                    disabled={auth.loading}
                    className={` ${ResponsiveValues.buttonFull}`}
                  >
                    Set Interview Schedule
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <Loader loadingText={"Processing..."} isOpen={ismodalOpen} />
    </>
  );
}

export default ScheduleInterview;
