import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import certificateApi from "./certificatesApi";

export const downloadCertificate = createAsyncThunk('/certificate/download', async ({ id }, { rejectWithValue }) => {
	try {
		await certificateApi.downloadCertificate(id);
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const certificateSlice = createSlice({
	name: 'certificate',
	initialState: {
		loading: false,
		error: null,
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// downloadCertificate
			.addCase(downloadCertificate.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(downloadCertificate.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(downloadCertificate.fulfilled, (state, action) => {
				state.loading = false;
				state.error = false;
			})
	}
});

export const certificateActions = certificateSlice.actions;
export default certificateSlice;