import React from "react";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Messages from "./messages/Messages";

function MainDashboard() {
  const { shouldRedirect, redirectTo } = useInspectRoute('/dashboard');

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />
  } else {
    return <Messages />
  }

}

export default MainDashboard;
