const ResponsiveValues = {
  forMobileScreen: "block md:hidden lg:hidden",
  forMobileScreenFlex: "flex md:hidden lg:hidden",

  forDesktopScreen: "hidden md:block lg:block",
  forDesktopScreenFlex: "hidden md:flex lg:flex",

  minHeight: "min-h-[89vh]",

  companyIcon: 'h-[50px]',
  navbar: 'px-8 pt-4 bg-white md:px-14 md:py-2 md:border-b md:border-solid md:border-neutral-200',
  cardScreenStyle: 'bg-white p-8 md:my-12 md:border md:border-solid md:border-neutral-200 md:rounded-lg',
  cardScreenStyleNoSpacing: 'bg-white md:my-12 md:border md:border-solid md:border-neutral-200 md:rounded-lg',
  cardSectionStyle: 'bg-white p-4 my-4 rounded-lg border border-neutral-200 ',
  cardSectionStyleNoPadding: 'bg-white rounded-lg border border-neutral-200 ',
  minWidthCard: "min-w-[90vh]",
  maxWidthCard: "max-w-[90vh]",


  buttonFull: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-6 w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5',
  buttonFullNoColor: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-4 w-full text-base font-semibold text-black whitespace-nowrap bg-white border border-black rounded-3xl w-[281px] max-md:px-5',

  buttonFullNoSpacing: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5',
  buttonFit: 'cursor-pointer text-center justify-center self-end items-center  px-8 py-2 w-fit text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5',
  buttonFitNoColor: 'cursor-pointer text-center justify-center self-end items-center px-8 py-2 w-fit text-base font-semibold text-black whitespace-nowrap bg-white border border-black rounded-3xl w-[281px] max-md:px-5',

  buttonFullSecondary: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-6 w-full text-base font-semibold text-blue-900 whitespace-nowrap bg-white border border-2 border-blue-900 rounded-3xl w-[281px] max-md:px-5',
  buttonFitSecondary: 'cursor-pointer text-center justify-center self-end items-center  px-8 py-2 w-fit text-base font-semibold text-blue-900 whitespace-nowrap bg-blue-100 rounded-3xl w-[281px] max-md:px-5',

  buttonFullSecondaryLightBlue: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-6 w-full text-base font-semibold text-blue-900 whitespace-nowrap bg-[#E0E7FF] rounded-3xl w-[281px] max-md:px-5',
  buttonFitSecondaryLightBlue: 'cursor-pointer text-center justify-center self-end items-center px-8 py-2 w-fit text-base font-semibold text-blue-900 whitespace-nowrap bg-[#E0E7FF] rounded-3xl w-[281px] max-md:px-5',

  buttonSquareFull: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-6 w-full text-base text-white whitespace-nowrap bg-blue-900 rounded-lg w-[281px] max-md:px-5',
  buttonSquareFit: 'cursor-pointer text-center justify-center self-end items-center  px-8 py-2 w-fit text-base text-white whitespace-nowrap bg-blue-900 rounded-lg w-[281px] max-md:px-5',

  buttonSquareFullSecondary: 'cursor-pointer text-center justify-center self-center items-center px-8 py-2 mt-6 w-full text-base text-blue-900 whitespace-nowrap bg-white border border-2 border-blue-900 rounded-lg w-[281px] max-md:px-5',
  buttonSquareFitSecondary: 'cursor-pointer text-center justify-center self-end items-center  px-8 py-2 w-fit text-base  text-blue-900 whitespace-nowrap bg-blue-100 rounded-lg w-[281px] max-md:px-5',

  addMore: 'cursor-pointer text-center justify-center self-end items-center  px-4 py-1 w-fit text-sm text-blue-950 whitespace-nowrap bg-blue-100 rounded-3xl w-[281px] max-md:px-5',

  forTesting: "bg-slate-400 sm:bg-red-400 md:bg-orange-400 lg:bg-yellow-400 xl:bg-lime-600 2xl:bg-sky-700",
}

export default ResponsiveValues; 