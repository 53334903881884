import React, { useState, useEffect } from "react";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import useHonorsAndAwards from "../../../feature/dashboard/professionalProfile/profileEdit/honorsAndAwards/useHonorsAndAwards";
import dateHelper from "../../../utils/dateHelper";
import HonorAwardsItems from "../../../components/Dashboard/profileEdit/HonorAwardsItem";
import HonorAwardsItemEdit from "../../../components/Dashboard/profileEdit/HonorAwardsItemEdit";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";
import InputStyling from "../../../styling/InputStyling";
import ResponsiveValues from "../../../styling/ResponsiveValues";

function HonorAwards() {

  const {
    success, error, loading,
    showAddButton, showEditButton,
    displayedHonorsAndAwards,
    postHonorsAndAwards, setPostHonorsAndAwards,
    thumbnail,
    handleImageUpload,
    handleAddRecord, handleRemoveButton, handleEditButton, handleUpdateButton,
    validationErrorMessage, showNotif, setShowNotif, isSafeToSubmit, validateTitle, validateDescription, validateIssuer, validateDate
  } = useHonorsAndAwards();

  return (
    <>
      <div className="flex flex-col max-md:max-w-full">
        <PageTitle title='Add Honor / Award' description='Showcase your achievements! Add your honors and awards to highlight your notable awards!' />

        <div className="flex justify-between gap-4 px-5">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <div className="mt-4 max-md:max-w-full">
          <div className="relative flex flex-col w-[16%] max-md:ml-0 max-md:w-full">
            <div className={` ${TextStyling.inputLabel}`}>
              School
            </div>
            <div className="mt-1 p-4 w-fit flex flex-col justify-center  self-center md:self-start text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300">
              <img alt="img"
                loading="lazy"
                src={thumbnail?.src || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
                className="self-center"
              />
              {!thumbnail?.src && <div>Issuer Logo</div>}
            </div>
            <input type="file"
              accept="image/*"
              value=""
              onChange={handleImageUpload}
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            />
          </div>

          <div className={`mt-4 md:mt-8 ${TextStyling.inputLabel}`}>
            Award Title
          </div>
          <input
            placeholder="Award Title"
            value={postHonorsAndAwards?.title || ''}
            autoComplete="organization"
            onChange={(e) => {
              setPostHonorsAndAwards({ ...postHonorsAndAwards, title: e.target.value });
              validateTitle(e.target.value);
            }}
            className={`mt-1 ${InputStyling.text}`}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.title?.errorMessage} />


          <div className="flex flex-row items-center gap-4 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1">
              <div className={`md:mt-4 ${TextStyling.inputLabel}`}>
                Name of Issuer
              </div>
              <input
                placeholder="Name of Issuer"
                value={postHonorsAndAwards?.issued_by || ''}
                autoComplete="organization"
                onChange={(e) => {
                  setPostHonorsAndAwards({ ...postHonorsAndAwards, issued_by: e.target.value });
                  validateIssuer(e.target.value);
                }}
                className={`mt-1 ${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.issuer?.errorMessage} />
            </div>

            <div className="flex flex-col flex-1">
              <div className={`md:mt-4 ${TextStyling.inputLabel}`}>
                Date Issued
              </div>
              <input
                type="date"
                placeholder="Date Issued"
                autoComplete="on"
                value={
                  dateHelper.isDatetimeChecker(postHonorsAndAwards?.issued_date) ? dateHelper.datetimeToInputDate(postHonorsAndAwards?.issued_date) : postHonorsAndAwards?.issued_date
                }
                onChange={(e) => {
                  setPostHonorsAndAwards({ ...postHonorsAndAwards, issued_date: e.target.value });
                  validateDate(e.target.value);
                }}
                className={`mt-1 ${InputStyling.text}`}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.date?.errorMessage} />
            </div>

          </div>


          <div className="flex flex-col grow justify-center leading-[121%] text-stone-300 max-md:max-w-full">
            <div className={`md:mt-4  ${TextStyling.inputLabel}`}>
              Description
            </div>
            <textarea
              rows="5"
              placeholder="Description"
              value={postHonorsAndAwards?.description || ''}
              autoComplete="organization"
              onChange={(e) => {
                setPostHonorsAndAwards({ ...postHonorsAndAwards, description: e.target.value });
                validateDescription(e.target.value);
              }}
              className={`mt-1 ${InputStyling.text}`} />
            <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.desription?.errorMessage} />
          </div>
        </div>

        {showAddButton &&
          <div className="flex flex-col justify-center items-end  text-base font-semibold text-white max-md:max-w-full">
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-10 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }

            <button onClick={() => handleAddRecord()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Add Honor / Award
            </button>

            <button onClick={() => handleAddRecord()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Add Honor / Award
            </button>
          </div>
        }

        {showEditButton &&
          <div className="flex flex-col justify-center items-end py-6 mt-4 text-base font-semibold text-white max-md:pl-5 max-md:max-w-full">
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-10 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }
            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
              Update Honor / Award
            </button>

            <button onClick={() => handleUpdateButton()}
              className={`${(isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
              Update Honor / Award
            </button>
          </div>
        }

        {displayedHonorsAndAwards?.length > 0 &&
          <div className="flex flex-col p-4 mt-4 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
            <div className="flex flex-row gap-2 justify-between items-center">
              <div className={`${TextStyling.pageHeader2} w-fit`}>Honors and Awards</div>
              <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1`}></div>
            </div>

            {displayedHonorsAndAwards?.map((item) => <HonorAwardsItemEdit data={item} onEdit={handleEditButton} onDelete={handleRemoveButton} />)}

          </div>
        }
      </div>
    </>
  );
}

export default HonorAwards;
