import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processPayment, confirmPayment, validateCoupon, personalInfo } from "../../feature/authSlice";
import { useNavigate } from "react-router-dom";

const useStripePayment = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [membershipCountryValues, setMembershipCountryValues] = useState({
    currencyName: "USD",
    currencySymbol: "$",
    membershipFeeForDisplay: "195",
    membershipFeeForComputation: 0,
    membershipRenewalFee: "100",
    updated: false,
  });

  const [cardComplete, setCardComplete] = useState(false);
  const [cardError, setCardError] = useState('');

  const [paymentMethod, setPaymentMethod] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [coupon, setCoupon] = useState("");
  const [couponUsedCode, setCouponUsedCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  // const [amount, setAmount] = useState(195);

  const [errorMessage, setErrorMessage] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMembershipValues = async () => {

    setMembershipCountryValues({
      currencyName: 'USD',
      currencySymbol: '$',
      membershipFee: 195,
      membershipRenewalFee: 135,
      membershipFeeForComputation: 195,
      updated: true,
    });

    // const id = auth.user.id || userData.id;

    // await dispatch(membershipValues({ id })).then(data => {
    //   if (data?.payload) {
    //     const x = data?.payload;

    //     setMembershipCountryValues({
    //       currencyName: x.currency_name,
    //       currencySymbol: x.currency_symbol,
    //       membershipFee: x.membership_fee,
    //       membershipRenewalFee: x.membership_renewal_fee,
    //       membershipFeeForComputation: x.membership_fee,
    //       updated: true,
    //     });
    //   }
    // });
  }

  // processPayment
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);

    if (!stripe || !elements) {
      setIsModalOpen(false);
      return;
    } else if (elements.getElement(CardElement) === null) {
      setErrorMessage("Please enter correct payment method.");
      return;
    }

    if (cardComplete === false) {
      setCardError(cardError || 'Please complete payment method.');
      setIsModalOpen(false);
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        setPaymentMethod(paymentMethod);
        const { id } = userData || { id: 0 };
        const data = {
          paymentMethod: paymentMethod.id,
          coupon: couponUsedCode || '',
          membershipFee: membershipCountryValues.membershipFee,
          amount: membershipCountryValues.membershipFeeForComputation - couponValue,
        };

        const response = await dispatch(processPayment({ data: data }));
        // console.log(`data : ${JSON.stringify(data)}`);
        // console.log(`response : ${JSON.stringify(response)}`);

        if (response?.payload?.clientSecret) {

          if (response?.payload?.status === "requires_confirmation") {

            handlesRequiresConfirmation({
              clientSecret: response?.payload?.clientSecret,
              payment_intent_id: response?.payload?.payment_intent_id,
              showConfirmation: true
            });

            // setErrorMessage("An error occurred while processing your payment. Please try again later.");
          } else {
            let udata = JSON.parse(localStorage.getItem('user'));

            if (udata) {
              udata.is_paid = 1;
              localStorage.setItem('user', JSON.stringify(udata));
            } else {
              console.error("User data not found in localStorage");
            }

            navigate('/register/payment-success');
          }

        } else {
          throw new Error("Payment not successful. Please check your details and try again.");
        }
      }
    } catch (err) {
      if (err.response.status === 402) {
        handlesRequiresConfirmation({
          clientSecret: err.response?.data?.clientSecret,
          payment_intent_id: err.response?.data?.payment_intent_id,
          showConfirmation: true
        });
      }
      setErrorMessage("An error occurred while processing your payment. Please try again later.");
    } finally {
      setIsModalOpen(false);
    }

    // if (error) {
    //   setErrorMessage(error.message);
    //   setIsModalOpen(false);
    // } else {
    //   const formData = {
    //     paymentMethod: paymentMethod.id,
    //     stripeToken: token.id,
    //     coupon: couponUsedCode || '',
    //     membershipFee: membershipCountryValues.membershipFee,
    //     amount: membershipCountryValues.membershipFeeForComputation - couponValue,
    //   }

    //   const { id } = userData || { id: 0 };
    //   await dispatch(processPayment({ id, data: { ...formData } }))
    //     .then(data => {
    //       setIsModalOpen(false);

    //       if (data?.payload?.data) {
    //         const udata = JSON.parse(localStorage.getItem('user'));
    //         udata.is_paid = "1";
    //         localStorage.setItem('user', JSON.stringify(udata));
    //         navigate('/register/payment-success');
    //       }
    //     });
    // }
  };

  const handlesRequiresConfirmation = ({ clientSecret, payment_intent_id, showConfirmation }) => {
    setClientSecret(clientSecret);
    setPaymentIntentId(payment_intent_id);
    setShowConfirmation(showConfirmation);
  }

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
    setCardError(event.error ? event.error.message : '');
  };

  const handleValidateCoupon = async () => {
    if (coupon.trim() !== "") {
      const userData = JSON.parse(localStorage.getItem("user"));
      const { id } = userData || { id: 0 };

      await dispatch(validateCoupon({ id, data: { code: coupon } })).then(
        (data) => {
          if (data?.payload?.message === "Coupon code valid") {
            setCouponValue(data?.payload?.data?.amount || 0);
            // setCouponUsedCode(coupon);
          } else {
            setCouponValue(0);
          }
        }
      );
    }
  };

  // confirmPayment
  const handlConfirmPayment = async () => {
    setIsModalOpen(false);
    try {

      const confirmResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      })

      console.log('confirmResult: ' + JSON.stringify(confirmResult));

      if (confirmResult.error) {
        setErrorMessage(confirmResult.error.message);
      } else if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === 'succeeded') {
        let data = {
          payment_intent_id: paymentIntentId,
        }

        const response = await dispatch(confirmPayment({ data: data }));
        console.log('confirmPayment: ' + JSON.stringify(response));

        if (response?.payload?.message === "Receipt sent successfully!") {
          userData.is_paid = 1;
          localStorage.setItem('user', JSON.stringify(userData));
          setShowConfirmation(false);
          console.log("User Info: ", localStorage.getItem('user'));
          navigate('/register/payment-success');
          // navigate('/thankyou');
        }
      }
    }
    catch (error) {
      console.error("Error confirming payment: ", error);
    }
    finally {
      setIsModalOpen(false);
    }
  }

  const stringToFloat = async (strParam) => {
    if (strParam) {
      return parseFloat(strParam.replace(/,/g, ""));
    } else {
      return 0;
    }
  };

  const stringToInt = async (strParam) => {
    if (strParam) {
      return parseInt(strParam.replace(/,/g, ""));
    } else {
      return 0;
    }
  };

  return {
    membershipCountryValues,
    // amount, setAmount,
    handleCardChange, cardComplete, cardError,
    showConfirmation, setShowConfirmation, clientSecret,
    handlConfirmPayment,
    coupon,
    setCoupon,
    couponValue,
    setCouponValue,
    errorMessage,
    handleSubmit,
    handleValidateCoupon,
    handleMembershipValues,
    ismodalOpen,
  };
};

export default useStripePayment;
