import React, { useState, useEffect } from "react";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import useSocialLinks from "../../../feature/dashboard/professionalProfile/profileEdit/socialLinks/useSocialLinks";
import { SocialsList } from "../../../feature/dashboard/professionalProfile/profileEdit/socialLinks/useSocialLinks";

import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import PageTitle from "../../../components/PageTitle";
import TextStyling from "../../../styling/TextStyling";
import InputStyling from "../../../styling/InputStyling";
import ResponsiveValues from "../../../styling/ResponsiveValues";

const SocialLinks = () => {

  const {
    success, error, loading,
    getSocialsList, setSocialsList,
    handleAddButton,
    validationErrorMessage, showNotif, isSafeToSubmit, setShowNotif,
    validateInstagram,
    validateFacebook,
    validateYoutube,
    validateLinkedIn,
    validateDiscord,
    validateTiktok,
  } = useSocialLinks();


  return (
    <>
      <div className="flex flex-col max-md:max-w-full">
        <PageTitle title='Social Links' description='' />

        <div className="flex  gap-2 pr-px pl-20 font-medium text-green-700 leading-[120%] max-md:flex-wrap max-md:pl-5">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." setShowNotif={setShowNotif} />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} setShowNotif={setShowNotif} />}
              </>
            )
          }
        </div>

        <div className="mt-8 md:mt-0 flex flex-col justify-center max-md:max-w-full">
          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaInstagram /> <InputTitle title='Instagram' />
          </div>
          <input
            placeholder="Type your Instagram link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.instagram)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.instagram, e.target.value);
              validateInstagram(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.instagram?.errorMessage} />

          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaFacebookF /> <InputTitle title='Facebook' /></div>
          <input
            placeholder="Type your Facebook link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.facebook)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.facebook, e.target.value);
              validateFacebook(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.facebook?.errorMessage} />

          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaLinkedinIn /> <InputTitle title='LinkedIn' /></div>
          <input
            placeholder="Type your LinkedIn link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.linkedIn)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.linkedIn, e.target.value);
              validateLinkedIn(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.linkedIn?.errorMessage} />

          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaYoutube /> <InputTitle title='Youtube' /></div>
          <input
            placeholder="Type your Youtube link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.youtube)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.youtube, e.target.value);
              validateYoutube(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.youtube?.errorMessage} />

          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaDiscord /> <InputTitle title='Discord' /></div>
          <input
            placeholder="Type your Discord link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.discord)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.discord, e.target.value);
              validateDiscord(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.discord?.errorMessage} />

          <div className={` flex flex-row items-center ${TextStyling.inputLabel}`}>
            <FaTiktok /> <InputTitle title='Tiktok' /></div>
          <input
            placeholder="Type your TikTok link here"
            autoComplete="url"
            className={`mt-1 ${InputStyling.text}`}
            value={getSocialsList(SocialsList?.tiktok)?.link}
            onChange={(e) => {
              setSocialsList(SocialsList?.tiktok, e.target.value);
              validateTiktok(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.tiktok?.errorMessage} />

        </div>


        <div className={`flex flex-col justify-center items-end text-base font-semibold text-white  max-md:max-w-full`}>
          <button onClick={() => handleAddButton()}
            className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forDesktopScreen}
                ${ResponsiveValues.buttonFit}
                mt-10
              `}>
            Submit
          </button>

          <button onClick={() => handleAddButton()}
            className={`${(loading === false && isSafeToSubmit === false) && 'disabled'} 
                ${ResponsiveValues.forMobileScreen}
                ${ResponsiveValues.buttonFull}
              `}>
            Submit
          </button>

          {loading &&
            <LoadingIndicator loading={loading} styling="max-md:px-5 max-md:ml-2" />
          }
        </div>
      </div>
    </>
  );
}

const InputTitle = ({ title }) => {
  return (
    <div className="mx-2 text-base item-center tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
      <div>{title || ''}</div>
    </div>
  );
}

export default SocialLinks;
