import { authAxiosPdf } from "../../../../config/common";

const downloadCertificate = async (id) => {
	try {
		const response = await authAxiosPdf().get(`/api/profile/download-certificate`, {
			responseType: 'blob',
		});

		if (response.status === 200) {
			await blobToPdf(response.data);
		}

	} catch (error) {
		throw error;
	}
};

const blobToPdf = async (blobParams) => {
	// Create a URL for the blob
	const blob = new Blob([blobParams], { type: 'application/pdf' });
	const url = window.URL.createObjectURL(blob);

	// Create a link element
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', 'FullIdCheck_Certificate.pdf');

	// Append to the document and trigger the download
	document.body.appendChild(link);
	link.click();

	// Cleanup
	link.parentNode.removeChild(link);
	window.URL.revokeObjectURL(url);
}

const certificateApi = {
	downloadCertificate,
};

export default certificateApi;