import React from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";

function FooterDash() {
  const navigate = useNavigate();

  const navLinks2 = [
    { text: "Terms & Conditions", to: "/terms-and-conditions" },
    { text: "Anti-Bribery and Corruption Policy", to: "/antibribe-policy" },
    { text: "Privacy Policy", to: "/privacy" },
  ];

  const handleClick = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className={`${ResponsiveValues.forMobileScreen}`}>
        <div className="flex items-center w-full h-8 pt-4 px-2 text-neutral-400 bg-white border-t border-neutral-400 border-solid">
          <div className="flex flex-row justify-between items-center text-xs w-full">
            {navLinks2.map((link, index) => (
              <div
                key={index}
                className="cursor-pointer text-center"
                onClick={() => handleClick(link.to)}
              >
                {link.text}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-cente px-2 pt-2 pb-4 text-neutral-400 text-xs">
          © 2023 Full ID Check All Rights Reserved
        </div>
      </div>

      <div className={`${ResponsiveValues.forDesktopScreen}`}>
        <div className="flex flex-row justify-between items-center min-w-screen w-full pt-4 px-4 bg-white border-t border-neutral-400 border-solid text-neutral-400">
          <div className="flex justify-start">
            © 2023 Full ID Check All Rights Reserved
          </div>
          <div className="flex justify-end gap-2">
            {navLinks2.map((link, index) => (
              <div
                key={index}
                className="cursor-pointer my-auto "
                onClick={() => handleClick(link.to)}
              >
                {link.text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterDash;
