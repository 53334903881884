import { useState } from "react";
import { AES } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../feature/authSlice";
import { useNavigate } from "react-router-dom";
import { data } from "../../data/JobListData";

const useRegistrationForm = () => {
  const [applySuccess, setApplySuccess] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const uniqueJobTypes = [...new Set(data.map((job) => job.type_of_job))];

  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedProfession1, setSelectedProfession1] = useState("");
  const [description, setDescription] = useState("");

  // Function to encrypt the token using Crypto.js
  const encryptToken = (token) => {
    const encryptedToken = AES.encrypt(token, "yourSecretKey").toString();
    return encryptedToken;
  };

  const handleApply = async () => {
    setIsModalOpen(true);

    const formData = {
      fullname: fullName,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      phone_number: registrationPhoneNumber,
      industry: selectedJobType,
      profession: selectedProfession1,
    };

    await dispatch(register(formData)).then((data) => {
      setIsModalOpen(false);
      if (data?.payload?.user) {
        navigate("/register/application-sent");
      }
    });
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectChangeProfession = (event) => {
    setSelectedProfession(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  };

  // ADDED NEW SELECTOR FOR THE DATA job list

  const handleJobTypeChange = (event) => {
    const jobType = event.target.value;
    setSelectedJobType(jobType);
    setSelectedProfession1("");
    setDescription("");
  };

  const handleProfessionChange = (event) => {
    const profession = event.target.value;
    setSelectedProfession1(profession);
    const job = data.find((job) => job.profession === profession);
    setDescription(job ? job.description : "");
  };

  const professionsForSelectedJobType = data
    .filter((job) => job.type_of_job === selectedJobType)
    .map((job) => job.profession);

  return {
    professionsForSelectedJobType,
    handleProfessionChange,
    handleJobTypeChange,
    selectedJobType,
    uniqueJobTypes,
    description,
    selectedProfession1,
    applySuccess,
    setCheckboxChecked,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    registrationPhoneNumber,
    setFullName,
    setPassword,
    setConfirmPassword,
    setIsLengthValid,
    setHasUpperCase,
    setHasLowerCase,
    setHasNumber,
    setHasSpecialChar,
    setPasswordsMatch,
    setEmail,
    setIsValidEmail,
    setRegistrationPhoneNumber,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    setApplySuccess,
    ismodalOpen,
  };
};

export default useRegistrationForm;
