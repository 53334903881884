import React from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveValues from "../../styling/ResponsiveValues";
import TopBarLogo from "./TopBarLogo";
import BacktoLogin from "./BacktoLogin";
import { FaCheckCircle } from "react-icons/fa";
import TextStyling from "../../styling/TextStyling";


function PassUpdateSuccess() {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center min-w-full min-h-screen bg-sky-600">
        <div className={`min-w-[50vh] max-w-[50vh] max-h-[50vh]
            ${ResponsiveValues.cardScreenStyleNoSpacing}
            flex flex-col grow`}>

          <TopBarLogo children={<BacktoLogin />} logoImg={"https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"} />


          <div className="p-6 flex flex-col items-center">
            <FaCheckCircle size={52} className="mt-4 fill-[#0082BA]" />

            <div className={`mt-4 flex justify-center ${TextStyling.pageHeader1}`}>
              Your password has been updated
            </div>

            <div className={`flex justify-center ${TextStyling.pageBody}`}>
              For security purposes we’ve sent an email to
            </div>

            <div className={`flex justify-center ${TextStyling.pageBody}`}>
              your account confirming
              this change.
            </div>


            <button
              className={ResponsiveValues.buttonFull}
              onClick={redirectToLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PassUpdateSuccess;
