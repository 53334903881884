import ResponsiveValues from "../styling/ResponsiveValues";
import TextStyling from "../styling/TextStyling";
import PageTitle from "./PageTitle";

const DestopCardScreen = ({ children, title = '', size = null }) => {
  return (
    <div className={`hidden md:flex justify-center`}>
      <div className={`${size ? size : 'max-w-[90vh]'} ${ResponsiveValues.cardSectionStyleNoPadding} shadow-md `}>

        {/* title */}
        <div className="border-b border-neutral-200">
          <div className="px-8 py-4 flex flex-col justify-between items-start">
            <PageTitle title={title}
              hasSpacing='false'
              customTextStyleDesktop={TextStyling.pageHeader1} />
            {/* <PiCaretUpBold size={25} /> */}
          </div>
        </div>

        {/* <div className={`${ResponsiveValues.forDesktopScreen} h-1/2 border border-neutral-200 flex-1 p-0 m-0 w-full`} /> */}

        {/* Content */}
        <div className="px-8 py-4">
          {children}
        </div>

      </div>
    </div>
  )
}

export default DestopCardScreen